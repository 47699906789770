import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import {
  bulkBranches,
  bulkBranchesEdit,
  AllbranchesGet,
  TableBranchesGet,
  generateExcelDownload,
  stateGets,
  companyTableGet,
} from "../../store/actions/otherActions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  Button,
  Typography,
  Paper,
  Input,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  CircularProgress,
  Card,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import GetAppIcon from "@mui/icons-material/GetApp";

const DownloadExcel = () => {
  const BranchesAddBulkRed = useSelector((state) => state.BranchesAddBulkRed);
  const { loadingtabBulk } = BranchesAddBulkRed;
  const { loadingtabBulkEdit, branchesEditBulkInfo, errorEdit } = useSelector(
    (state) => state.BranchesEditBulkRed
  );
  const { loadingExcelDownload, branchesDownloadInfo, errorBranchesDownload } =
    useSelector((state) => state.ExcelDownloadRed);
  const getCompanyTable = useSelector((state) => state.getCompanyTable);
  const { loadingcompanytable, companyGetTableInfo } = getCompanyTable;
  const getState = useSelector((state) => state.getState);
  const { loadings, stateInfo } = getState;

  const [companyId, setCompanyId] = useState("");
  const [stateId, setStateId] = useState("");
  const [file, setFile] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(companyTableGet());
    dispatch(stateGets());
  }, [dispatch]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = () => {
    if (file) {
      dispatch(bulkBranches(file));
      dispatch(AllbranchesGet());
      dispatch(TableBranchesGet());
    } else {
      toast.error("Please select a file first");
    }
  };
  const handleFileChangeEdit = (e) => {
    setFile(e.target.files[0]);
  };
  const handleUploadEdit = () => {
    if (file) {
      dispatch(bulkBranchesEdit(file));
      dispatch(AllbranchesGet());
      dispatch(TableBranchesGet());
    } else {
      toast.error("Please select a file first");
    }
  };

  const handleDownload = () => {
    if (companyId && stateId) {
      dispatch(generateExcelDownload({ companyId, stateId }));
    } else {
      toast.error("Please provide both Company ID and State ID");
    }
  };
  const handleCompanyChange = (event) => {
    setCompanyId(event.target.value); // Set selected company ID
  };

  const handleStateChange = (event) => {
    setStateId(event.target.value); // Set selected state ID
  };

  const branchData = [
    {
      company: "Name of Company",
      name: "Name of Branch",
      region: "choose one = SouthRegion, NorthRegion,WestRegion, EastRegion",
      isFactoryOrSE: "S&E",
      branchaddress: "123 Street",
      branchstate: "Name of State",
      branchdistrict: "District 1",
      branchpin: "123456",
      branchOpeningDateF: "2025-01-20",
      dateofclosure: "2025-01-20",
      dateofclosureRemark: "Remark",
      noOfEmpBranchF: 100,
      managerNameF1: "Manager 1",
      managerMobNoF1: "1234567890",
      managerEmailF1: "manager@example.com",
      managerAadharNoF1: "1234 5678 9101",
      managerPanF1: "ABCDE1234F",
      licensenumber: "License Number",
      // noOfContractorsF5: 5,
      lis_status_se: 1,
      dor: "2025-01-01",
      doe: "2025-12-31",
      doddr: "2025-01-01",
      managernamelicense: "Manager License Name",
      noe: 10,
      nom: 15,
      nof: 20,
      issuingauth: "Auth 1",
      isNightShiftPermission: "YES/NO",
      licensenumberNSP: "NSP License Number",
      dorNSP: "2025-01-01",
      doeNSP: "2025-12-31",
      doddrNSP: "2025-01-01",
      lis_statusNSP: 1,
      issuingauthNSP: "NSP Auth",
      isOTPermission: "YES/NO",
      licensenumberOTP: "OTP License Number",
      dorOTP: "2025-01-01",
      doeOTP: "2025-12-31",
      doddrOTP: "2025-01-01",
      lis_statusOTP: 1,
      issuingauthOTP: "OTP Auth",
      isTradeLicense: "YES/NO",
      licensenumberTL: "TL License Number",
      dorTL: "2025-01-01",
      doeTL: "2025-12-31",
      doddrTL: "2025-01-01",
      lis_statusTL: 1,
      issuingauthTL: "TL Auth",
      isWeeklyOffExemption: "YES/NO",
      licensenumberWOE: "WOE License Number",
      dorWOE: "2025-01-01",
      doeWOE: "2025-12-31",
      doddrWOE: "2025-01-01",
      lis_statusWOE: 1,
      issuingauthWOE: "WOE Auth",
      //PF
      isPF: "YES/NO",
      pfnumber: "PF Number",
      pfdremark: "PF Number Remark",
      lis_status_pf: 1,
      doc: "PF Date of Coverage",
      pfstate: "PF State",
      pfdistrict: "PF District",
      pfpin: "PF Pincode",
      pfaddressremark: "PF Adress Remark",
      //ESI
      isESI: "YES/NO",
      esinumber: "ESI Number",
      esidremark: "ESI Remark",
      lis_status_esi: 1,
      doc: "ESI Date of Coverage",
      esistate: "ESI State",
      esidistrict: "ESI District",
      esipin: "ESI Pincode",
      esiaddressremark: "ESI Adress Remark",
      //LWF
      isLWF: "YES/NO",
      registrationlwfD3: "LWF Regsitration Number",
      registrationlwfD3remark: " LWF Regsitration Remark",
      lis_status_lwf: 1,
      doregistrationlwfD3: "LWF Date of Coverage",
      //PTR
      isPTR: "YES/NO",
      registrationptrD3: "PTR Regsitration Number",
      registrationptrD3remark: "PTR Regsitration Remark",
      lis_status_ptr: 1,
      doregistrationptrD3: "PTR Date of Registration",
      //PTE
      isPTE: "YES/NO",
      registrationPTED3: "PTE Regsitration Number",
      registrationPTED3remark: "PTE Regsitration Remark",
      lis_status_pte: 1,
      doregistrationPTED3: "PTE Date of Registration",
      //MSME
      isMSME: "YES/NO",
      licensenumberMSME: "MSME License Number",
      licenseremarkMSME: "MSME License Remark",
      lis_status_msme: 1,
      dorMSME: "MSME Date of Registrations",
      issuingauthMSME: "MSME Issuing Authority",
      issuingauthremarkMSME: "MSME Issuing Authority Remark",
      //BOCW
      isBOCW: "YES/NO",
      licensenumberBOCW: "BOCW License Number",
      licenseremarkBOCW: "BOCW License Remark",
      dorBOCW: "BOCW Date of Registrations",
      lis_status_bocw: 1,
      doeBOCW: "BOCW Date of Expiry",
      reasonBOCW: "BOCW reason for License Not Applied",
      doddrBOCW: "BOCW Due Date of Renewal",
      noeBOCW: "BOCW Total Number of the Employees",
      nomBOCW: "BOCW Number of Male Employees",
      nofBOCW: "BOCW Number of Female Employees",
      issuingauthBOCW: "BOCW Issuing Authority",
      issuingauthremarkBOCW: "BOCW Issuing Authority Remarks",
      //ISMW
      isISMW: "YES/NO",
      licensenumberISMW: "ISMW License Number",
      licenseremarkISMW: "ISMW License Remark",
      dorISMW: "ISMW Date of Registrations",
      lis_status_ismw: 1,
      doeISMW: "ISMW Date of Expiry",
      reasonISMW: "ISMW reason for License Not Applied",
      doddrISMW: "ISMW Due Date of Renewal",
      noeISMW: "ISMW Total Number of the Employees",
      nomISMW: "ISMW Number of Male Employees",
      nofISMW: "ISMW Number of Female Employees",
      issuingauthISMW: "ISMW Issuing Authority",
      issuingauthremarkISMW: "ISMW Issuing Authority Remarks",
      //FASSAI
      isFASSAI: "YES/NO",
      licensenumberFASSAI: "FASSAI License Number",
      licenseremarkFASSAI: "FASSAI License Remark",
      dorFASSAI: "FASSAI Date of Registrations",
      lis_status_fassai: 1,
      reasonFASSAI: "FASSAI reason for License Not Applied",
      doeFASSAI: "FASSAI Date of Expiry",
      doddrFASSAI: "FASSAI Due Date of Renewal",
      issuingauthFASSAI: "FASSAI Issuing Authority",
      issuingauthremarkFASSAI: "FASSAI Issuing Authority Remarks",
      //SB
      isSB: "YES/NO",
      licensenumberSB: "SB License Number",
      licenseremarkSB: "SB License Remark",
      dorSB: "SB Date of Registrations",
      lis_status_sb: 1,
      reasonSB: "SB reason for License Not Applied",
      doeSB: "SB Date of Expiry",
      doddrSB: "SB Due Date of Renewal",
      issuingauthSB: "SB Issuing Authority",
      issuingauthremarkSB: "SB Issuing Authority Remarks",
      //is-Contract
      is_contract: "IF Contract Labour Registration then 0 = false or 1 = true",
      contractLabourRegistration: 'Contract Labour Registration',
      contractLabourRegistrationRemarks: 'Contract Labour Registration Remarks',
      contractLabourRegistrationNumber: 'Contract Labour Registration Number',
      dateOfContractReg: 'Date of Registration',
      dateofContractSur: 'Date of Surrender',
      nOofContractors: 'Number of Contractors',
      nOofContractEmp: 'Number of Contract Employees',
      isActive: "1",
    },
  ];

  const exportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Branches");

    // Define headers directly from branchData1 keys (no formatting)
    const headers = Object.keys(branchData[0]).map((key) => ({
      key,
      name: key, // Keep the exact key name from branchData1
    }));

    // Add header row with exact field names
    worksheet.addRow(headers.map((header) => header.name));

    // Apply styles (specific columns in red)
    worksheet.getRow(1).eachCell((cell, colNumber) => {
      const redColumns = [1, 2, 3, 4, 6, 4, 19, 28, 33, 35, 40, 42, 47, 49, 54, 56, 59, 65, 68, 73, 76, 78, 81, 83, 86, 88, 91, 95, 99, 108, 112, 121, 125, 131, 135, 141, 149]; // A=1, G=7, D=4, K=11, W=23, AG=33

      if (redColumns.includes(colNumber)) {
        cell.font = { bold: false, color: { argb: "FF0000" } }; // Red font for specific columns
      } else {
        cell.font = { bold: false }; // No bold for others
      }
      cell.alignment = { horizontal: "center", vertical: "middle" };
    });

    // Add data rows
    branchData.forEach((data) => {
      const rowData = headers.map((header) => data[header.key] || "");
      worksheet.addRow(rowData);
    });

    // Add comments to specific headers
    worksheet.getCell("A1").note = "It is Mandatory";
    worksheet.getCell("B1").note = "It is Mandatory";
    worksheet.getCell("C1").note = "It is Mandatory";
    worksheet.getCell("D1").note = "It is Mandatory and should be S&E";
    worksheet.getCell("E1").note = "Address of the Branch";
    worksheet.getCell("F1").note = "It is Mandatory";
    worksheet.getCell("G1").note = "District of the Branch";
    worksheet.getCell("H1").note = "Pincode of the Branch";
    worksheet.getCell("I1").note = "Opening Date of the Branch";
    worksheet.getCell("J1").note = "Closing Date of the Branch";
    worksheet.getCell("K1").note = "Date of closing remarks of the Branch";
    worksheet.getCell("L1").note = "Total No. of Employee in the Branch";
    worksheet.getCell("M1").note = "Name of the Branch Manager";
    worksheet.getCell("N1").note = "Contact No. of the Branch Manager";
    worksheet.getCell("O1").note = "Email of the Branch Manager";
    worksheet.getCell("P1").note = "Aadhar No. of the Branch Manager";
    worksheet.getCell("Q1").note = "PAN No. of the Branch Manager";
    worksheet.getCell("R1").note = "S&E License Number";
    worksheet.getCell("S1").note = "S&E License Status";
    worksheet.getCell("T1").note = "S&E Date of Registration";
    worksheet.getCell("U1").note = "S&E Date of Expiry";
    worksheet.getCell("V1").note = "S&E Due Date for Renewal";
    worksheet.getCell("W1").note = "S&E Manager Name of the License";
    worksheet.getCell("X1").note = "S&E Number of the Employees";
    worksheet.getCell("Y1").note = "S&E Male of the Employees";
    worksheet.getCell("Z1").note = "S&E Female of the Employees";
    worksheet.getCell("AA1").note = "S&E Issuing Authority";
    worksheet.getCell("AB1").note = "YES OR NO For Night Shift Permission(NSP)";
    worksheet.getCell("AC1").note = "NSP License Number";
    worksheet.getCell("AD1").note = "NSP Date of Registration";
    worksheet.getCell("AE1").note = "NSP Date of Expiry";
    worksheet.getCell("AF1").note = "NSP Due Date for Renewal";
    worksheet.getCell("AG1").note = "1=Not In Scope 2=License Applied 3=License Not-Applied 4=Lifetime";
    worksheet.getCell("AH1").note = "NSP Issuing Authority";
    worksheet.getCell("AI1").note = "YES OR NO For Over Time Permission(OTP)";
    worksheet.getCell("AJ1").note = "OTP License Number";
    worksheet.getCell("AK1").note = "OTP Date of Registration";
    worksheet.getCell("AL1").note = "OTP Date of Expiry";
    worksheet.getCell("AM1").note = "OTP Due Data for Renewal";
    worksheet.getCell("AN1").note = "1=Not In Scope 2=License Applied 3=License Not-Applied 4=Lifetime";
    worksheet.getCell("AO1").note = "OTP Issuing Authority";
    worksheet.getCell("AP1").note = "YES OR NO For Trade License(TL)";
    worksheet.getCell("AQ1").note = "TL License Number";
    worksheet.getCell("AR1").note = "TL Date of Registration";
    worksheet.getCell("AS1").note = "TL Date of Expiry";
    worksheet.getCell("AT1").note = "TL Due Data for Renewal";
    worksheet.getCell("AU1").note = "1=Not In Scope 2=License Applied 3=License Not-Applied 4=Lifetime";
    worksheet.getCell("AV1").note = "TL Issuing Authority";
    worksheet.getCell("AW1").note = "YES OR NO For Week Off Exemption(WOE)";
    worksheet.getCell("AX1").note = "WOE License Number";
    worksheet.getCell("AY1").note = "WOE Date of Registration";
    worksheet.getCell("AZ1").note = "WOE Date of Expiry";
    worksheet.getCell("BA1").note = "WOE Due Data for Renewal";
    worksheet.getCell("BB1").note = "1=Not In Scope 2=License Applied 3=License Not-Applied 4=Lifetime";
    worksheet.getCell("BC1").note = "WOE Issuing Authority";
    worksheet.getCell("BD1").note = "YES/NO For Provident Fund(PF)";
    worksheet.getCell("BE1").note = "PF Registration Number";
    worksheet.getCell("BF1").note = "PF Number Remark";
    worksheet.getCell("BG1").note = "1=Not In Scope 2=License Applied 3=License Not-Applied 4=Lifetime";
    worksheet.getCell("BH1").note = "ESI Date of Coverage";
    worksheet.getCell("BI1").note = "PF State";
    worksheet.getCell("BJ1").note = "PF District";
    worksheet.getCell("BK1").note = "PF Pincode";
    worksheet.getCell("BL1").note = "PF Address Remark";
    worksheet.getCell("BM1").note = "YES/NO For Employees State Insurance (ESI)";
    worksheet.getCell("BN1").note = "ESI Number";
    worksheet.getCell("BO1").note = "ESI Remark";
    worksheet.getCell("BP1").note = "1=Not In Scope 2=License Applied 3=License Not-Applied 4=Lifetime";
    worksheet.getCell("BQ1").note = "ESI State";
    worksheet.getCell("BR1").note = "ESI District";
    worksheet.getCell("BS1").note = "ESI Pincode";
    worksheet.getCell("BT1").note = "ESI Address Remark";
    worksheet.getCell("BU1").note = "YES/NO FOR Labour Welfare Fund(LWF)";
    worksheet.getCell("BV1").note = "LWF Registration Number";
    worksheet.getCell("BW1").note = "LWF Registration Remark";
    worksheet.getCell("BX1").note = "1=Not In Scope 2=License Applied 3=License Not-Applied 4=Lifetime";
    worksheet.getCell("BY1").note = "LWF Date of Coverage";
    worksheet.getCell("BZ1").note = "YES/NO FOR Professional Tax Registration(PTR)";
    worksheet.getCell("CA1").note = "PTR Registration Number";
    worksheet.getCell("CB1").note = "PTR Registration Remark";
    worksheet.getCell("CC1").note = "1=Not In Scope 2=License Applied 3=License Not-Applied 4=Lifetime";
    worksheet.getCell("CD1").note = "PTR Date of Registration";
    worksheet.getCell("CE1").note = "YES/NO FOR Professional Tax Enrollment(PTE)";
    worksheet.getCell("CF1").note = "PTE Registration Number";
    worksheet.getCell("CG1").note = "PTE Registration Remark";
    worksheet.getCell("CH1").note = "1=Not In Scope 2=License Applied 3=License Not-Applied 4=Lifetime";
    worksheet.getCell("CI1").note = "PTE Date of Registration";
    worksheet.getCell("CJ1").note = "YES/NO FOR Micro Small and Medium Enterprises(MSME)";
    worksheet.getCell("CK1").note = "MSME License Number";
    worksheet.getCell("CL1").note = "MSME License Remark";
    worksheet.getCell("CM1").note = "1=Not In Scope 2=License Applied 3=License Not-Applied 4=Lifetime";
    worksheet.getCell("CN1").note = "MSME Date of Registration";
    worksheet.getCell("CO1").note = "MSME Issuing Authority";
    worksheet.getCell("CP1").note = "MSME Issuing Authority Remark";
    worksheet.getCell("CQ1").note = "YES/NO Building and Other Construction Workers Welfare Board (BOCW)";
    worksheet.getCell("CR1").note = "BOCW License Number";
    worksheet.getCell("CS1").note = "BOCW License Remark";
    worksheet.getCell("CT1").note = "BOCW Date of Registration";
    worksheet.getCell("CU1").note = "1=Not In Scope 2=License Applied 3=License Not-Applied 4=Lifetime";
    worksheet.getCell("CV1").note = "BOCW Date of Expiry";
    worksheet.getCell("CW1").note = "BOCW Reason for License Not Applied";
    worksheet.getCell("CX1").note = "BOCW Due Date of Renewal";
    worksheet.getCell("CY1").note = "BOCW Total Number of the Employees";
    worksheet.getCell("CZ1").note = "BOCW Number of Male Employees";
    worksheet.getCell("DA1").note = "BOCW Number of Female Employees";
    worksheet.getCell("DB1").note = "BOCW Issuing Authority";
    worksheet.getCell("DC1").note = "BOCW Issuing Authority Remarks";
    worksheet.getCell("DD1").note = "YES/NO FOR INTER-STATE MIGRANT WORKMEN (ISMW)";
    worksheet.getCell("DE1").note = "ISMW License Number";
    worksheet.getCell("DF1").note = "ISMW License Remark";
    worksheet.getCell("DG1").note = "ISMW Date of Registration";
    worksheet.getCell("DH1").note = "1=Not In Scope 2=License Applied 3=License Not-Applied 4=Lifetime";
    worksheet.getCell("DI1").note = "ISMW Date of Expiry";
    worksheet.getCell("DJ1").note = "ISMW Reason for License Not Applied";
    worksheet.getCell("DK1").note = "ISMW Due Date of Renewal";
    worksheet.getCell("DL1").note = "ISMW Total Number of the Employees";
    worksheet.getCell("DM1").note = "ISMW Number of Male Employees";
    worksheet.getCell("DN1").note = "ISMW Number of Female Employees";
    worksheet.getCell("DO1").note = "ISMW Issuing Authority";
    worksheet.getCell("DP1").note = "ISMW Issuing Authority Remarks";
    worksheet.getCell("DQ1").note = "YES/NO FOR Food Safety and Standards Authority of India (FASSAI)";
    worksheet.getCell("DR1").note = "FASSAI License Number";
    worksheet.getCell("DS1").note = "FASSAI License Remark";
    worksheet.getCell("DT1").note = "FASSAI Date of Registration";
    worksheet.getCell("DU1").note = "1=Not In Scope 2=License Applied 3=License Not-Applied 4=Lifetime";
    worksheet.getCell("DV1").note = "FASSAI Reason for License Not Applied";
    worksheet.getCell("DW1").note = "FASSAI Date of Expiry";
    worksheet.getCell("DX1").note = "FASSAI Due Date of Renewal";
    worksheet.getCell("DY1").note = "FASSAI Issuing Authority";
    worksheet.getCell("DZ1").note = "FASSAI Issuing Authority Remarks";
    worksheet.getCell("EA1").note = "YES/NO FOR Signage Board (SB)";
    worksheet.getCell("EB1").note = "SB License Number";
    worksheet.getCell("EC1").note = "SB License Remark";
    worksheet.getCell("ED1").note = "SB Date of Registration";
    worksheet.getCell("EE1").note = "1=Not In Scope 2=License Applied 3=License Not-Applied 4=Lifetime";
    worksheet.getCell("EF1").note = "SB Reason for License Not Applied";
    worksheet.getCell("EG1").note = "SB Date of Expiry";
    worksheet.getCell("EH1").note = "SB Due Date of Renewal";
    worksheet.getCell("EI1").note = "SB Issuing Authority";
    worksheet.getCell("EJ1").note = "SB Issuing Authority Remarks";
    worksheet.getCell("EK1").note = "If Contract Labour Registration then 0 = false or 1 = true";
    worksheet.getCell("EL1").note = "Contract Labour Registration";
    worksheet.getCell("EM1").note = "Contract Labour Registration Remarks";
    worksheet.getCell("EN1").note = "Contract Labour Registration Number";
    worksheet.getCell("EO1").note = "Date of Registration";
    worksheet.getCell("EP1").note = "Date of Surrender";
    worksheet.getCell("EQ1").note = "Number of Contractors";
    worksheet.getCell("ER1").note = "Number of Contract Employees";
    worksheet.getCell("ES1").note = "Branch active status 0 = Inactive, 1 = Active, 2 = PartialActive";
    // Create file and trigger download
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "BranchDataSE.xlsx");
  };

  const branchData1 = [
    {
      company: "Name of Company",
      name: "Name of Branch",
      region: "choose one = SouthRegion, NorthRegion,WestRegion, EastRegion",
      isFactoryOrSE: "S&E",
      branchaddress: "123 Street",
      branchstate: "Name of State",
      branchdistrict: "District 1",
      branchpin: "123456",
      branchOpeningDateF: "2025-01-20",
      dateofclosure: "2025-01-20",
      dateofclosureRemark: "Remark",
      noOfEmpBranchF: 100,
      managerNameF1: "Manager 1",
      managerMobNoF1: "1234567890",
      managerEmailF1: "manager@example.com",
      managerAadharNoF1: "1234 5678 9101",
      // managerPanF1: "ABCDE1234F",
      // shopsEstLicenseF2: "License Number",
      // noOfContractorsF5: 5,
      licensenumber1: "License Number 1",
      lis_status_factory: 1,
      dor1: "2025-01-01",
      doe1: "2025-12-31",
      doddr1: "2025-01-01",
      managernamelicense1: "Manager License Name 1",
      noe1: 10,
      nom1: 15,
      nof1: 20,
      issuingauth1: "Auth 2",
      licensenumber2: "License Number 2",
      dor2: "2025-01-01",
      issuingauth2: "Auth 3",
      powerHP: "100 HP",
      powerKW: "75 KW",
      ApprovedPower: "Approved Power",
      isNightShiftPermission: "YES/NO",
      licensenumberNSP: "NSP License Number",
      dorNSP: "2025-01-01",
      doeNSP: "2025-12-31",
      doddrNSP: "2025-01-01",
      lis_statusNSP: 1,
      issuingauthNSP: "NSP Auth",
      isOTPermission: "YES/NO",
      licensenumberOTP: "OTP License Number",
      dorOTP: "2025-01-01",
      doeOTP: "2025-12-31",
      doddrOTP: "2025-01-01",
      lis_statusOTP: 1,
      issuingauthOTP: "OTP Auth",
      isTradeLicense: "YES/NO",
      licensenumberTL: "TL License Number",
      dorTL: "2025-01-01",
      doeTL: "2025-12-31",
      doddrTL: "2025-01-01",
      lis_statusTL: 1,
      issuingauthTL: "TL Auth",
      isWeeklyOffExemption: "YES/NO",
      licensenumberWOE: "WOE License Number",
      dorWOE: "2025-01-01",
      doeWOE: "2025-12-31",
      doddrWOE: "2025-01-01",
      lis_statusWOE: 1,
      issuingauthWOE: "WOE Auth",
      //PF
      isPF: "YES/NO",
      pfnumber: "PF Number",
      pfdremark: "PF Number Remark",
      lis_status_pf: 1,
      doc: "2025-01-01",
      pfstate: "PF State",
      pfdistrict: "PF District",
      pfpin: "PF Pincode",
      pfaddressremark: "PF Adress Remark",
      //ESI
      isESI: "YES/NO",
      esinumber: "ESI Number",
      esidremark: "ESI Remark",
      lis_status_esi: 1,
      doc: "2025-01-01",
      esistate: "ESI State",
      esidistrict: "ESI District",
      esipin: "ESI Pincode",
      esiaddressremark: "ESI Adress Remark",
      //LWF
      isLWF: "YES/NO",
      registrationlwfD3: "LWF Regsitration Number",
      registrationlwfD3remark: " LWF Regsitration Remark",
      lis_status_lwf: 1,
      doregistrationlwfD3: "2025-01-01",
      //PTR
      isPTR: "YES/NO",
      registrationptrD3: "PTR Regsitration Number",
      registrationptrD3remark: "PTR Regsitration Remark",
      lis_status_ptr: 1,
      doregistrationptrD3: "PTR Date of Registration",
      //PTE
      isPTE: "YES/NO",
      registrationPTED3: "2025-01-01",
      registrationPTED3remark: "PTE Regsitration Remark",
      lis_status_pte: 1,
      doregistrationPTED3: "2025-01-01",
      //MSME
      isMSME: "YES/NO",
      licensenumberMSME: "MSME License Number",
      licenseremarkMSME: "MSME License Remark",
      lis_status_msme: 1,
      dorMSME: "2025-01-01",
      issuingauthMSME: "MSME Issuing Authority",
      issuingauthremarkMSME: "MSME Issuing Authority Remark",
      //BOCW
      isBOCW: "YES/NO",
      licensenumberBOCW: "BOCW License Number",
      licenseremarkBOCW: "BOCW License Remark",
      dorBOCW: "2025-01-01",
      lis_status_bocw: 1,
      doeBOCW: "2025-01-01",
      reasonBOCW: "BOCW reason for License Not Applied",
      doddrBOCW: "2025-01-01",
      noeBOCW: "BOCW Total Number of the Employees",
      nomBOCW: "BOCW Number of Male Employees",
      nofBOCW: "BOCW Number of Female Employees",
      issuingauthBOCW: "BOCW Issuing Authority",
      issuingauthremarkBOCW: "BOCW Issuing Authority Remarks",
      //ISMW
      isISMW: "YES/NO",
      licensenumberISMW: "ISMW License Number",
      licenseremarkISMW: "ISMW License Remark",
      dorISMW: "2025-01-01",
      lis_status_ismw: 1,
      doeISMW: "2025-01-01",
      reasonISMW: "ISMW reason for License Not Applied",
      doddrISMW: "2025-01-01",
      noeISMW: "ISMW Total Number of the Employees",
      nomISMW: "ISMW Number of Male Employees",
      nofISMW: "ISMW Number of Female Employees",
      issuingauthISMW: "ISMW Issuing Authority",
      issuingauthremarkISMW: "ISMW Issuing Authority Remarks",
      //FASSAI
      isFASSAI: "YES/NO",
      licensenumberFASSAI: "FASSAI License Number",
      licenseremarkFASSAI: "FASSAI License Remark",
      dorFASSAI: "2025-01-01",
      lis_status_fassai: 1,
      reasonFASSAI: "FASSAI reason for License Not Applied",
      doeFASSAI: "2025-01-01",
      doddrFASSAI: "2025-01-01",
      issuingauthFASSAI: "FASSAI Issuing Authority",
      issuingauthremarkFASSAI: "FASSAI Issuing Authority Remarks",
      //SB
      isSB: "YES/NO",
      licensenumberSB: "SB License Number",
      licenseremarkSB: "SB License Remark",
      dorSB: "2025-01-01",
      lis_status_sb: 1,
      reasonSB: "SB reason for License Not Applied",
      doeSB: "2025-01-01",
      doddrSB: "2025-01-01",
      issuingauthSB: "SB Issuing Authority",
      issuingauthremarkSB: "SB Issuing Authority Remarks",
      //is-Contract
      is_contract: "IF Contract Labour Registration then 0 = false or 1 = true",
      contractLabourRegistration: 'Contract Labour Registration',
      contractLabourRegistrationRemarks: 'Contract Labour Registration Remarks',
      contractLabourRegistrationNumber: 'Contract Labour Registration Number',
      dateOfContractReg: '2025-01-01',
      dateofContractSur: '2025-01-01',
      nOofContractors: 'Number of Contractors',
      nOofContractEmp: 'Number of Contract Employees',
      isActive: "1",
    },
  ];

  const exportToExcel1 = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Branches");

    // Define headers directly from branchData1 keys (no formatting)
    const headers = Object.keys(branchData1[0]).map((key) => ({
      key,
      name: key, // Keep the exact key name from branchData1
    }));

    // Add header row with exact field names
    worksheet.addRow(headers.map((header) => header.name));

    // Apply styles (specific columns in red)
    worksheet.getRow(1).eachCell((cell, colNumber) => {
      const redColumns = [
        1, 2, 3, 4, 6, 4, 18, 33, 38, 40, 45, 47, 52, 54, 59, 61, 64, 70, 73, 78, 81, 83, 86, 88, 91, 93, 96, 100, 104, 113, 117, 126, 130, 136, 140, 146, 154
      ]; // A=1, G=7, D=4, K=11, W=23, AG=33

      if (redColumns.includes(colNumber)) {
        cell.font = { bold: true, color: { argb: "FF0000" } }; // Red font for specific columns
      } else {
        cell.font = { bold: false }; // No bold for others
      }
      cell.alignment = { horizontal: "center", vertical: "middle" };
    });

    // Add data rows
    branchData1.forEach((data) => {
      const rowData = headers.map((header) => data[header.key] || "");
      worksheet.addRow(rowData);
    });

    worksheet.getCell("A1").note = "It is Mandatory";
    worksheet.getCell("B1").note = "It is Mandatory";
    worksheet.getCell("C1").note = "It is Mandatory";
    worksheet.getCell("D1").note = "It is Mandatory and should be S&E";
    worksheet.getCell("E1").note = "Address of the Branch";
    worksheet.getCell("F1").note = "It is Mandatory";
    worksheet.getCell("G1").note = "District of the Branch";
    worksheet.getCell("H1").note = "Pincode of the Branch";
    worksheet.getCell("I1").note = "Opening Date of the Branch";
    worksheet.getCell("J1").note = "closing Date of the Branch";
    worksheet.getCell("K1").note = "closing Date Remarks of the Branch";
    worksheet.getCell("L1").note = "Total No. of Employees in the Branch";
    worksheet.getCell("M1").note = "Name of the Branch Manager";
    worksheet.getCell("N1").note = "Contact No. of the Branch Manager";
    worksheet.getCell("O1").note = "Email of the Branch Manager";
    worksheet.getCell("P1").note = "Aadhar No. of the Branch Manager";
    worksheet.getCell("Q1").note = "Factory License Number";
    worksheet.getCell("R1").note = "Factory License Status";
    worksheet.getCell("S1").note = "Factory Date of Registration";
    worksheet.getCell("T1").note = "Factory Date of Expiry";
    worksheet.getCell("U1").note = "Factory Due Date for Renewal";
    worksheet.getCell("V1").note = "Factory Manager Name of the License";
    worksheet.getCell("W1").note = "Factory Number of the Employees";
    worksheet.getCell("X1").note = "Factory Male of the Employees";
    worksheet.getCell("Y1").note = "Factory Female of the Employees";
    worksheet.getCell("Z1").note = "Factory Issuing Authority";
    worksheet.getCell("AA1").note = "Factory Plan License Number";
    worksheet.getCell("AB1").note = "Factory Plan Date of Registration";
    worksheet.getCell("AC1").note = "Factory Plan Issuing Authority";
    worksheet.getCell("AD1").note = "Factory Plan Power in HP";
    worksheet.getCell("AE1").note = "Factory Plan Power in KW";
    worksheet.getCell("AF1").note = "Factory Plan Approved Plan";
    worksheet.getCell("AG1").note = "YES OR NO For Night Shift Permission (NSP)";
    worksheet.getCell("AH1").note = "NSP License Number";
    worksheet.getCell("AI1").note = "NSP Date of Registration";
    worksheet.getCell("AJ1").note = "NSP Date of Expiry";
    worksheet.getCell("AK1").note = "NSP Due Date for Renewal";
    worksheet.getCell("AL1").note = "1=Not In Scope 2=License Applied 3=License Not-Applied 4=Lifetime";
    worksheet.getCell("AM1").note = "NSP Issuing Authority";
    worksheet.getCell("AN1").note = "YES OR NO For Over Time Permission (OTP)";
    worksheet.getCell("AO1").note = "OTP License Number";
    worksheet.getCell("AP1").note = "OTP Date of Registration";
    worksheet.getCell("AQ1").note = "OTP Date of Expiry";
    worksheet.getCell("AR1").note = "OTP Due Date for Renewal";
    worksheet.getCell("AS1").note = "1=Not In Scope 2=License Applied 3=License Not-Applied 4=Lifetime";
    worksheet.getCell("AT1").note = "OTP Issuing Authority";
    worksheet.getCell("AU1").note = "YES OR NO For Over Time Permission (WOE)";
    worksheet.getCell("AV1").note = "WOE License Number";
    worksheet.getCell("AW1").note = "WOE Date of Registration";
    worksheet.getCell("AX1").note = "WOE Date of Expiry";
    worksheet.getCell("AY1").note = "WOE Due Date for Renewal";
    worksheet.getCell("AZ1").note = "1=Not In Scope 2=License Applied 3=License Not-Applied 4=Lifetime";
    worksheet.getCell("BA1").note = "WOE Issuing Authority";
    worksheet.getCell("BB1").note = "YES OR NO For Over Time Permission (TL)";
    worksheet.getCell("BC1").note = "TL License Number";
    worksheet.getCell("BD1").note = "TL Date of Registration";
    worksheet.getCell("BE1").note = "TL Date of Expiry";
    worksheet.getCell("BF1").note = "TL Due Date for Renewal";
    worksheet.getCell("BG1").note = "1=Not In Scope 2=License Applied 3=License Not-Applied 4=Lifetime";
    worksheet.getCell("BH1").note = "TL Issuing Authority";
    worksheet.getCell("BI1").note = "YES OR NO For Provident Fund (PF)";
    worksheet.getCell("BJ1").note = "PF Registration Number";
    worksheet.getCell("BK1").note = "PF Number Remark";
    worksheet.getCell("BL1").note = "1=Not In Scope 2=License Applied 3=License Not-Applied 4=Lifetime";
    worksheet.getCell("BM1").note = "ESI Date of Coverage";
    worksheet.getCell("BN1").note = "PF State";
    worksheet.getCell("BO1").note = "PF District";
    worksheet.getCell("BP1").note = "PF Pincode";
    worksheet.getCell("BQ1").note = "PF Address Remark";
    worksheet.getCell("BR1").note = "YES/NO For Employees State Insurance (ESI)";
    worksheet.getCell("BS1").note = "ESI Number";
    worksheet.getCell("BT1").note = "ESI Remark";
    worksheet.getCell("BU1").note = "1=Not In Scope 2=License Applied 3=License Not-Applied 4=Lifetime";
    worksheet.getCell("BV1").note = "ESI State";
    worksheet.getCell("BW1").note = "ESI District";
    worksheet.getCell("BX1").note = "ESI Pincode";
    worksheet.getCell("BY1").note = "ESI Address Remark";
    worksheet.getCell("BZ1").note = "YES/NO FOR Labour Welfare Fund (LWF)";
    worksheet.getCell("CA1").note = "LWF Registration Number";
    worksheet.getCell("CB1").note = "LWF Registration Remark";
    worksheet.getCell("CC1").note = "1=Not In Scope 2=License Applied 3=License Not-Applied 4=Lifetime";
    worksheet.getCell("CD1").note = "LWF Date of Coverage";
    worksheet.getCell("CE1").note = "YES/NO FOR Professional Tax Registration (PTR)";
    worksheet.getCell("CF1").note = "PTR Registration Number";
    worksheet.getCell("CG1").note = "PTR Registration Remark";
    worksheet.getCell("CH1").note = "1=Not In Scope 2=License Applied 3=License Not-Applied 4=Lifetime";
    worksheet.getCell("CI1").note = "PTR Date of Registration";
    worksheet.getCell("CJ1").note = "YES/NO FOR Professional Tax Enrollment (PTE)";
    worksheet.getCell("CK1").note = "PTE Registration Number";
    worksheet.getCell("CL1").note = "PTE Registration Remark";
    worksheet.getCell("CM1").note = "1=Not In Scope 2=License Applied 3=License Not-Applied 4=Lifetime";
    worksheet.getCell("CN1").note = "PTE Date of Registration";
    worksheet.getCell("CO1").note = "YES/NO FOR Micro Small and Medium Enterprises (MSME)";
    worksheet.getCell("CP1").note = "MSME License Number";
    worksheet.getCell("CQ1").note = "MSME License Remark";
    worksheet.getCell("CR1").note = "1=Not In Scope 2=License Applied 3=License Not-Applied 4=Lifetime";
    worksheet.getCell("CS1").note = "MSME Date of Registrations";
    worksheet.getCell("CT1").note = "MSME Issuing Authority";
    worksheet.getCell("CU1").note = "MSME Issuing Authority Remark";
    worksheet.getCell("CV1").note = "YES/NO Building and Other Construction Workers Welfare Board (BOCW)";
    worksheet.getCell("CW1").note = "BOCW License Number";
    worksheet.getCell("CX1").note = "BOCW License Remark";
    worksheet.getCell("CY1").note = "BOCW Date of Registrations";
    worksheet.getCell("CZ1").note = "1=Not In Scope 2=License Applied 3=License Not-Applied 4=Lifetime";
    worksheet.getCell("DA1").note = "BOCW Date of Expiry";
    worksheet.getCell("DB1").note = "BOCW Reason for License Not Applied";
    worksheet.getCell("DC1").note = "BOCW Due Date of Renewal";
    worksheet.getCell("DD1").note = "BOCW Total Number of the Employees";
    worksheet.getCell("DE1").note = "BOCW Number of Male Employees";
    worksheet.getCell("DF1").note = "BOCW Number of Female Employees";
    worksheet.getCell("DG1").note = "BOCW Issuing Authority";
    worksheet.getCell("DH1").note = "BOCW Issuing Authority Remarks";
    worksheet.getCell("DI1").note = "YES/NO FOR INTER-STATE MIGRANT WORKMEN (ISMW)";
    worksheet.getCell("DJ1").note = "ISMW License Number";
    worksheet.getCell("DK1").note = "ISMW License Remark";
    worksheet.getCell("DL1").note = "ISMW Date of Registrations";
    worksheet.getCell("DM1").note = "1=Not In Scope 2=License Applied 3=License Not-Applied 4=Lifetime";
    worksheet.getCell("DN1").note = "ISMW Date of Expiry";
    worksheet.getCell("DO1").note = "ISMW Reason for License Not Applied";
    worksheet.getCell("DP1").note = "ISMW Due Date of Renewal";
    worksheet.getCell("DQ1").note = "ISMW Total Number of the Employees";
    worksheet.getCell("DR1").note = "ISMW Number of Male Employees";
    worksheet.getCell("DS1").note = "ISMW Number of Female Employees";
    worksheet.getCell("DT1").note = "ISMW Issuing Authority";
    worksheet.getCell("DU1").note = "ISMW Issuing Authority Remarks";
    worksheet.getCell("DV1").note = "YES/NO FOR Food Safety and Standards Authority of India (FSSAI)";
    worksheet.getCell("DW1").note = "FSSAI License Number";
    worksheet.getCell("DX1").note = "FSSAI License Remark";
    worksheet.getCell("DY1").note = "FSSAI Date of Registrations";
    worksheet.getCell("DZ1").note = "1=Not In Scope 2=License Applied 3=License Not-Applied 4=Lifetime";
    worksheet.getCell("EA1").note = "FSSAI Reason for License Not Applied";
    worksheet.getCell("EB1").note = "FSSAI Date of Expiry";
    worksheet.getCell("EC1").note = "FSSAI Due Date of Renewal";
    worksheet.getCell("ED1").note = "FSSAI Issuing Authority";
    worksheet.getCell("EE1").note = "FSSAI Issuing Authority Remarks";
    worksheet.getCell("EF1").note = "YES/NO FOR Signage Board (SB)";
    worksheet.getCell("EG1").note = "SB License Number";
    worksheet.getCell("EH1").note = "SB License Remark";
    worksheet.getCell("EI1").note = "SB Date of Registrations";
    worksheet.getCell("EJ1").note = "1=Not In Scope 2=License Applied 3=License Not-Applied 4=Lifetime";
    worksheet.getCell("EK1").note = "SB Reason for License Not Applied";
    worksheet.getCell("EL1").note = "SB Date of Expiry";
    worksheet.getCell("EM1").note = "SB Due Date of Renewal";
    worksheet.getCell("EN1").note = "SB Issuing Authority";
    worksheet.getCell("EO1").note = "SB Issuing Authority Remarks";
    worksheet.getCell("EP1").note = "If Contract Labour Registration then 0 = false or 1 = true";
    worksheet.getCell("EQ1").note = "Contract Labour Registration";
    worksheet.getCell("ER1").note = "Contract Labour Registration Remarks";
    worksheet.getCell("ES1").note = "Contract Labour Registration Number";
    worksheet.getCell("ET1").note = "Date of Registration";
    worksheet.getCell("EU1").note = "Date of Surrender";
    worksheet.getCell("EV1").note = "Number of Contractors";
    worksheet.getCell("EW1").note = "Number of Contract Employees";
    worksheet.getCell("EX1").note = "Isactive, 0 = Inacttive, 1 = Active, 2 = PartialyActive";

    // Create file and trigger download
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "BranchDataFactory.xlsx");
  };

  return (
    <>
      <div
        className="flex"
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "20px",
        }}
      >
        <div>
          {/* Bulk Branches Upload Paper */}
          <Paper
            elevation={3}
            sx={{ padding: 3, maxWidth: 800, margin: "auto", mt: 4, height: 730 }}
          >
            <Typography variant="h5" align="center" gutterBottom>
              Bulk Branches Upload
            </Typography>

            <Card
              variant="outlined"
              sx={{ background: "#fafafa", padding: 2, marginBottom: 3 }}
            >
              <Typography variant="h6">Upload Instructions</Typography>
              <Typography variant="body1">
                Please follow the guidelines below before uploading:
              </Typography>
              <ul>
                <li>
                  Headings with font <span style={{ color: "red" }}>RED</span> in
                  colour are Mandatory.
                </li>
                <li>
                  Download the sample Excel file to understand the required
                  format.
                </li>
                <li>Ensure that all required fields are filled correctly.</li>
                <li>
                  The file must be in{" "}
                  <strong style={{ color: "red" }}>.xlsx</strong> format.
                </li>
              </ul>
            </Card>

            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="success"
                        startIcon={<GetAppIcon />}
                        onClick={exportToExcel}
                        fullWidth
                      >
                        Download Sample Excel for S&E
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="success"
                        startIcon={<GetAppIcon />}
                        onClick={exportToExcel1}
                        fullWidth
                      >
                        Download Sample Excel for Factory
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Input
                        type="file"
                        onChange={handleFileChange}
                        fullWidth
                        sx={{
                          padding: "10px",
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                          marginTop: "10px",
                        }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Button
                        variant="contained"
                        startIcon={<CloudUploadIcon />}
                        onClick={handleUpload}
                        fullWidth
                        disabled={loadingtabBulk}
                        style={{ backgroundColor: "#013879", color: "white" }}
                      >
                        {loadingtabBulk ? (
                          <CircularProgress size={24} />
                        ) : (
                          "Upload"
                        )}
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
        <div>

          {/* Bulk Branches Edit Paper */}
          <Paper
            elevation={3}
            sx={{ padding: 3, maxWidth: 800, margin: "auto", mt: 4 }}
          >
            <Typography variant="h5" align="center" gutterBottom>
              Bulk Branches Edit
            </Typography>

            <Card
              variant="outlined"
              sx={{ background: "#fafafa", padding: 2, marginBottom: 3 }}
            >
              <Typography variant="h6">Upload Instructions</Typography>
              <Typography variant="body1">
                Please follow the guidelines below before uploading:
              </Typography>
              <ul>
                <li>
                  Headings with font <span style={{ color: "red" }}>RED</span> in
                  colour are Mandatory.
                </li>
                <li>Ensure that all required fields are filled correctly.</li>
                <li>
                  The file must be in{" "}
                  <strong style={{ color: "red" }}>.xlsx</strong> format.
                </li>
                <li style={{ color: 'red' }}>Please Download the Branch Details from below<br /> <strong style={{ color: "rgb(1, 56, 121)", }}>Download Option</strong> for Updated Current Data</li>
              </ul>
            </Card>



            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Input
                        type="file"
                        onChange={handleFileChangeEdit}
                        fullWidth
                        sx={{
                          padding: "10px",
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                          marginTop: "10px",
                        }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Button
                        variant="contained"
                        startIcon={<CloudUploadIcon />}
                        onClick={handleUploadEdit}
                        fullWidth
                        disabled={loadingtabBulkEdit}
                        style={{ backgroundColor: "#013879", color: "white" }}
                      >
                        {loadingtabBulkEdit ? (
                          <CircularProgress size={24} />
                        ) : (
                          "Upload"
                        )}
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <br />
          <Paper>

            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <div className="flex flex-col items-center space-y-6 p-6 bg-gray-50 rounded-lg shadow-lg max-w-md mx-auto">
                        <Typography variant="h5" align="center" gutterBottom className="text-blue-600 font-semibold">
                          Branches Download for Edit/Report
                        </Typography>

                        {/* Company Dropdown */}
                        <FormControl fullWidth variant="outlined" disabled={loadingcompanytable} sx={{ minWidth: 200 }}>
                          <InputLabel>Company</InputLabel>
                          <Select
                            value={companyId}
                            onChange={handleCompanyChange}
                            label="Company"
                            className="shadow-md"
                          >
                            {loadingcompanytable ? (
                              <MenuItem disabled>
                                <CircularProgress size={24} />
                              </MenuItem>
                            ) : (
                              companyGetTableInfo?.map((company) => (
                                <MenuItem key={company._id} value={company._id}>
                                  {company.companyname}
                                </MenuItem>
                              ))
                            )}
                          </Select>
                        </FormControl>

                        {/* State Dropdown */}
                        <FormControl fullWidth variant="outlined" disabled={loadings} sx={{ minWidth: 200 }}>
                          <InputLabel>State</InputLabel>
                          <Select
                            value={stateId}
                            onChange={handleStateChange}
                            label="State"
                            className="shadow-md"
                          >
                            {loadings ? (
                              <MenuItem disabled>
                                <CircularProgress size={24} />
                              </MenuItem>
                            ) : (
                              stateInfo?.map((state) => (
                                <MenuItem key={state._id} value={state._id}>
                                  {state.name}
                                </MenuItem>
                              ))
                            )}
                          </Select>
                        </FormControl>

                        {/* Download Button */}
                        <Button
                          style={{ width: '100%' }}
                          variant="contained"
                          color="primary"
                          onClick={handleDownload}
                          disabled={!companyId || !stateId}
                          className="mt-4"
                        >
                          {loadingcompanytable || loadings ? <CircularProgress size={24} /> : "Download Excel"}
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <br />
        </div>
      </div>
    </>
  );
};

export default DownloadExcel;
