import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { stateGets, CreatingContractor, updateLabourContractor, updateLabourContractorById, getLabourContractorTable } from "../../../store/actions/otherActions";
import { Form, Input, Button, message } from "antd";

const CreateLabourCont = ({ addOrEdit, recordForEdit, setOpenPopup }) => {
    // // console.log("recordForEdit22", recordForEdit);

    const dispatch = useDispatch();
    const { stateInfo = [] } = useSelector((state) => state.getState);

    const [form] = Form.useForm();
    const [selectedState, setSelectedState] = useState("");
    // const [formData, setFormData] = useState({}); // Stores binary files

    const [formData, setFormData] = useState({
        state: "", // Default empty state
    });

    // Handle change for select input
    const handleSelectChange = (e) => {
        const { value } = e.target;
        setSelectedState(value); // Always update state ID
    };

    useEffect(() => {
        if (recordForEdit?.stateId) {
            // console.log("Setting State from recordForEdit:", recordForEdit.stateId);
            setSelectedState(recordForEdit.stateId);
        }
    }, [recordForEdit]);

    useEffect(() => {
        form.setFieldsValue({ state: selectedState });
    }, [selectedState, form]);


    useEffect(() => {
        dispatch(stateGets());
    }, [dispatch]);



    useEffect(() => {
        if (recordForEdit && stateInfo?.length > 0) {
            form.setFieldsValue({
                contractorName: recordForEdit.contractorName || "",
                establishmentName: recordForEdit.establishmentName || "",
                contractorAddress: recordForEdit.contractorAddress || "",
                contEmail: recordForEdit.contEmail || "",
                contPhone: recordForEdit.contPhone || "",
                location: recordForEdit.location || "",
                district: recordForEdit.district || "",
                pin: recordForEdit.pin || "",
                state: recordForEdit.stateId || "",
                pan: recordForEdit.pan || "",
                panFile: recordForEdit.panFile || "",
                panRemark: recordForEdit.panRemark || "",

                gst: recordForEdit.gst || "",
                gstFile: recordForEdit.gstFile || "",
                gstRemark: recordForEdit.gstRemark || "",

                pfRegistration: recordForEdit.pfRegistration || "",
                pfRegistrationFile: recordForEdit.pfRegistrationFile || "",
                pfRegistrationRemark: recordForEdit.pfRegistrationRemark || "",

                esicRegistration: recordForEdit.esicRegistration || "",
                esicRegistrationFile: recordForEdit.esicRegistrationFile || "",
                esicRegistrationRemark: recordForEdit.esicRegistrationRemark || "",

                shopsEstablishment: recordForEdit.shopsEstablishment || "",
                shopsEstablishmentFile: recordForEdit.shopsEstablishmentFile || "",
                shopsEstablishmentRemark: recordForEdit.shopsEstablishmentRemark || "",

                lwfRegistration: recordForEdit.lwfRegistration || "",
                lwfRegistrationFile: recordForEdit.lwfRegistrationFile || "",
                lwfRegistrationRemark: recordForEdit.lwfRegistrationRemark || "",

                professionalTax: recordForEdit.professionalTax || "",
                professionalTaxFile: recordForEdit.professionalTaxFile || "",
                professionalTaxRemark: recordForEdit.professionalTaxRemark || "",

                professionalEnrolment: recordForEdit.professionalEnrolment || "",
                professionalEnrolmentFile: recordForEdit.professionalEnrolmentFile || "",
                professionalEnrolmentRemark: recordForEdit.professionalEnrolmentRemark || "",
                ...recordForEdit
            });

            setSelectedState(recordForEdit.stateId || "");
        }
    }, [recordForEdit, stateInfo, form]);

    // ✅ Handle File Upload
    const handleFileChange = (e, fieldName) => {
        const file = e.target.files[0];
        if (file) {
            setFormData((prev) => ({ ...prev, [fieldName]: file }));
        }
    };

    const handleSubmit = async (values) => {
        const finalData = new FormData();
        let stateToSend = selectedState || values.state || recordForEdit?.stateId || "";

        if (!stateToSend) {
            message.error("Please select a state before submitting.");
            return;
        }

        const { state, ...filteredValues } = values;

        // ✅ Append text fields
        Object.keys(filteredValues).forEach((key) => {
            finalData.append(key, filteredValues[key] ?? "");
        });

        // ✅ Append the selected state
        finalData.append("state", stateToSend);

        // ✅ Append file fields if they exist
        [
            "panFile",
            "gstFile",
            "pfRegistrationFile",
            "esicRegistrationFile",
            "shopsEstablishmentFile",
            "lwfRegistrationFile",
            "professionalTaxFile",
            "professionalEnrolmentFile",
        ].forEach((fileKey) => {
            if (formData[fileKey]) {
                finalData.append(fileKey, formData[fileKey]); // Append only if the file exists
            }
        });

        console.log("Final Data Sent:", Object.fromEntries(finalData.entries()));

        try {
            if (recordForEdit) {
                await dispatch(updateLabourContractorById(finalData, recordForEdit._id));
                dispatch(getLabourContractorTable());
            } else {
                await dispatch(CreatingContractor(finalData));
            }

            form.resetFields();
            setFormData({});
            setSelectedState("");
            setOpenPopup(false);
        } catch (error) {
            console.error("Failed to submit contractor registration:", error);
        }
    };

    return (
        <Form form={form} layout="vertical" onFinish={handleSubmit} style={{ maxWidth: "1000px", margin: "0 auto" }}>
            <table className="table creat_tbl">
                <tbody>
                    <tr>
                        <td colSpan="4" style={{ textAlign: 'center' }}>
                            <h3 style={{ color: "#013879" }}>{recordForEdit ? "Edit Labour Contractor" : "Labour Contractor Registration"}</h3>
                        </td>
                    </tr>

                    {/* <tr>
                        <td colSpan="4" style={{ textAlign: 'center' }}>
                            <h3>Labour Contractor Registration</h3>
                        </td>
                    </tr> */}
                    {/* CONTRACTOR INFO */}
                    <tr>
                        <td colSpan="2">
                            <Form.Item label="Name of the Contractor" value={formData?.contractorName} name="contractorName" rules={[{ required: true }]}>
                                <Input placeholder="Enter Contractor Name" />
                            </Form.Item>
                        </td>
                        <td colSpan="2">
                            <Form.Item label="Establishment Name" name="establishmentName" rules={[{ required: true }]}>
                                <Input placeholder="Enter Establishment Name" />
                            </Form.Item>
                        </td>
                    </tr>

                    <tr>
                        <td colSpan="4">
                            <Form.Item label="Contractor Address" name="contractorAddress" rules={[{ required: true }]}>
                                <Input placeholder="Enter Address" />
                            </Form.Item>
                        </td>
                    </tr>



                    <tr>
                        <td colSpan="2">
                            <Form.Item label="Location" name="location">
                                <Input placeholder="Enter Location" />
                            </Form.Item>
                        </td>
                        <td colSpan="2">
                            <Form.Item label="District" name="district">
                                <Input placeholder="Enter District" />
                            </Form.Item>
                        </td>
                    </tr>

                    <tr>
                        <td colSpan="2">
                            <Form.Item label="State" rules={[{ required: true }]}>
                                <select
                                    className="form-select"
                                    value={selectedState} // Ensure this is only an _id
                                    name="state"
                                    onChange={handleSelectChange}
                                >
                                    <option value="">Select State</option>
                                    {Array.isArray(stateInfo) &&
                                        stateInfo.map((state) => (
                                            <option key={state._id} value={state._id}>
                                                {state.name}
                                            </option>
                                        ))}
                                </select>
                            </Form.Item>
                        </td>
                        <td colSpan="2">
                            <Form.Item label="PIN Code" name="pin">
                                <Input placeholder="Enter PIN Code" maxLength={6} />
                            </Form.Item>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2">
                            <Form.Item label="Contractor's Email" name="contEmail" type="email">
                                <Input placeholder="Enter Contractor's Email" />
                            </Form.Item>
                        </td>
                        <td colSpan="2">
                            <Form.Item label="Contractor's Mobile No." name="contPhone" type="number" maxLength={10}>
                                <Input placeholder="Enter Contractor's Mobile No." />
                            </Form.Item>
                        </td>
                    </tr>

                    {/* FILE UPLOADS */}
                    <tr>
                        <td colSpan="4" style={{ textAlign: 'center' }}>
                            <h4 style={{ color: "#013879" }}>Contractor's Registration Documents</h4>
                        </td>
                    </tr>

                    {[
                        { label: "PAN", name: "pan" },
                        { label: "GST", name: "gst" },
                        { label: "PF Registration", name: "pfRegistration" },
                        { label: "ESIC Registration", name: "esicRegistration" },
                        { label: "Shops and Establishment", name: "shopsEstablishment" },
                        { label: "LWF Registration", name: "lwfRegistration" },
                        { label: "Professional Tax", name: "professionalTax" },
                        { label: "Professional Enrolment", name: "professionalEnrolment" },
                    ].map((field, index) => (
                        <tr key={index}>
                            <td colSpan="2">
                                <Form.Item label={field.label} name={field.name}>
                                    <Input placeholder={`Enter ${field.label} Number`} />
                                </Form.Item>
                            </td>
                            <td>
                                <Form.Item label="Upload File">
                                    <input
                                        type="file"
                                        className="form-control"
                                        accept="image/*,application/pdf"
                                        onChange={(e) => handleFileChange(e, `${field.name}File`)}
                                    />
                                    {recordForEdit?.[`${field.name}File`] && (
                                        <a href={recordForEdit[`${field.name}File`]} target="_blank" rel="noopener noreferrer">
                                            View Existing File
                                        </a>
                                    )}
                                </Form.Item>
                            </td>
                            <td>
                                <Form.Item label="Remarks" name={`${field.name}Remark`}>
                                    <Input.TextArea placeholder="Enter Remarks" rows={1} defaultValue={recordForEdit?.[`${field.name}Remark`] || ""} />
                                </Form.Item>
                            </td>
                        </tr>
                    ))}

                    {/* SUBMIT BUTTON */}
                    <tr>
                        <td colSpan="4">
                            <Form.Item>
                                <Button htmlType="submit" style={{ backgroundColor: "#013879", color: "white" }}>
                                    {recordForEdit ? "Update" : "Submit"}
                                </Button>
                                <Button htmlType="button" onClick={() => form.resetFields()} style={{ marginLeft: "8px" }}>
                                    Reset
                                </Button>
                            </Form.Item>
                        </td>
                    </tr>
                </tbody>
            </table>
        </Form>
    );
};

export default CreateLabourCont;
