import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Checkbox, message } from "antd";
import { stateGets, companyTableGet, branchGetByState, getContractorName, CreatingContractAgreement, getLabourContractAgreementTable, getPrincipleAgreementsById, updatePrincipleAgreementID, CreatingPrincipleAgreement, getPrincipleAgreementTable } from "../../../store/actions/otherActions";
import Multiselect from "multiselect-react-dropdown";
import { FormLabel, styled, FormControl, } from '@mui/material';

// const { option } = select;

const CreatePrinciAgree = ({ recordForEdit }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    // const myElementRefState = useRef(null);
    const myElementRefCompany = useRef(null);
    const myElementRefBranch = useRef(null);

    // Redux State
    const stateInfo = useSelector((state) => state.getState.stateInfo);
    const { companyGetTableInfo } = useSelector((state) => state.getCompanyTable);
    // const { branchInfo } = useSelector((state) => state.getBranch);
    const { contractorNameInfo } = useSelector((state) => state.ContractorNameRed);
    const { PAByIDInfo } = useSelector((state) => state.PrincipleAgreementsByIdRed);
    // console.log("PAByIDInfo", PAByIDInfo);

    const getBranchByState = useSelector((state) => state.getBranchByState);
    const { branchByStateInfo } = getBranchByState;

    // Component State
    const [company, setCompany] = useState("");
    const [companyContractor, setCompanyContractor] = useState("");
    const [state, setState] = useState([]);
    const [labourContractor, setLabourContractor] = useState("");
    const [branch, setBranch] = useState([]);
    const [isCLLicenseApplicable, setIsCLLicenseApplicable] = useState(false);
    const [isApplicableToAllBranches, setIsApplicableToAllBranches] = useState(false);
    const [formData, setFormData] = useState({});

    const formatDateToInput = (isoDate) => {
        if (!isoDate) return ""; // Return an empty string for empty or invalid dates
        const date = new Date(isoDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero
        const day = String(date.getDate()).padStart(2, "0"); // Add leading zero
        return `${year}-${month}-${day}`; // Format as YYYY-MM-DD for input[type="date"]
    };


    useEffect(() => {
        dispatch(stateGets());
        dispatch(companyTableGet());
        dispatch(getContractorName());
    }, [dispatch]);

    useEffect(() => {
        if (recordForEdit && recordForEdit._id) {
            // console.log("Sending ID to API:", recordForEdit._id);
            dispatch(getPrincipleAgreementsById(recordForEdit._id)); // ✅ Sends string `_id`
        }
    }, [dispatch, recordForEdit]);



    const getBbranch = (selectedCompany = company, selectedStates = state) => {
        const companyId = selectedCompany || company;
        const stateIds = Array.isArray(selectedStates) ? selectedStates.map(s => s._id) : [];

        if (companyId && stateIds.length > 0) {
            const postBody = {
                id: companyId,
                stateIds: stateIds,
            };
            dispatch(branchGetByState(postBody));
        }
    };

    useEffect(() => {
        if (recordForEdit && recordForEdit._id) {
            // ✅ Edit Mode: Fetch Data
            dispatch(getPrincipleAgreementsById(recordForEdit._id));
        } else {
            // ✅ Create Mode: Reset Everything
            console.log("🔄 Switching to Create Mode: Resetting form...");

            // 🔹 Reset Ant Design Form
            form.resetFields();

            // 🔹 Clear State Variables
            setCompany("");
            setState([]);
            // setLabourContractor("");
            setBranch([]);
            setIsApplicableToAllBranches(false);
            setIsCLLicenseApplicable(false);
            setFormData({});
        }
    }, [recordForEdit, dispatch, form]);
    useEffect(() => {
        if (!PAByIDInfo || Object.keys(PAByIDInfo).length === 0) {
            console.warn("⏳ PAByIDInfo is not ready yet.");
            return; // ✅ Prevents accessing undefined properties
        }

        console.log("✅ PAByIDInfo Loaded:", PAByIDInfo); // Debugging

        form.setFieldsValue({
            company: PAByIDInfo.company?._id || "",
            state: PAByIDInfo.state ? PAByIDInfo.state.map(s => s._id) : [],
            branch: PAByIDInfo.branch ? PAByIDInfo.branch.map(b => b._id) : [], // ✅ Safe check
            companyContractor: PAByIDInfo.companyContractor?._id || "", // Ensure it's set

        });

        setCompany(PAByIDInfo.company?._id || "");
        setCompanyContractor(PAByIDInfo.companyContractor?._id || "");
        setState(PAByIDInfo.state || []);

        // ✅ Fix: Ensure `branch` exists before mapping
        if (Array.isArray(PAByIDInfo.branch)) {
            setBranch(PAByIDInfo.branch.map(b => ({ _id: b._id, name: b.name })));
        } else {
            console.warn("⚠️ PAByIDInfo.branch is undefined or not an array");
            setBranch([]); // ✅ Prevents crash
        }

    }, [PAByIDInfo, form]);


    console.log("PAByIDInfo.branch:", PAByIDInfo?.branch);
    useEffect(() => {

        if (PAByIDInfo && Object.keys(PAByIDInfo).length > 0) {
            // ✅ Populate Form for Editing
            form.setFieldsValue({
                company: PAByIDInfo.company?._id || "",
                companyContractor: PAByIDInfo.companyContractor?._id || "",
                state: PAByIDInfo.state ? PAByIDInfo.state.map(s => s._id) : [],
                branch: PAByIDInfo.branch ? PAByIDInfo.branch.map(b => b._id) : [],

                agreementNumber: PAByIDInfo.agreementNumber || "",
                agreementStartDate: PAByIDInfo.agreementStartDate ? formatDateToInput(PAByIDInfo.agreementStartDate) : "",
                agreementEndDate: PAByIDInfo.agreementEndDate ? formatDateToInput(PAByIDInfo.agreementEndDate) : "",
                contractWorkNature: PAByIDInfo.contractWorkNature || "",
                contractEmployees: PAByIDInfo.contractEmployees || "",

                isCLLicenseApplicable: PAByIDInfo.isCLLicenseApplicable || false,

                CLRAregNum: PAByIDInfo.CLRAregNum || "",
                CLRAdateOfReg: PAByIDInfo.CLRAdateOfReg ? formatDateToInput(PAByIDInfo.CLRAdateOfReg) : "",
                CLRAnumOfWorkers: PAByIDInfo.CLRAnumOfWorkers || "",
                CLRArepresentive: PAByIDInfo.CLRArepresentive || "",

                formNumber: PAByIDInfo.formNumber || "",
                formNumberFile: PAByIDInfo.formNumberFile || null,
                formNumberRemark: PAByIDInfo.formNumberRemark || "",
                formDate: PAByIDInfo.formDate ? formatDateToInput(PAByIDInfo.formDate) : "",
                contractCommencedDate: PAByIDInfo.contractCommencedDate ? formatDateToInput(PAByIDInfo.contractCommencedDate) : "",

                licenseNumber: PAByIDInfo.licenseNumber || "",
                licenseNumberFile: PAByIDInfo.licenseNumberFile || null,
                licenseNumberRemark: PAByIDInfo.licenseNumberRemark || "",
                licenseDate: PAByIDInfo.licenseDate ? formatDateToInput(PAByIDInfo.licenseDate) : "",
                licenseExpiryDate: PAByIDInfo.licenseExpiryDate ? formatDateToInput(PAByIDInfo.licenseExpiryDate) : "",
                licenseRenewalDueDate: PAByIDInfo.licenseRenewalDueDate ? formatDateToInput(PAByIDInfo.licenseRenewalDueDate) : "",
                noOfWorkersPerLis: PAByIDInfo.noOfWorkersPerLis || "",
                managerName: PAByIDInfo.managerName || "",

                licenseFee: PAByIDInfo.licenseFee || "",
                licenseFeeFile: PAByIDInfo.licenseFeeFile || null,
                licenseFeeRemark: PAByIDInfo.licenseFeeRemark || "",

                securityDeposite: PAByIDInfo.securityDeposite || "",
                securityDepositeFile: PAByIDInfo.securityDepositeFile || null,
                securityDepositeRemark: PAByIDInfo.securityDepositeRemark || "",

                contractWorkStartDate: PAByIDInfo.contractWorkStartDate ? formatDateToInput(PAByIDInfo.contractWorkStartDate) : "",
                commencedNoticeDate: PAByIDInfo.commencedNoticeDate ? formatDateToInput(PAByIDInfo.commencedNoticeDate) : "",
                commencedNoticeFile: PAByIDInfo.commencedNoticeFile || null,
                commencedNoticeRemarks: PAByIDInfo.commencedNoticeRemarks || "",

                contractCompDate: PAByIDInfo.contractCompDate ? formatDateToInput(PAByIDInfo.contractCompDate) : "",

                contractCompNotice: PAByIDInfo.contractCompNotice || "",
                contractCompNoticeFile: PAByIDInfo.contractCompNoticeFile || null,
                contractCompNoticeRemarks: PAByIDInfo.contractCompNoticeRemarks || "",

                appRefundSD: PAByIDInfo.appRefundSD ? formatDateToInput(PAByIDInfo.appRefundSD) : "",
                appRefundSDFile: PAByIDInfo.appRefundSDFile || null,
                appRefundSDRemarks: PAByIDInfo.appRefundSDRemarks || "",
                licenseSurrendeer: PAByIDInfo.licenseSurrendeer ? formatDateToInput(PAByIDInfo.licenseSurrendeer) : "",
            });


            // ✅ Update State Variables
            setCompany(PAByIDInfo.company?._id || "");
            setCompanyContractor(PAByIDInfo.companyContractor?._id || "");
            // setLabourContractor(PAByIDInfo.labourContractor?._id || "");
            setState(PAByIDInfo.state || []);

            // ✅ Fix Branch Mapping (Convert to Correct Structure)
            setBranch(PAByIDInfo.branches?.map(b => ({ _id: b._id, name: b.name })) || []);

            setIsApplicableToAllBranches(PAByIDInfo.isApplicableToAllBranches || false);
            setIsCLLicenseApplicable(PAByIDInfo.isCLLicenseApplicable || false);
        }
    }, [PAByIDInfo, form]);



    // ✅ RESET FORM WHEN SWITCHING TO CREATE MODE
    useEffect(() => {
        if (!recordForEdit) {
            console.log("Switching to Create Mode: Resetting form..."); // Debugging
            form.resetFields();
            setCompany("");
            setState([]);
            // setLabourContractor("");
            setBranch([]);
            setIsApplicableToAllBranches(false);
            setIsCLLicenseApplicable(false);
        }
    }, [recordForEdit, form]);


    // Handle File Uploads
    const handleFileChange = (e, fieldName) => {
        const file = e.target.files[0];
        if (file) {
            setFormData((prev) => ({ ...prev, [fieldName]: file }));
        }
    };

   
    const resetForm = () => {
        console.log("🔄 Resetting form...");

        form.resetFields(); // ✅ Reset Ant Design form fields

        setCompany("");
        setState([]);
        setBranch([]);
        setIsCLLicenseApplicable(false);
        setIsApplicableToAllBranches(false);
        setFormData({});

        // ✅ Clear File Inputs
        document.querySelectorAll("input[type=file]").forEach((input) => (input.value = ""));

        // ✅ Log for Debugging
        console.log("✅ Form successfully reset!");
    };


    const handleSubmit = async (values) => {
        try {
            const finalData = new FormData();

            // ✅ Append only non-empty form values (excluding `state` & `branch`)
            Object.keys(values).forEach((key) => {
                const value = values[key];
                if (!["state", "branch", "company"].includes(key) && value !== "" && value !== null && value !== undefined) {
                    finalData.append(key, value);
                }
            });

            // ✅ Append Company (ONLY ONCE)
            if (company) {
                finalData.append("company", company);
            }
            // finalData.append("company", company);
            // if (companyContractor) {
            //     finalData.append("companyContractor", String(companyContractor.trim()));
            // }
            

            // ✅ Append Checkboxes as Boolean Strings
            finalData.append("isCLLicenseApplicable", isCLLicenseApplicable ? "true" : "false");

            // ✅ Convert State IDs to JSON String
            if (Array.isArray(state) && state.length > 0) {
                const formattedStates = state.map(item => ({ stateId: item._id, name: item.name }));
                finalData.append("state", JSON.stringify(formattedStates));
            } else {
                message.error("Please select at least one state.");
                return;
            }

            // ✅ Convert Branch IDs to JSON String (Only If Not Applicable to All Branches)
            if (!isApplicableToAllBranches) {
                if (Array.isArray(branch) && branch.length > 0) {
                    const formattedBranches = branch.map(branchItem => ({
                        branchId: branchItem?._id || branchItem.id || branchItem,
                        name: branchItem?.name || "Unknown"
                    }));
                    finalData.append("branch", JSON.stringify(formattedBranches));
                } else {
                    finalData.append("branch", JSON.stringify([])); // Ensure an empty array if no branch is selected
                }
            }

            // ✅ Append Files
            Object.keys(formData).forEach((fileKey) => {
                if (formData[fileKey]) {
                    finalData.append(fileKey, formData[fileKey]);
                }
            });

            // ✅ Debugging - Log Final Payload
            console.log("🚀 Final Payload:");
            for (let pair of finalData.entries()) {
                console.log(pair[0], pair[1]);
            }

            // ✅ Check if updating or creating
            if (recordForEdit && recordForEdit._id) {
                console.log("Updating Agreement...");
                await dispatch(updatePrincipleAgreementID(finalData, recordForEdit._id));
                await dispatch(getPrincipleAgreementTable());
            } else {
                console.log("Creating New Agreement...");
                await dispatch(CreatingPrincipleAgreement(finalData));
                await dispatch(getPrincipleAgreementTable());

                // ✅ Reset the Form **AFTER** successful creation
                resetForm();
            }
        } catch (error) {
            console.error("❌ Error submitting form:", error);
        }
    };


    useEffect(() => {
        if (!PAByIDInfo || Object.keys(PAByIDInfo).length === 0) {
            console.warn("⏳ PAByIDInfo is not ready yet.");
            return; // ✅ Prevents accessing undefined properties
        }

        console.log("✅ PAByIDInfo Loaded:", PAByIDInfo); // Debugging

        form.setFieldsValue({
            company: PAByIDInfo.company?._id || "",
            state: PAByIDInfo.state ? PAByIDInfo.state.map(s => s._id) : [],
            branch: PAByIDInfo.branch ? PAByIDInfo.branch.map(b => b._id) : [], // ✅ Safe check
        });

        setCompany(PAByIDInfo.company?._id || "");
        setState(PAByIDInfo.state || []);

        // ✅ Fix: Ensure `branch` is formatted correctly
        if (Array.isArray(PAByIDInfo.branch)) {
            const formattedBranches = PAByIDInfo.branch.map(b => ({
                _id: b._id,
                name: b.name
            }));
            setBranch(formattedBranches); // ✅ Ensures MultiSelect gets the correct format
        } else {
            console.warn("⚠️ PAByIDInfo.branch is undefined or not an array");
            setBranch([]); // ✅ Prevents crash
        }

    }, [PAByIDInfo, form]);






    return (
        
        <Form form={form} layout="vertical" onFinish={handleSubmit} style={{ maxWidth: "1100px", margin: "0 auto" }}>
            <table className="table creat_tbl">
                <tbody>
                    {/* 🔹 Header */}
                    <tr>
                        <td colSpan="4" style={{ textAlign: "center" }}>
                            <h3 style={{ color: "#013879" }}>Principle Contract Agreement</h3>
                        </td>
                    </tr>

                    {/* 🔹 Company & State Selection */}
                    <tr>
                        <td colSpan="3">
                            <Form.Item label="Select Contractor" name="companyContractor" rules={[{ required: true, message: "Company Contractor is required" }]}>
                                <select className="form-select" value={companyContractor} onChange={(e) => {
                                    setCompanyContractor(e.target.value);
                                }}>
                                    <option value="">Select Company</option>
                                    {companyGetTableInfo?.map(item => (
                                        <option key={item._id} value={item._id}>{item.companyname}</option>
                                    ))}
                                </select>
                            </Form.Item>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2">
                            <Form.Item label="Select Principle" name="company" rules={[{ required: true, message: "Company is required" }]}>
                                <select className="form-select" value={company} onChange={(e) => {
                                    setCompany(e.target.value);
                                    getBbranch(e.target.value, state);
                                }}>
                                    <option value="">Select Company</option>
                                    {companyGetTableInfo?.map(item => (
                                        <option key={item._id} value={item._id}>{item.companyname}</option>
                                    ))}
                                </select>
                            </Form.Item>
                        </td>

                        {/* 🔹 State Selection */}
                        {/* <tr> */}
                        <td colSpan="2">
                            <Form.Item label="Select Principle State *" name="state">
                                <Multiselect
                                    options={stateInfo}
                                    displayValue="name"
                                    selectedValues={state}
                                    onSelect={(selectedList) => {
                                        setState(selectedList);
                                        getBbranch(company, selectedList);
                                    }}
                                    onRemove={(selectedList) => {
                                        setState(selectedList);
                                        getBbranch(company, selectedList);
                                    }}
                                    placeholder="Select Principle State"
                                    isObject={true}
                                    showCheckbox={true}
                                    style={{ color: "#013879" }}
                                />
                            </Form.Item>
                        </td>
                    </tr>

                    {/* 🔹 Branch Selection (Disabled until State is Selected) */}
                    <tr>
                        <td colSpan="4">
                            <Form.Item label="Select Principle Branches" name="branch">
                                <div>
                                    <Multiselect
                                        options={branchByStateInfo || []}
                                        displayValue="name"
                                        selectedValues={branch || []} // ✅ Ensure correct structure
                                        onSelect={(selectedList) => setBranch(selectedList)}
                                        onRemove={(selectedList) => setBranch(selectedList)}
                                        placeholder="Select Principle Branches"
                                        isObject={true}
                                        showCheckbox={true}
                                        style={{ color: "#013879" }}
                                        disabled={state.length === 0} // ✅ Disable if no state selected
                                    />
                                    <br />
                                    {/* ✅ "Select All" Button */}
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => setBranch(branchByStateInfo || [])}
                                        disabled={state.length === 0 || !branchByStateInfo || branch.length === branchByStateInfo.length} // ✅ Fully disabled when needed
                                    >
                                        Select All
                                    </button>
                                    {" "}
                                    {/* ✅ "Deselect All" Button */}
                                    <button
                                        type="button"
                                        className="btn btn-danger"
                                        onClick={() => setBranch([])}
                                        disabled={state.length === 0 || branch.length === 0} // ✅ Prevents unnecessary clicks
                                    >
                                        Deselect All
                                    </button>
                                </div>
                            </Form.Item>
                        </td>
                    </tr>


                    {/* 🔹 Agreement Details */}
                    <tr>
                        <td colSpan="8">
                            <Form.Item label="Agreement Number" name="agreementNumber" rules={[{ required: true }]}>
                                <Input placeholder="Enter Agreement Number" />
                            </Form.Item>
                        </td>
                    </tr>

                    <tr>
                        <td colSpan="2">
                            <Form.Item label="Agreement Start Date" name="agreementStartDate" rules={[{ required: true }]}>
                                <input type="date" className="form-control" />
                            </Form.Item>
                        </td>
                        <td colSpan="2">
                            <Form.Item label="Agreement End Date" name="agreementEndDate" rules={[{ required: true }]}>
                                <input type="date" className="form-control" />
                            </Form.Item>
                        </td>
                    </tr>

                    <tr>
                        <td colSpan="4">
                            <Form.Item label="Nature of Contract Work" name="contractWorkNature">
                                <Input.TextArea placeholder="Describe contract work" />
                            </Form.Item>
                        </td>
                    </tr>

                    <tr>
                        <td colSpan="4">
                            <Form.Item label="Number of Contract Employees" name="contractEmployees">
                                <Input type="number" placeholder="Enter number of employees" />
                            </Form.Item>
                        </td>
                    </tr>

                    {/* 🔹 License Applicable Checkbox */}
                    <tr>
                        <td colSpan="4">
                            <Form.Item style={{ textAlign: "center" }}>
                                <Checkbox checked={isCLLicenseApplicable} onChange={(e) => setIsCLLicenseApplicable(e.target.checked)}>
                                    <h4>Is Contract Labor License Applicable?</h4>
                                </Checkbox>
                            </Form.Item>
                        </td>
                    </tr>

                    {/* 🔹 License Details (Only if Applicable) */}
                    {isCLLicenseApplicable && (
                        <>
                            <tr>
                                <td colSpan="2">
                                    <Form.Item label="CLRA Registration Number" name="CLRAregNum">
                                        <Input placeholder="Enter CLRA Registration Number" />
                                    </Form.Item>
                                </td>
                                <td colSpan="2">
                                    <Form.Item label="CLRA Date of Registration" name="CLRAdateOfReg">
                                        <input type="date" className="form-control" />
                                    </Form.Item>
                                </td>
                            </tr>

                            <tr>
                                <td colSpan="2">
                                    <Form.Item label="Number of Workers under CLRA" name="CLRAnumOfWorkers">
                                        <Input type="number" placeholder="Enter Number of Workers" />
                                    </Form.Item>
                                </td>
                                <td colSpan="2">
                                    <Form.Item label="CLRA Representative" name="CLRArepresentive">
                                        <Input placeholder="Enter Representative Name" />
                                    </Form.Item>
                                </td>
                            </tr>


                            {/* 🔹 Contract Commencement */}
                            <tr>
                                <td colSpan="4">
                                    <Form.Item label="Date on which the contract work commenced" name="contractCommencedDate">
                                        <input type="date" className="form-control" />
                                    </Form.Item>
                                </td>
                            </tr>

                            {/* 🔹 License Fee & Security Deposit */}
                            <tr>
                                <td>
                                    <Form.Item label="License Fee" name="licenseFee">
                                        <Input type="number" placeholder="Enter License Fee Amount" />
                                    </Form.Item>
                                </td>
                                <td>
                                    <Form.Item label="Upload License Fee Receipt">
                                        {PAByIDInfo?.licenseFeeFile ? (
                                            <>
                                                <a href={PAByIDInfo.licenseFeeFile} target="_blank" rel="noopener noreferrer">
                                                    View Uploaded File
                                                </a>
                                                <br />
                                            </>
                                        ) : null}
                                        <input type="file" name="licenseFeeFile" className="form-control" accept="image/*,application/pdf" onChange={(e) => handleFileChange(e, "licenseFeeFile")} />
                                    </Form.Item>
                                </td>
                                <td>
                                    <Form.Item label="Remarks" name="licenseFeeRemark">
                                        <Input placeholder="Remarks" />
                                    </Form.Item>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <Form.Item label="Security Deposit" name="securityDeposite">
                                        <Input type="number" placeholder="Enter Security Deposit Amount" />
                                    </Form.Item>
                                </td>
                                <td>
                                    <Form.Item label="Upload Security Deposit Receipt">
                                        {PAByIDInfo?.securityDepositeFile ? (
                                            <>
                                                <a href={PAByIDInfo.securityDepositeFile} target="_blank" rel="noopener noreferrer">
                                                    View Uploaded File
                                                </a>
                                                <br />
                                            </>
                                        ) : null}
                                        <input type="file" name="securityDepositeFile" className="form-control" accept="image/*,application/pdf" onChange={(e) => handleFileChange(e, "securityDepositeFile")} />
                                    </Form.Item>
                                </td>
                                <td>
                                    <Form.Item label="Remarks" name="securityDepositeRemark">
                                        <Input placeholder="Remarks" />
                                    </Form.Item>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="4">
                                    <Form.Item label="Date on which the contract Work Started" name="contractWorkStartDate">
                                        <input type="date" className="form-control" />
                                    </Form.Item>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <Form.Item label="Date of Notice of the Commencement of Contract" name="commencedNoticeDate">
                                        <Input type="date" placeholder="Enter Date of Notice of the Commencement of Contract" />
                                    </Form.Item>
                                </td>
                                <td>
                                    <Form.Item label="Upload Security Deposit Receipt">
                                        {PAByIDInfo?.commencedNoticeFile ? (
                                            <>
                                                <a href={PAByIDInfo.commencedNoticeFile} target="_blank" rel="noopener noreferrer">
                                                    View Uploaded File
                                                </a>
                                                <br />
                                            </>
                                        ) : null}
                                        <input type="file" name="commencedNoticeFile" className="form-control" accept="image/*,application/pdf" onChange={(e) => handleFileChange(e, "commencedNoticeFile")} />
                                    </Form.Item>
                                </td>
                                <td>
                                    <Form.Item label="Remarks" name="commencedNoticeRemarks">
                                        <Input placeholder="Remarks" />
                                    </Form.Item>
                                </td>
                            </tr>
                            {/* only edit part */}
                            {recordForEdit?._id && (
                                <>
                                    <tr>
                                        <td colSpan="4">
                                            <Form.Item label="Date on which the contract work Completed" name="contractCompDate">
                                                <input type="date" className="form-control" />
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Form.Item label="Date of Notice of the Completion of Contract" name="contractCompNotice">
                                                <Input type="date" placeholder="Date of Notice of the Completion of Contract" />
                                            </Form.Item>
                                        </td>
                                        <td>
                                            <Form.Item label="Upload  Notice of the Completion of Contract">
                                                {PAByIDInfo?.commencedNoticeFile ? (
                                                    <>
                                                        <a href={PAByIDInfo.commencedNoticeFile} target="_blank" rel="noopener noreferrer">
                                                            View Uploaded File
                                                        </a>
                                                        <br />
                                                    </>
                                                ) : null}
                                                <input type="file" name="commencedNoticeFile" className="form-control" accept="image/*,application/pdf" onChange={(e) => handleFileChange(e, "contractCompNoticeFile")} />
                                            </Form.Item>
                                        </td>
                                        <td>
                                            <Form.Item label="Remarks" name="contractCompNoticeRemarks">
                                                <Input placeholder="Remarks" />
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Form.Item label="Date of application for the refund of the Security Deposit" name="appRefundSD">
                                                <Input type="date" placeholder="Enter Security Deposit Amount" />
                                            </Form.Item>
                                        </td>
                                        <td>
                                            <Form.Item label="Upload Security Deposit Receipt">
                                                {PAByIDInfo?.securityDepositeFile ? (
                                                    <>
                                                        <a href={PAByIDInfo.securityDepositeFile} target="_blank" rel="noopener noreferrer">
                                                            View Uploaded File
                                                        </a>
                                                        <br />
                                                    </>
                                                ) : null}
                                                <input type="file" name="securityDepositeFile" className="form-control" accept="image/*,application/pdf" onChange={(e) => handleFileChange(e, "securityDepositeFile")} />
                                            </Form.Item>
                                        </td>
                                        <td>
                                            <Form.Item label="Remarks" name="appRefundSDRemarks">
                                                <Input placeholder="Remarks" />
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="4">
                                            <Form.Item label="Date on which the License is surrendered" name="licenseSurrendeer">
                                                <input type="date" className="form-control" />
                                            </Form.Item>
                                        </td>
                                    </tr>
                                </>
                            )}
                        </>
                    )}

                    {/* Submit Button */}
                    {/* <tr> */}
                    <td colSpan="4">
                        <Form.Item>
                            <Button htmlType="submit" style={{ backgroundColor: "#013879", color: "white" }}>
                                Submit
                            </Button>
                            <Button htmlType="button" onClick={() => form.resetFields()} style={{ marginLeft: "8px" }}>
                                Reset
                            </Button>
                        </Form.Item>
                    </td>
                    {/* </tr> */}
                </tbody>
            </table>
        </Form >
    );
};

export default CreatePrinciAgree;
const Spanning = styled(FormLabel)`
color: red;
font-size:13px;
`