import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
// import { fetchDueDates } from '../../store/actions/otherActions';
import * as Yup from 'yup'; // Yup is a JavaScript object schema validator.
import { useFormik } from 'formik'; //formik is third party React form library. It provides basic form programming and validation
import ViewAllPrinciAgree from './ViewAllPrinciAgree';
import CreatePrinciAgree from './CreatePrinciAgree';


// import DueDateTable from './DueDateTable';

function PrincipleContract() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let defaultDate = new Date()
  defaultDate.setDate(defaultDate.getDate())

  const [date, setDate] = useState(defaultDate)



  const calling = () => {
    //   dispatch(categoryGet());
    //   dispatch(fetchDueDates());

  }

  return (

    <React.Fragment>
      <div className='dashboard_wrapper'>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <ul class="nav nav-pills mb-3 bg-light" id="pills-tab" role="tablist">
                <li class="nav-item col-md-6 col-lg-6 col-12" role="presentation">
                  <button class="nav-link w-100 active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true" onClick={calling} style={{ color: 'black' }}>View All Agreement</button>
                </li>

                <li class="nav-item col-md-6 col-lg-6 col-12" role="presentation">
                  <button class="nav-link w-100" id="pills-profile-tab1" data-bs-toggle="pill" data-bs-target="#pills-profile1" type="button" role="tab" aria-controls="pills-profile1" aria-selected="false" style={{ color: 'black' }}>Create New Agreement</button>
                </li>
              </ul>
              <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                  <div class="row">
                    <div class="col-12 col-lg-12">
                      <ViewAllPrinciAgree />
                    </div>
                  </div>
                </div>

                <div class="tab-pane fade" id="pills-profile1" role="tabpanel1" aria-labelledby="pills-profile-tab1">
                  <CreatePrinciAgree />
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    </React.Fragment>
  )
}

export default PrincipleContract;
