import React from 'react'

const Books = () => {
  return (
    <div style={{textAlign:'center', justifyContent:'center'}}>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li>Books</li>
    </div>
  )
}

export default Books