import React, { useState, useEffect, useRef } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { FormLabel, styled, FormControl, InputLabel, MenuItem, Select, Checkbox, ListItemText } from '@mui/material';
import { Modal } from 'antd';
import { stateGets, branchGet, companyGet, commonexecutiveGet, auditGetDataAll, auditOnCreate, checklistGetonCreateAudit, companyTableGet, getLabourContractAgreementNames, getPrincipleAgreementNames, auditGetDataAllLCA, auditGetDataAllPA } from "../../store/actions/otherActions";
import { useDispatch, useSelector } from 'react-redux';
import AuditTable from './AuditTable';
import AuditChecklistTable from './AuditChecklistTable';
import Checkilist from '../Checklist/Checklist'
import checklist from '../../../src/checklist.jpg'
import { Multiselect } from "multiselect-react-dropdown";
import AuditForLCA from './AuditForLCA';
import AuditForPA from './AuditForPA';

const Audit = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [auditor, setAuditor] = useState();
    // const [ExecutiveCommon, setExecutiveCommon] = useState();

    const [ExecutiveCommon, setExecutiveCommon] = useState();
    const [briefExecutiveCommon, setBriefExecutiveCommon] = useState('');
    const [activeTab, setActiveTab] = useState("regular"); // ✅ Default to "regular"



    const [title, setTitle] = useState('');
    const [fileto, setFile] = useState('');
    const [LCAgreement, setLCAgreement] = useState(null);
    const [PAgreement, setPAgreement] = useState(null);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [auditstatus, setAuditStatus] = useState('');
    const [audituploadstatus, setAudituploadstatus] = useState('');
    const [risk, setRisk] = useState('');
    const [endDateDisabled, setEndDateDisabled] = useState(true);
    const [scope, setScope] = useState('');
    const [scopeCE, setScopeCE] = useState('');
    const [briefauditor, setBriefAuditor] = useState('');
    const [value, setValue] = useState('Select Compliance');
    const [state, setState] = useState('');
    const [isLBAOrPA, setIsLBAOrPA] = useState(0);
    const [company, setCompany] = useState('');
    const [branch, setBranch] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [error, setError] = useState('');
    const myElementRefTable = useRef(null);
    const myElementRefTitle = useRef(null);
    const myElementRefIsLBAOrPA = useRef(null);
    const myElementRefState = useRef(null);
    const myElementRefStartdate = useRef(null);
    const myElementRefEnddate = useRef(null);
    const myElementRefRisk = useRef(null);
    const myElementRefAuditStatus = useRef(null);
    const myElementRefScope = useRef(null);
    const myElementRefScopeCE = useRef(null);
    const myElementRefAuditor = useRef(null);
    const myElementRefBriefAuditor = useRef(null);
    const myElementRefExecutiveCommon = useRef(null);
    const myElementRefBriefExecutiveCommon = useRef(null);
    const myElementRefCompany = useRef(null);
    const myElementCompany = useRef(null);
    const myElementRefBranch = useRef(null);
    const myElementRefTableInput = useRef(null);
    const myElementRefTab1 = useRef(null);
    const getState = useSelector((state) => state.getState);
    const { loadings, stateInfo } = getState;
    // const getBranch = useSelector((state) => state.getBranch);
    // const { branchInfo } = getBranch;
    const getBranch = useSelector((state) => state.getBranch);
    const { branchInfo = [], loading } = getBranch;

    const getCompanyTable = useSelector(state => state.getCompanyTable)
    const { loadingcompanytable, companyGetTableInfo } = getCompanyTable;
    const createOnAudit = useSelector((state) => state.createOnAudit);
    const { auditorCreateInfo } = createOnAudit;
    const getAuditor = useSelector((state) => state.getAuditor);
    const { auditorInfo } = getAuditor;

    const getLabourContractAgreementNameRed = useSelector((state) => state.getLabourContractAgreementNameRed);
    const { LCAN_NameInfo } = getLabourContractAgreementNameRed;
    const getPrincipleAgreementNameRed = useSelector((state) => state.getPrincipleAgreementNameRed);
    const { PAN_NameInfo } = getPrincipleAgreementNameRed;

    const getExecutiveCommon = useSelector((state) => state.getExecutiveCommon);
    const { ExecutiveCommonInfo } = getExecutiveCommon;
    const onCreateChecklistAudit = useSelector((state) => state.onCreateChecklistAudit);
    const { loadingoncreate, auditorChecklistInfoOncreate } = onCreateChecklistAudit;
    useEffect(() => {
        dispatch(stateGets());
        // const elementcompanybranch = myElementCompany.current;
        // const postBody = {
        //   id : elementcompanybranch.value
        // }
        // if (elementcompanybranch) {
        //   dispatch(branchGet(postBody));
        // }
        dispatch(companyTableGet());
        dispatch(auditGetDataAll());
        dispatch(auditGetDataAllLCA());
        dispatch(auditGetDataAllPA());
        dispatch(commonexecutiveGet())
        // dispatch(getExecutive())
        dispatch(checklistGetonCreateAudit());
    }, [dispatch]);
    const getBbranchs = (company, state) => {
        // const elementcompanybranch = myElementRefCompany1.current;
        // alert(company);return;
        const postBody = {
            id: company,
            state: state || ''
        }
        dispatch(branchGet(postBody));
    }
    const getAgree = (selectedBranch) => {
        setBranch(selectedBranch); // ✅ Just updates state, API is handled by `useEffect`
    };

    const handleSelect = (selectedList, selectedItem) => {
        setBranch(selectedList.map(item => item.id)); // Store only branch IDs

    };

    const handleRemove = (selectedList, removedItem) => {
        setBranch(selectedList.map(item => item.id)); // Update branch IDs after removal
    };

    const handleAuditTypeChange = (e) => {
        const selectedType = e.target.value;
        setIsLBAOrPA(selectedType);

        // ✅ Call API only when a valid type is selected
        if (selectedType === "1" || selectedType === 1) {
            dispatch(getLabourContractAgreementNames({ branches: branch.map(b => b.id) }));
        } else if (selectedType === "2" || selectedType === 2) {
            dispatch(getPrincipleAgreementNames({ branches: branch.map(b => b.id) }));
        }
    };



    useEffect(() => {
        setAuditor('');
        setTitle('');
        setIsLBAOrPA('');
        setEndDate('');
        setScope('');
        setScopeCE('');
        setBriefAuditor('');
        setState('');
        setCompany('');
        setBranch('');
        setStartDate('');
        setRisk('');
        setAuditStatus('');
        setSelectedRows([]);

        // ✅ SAFELY CHECK IF REF EXISTS BEFORE ACCESSING `.innerText`
        if (myElementRefTitle.current) myElementRefTitle.current.innerText = '';
        if (myElementRefCompany.current) myElementRefCompany.current.innerText = '';
        if (myElementRefState.current) myElementRefState.current.innerText = '';
        if (myElementRefBranch.current) myElementRefBranch.current.innerText = '';
        if (myElementRefIsLBAOrPA.current) myElementRefIsLBAOrPA.current.innerText = '';
        if (myElementRefStartdate.current) myElementRefStartdate.current.innerText = '';
        if (myElementRefEnddate.current) myElementRefEnddate.current.innerText = '';
        if (myElementRefScope.current) myElementRefScope.current.innerText = '';
        if (myElementRefScopeCE.current) myElementRefScopeCE.current.innerText = '';
        if (myElementRefAuditor.current) myElementRefAuditor.current.innerText = '';
        if (myElementRefBriefAuditor.current) myElementRefBriefAuditor.current.innerText = '';
        if (myElementRefExecutiveCommon.current) myElementRefExecutiveCommon.current.innerText = '';
        if (myElementRefBriefExecutiveCommon.current) myElementRefBriefExecutiveCommon.current.innerText = '';

        // ✅ SAFELY CHECK IF REF EXISTS BEFORE ACCESSING `.style`
        if (myElementRefTable.current) {
            myElementRefTable.current.style.display = 'none';
        }

        if (myElementRefTableInput.current) {
            myElementRefTableInput.current.innerText = '';
        }

    }, []);

    const showtable = () => {
        const elementtable = myElementRefTable.current;
        const elementtableinput = myElementRefTableInput.current;
        elementtable.style.display = 'inline';
        // const elementtable = myElementRefTable.current;
        if (elementtableinput) {
            elementtableinput.innerText = '';
        }
    }
    const handleStartDateChange = (event) => {
        const selectedStartDate = event.target.value;
        setStartDate(selectedStartDate);
        if (endDate && selectedStartDate > endDate) {
            setEndDate(null);
        }

    };
    const handleEndDateChange = (event) => {
        const selectedEndDate = event.target.value;
        setEndDate(selectedEndDate);
    };
    console.log("branchInfo", branchInfo);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const elementtitle = myElementRefTitle.current;
        const elementcompany = myElementRefCompany.current;
        const elementstate = myElementRefState.current;
        const elementbranch = myElementRefBranch.current;
        const elementIsLBAOrPA = myElementRefIsLBAOrPA.current;
        const elementsdate = myElementRefStartdate.current;
        const elementedate = myElementRefEnddate.current;
        const elementrisk = myElementRefRisk.current;
        // const elementauditstatus = myElementRefAuditStatus.current;
        const elementscope = myElementRefScope.current;
        const elementscopeCE = myElementRefScopeCE.current;
        const elementauditor = myElementRefAuditor.current;
        const elementbauditor = myElementRefBriefAuditor.current;
        const elementexecutivecommon = myElementRefExecutiveCommon.current;
        const elementbexecutivecommon = myElementRefBriefExecutiveCommon.current;
        let i = true;
        //alert(typeof(title))
        if (title === '') {
            // alert(elementtitle)
            elementtitle.focus();
            elementtitle.innerText = 'Please select a Title!';
            i = false;
        }
        else {
            // elementtitle.style.display='none';
            elementtitle.innerText = '';
        }
        // alert(i+'1')
        if (company === '') {
            elementcompany.focus();
            elementcompany.innerText = 'Please select a Company!';
            i = false;
        }
        else {
            // elementcompany.style.display='none';
            elementcompany.innerText = '';
        }
        // alert(i+'2')
        if (state === '') {
            elementstate.focus();
            elementstate.innerText = 'Please select a State!';
            i = false;
        }
        else {
            // elementstate.style.display='none';
            elementstate.innerText = '';
        }
        // alert(i+'3')
        if (branch === '') {
            elementbranch.focus();
            elementbranch.innerText = 'Please select a Branch!';
            i = false;
        }
        else {
            // elementbranch.style.display='none';
            elementbranch.innerText = '';
        }
        // alert(i+'4')
        if (isLBAOrPA === 0) {
            elementIsLBAOrPA.focus();
            elementIsLBAOrPA.innerText = 'Please select a Audit Type!';
            i = false;
        }
        else {
            // elementIsLBAOrPA.style.display='none';
            elementIsLBAOrPA.innerText = '';
        }
        if (startDate === '') {
            elementsdate.focus();
            elementsdate.innerText = 'Please select a Start Date!';
            i = false;
        }
        else {
            // elementsdate.style.display='none';
            elementsdate.innerText = '';
        }
        // alert(i+'5')
        if (endDate === '') {
            elementedate.focus();
            elementedate.innerText = 'Please select a End date!';
            i = false;
        }
        else {
            elementedate.innerText = '';
            // elementedate.style.display='none';
        }
        // alert(i+'6')
        // if(risk === '' )
        // {
        //     elementrisk.focus();
        //     elementrisk.innerText='Please select a Risk!';
        //     i=false;
        // }
        // else{
        //     // elementrisk.style.display='none';
        //     elementrisk.innerText='';
        // } 
        // alert(i+'7')
        // if(auditstatus === '' )
        // {
        //     elementauditstatus.focus();
        //     elementauditstatus.innerText='Please select a Audit Status!';
        //     i=false;
        // }
        // else{
        //     // elementauditstatus.style.display='none';
        //     elementauditstatus.innerText='';
        // }
        // alert(i+'8')
        if (scope === '') {
            elementscope.focus();
            elementscope.innerText = 'Please select a Scope!';
            i = false;
        }
        else {
            // elementscope.style.display='none';
            elementscope.innerText = '';
        }
        if (scopeCE === '') {
            elementscopeCE.focus();
            elementscopeCE.innerText = 'Please select a Scope!';
            i = false;
        }
        else {
            // elementscopeCE.style.display='none';
            elementscopeCE.innerText = '';
        }
        // alert(i+'9')
        if (auditor === '') {
            elementauditor.focus();
            elementauditor.innerText = 'Please select an Auditor!';
            i = false;
        }
        else {
            // elementauditor.style.display='none';
            elementauditor.innerText = '';
        }
        // alert(i+'10')
        if (briefauditor === '') {
            elementbauditor.focus();
            elementbauditor.innerText = 'Please select a brief of Auditor!';
            i = false;
        }
        else {
            // elementbauditor.style.display='none';
            elementbauditor.innerText = '';
        }
        if (ExecutiveCommon === '') {
            elementexecutivecommon.focus();
            elementexecutivecommon.innerText = 'Please select an Auditor!';
            i = false;
        }
        else {
            // elementauditor.style.display='none';
            elementexecutivecommon.innerText = '';
        }
        // alert(i+'10')
        if (briefExecutiveCommon === '') {
            elementbexecutivecommon.focus();
            elementbexecutivecommon.innerText = 'Please select a brief of Auditor!';
            i = false;
        }
        else {
            // elementbauditor.style.display='none';
            elementbexecutivecommon.innerText = '';
        }
        // alert(i+'11')
        const selectedRowIds = selectedRows.map((row) => row.id);
        console.log("me2", selectedRowIds);
        const elementtable = myElementRefTable.current;
        const elementtableinput = myElementRefTableInput.current;

        if (elementtable.style.display === 'none') {
            // alert(elementtableinput)
            elementtableinput.innerText = 'Please click on Select Checklist!';
            i = false;
        }
        else if (selectedRows.length === 0) {
            // Prompt user to select at least one item
            Modal.error({
                title: 'Error',
                content: 'Please click Add Button or select at least one checklist from list.',
            });
            i = false;
        }
        // alert(i)
        if (i === true) {
            // showtable();
            // alert('start')
            const formData = new FormData();
            formData.append("title", title);
            formData.append("state", state);
            formData.append("company", company);
            // formData.append("branch", branch);
            // branch.forEach((branchId, index) => {
            //     console.log("branchId",branchId);

            //     formData.append(`branch[${index}]`, branchId);
            // });
            if (Array.isArray(branch) && branch.length > 0) {
                const branchIds = branch.map(b => b.id); // Extract only the `id` from each object
                branchIds.forEach((branchId, index) => {
                    formData.append(`branch[${index}]`, branchId);
                });
            } else {
                formData.append("branch", ""); // If no branch selected, send an empty string or handle as required
            }
            // formData.append("isLBAOrPA", isLBAOrPA);
            formData.append("isLBAOrPA", isLBAOrPA ? isLBAOrPA : 0);
            formData.append("LCAgreement", LCAgreement);
            formData.append("PAgreement", PAgreement);
            formData.append("start_date", startDate);
            formData.append("end_date", endDate);
            formData.append("executive", '659d4f2609c9923c9e7b8f72');
            formData.append("auditor", auditor);
            formData.append("ExecutiveCommon", ExecutiveCommon);
            formData.append("scope", scope);
            formData.append("briefauditor", briefauditor);
            formData.append("briefExecutiveCommon", briefExecutiveCommon);
            // formData.append("checkboxlist", selectedRowIds);
            selectedRowIds.forEach((id, index) => {
                formData.append(`checkboxlist[${index}]`, id);
            });
            formData.append("risk", risk);
            formData.append("auditstatus", auditstatus);
            formData.append("audituploadstatus", audituploadstatus);
            // alert('end')
            dispatch(auditOnCreate(formData,));
            // alert('after')
            setAuditor('');
            setTitle('');
            setStartDate('');
            setEndDate('');
            setScope('');
            setIsLBAOrPA(0);
            setLCAgreement('');
            setPAgreement('');
            setScopeCE('');
            setBriefAuditor('');
            setState('');
            setCompany('');
            setBranch('');
            setRisk('');
            setAuditStatus('');
            setSelectedRows([])
            const elementtitle = myElementRefTitle.current;
            const elementcompany = myElementRefCompany.current;
            const elementstate = myElementRefState.current;
            const elementbranch = myElementRefBranch.current;
            const elementIsLBAOrPA = myElementRefIsLBAOrPA.current;
            const elementsdate = myElementRefStartdate.current;
            const elementedate = myElementRefEnddate.current;
            // const elementrisk = myElementRefRisk.current;
            // const elementauditstatus = myElementRefAuditStatus.current;
            const elementscope = myElementRefScope.current;
            const elementscopeCE = myElementRefScopeCE.current;
            const elementauditor = myElementRefAuditor.current;
            const elementbauditor = myElementRefBriefAuditor.current;
            const elementexecutivecommon = myElementRefExecutiveCommon.current;
            const elementbexecutivecommon = myElementRefBriefExecutiveCommon.current;
            // alert(typeof(myElementRefTable))
            if (typeof (myElementRefTable) === 'object') {
                elementtable.style.display = 'none';
            }
            const elementtableinput = myElementRefTableInput.current;
            if (elementtable) {
                elementtableinput.innerText = '';
            }

            elementtitle.innerText = '';
            elementcompany.innerText = '';
            elementstate.innerText = '';
            elementbranch.innerText = '';
            elementIsLBAOrPA.innerText = '';
            elementsdate.innerText = '';
            elementedate.innerText = '';
            // elementrisk.innerText='';
            // elementauditstatus.innerText='';
            elementscope.innerText = '';
            elementscopeCE.innerText = '';
            elementauditor.innerText = '';
            elementbauditor.innerText = '';
            elementexecutivecommon.innerText = '';
            elementbexecutivecommon.innerText = '';
            unsetRefValue(elementtitle);
            unsetRefValue(elementcompany);
            unsetRefValue(elementstate);
            unsetRefValue(elementbranch);
            unsetRefValue(elementIsLBAOrPA);
            // unsetRefValue(elementrisk);
            // unsetRefValue(elementauditstatus);
            unsetRefValue(elementauditor);
            unsetRefValue(elementbauditor);
            unsetRefValue(elementexecutivecommon);
            unsetRefValue(elementbexecutivecommon);
            unsetRefValue(elementtableinput);
            unsetRefValue(elementtable);
            // setTimeout(() => {
            //     navigate(0);
            // }, 5000);

            const elementTab1 = myElementRefTab1.current;
            if (elementTab1) {
                elementTab1.click();
            }
        }

        console.log('Selected Row IDs:', selectedRowIds);
        // dispatch(auditOnCreate())
    }
    const unsetRefValue = (ref) => {
        ref = null;
    };
    const viewall = () => {
        dispatch(auditGetDataAll());
    }
    const createnew = () => {
        setTimeout(() => {
            dispatch(checklistGetonCreateAudit());
        }, 2000);
    }
    const viewall1 = () => {
        dispatch(auditGetDataAllLCA());
    }
    const viewall2 = () => {
        dispatch(auditGetDataAllPA());
    }
    const tocategorypage = () => {
        navigate('/dashboard')
    };
    const filter = () => {
        const elementstate = myElementRefState.current;
        const elementcompany = myElementRefCompany.current;
        const elementbranch = myElementRefBranch.current;
        // const elementdate = myElementRefDate.current;
        const postBody = {
            // created_at:elementdate.value,
            state: elementstate.value,
            company: elementcompany.value,
            branch: elementbranch.value
        }
        // dispatch(checklistCreateFilters(postBody));
    }
    const handleProductImageUpload = (e) => {
        const file = e.target.files[0];
        // alert(JSON.stringify(file))
        setFile(file);
        TransformFileData(file);
    };
    //reading image using The FileReader object lets web applications asynchronously read the contents of files (or raw data buffers) stored on the user's computer, using File or Blob objects to specify the file or data to read.
    const TransformFileData = (file) => {
        const reader = new FileReader();
        const fileType = file.type;
        let types = false;
        if (fileType !== "image/jpeg" && fileType !== "image/bmp" && fileType !== "image/jpg" && fileType !== "image/png" && fileType !== "application/pdf") {
            types = true;
            alert('You can only upload JPG/JPEG/PNG/BMP/PDF file!');
            return false;
        }
        else {
            types = false;
        }
        if (types === false) {
            //   alert('sdsds')
            if (file) {
                reader.readAsDataURL(file);
                reader.onloadend = () => {
                    //   setImage(reader.result);
                }
            }
        }
        else {
            // setImage("");
        }

    };
    return (
        <React.Fragment>
            <div className='dashboard_wrapper'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className='card'>
                                <div className='card-body'>
                                    <ul className="nav nav-pills mb-3 bg-light" id="pills-tab" role="tablist">
                                        <li className="nav-item col-md-6 col-lg-6 col-12" role="presentation" style={{ border: "1px solid lightgrey", borderRadius: "0.375rem" }}>
                                            <button
                                                className={`nav-link w-100 ${activeTab === "regular" ? "active" : ""}`}
                                                onClick={() => setActiveTab("regular")}
                                            >
                                                View All Regular Audit
                                            </button>
                                        </li>

                                        <li className="nav-item col-md-6 col-lg-6 col-12" role="presentation" style={{ border: "1px solid lightgrey", borderRadius: "0.375rem" }}>
                                            <button
                                                className={`nav-link w-100 ${activeTab === "create" ? "active" : ""}`}
                                                onClick={() => setActiveTab("create")}
                                            >
                                                Create New
                                            </button>
                                        </li>

                                        <li className="nav-item col-md-6 col-lg-6 col-12" role="presentation" style={{ border: "1px solid lightgrey", borderRadius: "0.375rem" }}>
                                            <button
                                                className={`nav-link w-100 ${activeTab === "labour" ? "active" : ""}`}
                                                onClick={() => setActiveTab("labour")}
                                            >
                                                View All Labour Agreement Audit
                                            </button>
                                        </li>

                                        <li className="nav-item col-md-6 col-lg-6 col-12" role="presentation" style={{ border: "1px solid lightgrey", borderRadius: "0.375rem" }}>
                                            <button
                                                className={`nav-link w-100 ${activeTab === "principle" ? "active" : ""}`}
                                                onClick={() => setActiveTab("principle")}
                                            >
                                                View All Principle Agreement Audit
                                            </button>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="pills-tabContent">
                                        <div className={`tab-pane fade ${activeTab === "regular" ? "show active" : ""}`} id="pills-home">
                                            <AuditTable />
                                        </div>

                                        <div className={`tab-pane fade ${activeTab === "labour" ? "show active" : ""}`} id="pills-labour">
                                            <AuditForLCA />
                                        </div>

                                        <div className={`tab-pane fade ${activeTab === "principle" ? "show active" : ""}`} id="pills-principle">
                                            <AuditForPA />
                                        </div>

                                        {/* <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab"> */}
                                        <div className={`tab-pane fade ${activeTab === "create" ? "show active" : ""}`} id="pills-create">

                                            <form class="row g-3" method="post" enctype="multipart/form-data" onSubmit={handleSubmit} novalidate="novalidate">
                                                <div className="col-md-12">
                                                    <h4 className='text-center my-2'>General Details</h4>
                                                </div>
                                                <div className="col-md-3">
                                                    <label for="inputtitle" className="form-label">Title</label>
                                                    <input type="text" id="title" name="title" aria-label="Default select example" className="form-control" placeholder='Write here' value={title} onChange={(e) => setTitle(e.target.value)} required />
                                                    <Spanning ref={myElementRefTitle}></Spanning>
                                                </div>
                                                <div className="col-md-3">
                                                    <label for="inputcompany" className="form-label">Select Company</label>
                                                    <select className="form-select" aria-label="Default select example" id="company" name="company" value={company} onChange={(e) => {
                                                        const selectedCompany = e.target.value;
                                                        setCompany(selectedCompany);
                                                        getBbranchs(selectedCompany, state); // Pass current state value
                                                    }} required>
                                                        <option value="">Select Company</option>
                                                        {companyGetTableInfo && companyGetTableInfo.length > 0 &&
                                                            companyGetTableInfo.map((item) => (
                                                                <option key={item._id} value={item._id}>
                                                                    {item.companyname}
                                                                </option>
                                                            ))}
                                                    </select>
                                                    <Spanning ref={myElementRefCompany}></Spanning>
                                                </div>
                                                <div className="col-md-3">
                                                    <label for="" className="form-label">Select State</label>
                                                    <select className="form-select" aria-label="Default select example" id="states" value={state} name="state" onChange={(e) => {
                                                        const selectedState = e.target.value;
                                                        setState(selectedState);
                                                        getBbranchs(company, selectedState); // Pass current company value
                                                    }} required>
                                                        <option value="">Select State</option> {/*onBlur={handlestateChange}*/}
                                                        {stateInfo != 'undefind' && stateInfo?.length > 0 && stateInfo.map(item =>
                                                            <option value={item._id}>{item.name}</option>
                                                        )};
                                                    </select>
                                                    <Spanning id="states" ref={myElementRefState}></Spanning>
                                                </div>
                                                <div className="col-md-3">
                                                    <InputLabel>Select Branches</InputLabel>
                                                    <FormControl fullWidth>
                                                        {/* <Multiselect
                                                            options={branchInfo} // Options to display in the dropdown
                                                            selectedValues={branchInfo.filter(b => branch.includes(b.id))} // Pre-selected values
                                                            onChange={(e) => {
                                                                const selectedBranch = e.target.value;
                                                                getAgree(selectedBranch); // Pass current state value
                                                            }}
                                                            onSelect={handleSelect} // Function when item is selected
                                                            onRemove={handleRemove} // Function when item is removed
                                                            displayValue="name" // Property to display in the dropdown
                                                        /> */}
                                                        <Multiselect
                                                            options={branchInfo} // ✅ Available branch options
                                                            selectedValues={branch} // ✅ Controlled state
                                                            onSelect={(selectedList) => getAgree(selectedList)} // ✅ Update state
                                                            onRemove={(selectedList) => getAgree(selectedList)} // ✅ Update state
                                                            displayValue="name" // ✅ Show branch names
                                                            showCheckbox={true} // ✅ Allow multi-selection
                                                        />
                                                    </FormControl>
                                                    <Spanning ref={myElementRefBranch}></Spanning>

                                                </div>
                                                <div>
                                                    <div className="col-md-12">
                                                        <label for="" className="form-label">Select Audit Type</label>

                                                        <select
                                                            className="form-select"
                                                            name='isLBAOrPA'
                                                            id='isLBAOrPA'
                                                            value={isLBAOrPA}
                                                            required
                                                            onChange={handleAuditTypeChange}
                                                        >
                                                            <option value={0}>Regular Audit</option>
                                                            <option value={1}>Labour Contract Audit</option>
                                                            <option value={2}>Principle Contract Audit</option>
                                                        </select>
                                                        <Spanning ref={myElementRefIsLBAOrPA}></Spanning>

                                                    </div>
                                                    {/* 1 */}
                                                    {(isLBAOrPA === 1 || isLBAOrPA === "1") && (
                                                        <div className="col-md-12">
                                                            <label for="" className="form-label">Select Labour Agreement</label>

                                                            <select
                                                                className="form-select"
                                                                name='LCAgreement'
                                                                id='LCAgreement'
                                                                value={LCAgreement}
                                                                required
                                                                onChange={(e) => {
                                                                    const selectedLCAgreement = e.target.value;
                                                                    setLCAgreement(selectedLCAgreement);
                                                                }}
                                                            >
                                                                <option value="">Select Labour Agreement</option> {/*onBlur={handlestateChange}*/}
                                                                {LCAN_NameInfo != 'undefind' && LCAN_NameInfo?.length > 0 && LCAN_NameInfo.map(item =>
                                                                    <option value={item._id}>{item.agreementNumber}</option>
                                                                )};
                                                            </select>
                                                            {/* <Spanning ref={myElementRefLCAgreement}></Spanning> */}
                                                        </div>
                                                    )}
                                                    {/* LCAN_NameInfo */}
                                                    {/* 1 */}
                                                    {/* 2 */}
                                                    {(isLBAOrPA === 2 || isLBAOrPA === "2") && (

                                                        <div className="col-md-12">
                                                            <label for="" className="form-label">Select Principle Agreement</label>

                                                            <select
                                                                className="form-select"
                                                                name='PAgreement'
                                                                id='PAgreement'
                                                                value={PAgreement}
                                                                required
                                                                onChange={(e) => {
                                                                    const selectedPAgreement = e.target.value;
                                                                    setPAgreement(selectedPAgreement);
                                                                }}
                                                            >
                                                                <option value="">Select Labour Agreement</option> {/*onBlur={handlestateChange}*/}
                                                                {PAN_NameInfo != 'undefind' && PAN_NameInfo?.length > 0 && PAN_NameInfo.map(item =>
                                                                    <option value={item._id}>{item.agreementNumber}</option>
                                                                )};
                                                            </select>
                                                            {/* <Spanning ref={myElementRefIsLBAOrPA}></Spanning> */}
                                                        </div>
                                                    )}
                                                    {/* 2 */}
                                                </div>
                                                <div className="col-md-6">
                                                    <label for="" className="form-label">Start Date</label>
                                                    <input type="date" className="form-control" id="" placeholder='start date' value={startDate} onChange={(e) => { handleStartDateChange(e) }} required />
                                                    <Spanning ref={myElementRefStartdate}></Spanning>
                                                </div>
                                                <div className="col-md-6">
                                                    <label for="" className="form-label">End Date</label>
                                                    <input type="date" className="form-control" id="" placeholder='End date' value={endDate} onChange={(e) => { handleEndDateChange(e) }} min={startDate || undefined}
                                                        disabled={!startDate} required />
                                                    <Spanning ref={myElementRefEnddate}></Spanning>
                                                </div>
                                                {/* <div className="col-md-6" >
                                            <label for="" className="form-label">Risk</label>
                                            <select className="form-select" id="risk" aria-label="Default select example" name="risk" value={risk} onChange={(e)=>setRisk(e.target.value)} required>
                                                <option value="">Select Risk</option>
                                                <option value="Low">Low</option>
                                                <option value="Medium">Medium</option>
                                                <option value="High">High</option>
                                                <option value="Very High">Very High</option>
                                            </select>
                                            <Spanning ref={myElementRefRisk}></Spanning>
                                        </div>
                                        <div className="col-md-6">
                                            <label for="" className="form-label">Audit Status</label>
                                            <select className="form-select" id="risk" aria-label="Default select example" name="auditstatus" value={auditstatus} onChange={(e)=>setAuditStatus(e.target.value)} required>
                                                <option value="">Select Audit Status</option>
                                                <option value="1">Ongoing</option>
                                                <option value="2">Submitted</option>
                                                <option value="3">Approved</option>
                                                <option value="4">Rejected</option>
                                            </select>
                                            <Spanning ref={myElementRefAuditStatus}></Spanning>
                                        </div> */}
                                                <div hidden>
                                                    <select className="form-select" id="risk" aria-label="Default select example" name="audituploadstatus" value="0" onChange={(e) => setAudituploadstatus(e.target.value)} hidden>

                                                    </select>
                                                </div>
                                                <div className="col-md-12">
                                                    <label for="exampleFormControlTextarea1" class="form-label">Scope</label>
                                                    <textarea class="form-control" placeholder='write a discription' id="exampleFormControlTextarea1" rows="3" value={scope} onChange={(e) => { setScope(e.target.value) }} required />
                                                    <Spanning ref={myElementRefScope}></Spanning>
                                                </div>
                                                <div className="col-md-12">
                                                    <h4 className='text-center my-2'>Auditor Details</h4>
                                                </div>
                                                <div className="col-md-12">
                                                    <label for="" className="form-label">Select Auditor</label>
                                                    <select className="form-select" aria-label="Default select example" name="users" value={auditor} onChange={(e) => setAuditor(e.target.value)} required>
                                                        <option value="">Select Auditor</option>
                                                        {auditorInfo != 'undefind' && auditorInfo?.length > 0 && auditorInfo.map(item =>
                                                            <option value={item._id}>{item.firstName}{' '}{item.lastName}
                                                            </option>
                                                        )};
                                                    </select>
                                                    <Spanning ref={myElementRefAuditor}></Spanning>
                                                </div>
                                                <div className="col-md-12">
                                                    <label for="exampleFormControlTextarea1" class="form-label">Brief To Auditor</label>
                                                    <textarea class="form-control" placeholder='Brif To Auditor' id="exampleFormControlTextarea1" rows="3" value={briefauditor} onChange={(e) => { setBriefAuditor(e.target.value) }} required></textarea>
                                                    <Spanning ref={myElementRefBriefAuditor}></Spanning>
                                                </div>
                                                {/* Executive Add */}






                                                <div className="col-md-12">
                                                    <h4 className='text-center my-2'>Executive Details</h4>
                                                </div>
                                                <div className="col-md-12">
                                                    <label for="" className="form-label">Select Executive</label>
                                                    <select className="form-select" aria-label="Default select example" name="users" value={ExecutiveCommon} onChange={(e) => setExecutiveCommon(e.target.value)} required>
                                                        <option value="">Select Executive</option>
                                                        {ExecutiveCommonInfo != 'undefind' && ExecutiveCommonInfo?.length > 0 && ExecutiveCommonInfo.map(item =>
                                                            <option value={item._id}>{item.firstName}{' '}{item.lastName}
                                                            </option>
                                                        )};
                                                    </select>
                                                    <Spanning ref={myElementRefExecutiveCommon}></Spanning>
                                                </div>
                                                <div className="col-md-12">
                                                    <label for="exampleFormControlTextarea1" class="form-label">Brief To Executive</label>
                                                    <textarea class="form-control" placeholder='Brif To Executive' id="exampleFormControlTextarea1" rows="3" value={briefExecutiveCommon} onChange={(e) => { setBriefExecutiveCommon(e.target.value) }} required></textarea>
                                                    <Spanning ref={myElementRefBriefExecutiveCommon}></Spanning>
                                                </div>





                                                <div className="col-md-12">
                                                    <label for="exampleFormControlTextarea1" class="form-label">Scope</label>
                                                    <textarea class="form-control" placeholder='write a discription' id="exampleFormControlTextarea1" rows="3" value={scopeCE} onChange={(e) => { setScopeCE(e.target.value) }} required />
                                                    <Spanning ref={myElementRefScopeCE}></Spanning>
                                                </div>
                                                <div className="col-md-12">
                                                    <h4 className='text-center my-2'>Compliance Details</h4>
                                                </div>
                                                <div className="col-md-12">
                                                    <a onClick={showtable} style={{ cursor: 'pointer' }}>
                                                        <input type="text" class="form-control" className="custom-input" style={{ width: '100%', cursor: 'pointer' }} value={value}
                                                            onChange={(e) => setValue(e.target.value)} readOnly />
                                                        {/* <img src={checklist} style={{ width:'100%',height:'40px' }} onClick={showtable}/> */}
                                                    </a>
                                                    <Spanning ref={myElementRefTableInput}></Spanning>
                                                </div>
                                                <div className="col-md-12" style={{ display: 'none' }} ref={myElementRefTable}>
                                                    <AuditChecklistTable selectedRows={selectedRows} setSelectedRows={setSelectedRows} />
                                                </div>
                                                {/* <div className="col-md-12">
                                            <div>
                                                <div class="form-group files">
                                                    <input type="file" name="image" class="form-control" multiple="" accept="image/*,application/pdf" id="input-file-now-custom-2" className="file-upload"
                                                    dataHeight="500" onChange={(e) => {handleProductImageUpload(e)}}
                                                    />
                                                </div>   
                                            </div>     
                                        </div> */}
                                                <div className="col-md-4 col-3 text-lg-end">
                                                    <button type="submit" className="btn btn-light">Draf</button>
                                                </div>
                                                <div className="col-md-4 col-3  text-lg-center">
                                                    <button type="submit" className="btn btn-dark" onClick={tocategorypage}>Cancel</button>
                                                </div>
                                                <div className="col-md-4 col-3 text-lg-start">
                                                    <button type="submit" className="btn btn-primary">Submit</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Audit;
const Spanning = styled(FormLabel)`
color: red;
font-size:13px;
`