import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Checkbox, message } from "antd";
import { stateGets, companyTableGet, branchGetByState, getContractorName, CreatingContractAgreement, getLabourContractAgreementTable, getLabourContractAgreementsById, updateLabourContractAgreementID } from "../../../store/actions/otherActions";
import Multiselect from "multiselect-react-dropdown";
import { FormLabel, styled, FormControl, } from '@mui/material';

// const { option } = select;

const CreateAgreeLabCont = ({ recordForEdit }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    // const myElementRefState = useRef(null);
    const myElementRefCompany = useRef(null);
    const myElementRefBranch = useRef(null);
    // console.log("recordForEditAgree", recordForEdit);

    // const handleSelect = (selectedList, selectedItem) => {
    //     setBranch(selectedList.map(item => item.id)); // Store only branch IDs
    // };

    // const handleRemove = (selectedList, removedItem) => {
    //     setBranch(selectedList.map(item => item.id)); // Update branch IDs after removal
    // };

    // Redux State
    const stateInfo = useSelector((state) => state.getState.stateInfo);
    const { companyGetTableInfo } = useSelector((state) => state.getCompanyTable);
    // const { branchInfo } = useSelector((state) => state.getBranch);
    const { contractorNameInfo } = useSelector((state) => state.ContractorNameRed);
    const { LCAByIDInfo } = useSelector((state) => state.LabourContractAgreementsByIdRed);
    // console.log("LCAByIDInfo", LCAByIDInfo);

    const getBranchByState = useSelector((state) => state.getBranchByState);
    const { branchByStateInfo } = getBranchByState;

    // Component State
    const [company, setCompany] = useState("");
    const [state, setState] = useState([]);
    const [labourContractor, setLabourContractor] = useState("");
    const [branch, setBranch] = useState([]);
    const [isCLLicenseApplicable, setIsCLLicenseApplicable] = useState(false);
    const [isApplicableToAllBranches, setIsApplicableToAllBranches] = useState(false);
    const [formData, setFormData] = useState({});
    // const [selectedStates, setSelectedStates] = useState([]); // ✅ Fix for Multi-State Selection

    // const formatDateToInput = (isoDate) => {
    //     if (!isoDate) return ""; // Return an empty string for empty or invalid dates
    //     const date = new Date(isoDate);
    //     const year = date.getFullYear();
    //     const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero
    //     const day = String(date.getDate()).padStart(2, "0"); // Add leading zero
    //     return `${day}/${month}/${year}`;
    // };
    const formatDateToInput = (isoDate) => {
        if (!isoDate) return ""; // Return an empty string for empty or invalid dates
        const date = new Date(isoDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero
        const day = String(date.getDate()).padStart(2, "0"); // Add leading zero
        return `${year}-${month}-${day}`; // Format as YYYY-MM-DD for input[type="date"]
    };


    useEffect(() => {
        dispatch(stateGets());
        dispatch(companyTableGet());
        dispatch(getContractorName());
    }, [dispatch]);

    useEffect(() => {
        if (recordForEdit && recordForEdit._id) {
            // console.log("Sending ID to API:", recordForEdit._id);
            dispatch(getLabourContractAgreementsById(recordForEdit._id)); // ✅ Sends string `_id`
        }
    }, [dispatch, recordForEdit]);



    const getBbranch = (selectedCompany = company, selectedStates = state) => {
        const companyId = selectedCompany || company;
        const stateIds = Array.isArray(selectedStates) ? selectedStates.map(s => s._id) : [];

        if (companyId && stateIds.length > 0) {
            const postBody = {
                id: companyId,
                stateIds: stateIds,
            };
            dispatch(branchGetByState(postBody));
        }
    };

    useEffect(() => {
        if (recordForEdit && recordForEdit._id) {
            // ✅ Edit Mode: Fetch Data
            dispatch(getLabourContractAgreementsById(recordForEdit._id));
        } else {
            // ✅ Create Mode: Reset Everything
            console.log("🔄 Switching to Create Mode: Resetting form...");

            // 🔹 Reset Ant Design Form
            form.resetFields();

            // 🔹 Clear State Variables
            setCompany("");
            setState([]);
            setLabourContractor("");
            setBranch([]);
            setIsApplicableToAllBranches(false);
            setIsCLLicenseApplicable(false);
            setFormData({});
        }
    }, [recordForEdit, dispatch, form]);

    useEffect(() => {
        if (LCAByIDInfo && Object.keys(LCAByIDInfo).length > 0) {
            // ✅ Populate Form for Editing
            form.setFieldsValue({
                agreementNumber: LCAByIDInfo.agreementNumber || "",
                agreementStartDate: LCAByIDInfo.agreementStartDate ? formatDateToInput(LCAByIDInfo.agreementStartDate) : "",
                agreementEndDate: LCAByIDInfo.agreementEndDate ? formatDateToInput(LCAByIDInfo.agreementEndDate) : "",
                contractEmployees: LCAByIDInfo.contractEmployees || "",
                contractWorkNature: LCAByIDInfo.contractWorkNature || "",
                isApplicableToAllBranches: LCAByIDInfo.isApplicableToAllBranches || false,
                company: LCAByIDInfo.company?._id || "",
                labourContractor: LCAByIDInfo.labourContractor?._id || "",
                state: LCAByIDInfo.state ? LCAByIDInfo.state.map(s => s._id) : [],
                branch: LCAByIDInfo.branches ? LCAByIDInfo.branches.map(b => b._id) : [],

                // Contract Work Details
                formNumber: LCAByIDInfo.formNumber || "",
                formDate: LCAByIDInfo.formDate ? formatDateToInput(LCAByIDInfo.formDate) : "",
                contractCommencedDate: LCAByIDInfo.contractCommencedDate ? formatDateToInput(LCAByIDInfo.contractCommencedDate) : "",

                // License Details
                licenseNumber: LCAByIDInfo.licenseNumber || "",
                licenseDate: LCAByIDInfo.licenseDate ? formatDateToInput(LCAByIDInfo.licenseDate) : "",
                licenseExpiryDate: LCAByIDInfo.licenseExpiryDate ? formatDateToInput(LCAByIDInfo.licenseExpiryDate) : "",
                licenseRenewalDueDate: LCAByIDInfo.licenseRenewalDueDate ? formatDateToInput(LCAByIDInfo.licenseRenewalDueDate) : "",
                noOfWorkersPerLis: LCAByIDInfo.noOfWorkersPerLis || "",
                isCLLicenseApplicable: LCAByIDInfo.isCLLicenseApplicable || false,

                // Commencement Details
                comencementDetails: LCAByIDInfo.comencementDetails || "",
                commencedDate: LCAByIDInfo.commencedDate ? formatDateToInput(LCAByIDInfo.commencedDate) : "",
                commencedNoticeDate: LCAByIDInfo.commencedNoticeDate ? formatDateToInput(LCAByIDInfo.commencedNoticeDate) : "",
                commencedNoticeRemarks: LCAByIDInfo.commencedNoticeRemarks || "",

                // Completion Details
                contractCompDetails: LCAByIDInfo.contractCompDetails || "",
                contractCompDate: LCAByIDInfo.contractCompDate ? formatDateToInput(LCAByIDInfo.contractCompDate) : "",
                contractCompRemarks: LCAByIDInfo.contractCompRemarks || "",
                contractCompDateRemarks: LCAByIDInfo.contractCompDateRemarks || "",
            });

            // ✅ Update State Variables
            setCompany(LCAByIDInfo.company?._id || "");
            setLabourContractor(LCAByIDInfo.labourContractor?._id || "");
            setState(LCAByIDInfo.state || []);

            // ✅ Fix Branch Mapping (Convert to Correct Structure)
            setBranch(LCAByIDInfo.branches?.map(b => ({ _id: b._id, name: b.name })) || []);

            setIsApplicableToAllBranches(LCAByIDInfo.isApplicableToAllBranches || false);
            setIsCLLicenseApplicable(LCAByIDInfo.isCLLicenseApplicable || false);
        }
    }, [LCAByIDInfo, form]);



    // ✅ RESET FORM WHEN SWITCHING TO CREATE MODE
    useEffect(() => {
        if (!recordForEdit) {
            console.log("Switching to Create Mode: Resetting form..."); // Debugging
            form.resetFields();
            setCompany("");
            setState([]);
            setLabourContractor("");
            setBranch([]);
            setIsApplicableToAllBranches(false);
            setIsCLLicenseApplicable(false);
        }
    }, [recordForEdit, form]);


    // Handle File Uploads
    const handleFileChange = (e, fieldName) => {
        const file = e.target.files[0];
        if (file) {
            setFormData((prev) => ({ ...prev, [fieldName]: file }));
        }
    };

    // const handleSubmit = async (values) => {
    //     try {
    //         const finalData = new FormData();

    //         // ✅ Append all form values except state, branch, and files
    //         Object.keys(values).forEach((key) => {
    //             if (!["state", "branch", "isApplicableToAllBranches"].includes(key)) {
    //                 finalData.append(key, values[key]);
    //             }
    //         });

    //         // ✅ Append Checkbox as Boolean
    //         finalData.append("isApplicableToAllBranches", isApplicableToAllBranches ? "true" : "false");

    //         // ✅ Ensure `state` is an array & Append Multi-Selected States Properly
    //         if (Array.isArray(state) && state.length > 0) {
    //             state.forEach((s) => {
    //                 console.log("Appending State:", s._id); // Debugging
    //                 finalData.append("state[]", s._id); // ✅ Append each state ID
    //             });
    //         } else {
    //             message.error("Please select at least one state.");
    //             return;
    //         }

    //         // ✅ Append Company & Contractor
    //         if (company) finalData.append("company", company);
    //         if (labourContractor) finalData.append("labourContractor", labourContractor);

    //         // ✅ Append Multi-Selected Branches Only If Not Applicable to All Branches
    //         if (!isApplicableToAllBranches) {
    //             branch.forEach((b) => finalData.append("branch[]", b));
    //         }

    //         // ✅ Append Files in Binary
    //         Object.keys(formData).forEach((fileKey) => {
    //             if (formData[fileKey]) {
    //                 finalData.append(fileKey, formData[fileKey]); // Store as binary
    //             }
    //         });

    //         // ✅ Debugging - Log Final FormData Payload
    //         console.log("🚀 Final Payload:");
    //         for (let pair of finalData.entries()) {
    //             console.log(pair[0], pair[1]); // Logs fields & binary files
    //         }

    //         // ✅ Dispatch Redux Action
    //         await dispatch(CreatingContractAgreement(finalData));

    //         // ✅ Reset Form Fields
    //         form.resetFields();
    //         setCompany("");
    //         setState([]); // ✅ Reset state to an empty array
    //         setLabourContractor("");
    //         setBranch([]);
    //         setIsCLLicenseApplicable(false);
    //         setIsApplicableToAllBranches(false); // ✅ Reset checkbox to false
    //         setFormData({});

    //         // ✅ Reset File Inputs Manually
    //         document.querySelectorAll("input[type=file]").forEach((input) => (input.value = ""));
    //     } catch (error) {
    //         console.error("❌ Error submitting form:", error);
    //         message.error("Failed to submit contract agreement.");
    //     }
    // };

    const handleSubmit = async (values) => {
        try {
            const finalData = new FormData();

            // ✅ Append only non-empty form values
            Object.keys(values).forEach((key) => {
                const value = values[key];
                if (!["state", "branch"].includes(key) && value !== "" && value !== null && value !== undefined) {
                    finalData.append(key, value);
                }
            });

            // ✅ Append Checkboxes as Boolean Strings
            finalData.append("isApplicableToAllBranches", isApplicableToAllBranches ? "true" : "false");
            finalData.append("isCLLicenseApplicable", isCLLicenseApplicable ? "true" : "false");

            // ✅ Convert State IDs to JSON String
            if (Array.isArray(state) && state.length > 0) {
                const formattedStates = state.map(item => ({ stateId: item._id, name: item.name }));
                finalData.append("state", JSON.stringify(formattedStates));
            } else {
                message.error("Please select at least one state.");
                return;
            }

            // ✅ Append Company & Contractor
            if (company) finalData.append("company", company);
            if (labourContractor) finalData.append("labourContractor", labourContractor);

            if (!isApplicableToAllBranches) {
                if (Array.isArray(branch) && branch.length > 0) {
                    const formattedBranches = branch.map(branchItem => ({
                        branchId: branchItem?._id || branchItem.id || branchItem, // Ensure ID is always included
                        name: branchItem?.name || "Unknown" // Fallback for safety
                    }));

                    finalData.append("branch", JSON.stringify(formattedBranches));
                } else {
                    finalData.append("branch", JSON.stringify([])); // Ensure an empty array if no branch is selected
                }
            }


            // ✅ Append Files
            Object.keys(formData).forEach((fileKey) => {
                if (formData[fileKey]) {
                    finalData.append(fileKey, formData[fileKey]);
                }
            });

            // ✅ Debugging - Log Final Payload
            console.log("🚀 Final Payload:");
            for (let pair of finalData.entries()) {
                console.log(pair[0], pair[1]);
            }

            // ✅ Check if updating or creating
            if (recordForEdit && recordForEdit._id) {
                console.log("Updating Agreement...");
                await dispatch(updateLabourContractAgreementID(finalData, recordForEdit._id));
                await dispatch(getLabourContractAgreementTable());
                // message.success("Contract Agreement updated successfully!");
            } else {
                console.log("Creating New Agreement...");
                await dispatch(CreatingContractAgreement(finalData));
                await dispatch(getLabourContractAgreementTable());
                // message.success("Contract Agreement created successfully!");
            }

            // ✅ Reset Form Fields
            form.resetFields();
            setCompany("");
            setState([]);
            setLabourContractor("");
            setBranch([]);
            setIsCLLicenseApplicable(false);
            setIsApplicableToAllBranches(false);
            setFormData({});

            // ✅ Reset File Inputs
            document.querySelectorAll("input[type=file]").forEach((input) => (input.value = ""));
        } catch (error) {
            console.error("❌ Error submitting form:", error);
            // message.error("Failed to submit contract agreement.");
        }
    };






    return (
        <Form form={form} layout="vertical" onFinish={handleSubmit} style={{ maxWidth: "1100px", margin: "0 auto" }}>
            <table className="table creat_tbl">
                <tbody>
                    <tr>
                        <td colSpan="4" style={{ textAlign: "center" }}>

                            <h3 style={{ color: "#013879" }}>{recordForEdit ? "Edit Labour Contractor Agreement" : "Labour Contractor Agreement"}</h3>
                        </td>
                    </tr>
                    {/* Company Selection */}
                    <tr>
                        <td colSpan="2">
                            <label for="inputcompany" className="form-label">Select Company</label>
                            <select className="form-select" ref={myElementRefCompany} id="company" name="company"
                                value={company}
                                onChange={(e) => {
                                    const selectedCompany = e.target.value;
                                    setCompany(selectedCompany);
                                    getBbranch(selectedCompany, state);
                                }} required>
                                <option value="">Select Company</option>
                                {companyGetTableInfo && companyGetTableInfo.map(item => (
                                    <option key={item._id} value={item._id}>{item.companyname}</option>
                                ))}
                            </select>

                            <Spanning ref={myElementRefCompany}></Spanning>
                        </td>
                        {/* <td colSpan="2"><label for="" className="form-label">Select State</label>
                            <select className="form-select" aria-label="Default select example" id="states" value={state} name="state" onChange={(e) => {
                                const selectedState = e.target.value;
                                setState(selectedState);
                                getBbranch(company, selectedState); // Pass current company value
                            }} required>
                                <option value="">Select State</option> 
                                {stateInfo != 'undefind' && stateInfo?.length > 0 && stateInfo.map(item =>
                                    <option value={item._id}>{item.name}</option>
                                )};
                            </select>
                            <Spanning id="states" ref={myElementRefState}></Spanning>
                        </td> */}
                        <td colSpan="2">

                            <label for="" class="form-label">Select State *</label>
                            <Multiselect
                                options={stateInfo}
                                displayValue="name"
                                selectedValues={state} // ✅ Now correctly pre-filling selected states
                                onSelect={(selectedList) => {
                                    console.log("Selected States:", selectedList); // Debugging
                                    setState(selectedList); // ✅ Store selected states as an array
                                    getBbranch(company, selectedList); // ✅ Fetch branches on state selection
                                }}
                                onRemove={(selectedList) => {
                                    setState(selectedList); // ✅ Update state when a state is removed
                                    getBbranch(company, selectedList); // ✅ Fetch branches on state removal
                                }}
                                placeholder="Select State"
                                isObject={true}
                                showCheckbox={true}
                                style={{ color: "#013879" }} // ✅ Apply Color
                            />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="4">
                            <Form.Item style={{ textAlign: "center" }}>
                                <Checkbox
                                    checked={isApplicableToAllBranches}
                                    onChange={(e) => {
                                        setIsApplicableToAllBranches(e.target.checked);
                                        if (e.target.checked) {
                                            setBranch([]); // ✅ Clear selected branches when checked
                                        }
                                    }}
                                >
                                    <h4 style={{ color: "#013879" }}>Is Applicable to All Branches?</h4>
                                </Checkbox>
                            </Form.Item>
                        </td>
                    </tr>

                    <tr>
                        {(!isApplicableToAllBranches || isApplicableToAllBranches === false || form.getFieldValue("isApplicableToAllBranches") === false) && (
                            <td colSpan="2">
                                {/* <InputLabel>Select Branches</InputLabel> */}
                                <FormControl fullWidth>
                                    <Form.Item label="Select Branches" name="branch">
                                        <Multiselect
                                            options={branchByStateInfo || []} // ✅ Ensure options is always an array
                                            displayValue="name"
                                            selectedValues={branch || []} // ✅ Ensure selected values match { _id, name } format
                                            onSelect={(selectedList) => {
                                                console.log("Selected Branches:", selectedList);
                                                setBranch(selectedList); // ✅ Store full objects, not just IDs
                                            }}
                                            onRemove={(selectedList) => {
                                                console.log("Updated Selected Branches:", selectedList);
                                                setBranch(selectedList); // ✅ Update state properly on remove
                                            }}
                                            placeholder="Select Branches"
                                            isObject={true}
                                            showCheckbox={true}
                                            style={{ color: "#013879" }} // ✅ Apply styling
                                        />
                                    </Form.Item>
                                </FormControl>
                                <Spanning ref={myElementRefBranch}></Spanning>
                            </td>
                        )}
                        <td colSpan="2">
                            <label htmlFor="labourContractor" className="form-label">Select Contractor</label>
                            <select
                                className="form-select"
                                id="labourContractor"
                                name="labourContractor"
                                value={labourContractor}
                                onChange={(e) => setLabourContractor(e.target.value)}
                                required
                            >
                                <option value="">Select Contractor</option>
                                {contractorNameInfo && contractorNameInfo.length > 0 &&
                                    contractorNameInfo.map((item) => (
                                        <option key={item._id} value={item._id}>
                                            {item.contractorName}
                                        </option>
                                    ))}
                            </select>
                        </td>
                    </tr>
                    {/* Contract Details */}
                    <tr>
                        <td colSpan="8">
                            <Form.Item label="Agreement Number" name="agreementNumber" rules={[{ required: true }]}>
                                <Input placeholder="Enter Agreement Number" />
                            </Form.Item>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2">
                            <Form.Item label="Agreement Start Date" name="agreementStartDate" rules={[{ required: true }]}>
                                <input style={{ width: '400px' }} type="date" className="form-control" value={form.getFieldValue("agreementStartDate")} />
                            </Form.Item>
                        </td>
                        <td colSpan="2">
                            <Form.Item label="Agreement End Date" name="agreementEndDate" rules={[{ required: true }]}>
                                <input style={{ width: '400px' }} type="date" className="form-control" value={form.getFieldValue("agreementEndDate")} />
                            </Form.Item>
                        </td>
                    </tr>
                    <tr><td colSpan="4">
                        <Form.Item label="Nature of Contract Work" name="contractWorkNature" >
                            <Input.TextArea placeholder="Describe contract work" />
                        </Form.Item>
                    </td></tr>
                    <tr>
                        <td colSpan="4">
                            <Form.Item label="Number of Contract Employees" name="contractEmployees" >
                                <Input type="number" placeholder="Enter number of employees" />
                            </Form.Item>
                        </td>
                    </tr>

                    {/* License Toggle */}
                    <tr><td colSpan="4">
                        <Form.Item style={{ textAlign: 'center' }}>
                            <Checkbox checked={isCLLicenseApplicable} onChange={(e) => setIsCLLicenseApplicable(e.target.checked)}>
                                <h4 style={{ color: "#013879" }}>Is Contract Labor License Applicable?</h4>
                            </Checkbox>
                        </Form.Item>
                    </td></tr>

                    {/* License Fields (Conditional Rendering) */}
                    {(isCLLicenseApplicable === true || form.getFieldValue("agreementStartDate") === true) && (
                        <>
                            <tr>
                                <td colSpan="2">
                                    <Form.Item label="Number of Form 5/Form III" name="formNumber">
                                        <Input style={{ width: '400px' }} placeholder="Enter Number of Form 5/Form III" />
                                    </Form.Item>
                                </td>
                                <td colSpan="2">
                                    <Form.Item label="Form 5/Form III date" name="formDate">
                                        <input style={{ width: '400px' }} type="date" className="form-control" value={form.getFieldValue("formDate")} />
                                    </Form.Item>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="4">
                                    <Form.Item label="Date on which the contract work commenced" name="contractCommencedDate">
                                        <input style={{ width: '100%' }} type="date" className="form-control" value={form.getFieldValue("contractCommencedDate")} />
                                    </Form.Item>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="4">
                                    <Form.Item label="Contract Labor License Number" name="licenseNumber">
                                        <Input placeholder="Contract Labor License Number" />
                                    </Form.Item>
                                </td>
                            </tr>
                            <tr>
                                <td >
                                    <Form.Item label="License Date" name="licenseDate">
                                        <input style={{ width: '300px' }} type="date" className="form-control" value={form.getFieldValue("licenseDate")} />
                                    </Form.Item>
                                </td>
                                {/* </tr>
                            <tr> */}
                                <td >
                                    <Form.Item label="Date of Expiry" name="licenseExpiryDate">
                                        <input style={{ width: '300px' }} type="date" className="form-control" value={form.getFieldValue("contractCompDate")} />
                                    </Form.Item>
                                </td>
                                {/* </tr>
                            <tr> */}
                                <td colSpan="4">
                                    <Form.Item label="Due Date of Renewal" name="licenseRenewalDueDate">
                                        <input type="date" className="form-control" style={{ width: '300px' }} value={form.getFieldValue("licenseRenewalDueDate")} />
                                    </Form.Item>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="4">
                                    <Form.Item label="Number of Workers as per the License" name="noOfWorkersPerLis">
                                        <Input type="number" placeholder="0" />
                                    </Form.Item>
                                </td>
                            </tr>
                            <tr >
                                <td colSpan="4">
                                    <Form.Item label="Details of the Comencement of the Contract" name="comencementDetails">
                                        <Input.TextArea width={100} rows={2} placeholder="Details of the Comencement of the Contract" />
                                    </Form.Item>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="4">
                                    <Form.Item label="Date on which the contract work Comenced" name="commencedDate">
                                        <input style={{ width: '100%' }} type="date" className="form-control" value={form.getFieldValue("commencedDate")} />
                                    </Form.Item>
                                </td>
                            </tr>
                            <tr>
                                <td >
                                    <Form.Item label="Date of Notice of the Comencement of Contract" name="commencedNoticeDate">
                                        <input style={{ width: "100%" }} type="date" className="form-control" value={form.getFieldValue("commencedNoticeDate")} />
                                    </Form.Item>
                                </td>
                                {/* <td>
                                    <Form.Item label="Upload File">
                                        <input
                                            type="file"
                                            name="commencedNoticeFile"
                                            className="form-control"
                                            accept="image/*,application/pdf"
                                            onChange={(e) => handleFileChange(e, "commencedNoticeFile")}
                                        />
                                    </Form.Item>
                                </td> */}
                                <td>
                                    <Form.Item label="Upload Contract Completion Date File">
                                        {LCAByIDInfo?.commencedNoticeFile ? (
                                            <>
                                                <a href={LCAByIDInfo.commencedNoticeFile} target="_blank" rel="noopener noreferrer">
                                                    View Uploaded File
                                                </a>
                                                <br />
                                            </>
                                        ) : null}
                                        <input
                                            type="file"
                                            name="commencedNoticeFile"
                                            className="form-control"
                                            accept="image/*,application/pdf"
                                            onChange={(e) => handleFileChange(e, "commencedNoticeFile")}
                                        />
                                    </Form.Item>
                                </td>
                                <td >
                                    <Form.Item label="Remarks" name="commencedNoticeRemarks">
                                        <Input placeholder="Remarks" rows={1} />
                                    </Form.Item>
                                </td>
                            </tr>
                            {recordForEdit?._id && (
                                <>
                                    <tr>
                                        <td>
                                            <Form.Item label="Details of the Completion of the Contract" name="contractCompDetails">
                                                <Input.TextArea placeholder="Details of the Completion of the Contract" />
                                            </Form.Item>
                                        </td>
                                        <td>
                                            <Form.Item label="Upload Contract Completion File">
                                                {LCAByIDInfo?.contractCompFile ? (
                                                    <>
                                                        <a href={LCAByIDInfo.contractCompFile} target="_blank" rel="noopener noreferrer">
                                                            View Uploaded File
                                                        </a>
                                                        <br />
                                                    </>
                                                ) : null}
                                                <input
                                                    type="file"
                                                    name="contractCompFile"
                                                    className="form-control"
                                                    accept="image/*,application/pdf"
                                                    onChange={(e) => handleFileChange(e, "contractCompFile")}
                                                />
                                            </Form.Item>
                                        </td>
                                        <td>
                                            <Form.Item label="Remarks" name="contractCompRemarks">
                                                <Input placeholder="Remarks" rows={1} />
                                            </Form.Item>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <Form.Item label="Date on which the contract work completed" name="contractCompDate">
                                                <input
                                                    style={{ width: "100%" }}
                                                    type="date"
                                                    className="form-control"
                                                    value={form.getFieldValue("contractCompDate")}
                                                />
                                            </Form.Item>
                                        </td>
                                        <td>
                                            <Form.Item label="Upload Contract Completion Date File">
                                                {LCAByIDInfo?.contractCompDateFile ? (
                                                    <>
                                                        <a href={LCAByIDInfo.contractCompDateFile} target="_blank" rel="noopener noreferrer">
                                                            View Uploaded File
                                                        </a>
                                                        <br />
                                                    </>
                                                ) : null}
                                                <input
                                                    type="file"
                                                    name="contractCompDateFile"
                                                    className="form-control"
                                                    accept="image/*,application/pdf"
                                                    onChange={(e) => handleFileChange(e, "contractCompDateFile")}
                                                />
                                            </Form.Item>
                                        </td>
                                        <td>
                                            <Form.Item label="Remarks" name="contractCompDateRemarks">
                                                <Input placeholder="Remarks" rows={1} />
                                            </Form.Item>
                                        </td>
                                    </tr>
                                </>
                            )}

                            {/* <tr>
                                <td>
                                    <Form.Item label="Details of the Completion of the Contract" name="contractCompDetails">
                                        <Input.TextArea placeholder="Details of the Completion of the Contract" />
                                    </Form.Item>
                                </td>
                                <td>
                                    <Form.Item label="Upload Contract Completion File">
                                        {LCAByIDInfo?.contractCompFile ? (
                                            <>
                                                <a href={LCAByIDInfo.contractCompFile} target="_blank" rel="noopener noreferrer">
                                                    View Uploaded File
                                                </a>
                                                <br />
                                            </>
                                        ) : null}
                                        <input
                                            type="file"
                                            name="contractCompFile"
                                            className="form-control"
                                            accept="image/*,application/pdf"
                                            onChange={(e) => handleFileChange(e, "contractCompFile")}
                                        />
                                    </Form.Item>
                                </td>
                                <td >
                                    <Form.Item label="Remarks" name="contractCompRemarks">
                                        <Input placeholder="Remarks" rows={1} />
                                    </Form.Item>
                                </td>
                            </tr>
                            <tr>
                                <td >
                                    <Form.Item label="Date on which the contract work completed" name="contractCompDate">
                                        <input style={{ width: '100%' }} type="date" className="form-control" value={form.getFieldValue("contractCompDate")} />
                                    </Form.Item>
                                </td>
                                <td>
                                    <Form.Item label="Upload Contract Completion Date File">
                                        {LCAByIDInfo?.contractCompDateFile ? (
                                            <>
                                                <a href={LCAByIDInfo.contractCompDateFile} target="_blank" rel="noopener noreferrer">
                                                    View Uploaded File
                                                </a>
                                                <br />
                                            </>
                                        ) : null}
                                        <input
                                            type="file"
                                            name="contractCompDateFile"
                                            className="form-control"
                                            accept="image/*,application/pdf"
                                            onChange={(e) => handleFileChange(e, "contractCompDateFile")}
                                        />
                                    </Form.Item>
                                </td>
                                <td >
                                    <Form.Item label="Remarks" name="contractCompDateRemarks">
                                        <Input placeholder="Remarks" rows={1} />
                                    </Form.Item>
                                </td>
                            </tr> */}
                        </>
                    )}

                    {/* Submit Button */}
                    {/* <tr> */}
                    <td colSpan="4">
                        <Form.Item>
                            <Button htmlType="submit" style={{ backgroundColor: "#013879", color: "white" }}>
                                Submit
                            </Button>
                            <Button htmlType="button" onClick={() => form.resetFields()} style={{ marginLeft: "8px" }}>
                                Reset
                            </Button>
                        </Form.Item>
                    </td>
                    {/* </tr> */}
                </tbody>
            </table>
        </Form >
    );
};

export default CreateAgreeLabCont;
const Spanning = styled(FormLabel)`
color: red;
font-size:13px;
`