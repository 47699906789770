import React, { useState, useEffect } from "react";
import { Form, Input, Select, Button, notification } from 'antd';
import { stateGets } from '../../store/actions/otherActions';
import { useDispatch, useSelector } from "react-redux";
import { createDueDates, fetchDueDates } from '../../store/actions/otherActions';

const { Option } = Select;

const DueDateCreate = () => {
    const dispatch = useDispatch();
    const getState = useSelector((state) => state.getState);
    const { stateInfo } = getState;

    useEffect(() => {
        dispatch(stateGets());
    }, [dispatch]);

    const [form] = Form.useForm();
    const [state, setState] = useState("");
    const [updatedDueDate, setUpdatedDueDate] = useState("");
    const [updatedDueDateForPayload, setUpdatedDueDateForPayload] = useState("");

    // Format date as "DD/MM/YYYY"
    const formatDateToDisplay = (isoDate) => {
        if (!isoDate) return "";
        const date = new Date(isoDate);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const formatDateForPayload = (dateObj) => {
        const year = dateObj.getFullYear();
        const month = String(dateObj.getMonth() + 1).padStart(2, "0");
        const day = String(dateObj.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    };

    const handleDueDateChange = (e) => {
        const dueDate = e.target.value;
        const frequency = form.getFieldValue("frequency");
        if (!dueDate || !frequency) return;

        const dueDateObj = new Date(dueDate);
        const updatedDueDateObj = new Date(dueDateObj);

        switch (frequency) {
            case 1:
                updatedDueDateObj.setDate(dueDateObj.getDate());
                break;
            case 2:
                updatedDueDateObj.setDate(dueDateObj.getDate() + 15);
                break;
            case 3:
                updatedDueDateObj.setMonth(dueDateObj.getMonth() + 1);
                break;
            case 4:
                updatedDueDateObj.setMonth(dueDateObj.getMonth() + 3);
                break;
            case 5:
                updatedDueDateObj.setMonth(dueDateObj.getMonth() + 6);
                break;
            case 6:
                updatedDueDateObj.setFullYear(dueDateObj.getFullYear() + 1);
                break;
            case 7:
                updatedDueDateObj.setFullYear(dueDateObj.getFullYear() + 2);
                break;
            default:
                return;
        }

        // Store both formats: Display format & Payload format
        setUpdatedDueDate(formatDateToDisplay(updatedDueDateObj));
        setUpdatedDueDateForPayload(formatDateForPayload(updatedDueDateObj));
    };

    const handleSubmit = async (values) => {
        const formData = {
            state: state,
            act: values.Act,
            compliance: values.compliance,
            formType: values.FormType,
            frequency: values.frequency,
            date: values.dueDate,
            dueDate: values.dueDate,
            updatedDueDate: updatedDueDateForPayload, // Send YYYY-MM-DD format
        };

        try {
            await dispatch(createDueDates(formData));
            // notification.success({
            //     message: "Success",
            //     description: "Due date created successfully.",
            // });

            dispatch(fetchDueDates());

            form.resetFields();
            setState("");
            setUpdatedDueDate("");
            setUpdatedDueDateForPayload(""); // Reset payload date
        } catch (error) {
            console.error({
                message: "Error",
                description: error?.response?.data?.message || "There was an error while creating the due date.",
            });
        }
    };


    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            style={{ maxWidth: '1000px', margin: '0 auto' }}
        >
            <table className="table creat_tbl">
                <tbody>
                    <tr>
                        <td colSpan="8">
                            <div className="form-group">
                                <label htmlFor="state">State</label>
                                <select
                                    className="form-select"
                                    id="state"
                                    name="state"
                                    value={state}
                                    onChange={(e) => setState(e.target.value)}
                                    required
                                >
                                    <option value="">Select State</option>
                                    {stateInfo?.length > 0 &&
                                        stateInfo.map((item) => (
                                            <option key={item._id} value={item._id}>
                                                {item.name}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2">
                            <Form.Item
                                label="Act"
                                name="Act"
                                rules={[{ required: true, message: 'Please enter the Act name!' }]}
                            >
                                <Input placeholder="Enter Act name" />
                            </Form.Item>
                        </td>
                        <td colSpan="1">
                            <Form.Item
                                label="Compliance"
                                name="compliance"
                                rules={[{ required: true, message: 'Please enter the compliance name!' }]}
                            >
                                <Input placeholder="Enter compliance name" />
                            </Form.Item>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2">
                            <Form.Item
                                label="Form Type"
                                name="FormType"
                                rules={[{ required: true, message: 'Please enter the Form Type name!' }]}
                            >
                                <Input placeholder="Enter Form Type name" />
                            </Form.Item>
                        </td>

                        <td colSpan="1">
                            <Form.Item
                                label="Frequency"
                                name="frequency"
                                rules={[{ required: true, message: 'Please select the Frequency!' }]}
                            >
                                <Select placeholder="Select Frequency">
                                    <Option value={1}>One Time</Option>
                                    <Option value={2}>Fortnightly</Option>
                                    <Option value={3}>Monthly</Option>
                                    <Option value={4}>Quarterly</Option>
                                    <Option value={5}>Half Yearly</Option>
                                    <Option value={6}>Yearly</Option>
                                    <Option value={7}>Bi-Annual</Option>
                                </Select>
                            </Form.Item>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="8">
                            <Form.Item
                                label="Due Date"
                                name="dueDate"
                                rules={[{ required: true, message: 'Please select the date!' }]}
                            >
                                <input
                                    className="form-control"
                                    type="date"
                                    style={{ width: '100%' }}
                                    onChange={handleDueDateChange}
                                />
                            </Form.Item>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="8">
                            <Form.Item label="Updated Due Date">
                                <Input placeholder="Updated Due Date" value={updatedDueDate} disabled />
                            </Form.Item>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="8">
                            <Form.Item>
                                <Button htmlType="submit" style={{ marginRight: '8px', backgroundColor: '#013879', color: 'white' }}>
                                    Submit
                                </Button>
                                <Button htmlType="button" onClick={() => form.resetFields()}>
                                    Reset
                                </Button>
                            </Form.Item>
                        </td>
                    </tr>
                </tbody>
            </table>
        </Form>
    );
};

export default DueDateCreate;
