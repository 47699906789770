export const AUDITOR_REQUEST_GET = "AUDITOR_REQUEST_GET";
export const AUDITOR_SUCCESS_GET = "AUDITOR_SUCCESS_GET";
export const AUDITOR_GET_FAIL = "AUDITOR_GET_FAIL";

export const EXECUTIVE_REQUEST_GET = "EXECUTIVE_REQUEST_GET";
export const EXECUTIVE_SUCCESS_GET = "EXECUTIVE_SUCCESS_GET";
export const EXECUTIVE_GET_FAIL = "EXECUTIVE_GET_FAIL";

export const AUDIT_REQUEST_CREATE = "AUDIT_REQUEST_CREATE";
export const AUDIT_SUCCESS_CREATE = "AUDIT_SUCCESS_CREATE";
export const AUDIT_FAIL_CREATE = "AUDIT_FAIL_CREATE";

export const CHECKLIST_REQUEST_GET_CREATE_AUDIT = "CHECKLIST_REQUEST_GET_CREATE_AUDIT";
export const CHECKLIST_SUCCESS_GET_CREATE_AUDIT = "CHECKLIST_SUCCESS_GET_CREATE_AUDIT";
export const CHECKLIST_GET_FAIL_CREATE_AUDIT = "CHECKLIST_GET_FAIL_CREATE_AUDIT";

export const AUDITOR_REQUEST_GET_FILTER = "AUDITOR_REQUEST_GET_FILTER";
export const AUDITOR_SUCCESS_GET_FILTER = "AUDITOR_SUCCESS_GET_FILTER";
export const AUDITOR_GET_FAIL_FILTER = "AUDITOR_GET_FAIL_FILTER";

export const AUDITOR_REQUEST_GET_ALL_FILTER = "AUDITOR_REQUEST_GET_ALL_FILTER";
export const AUDITOR_SUCCESS_GET_ALL_FILTER = "AUDITOR_SUCCESS_GET_ALL_FILTER";
export const AUDITOR_GET_FAIL_ALL_FILTER = "AUDITOR_GET_FAIL_ALL_FILTER";

export const AUDIT_REQUEST_ONGOING_DETAIL = "AUDIT_REQUEST_ONGOING_DETAIL";
export const AUDIT_SUCCESS_ONGOING_DETAIL = "AUDIT_SUCCESS_ONGOING_DETAIL";
export const AUDIT_FAIL_ONGOING_DETAIL = "AUDIT_FAIL_ONGOING_DETAIL";

export const CATEGORY_REQUEST = "CATEGORY_REQUEST";
export const CATEGORY_SUCCESS = "CATEGORY_SUCCESS";
export const CATEGORY_FAIL = "CATEGORY_FAIL";

export const CATEGORY_REQUEST_GET= "CATEGORY_REQUEST_GET";
export const CATEGORY_SUCCESS_GET= "CATEGORY_SUCCESS_GET";
export const CATEGORY_GET_FAIL= "CATEGORY_GET_FAIL";

export const CATEGORY_REQUEST_EDIT= "CATEGORY_REQUEST_EDIT";
export const CATEGORY_SUCCESS_EDIT= "CATEGORY_SUCCESS_EDIT";
export const CATEGORY_EDIT_FAIL= "CATEGORY_EDIT_FAIL";

export const CATEGORY_DELETE_REQUEST= "CATEGORY_DELETE_REQUEST";
export const CATEGORY_DELETE_SUCCESS= "CATEGORY_DELETE_SUCCESS";
export const CATEGORY_DELETE_FAIL= "CATEGORY_DELETE_FAIL";

export const STATE_REQUEST_GET= "STATE_REQUEST_GET";
export const STATE_SUCCESS_GET= "STATE_SUCCESS_GET";
export const STATE_GET_FAIL= "STATE_GET_FAIL";

export const USER_REQUEST_GET= "USER_REQUEST_GET";
export const USER_SUCCESS_GET= "USER_SUCCESS_GET";
export const USER_GET_FAIL= "USER_GET_FAIL";

export const USER_DELETE_REQUEST= "USER_DELETE_REQUEST";
export const USER_DELETE_SUCCESS= "USER_DELETE_SUCCESS";
export const USER_DELETE_FAIL= "USER_DELETE_FAIL";

export const NOTFICATION_CREATE_REQUEST = "NOTFICATION_CREATE_REQUEST";
export const NOTFICATION_CREATE_SUCCESS = "NOTFICATION_CREATE_SUCCESS";
export const NOTFICATION_CREATE_FAIL = "NOTFICATION_CREATE_FAIL";

export const NOTFICATION_REQUEST_GET = "NOTFICATION_REQUEST_GET";
export const NOTFICATION_SUCCESS_GET = "NOTFICATION_SUCCESS_GET";
export const NOTFICATION_GET_FAIL = "NOTFICATION_GET_FAIL";    

export const USER_CREATE_REQUEST = "USER_CREATE_REQUEST";
export const USER_CREATE_SUCCESS = "USER_CREATE_SUCCESS";
export const USER_CREATE_FAIL = "USER_CREATE_FAIL";

export const USER_REQUEST_EDIT= "USER_REQUEST_EDIT";
export const USER_SUCCESS_EDIT= "USER_SUCCESS_EDIT";
export const USER_EDIT_FAIL= "USER_EDIT_FAIL";

export const CHECKLIST_CREATE_REQUEST= "CHECKLIST_CREATE_REQUEST";
export const CHECKLIST_CREATE_SUCCESS= "CHECKLIST_CREATE_SUCCESS";
export const CHECKLIST_CREATE_FAIL= "CHECKLIST_CREATE_FAIL";

export const CHECKLIST_REQUEST_GET= "CHECKLIST_REQUEST_GET";
export const CHECKLIST_SUCCESS_GET= "CHECKLIST_SUCCESS_GET";
export const CHECKLIST_GET_FAIL= "CHECKLIST_GET_FAIL";

export const CHECKLIST_REQUEST_UPDATE_BYID= "CHECKLIST_REQUEST_UPDATE_BYID";
export const CHECKLIST_SUCCESS_UPDATE_BYID= "CHECKLIST_SUCCESS_UPDATE_BYID";
export const CHECKLIST_FAIL_UPDATE_BYID= "CHECKLIST_FAIL_UPDATE_BYID";

export const CHECKLIST_REQUEST_GET_CREATE= "CHECKLIST_REQUEST_GET_CREATE";
export const CHECKLIST_SUCCESS_GET_CREATE= "CHECKLIST_SUCCESS_GET_CREATE";
export const CHECKLIST_GET_FAIL_CREATE= "CHECKLIST_GET_FAIL_CREATE";

export const CHECKLIST_REQUEST_GET_BYID= "CHECKLIST_REQUEST_GET_BYID";
export const CHECKLIST_SUCCESS_GET_BYID= "CHECKLIST_SUCCESS_GET_BYID";
export const CHECKLIST_FAIL_GET_BYID= "CHECKLIST_FAIL_GET_BYID";    

export const CHECKLIST_REQUEST_GET_ALL= "CHECKLIST_REQUEST_GET_ALL";
export const CHECKLIST_SUCCESS_GET_ALL= "CHECKLIST_SUCCESS_GET_ALL";
export const CHECKLIST_GET_FAIL_ALL= "CHECKLIST_GET_FAIL_ALL";    

export const CHECKLIST_REQUEST_GET_APPROVE= "CHECKLIST_REQUEST_GET_APPROVE";
export const CHECKLIST_SUCCESS_GET_APPROVE= "CHECKLIST_SUCCESS_GET_APPROVE";
export const CHECKLIST_REQUEST_GET_APPROVE_FAIL= "CHECKLIST_REQUEST_GET_APPROVE_FAIL";    

export const CHECKLIST_REQUEST_ALL_FILTER= "CHECKLIST_REQUEST_ALL_FILTER";
export const CHECKLIST_SUCCESS_ALL_FILTER= "CHECKLIST_SUCCESS_ALL_FILTER";
export const CHECKLIST_ALL_FAIL_FILTER= "CHECKLIST_ALL_FAIL_FILTER";    

export const CHECKLIST_REQUEST_CREATE_FILTER= "CHECKLIST_REQUEST_CREATE_FILTER";
export const CHECKLIST_SUCCESS_CREATE_FILTER= "CHECKLIST_SUCCESS_CREATE_FILTER";
export const CHECKLIST_ALL_CREATE_FILTER= "CHECKLIST_ALL_CREATE_FILTER";    

export const CHECKLIST_REQUEST_All_COMPLIANCE= "CHECKLIST_REQUEST_All_COMPLIANCE";
export const CHECKLIST_SUCCESS_All_COMPLIANCE= "CHECKLIST_SUCCESS_All_COMPLIANCE";
export const CHECKLIST_FAIL_All_COMPLIANCE= "CHECKLIST_FAIL_All_COMPLIANCE";   

export const CHECKLIST_REQUEST_REJECT_FILTER= "CHECKLIST_REQUEST_REJECT_FILTER";
export const CHECKLIST_SUCCESS_REJECT_FILTER= "CHECKLIST_SUCCESS_REJECT_FILTER";
export const CHECKLIST_ALL_REJECT_FILTER= "CHECKLIST_ALL_REJECT_FILTER";

export const CHECKLIST_REQUEST_REJECT= "CHECKLIST_REQUEST_REJECT";
export const CHECKLIST_SUCCESS_REJECT= "CHECKLIST_SUCCESS_REJECT";
export const CHECKLIST_REQUEST_REJECT_FAIL= "CHECKLIST_REQUEST_REJECT_FAIL";   

export const CHECKLIST_REQUEST_GET_REJECT= "CHECKLIST_REQUEST_GET_REJECT";
export const CHECKLIST_SUCCESS_GET_REJECT= "CHECKLIST_SUCCESS_GET_REJECT";
export const CHECKLIST_GET_FAIL_REJECT= "CHECKLIST_GET_FAIL_REJECT"; 

export const AUDIT_REQUEST_All_DETAIL= "AUDIT_REQUEST_All_DETAIL";
export const AUDIT_SUCCESS_All_DETAIL= "AUDIT_SUCCESS_All_DETAIL";
export const AUDIT_FAIL_All_DETAIL= "AUDIT_FAIL_All_DETAIL";     

export const BRANCH_REQUEST_GET= "BRANCH_REQUEST_GET";
export const BRANCH_SUCCESS_GET= "BRANCH_SUCCESS_GET";
export const BRANCH_GET_FAIL= "BRANCH_GET_FAIL";

export const COMPANY_REQUEST_GET= "COMPANY_REQUEST_GET";
export const COMPANY_SUCCESS_GET= "COMPANY_SUCCESS_GET";
export const COMPANY_GET_FAIL= "COMPANY_GET_FAIL";

export const COMPLIANCE_REQUEST_GET= "COMPLIANCE_REQUEST_GET";
export const COMPLIANCE_SUCCESS_GET= "COMPLIANCE_SUCCESS_GET";
export const COMPLIANCE_GET_FAIL= "COMPLIANCE_GET_FAIL";

export const CHECKLIST_REQUEST_APPROVE_FILTER= "CHECKLIST_REQUEST_APPROVE_FILTER";
export const CHECKLIST_SUCCESS_APPROVE_FILTER= "CHECKLIST_SUCCESS_APPROVE_FILTER";
export const CHECKLIST_ALL_APPROVE_FILTER= "CHECKLIST_ALL_APPROVE_FILTER";

export const CHECKLIST_REQUEST_GET_APPROVE_PAGE= "CHECKLIST_REQUEST_GET_APPROVE_PAGE";
export const CHECKLIST_SUCCESS_GET_APPROVE_PAGE= "CHECKLIST_SUCCESS_GET_APPROVE_PAGE";
export const CHECKLIST_GET_FAIL_APPROVE_PAGE= "CHECKLIST_GET_FAIL_APPROVE_PAGE";

export const COMPLIANCE_REQUEST_APPROVE_FILTER= "COMPLIANCE_REQUEST_APPROVE_FILTER";
export const COMPLIANCE_SUCCESS_APPROVE_FILTER= "COMPLIANCE_SUCCESS_APPROVE_FILTER";
export const COMPLIANCE_APPROVE_FAIL_FILTER= "COMPLIANCE_APPROVE_FAIL_FILTER";

export const COMPLIANCE_SUCCESS_GET_ALL_FILTER= "COMPLIANCE_SUCCESS_GET_ALL_FILTER";
export const COMPLIANCE_REQUEST_GET_ALL_FILTER= "COMPLIANCE_REQUEST_GET_ALL_FILTER";
export const COMPLIANCE_GET_FAIL_ALL_FILTER= "COMPLIANCE_GET_FAIL_ALL_FILTER";

export const COMPLIANCE_REQUEST_REJECT_FILTER= "COMPLIANCE_REQUEST_REJECT_FILTER";
export const COMPLIANCE_SUCCESS_REJECT_FILTER= "COMPLIANCE_SUCCESS_REJECT_FILTER";
export const COMPLIANCE_REJECT_FAIL_FILTER= "COMPLIANCE_REJECT_FAIL_FILTER";

export const COMPLIANCE_REQUEST_GET_ALL= "COMPLIANCE_REQUEST_GET_ALL";
export const COMPLIANCE_SUCCESS_GET_ALL= "COMPLIANCE_SUCCESS_GET_ALL";
export const COMPLIANCE_GET_FAIL_ALL= "COMPLIANCE_GET_FAIL_ALL";

export const COMPLIANCE_REQUEST_GET_CREATE= "COMPLIANCE_REQUEST_GET_CREATE";
export const COMPLIANCE_SUCCESS_GET_CREATE= "COMPLIANCE_SUCCESS_GET_CREATE";
export const COMPLIANCE_GET_FAIL_CREATE= "COMPLIANCE_GET_FAIL_CREATE";

export const COMPLIANCE_REQUEST_GET_BYID= "COMPLIANCE_REQUEST_GET_BYID";
export const COMPLIANCE_SUCCESS_GET_BYID= "COMPLIANCE_SUCCESS_GET_BYID";
export const COMPLIANCE_FAIL_GET_BYID= "COMPLIANCE_FAIL_GET_BYID";

export const BRANCH_CREATE_REQUEST = "BRANCH_CREATE_REQUEST";
export const BRANCH_CREATE_SUCCESS = "BRANCH_CREATE_SUCCESS";
export const BRANCH_CREATE_FAIL = "BRANCH_CREATE_FAIL";

export const COMPANY_CREATE_REQUEST = "COMPANY_CREATE_REQUEST";
export const COMPANY_CREATE_SUCCESS = "COMPANY_CREATE_SUCCESS";
export const COMPANY_CREATE_FAIL = "COMPANY_CREATE_FAIL";

export const COMPLIANCE_CREATE_REQUEST = "COMPLIANCE_CREATE_REQUEST";
export const COMPLIANCE_CREATE_SUCCESS = "COMPLIANCE_CREATE_SUCCESS";
export const COMPLIANCE_CREATE_FAIL = "COMPLIANCE_CREATE_FAIL";

export const COMPLIANCE_REQUEST_UPDATE_BYID = "COMPLIANCE_REQUEST_UPDATE_BYID";
export const COMPLIANCE_SUCCESS_UPDATE_BYID = "COMPLIANCE_SUCCESS_UPDATE_BYID";
export const COMPLIANCE_FAIL_UPDATE_BYID = "COMPLIANCE_FAIL_UPDATE_BYID";

export const COMPLIANCE_REQUEST_GET_APPROVE = "COMPLIANCE_REQUEST_GET_APPROVE";
export const COMPLIANCE_SUCCESS_GET_APPROVE = "COMPLIANCE_SUCCESS_GET_APPROVE";
export const COMPLIANCE_REQUEST_GET_APPROVE_FAIL = "COMPLIANCE_REQUEST_GET_APPROVE_FAIL";

export const COMPLIANCE_REQUEST_GET_FILTER = "COMPLIANCE_REQUEST_GET_FILTER";
export const COMPLIANCE_SUCCESS_GET_FILTER = "COMPLIANCE_SUCCESS_GET_FILTER";
export const COMPLIANCE_GET_FAIL_FILTER = "COMPLIANCE_GET_FAIL_FILTER";

export const COMPLIANCE_REQUEST_GET_REJECT = "COMPLIANCE_REQUEST_GET_REJECT";
export const COMPLIANCE_SUCCESS_GET_REJECT = "COMPLIANCE_SUCCESS_GET_REJECT";
export const COMPLIANCE_REQUEST_GET_REJECT_FAIL = "COMPLIANCE_REQUEST_GET_REJECT_FAIL";

export const COMPLIANCE_REQUEST_REJECT = "COMPLIANCE_REQUEST_REJECT";
export const COMPLIANCE_SUCCESS_REJECT = "COMPLIANCE_SUCCESS_REJECT";
export const COMPLIANCE_REQUEST_REJECT_FAIL = "COMPLIANCE_REQUEST_REJECT_FAIL";

export const NAMERATE_CREATE_REQUEST = "NAMERATE_CREATE_REQUEST";
export const NAMERATE_CREATE_SUCCESS = "NAMERATE_CREATE_SUCCESS";
export const NAMERATE_CREATE_FAIL = "NAMERATE_CREATE_FAIL";

export const DOC_CREATE_REQUEST = "DOC_CREATE_REQUEST";
export const DOC_CREATE_SUCCESS = "DOC_CREATE_SUCCESS";
export const DOC_CREATE_FAIL = "DOC_CREATE_FAIL";

export const APPDETAILS_CREATE_REQUEST = "APPDETAILS_CREATE_REQUEST";
export const APPDETAILS_CREATE_SUCCESS = "APPDETAILS_CREATE_SUCCESS";
export const APPDETAILS_CREATE_FAIL = "APPDETAILS_CREATE_FAIL";

export const EXPENSE_CREATE_REQUEST = "EXPENSE_CREATE_REQUEST";
export const EXPENSE_CREATE_SUCCESS = "EXPENSE_CREATE_SUCCESS";
export const EXPENSE_CREATE_FAIL = "EXPENSE_CREATE_FAIL";

export const LICENSE_CREATE_REQUEST = "LICENSE_CREATE_REQUEST";
export const LICENSE_CREATE_SUCCESS = "LICENSE_CREATE_SUCCESS";
export const LICENSE_CREATE_FAIL = "LICENSE_CREATE_FAIL";

export const INVOICE_CREATE_REQUEST = "INVOICE_CREATE_REQUEST";
export const INVOICE_CREATE_SUCCESS = "INVOICE_CREATE_SUCCESS";
export const INVOICE_CREATE_FAIL = "INVOICE_CREATE_FAIL";

export const COMPANYINFO_CREATE_REQUEST = "COMPANYINFO_CREATE_REQUEST";
export const COMPANYINFO_CREATE_SUCCESS = "COMPANYINFO_CREATE_SUCCESS";
export const COMPANYINFO_CREATE_FAIL = "COMPANYINFO_CREATE_FAIL";

export const REGSGET_REQUEST = "REGSGET_REQUEST";
export const REGSGET_SUCCESS = "REGSGET_SUCCESS";
export const REGSGET_FAIL = "REGSGET_FAIL";

export const LISEREGS_REQUEST_GET_APPROVE = "LISEREGS_REQUEST_GET_APPROVE";
export const LISEREGS_SUCCESS_GET_APPROVE = "LISEREGS_SUCCESS_GET_APPROVE";
export const LISEREGS_REQUEST_GET_APPROVE_FAIL = "LISEREGS_REQUEST_GET_APPROVE_FAIL";

export const LISEREGS_REQUEST_GET_FILTER = "LISEREGS_REQUEST_GET_FILTER";
export const LISEREGS_SUCCESS_GET_FILTER = "LISEREGS_SUCCESS_GET_FILTER";
export const LISEREGS_REQUEST_GET_FILTER_FAIL = "LISEREGS_REQUEST_GET_FILTER_FAIL";

export const LISEREGS_REQUEST_GET_BYID = "LISEREGS_REQUEST_GET_BYID";
export const LISEREGS_SUCCESS_GET_BYID = "LISEREGS_SUCCESS_GET_FILTER";
export const LISEREGS_REQUEST_GET_BYID_FAIL = "LISEREGS_REQUEST_GET_BYID_FAIL";

export const LISEREGS_REQUEST_GET_REJECT = "LISEREGS_REQUEST_GET_REJECT";
export const LISEREGS_SUCCESS_GET_REJECT = "LISEREGS_SUCCESS_GET_REJECT";
export const LISEREGS_REQUEST_GET_REJECT_FAIL = "LISEREGS_REQUEST_GET_REJECT_FAIL";

export const NAMERATE_UPDATE_REQUEST = "NAMERATE_UPDATE_REQUEST";
export const NAMERATE_UPDATE_SUCCESS = "NAMERATE_UPDATE_SUCCESS";
export const NAMERATE_UPDATE_FAIL = "NAMERATE_UPDATE_FAIL";

export const DOC_UPDATE_REQUEST = "DOC_UPDATE_REQUEST";
export const DOC_UPDATE_SUCCESS = "DOC_UPDATE_SUCCESS";
export const DOC_UPDATE_FAIL = "DOC_UPDATE_FAIL";

export const APPDETAILS_UPDATE_REQUEST = "APPDETAILS_UPDATE_REQUEST";
export const APPDETAILS_UPDATE_SUCCESS = "APPDETAILS_UPDATE_SUCCESS";
export const APPDETAILS_UPDATE_FAIL = "APPDETAILS_UPDATE_FAIL";

export const EXPENSE_UPDATE_REQUEST = "EXPENSE_UPDATE_REQUEST";
export const EXPENSE_UPDATE_SUCCESS = "EXPENSE_UPDATE_SUCCESS";
export const EXPENSE_UPDATE_FAIL = "EXPENSE_UPDATE_FAIL";

export const LICENSE_UPDATE_REQUEST = "LICENSE_UPDATE_REQUEST";
export const LICENSE_UPDATE_SUCCESS = "LICENSE_UPDATE_SUCCESS";
export const LICENSE_UPDATE_FAIL = "LICENSE_UPDATE_FAIL";

export const INVOICE_UPDATE_REQUEST = "INVOICE_UPDATE_REQUEST";
export const INVOICE_UPDATE_SUCCESS = "INVOICE_UPDATE_SUCCESS";
export const INVOICE_UPDATE_FAIL = "INVOICE_UPDATE_FAIL";

export const COMPANYINFO_UPDATE_REQUEST = "COMPANYINFO_UPDATE_REQUEST";
export const COMPANYINFO_UPDATE_SUCCESS = "COMPANYINFO_UPDATE_SUCCESS";
export const COMPANYINFO_UPDATE_FAIL = "COMPANYINFO_UPDATE_FAIL";

export const ELIBRARY_CREATE_REQUEST = "ELIBRARY_CREATE_REQUEST";
export const ELIBRARY_CREATE_SUCCESS = "ELIBRARY_CREATE_SUCCESS";
export const ELIBRARY_CREATE_FAIL = "ELIBRARY_CREATE_FAIL";

export const ELIBRARY_GET_REQUEST = "ELIBRARY_GET_REQUEST";
export const ELIBRARY_GET_SUCCESS = "ELIBRARY_GET_SUCCESS";
export const ELIBRARY_GET_FAIL = "ELIBRARY_GET_FAIL";

export const ELIBRARY_REQUEST_GET_BYID = "ELIBRARY_REQUEST_GET_BYID";
export const ELIBRARY_SUCCESS_GET_BYID = "ELIBRARY_SUCCESS_GET_BYID";
export const ELIBRARY_REQUEST_GET_BYID_FAIL = "ELIBRARY_REQUEST_GET_BYID_FAIL";

export const ELIBRARY_GET_REQUEST_UPDATE_BY_ID = "ELIBRARY_GET_REQUEST_UPDATE_BY_ID";
export const ELIBRARY_GET_SUCCESS_UPDATE_BY_ID = "ELIBRARY_GET_SUCCESS_UPDATE_BY_ID";
export const ELIBRARY_GET_FAIL_UPDATE_BY_ID = "ELIBRARY_GET_FAIL_UPDATE_BY_ID";

export const ELIBRARY_REQUEST_GET_REJECT = "ELIBRARY_REQUEST_GET_REJECT";
export const ELIBRARY_SUCCESS_GET_REJECT = "ELIBRARY_SUCCESS_GET_REJECT";
export const ELIBRARY_REQUEST_GET_REJECT_FAIL = "ELIBRARY_REQUEST_GET_REJECT_FAIL";

export const ELIBRARY_REQUEST_SAVE_APPROVE = "ELIBRARY_REQUEST_SAVE_APPROVE";
export const ELIBRARY_SUCCESS_SAVE_APPROVE = "ELIBRARY_SUCCESS_SAVE_APPROVE";
export const ELIBRARY_REQUEST_SAVE_APPROVE_FAIL = "ELIBRARY_REQUEST_SAVE_APPROVE_FAIL";

export const ELIBRARY_REQUEST_GET_REJECT_LIST = "ELIBRARY_REQUEST_GET_REJECT_LIST";
export const ELIBRARY_SUCCESS_GET_REJECT_LIST = "ELIBRARY_SUCCESS_GET_REJECT_LIST";
export const ELIBRARY_GET_FAIL_REJECT_LIST = "ELIBRARY_GET_FAIL_REJECT_LIST";

export const AUDIT_REQUEST_OVERDUE_DETAIL_DASHBOARD = "AUDIT_REQUEST_OVERDUE_DETAIL_DASHBOARD";
export const AUDIT_SUCCESS_OVERDUE_DETAIL_DASHBOARD = "AUDIT_SUCCESS_OVERDUE_DETAIL_DASHBOARD";
export const AUDIT_FAIL_OVERDUE_DETAIL_DASHBOARD = "AUDIT_FAIL_OVERDUE_DETAIL_DASHBOARD";

export const COMPANYTAB1_CREATE_REQUEST = "COMPANYTAB1_CREATE_REQUEST";
export const COMPANYTAB1_CREATE_SUCCESS = "COMPANYTAB1_CREATE_SUCCESS";
export const COMPANYTAB1_CREATE_FAIL = "COMPANYTAB1_CREATE_FAIL";

export const COMPANYTAB2_CREATE_REQUEST = "COMPANYTAB2_CREATE_REQUEST";
export const COMPANYTAB2_CREATE_SUCCESS = "COMPANYTAB2_CREATE_SUCCESS";
export const COMPANYTAB2_CREATE_FAIL = "COMPANYTAB2_CREATE_FAIL";

export const COMPANYTAB3_CREATE_REQUEST = "COMPANYTAB3_CREATE_REQUEST";
export const COMPANYTAB3_CREATE_SUCCESS = "COMPANYTAB3_CREATE_SUCCESS";
export const COMPANYTAB3_CREATE_FAIL = "COMPANYTAB3_CREATE_FAIL";

export const COMPANYTAB4_CREATE_REQUEST = "COMPANYTAB4_CREATE_REQUEST";
export const COMPANYTAB4_CREATE_SUCCESS = "COMPANYTAB4_CREATE_SUCCESS";
export const COMPANYTAB4_CREATE_FAIL = "COMPANYTAB4_CREATE_FAIL";

export const COMPANYTAB5_CREATE_REQUEST = "COMPANYTAB5_CREATE_REQUEST";
export const COMPANYTAB5_CREATE_SUCCESS = "COMPANYTAB5_CREATE_SUCCESS";
export const COMPANYTAB5_CREATE_FAIL = "COMPANYTAB5_CREATE_FAIL";

export const COMPANYTAB6_CREATE_REQUEST = "COMPANYTAB6_CREATE_REQUEST";
export const COMPANYTAB6_CREATE_SUCCESS = "COMPANYTAB6_CREATE_SUCCESS";
export const COMPANYTAB6_CREATE_FAIL = "COMPANYTAB6_CREATE_FAIL";

export const COMPANYTAB7_CREATE_REQUEST = "COMPANYTAB7_CREATE_REQUEST";
export const COMPANYTAB7_CREATE_SUCCESS = "COMPANYTAB7_CREATE_SUCCESS";
export const COMPANYTAB7_CREATE_FAIL = "COMPANYTAB7_CREATE_FAIL";

export const COMPANYL_CREATE_REQUEST = "COMPANYL_CREATE_REQUEST";
export const COMPANYL_CREATE_SUCCESS = "COMPANYL_CREATE_SUCCESS";
export const COMPANYL_CREATE_FAIL = "COMPANYL_CREATE_FAIL";

export const COMPANY_GET_TABLE_REQUEST = "COMPANY_GET_TABLE_REQUEST";
export const COMPANY_GET_TABLE_SUCCESS = "COMPANY_GET_TABLE_SUCCESS";
export const COMPANY_GET_TABLE_FAIL = "COMPANY_GET_TABLE_FAIL";

export const COMPANY_REQUEST_GET_BYID = "COMPANY_REQUEST_GET_BYID";
export const COMPANY_SUCCESS_GET_BYID = "COMPANY_SUCCESS_GET_BYID";
export const COMPANY_REQUEST_GET_BYID_FAIL = "COMPANY_REQUEST_GET_BYID_FAIL";

export const COMPANYL_GET_REQUEST = "COMPANYL_GET_REQUEST";
export const COMPANYL_GET_SUCCESS = "COMPANYL_GET_SUCCESS";
export const COMPANYL_GET_FAIL = "COMPANYL_GET_FAIL";

export const COMPANYL_GET_REQUEST_BY_ID = "COMPANYL_GET_REQUEST_BY_ID";
export const COMPANYL_GET_SUCCESS_BY_ID = "COMPANYL_GET_SUCCESS_BY_ID";
export const COMPANYL_GET_FAIL_BY_ID = "COMPANYL_GET_FAIL_BY_ID";

export const COMPANY_LICENSE_REQUEST_GET_APPROVE = "COMPANY_LICENSE_REQUEST_GET_APPROVE";
export const COMPANY_LICENSE_SUCCESS_GET_APPROVE = "COMPANY_LICENSE_SUCCESS_GET_APPROVE";
export const COMPANY_LICENSE_REQUEST_GET_APPROVE_FAIL = "COMPANY_LICENSE_REQUEST_GET_APPROVE_FAIL";

export const COMPANY_LICENSE_REQUEST_GET_FILTER = "COMPANY_LICENSE_REQUEST_GET_FILTER";
export const COMPANY_LICENSE_SUCCESS_GET_FILTER = "COMPANY_LICENSE_SUCCESS_GET_FILTER";
export const COMPANY_LICENSE_GET_FAIL_FILTER = "COMPANY_LICENSE_GET_FAIL_FILTER";

export const COMPANY_REQUEST_SAVE_APPROVE = "COMPANY_REQUEST_SAVE_APPROVE";  /////company save and approve not implemented in backendyet
export const COMPANY_SUCCESS_SAVE_APPROVE = "COMPANY_SUCCESS_SAVE_APPROVE";
export const COMPANY_REQUEST_SAVE_APPROVE_FAIL = "COMPANY_REQUEST_SAVE_APPROVE_FAIL";

export const COMLIANCE_REQUEST_GET_BY_CSID = "COMLIANCE_REQUEST_GET_BY_CSID";
export const COMLIANCE_SUCCESS_GET_BY_CSID = "COMLIANCE_SUCCESS_GET_BY_CSID";
export const COMLIANCE_GET_BY_CSID_FAIL = "COMLIANCE_GET_BY_CSID_FAIL";    

export const COMPANY_INERACTION_CREATE_REQUEST = "COMPANY_INERACTION_CREATE_REQUEST";
export const COMPANY_INERACTION_CREATE_SUCCESS = "COMPANY_INERACTION_CREATE_SUCCESS";
export const COMPANY_INERACTION_CREATE_FAIL = "COMPANY_INERACTION_CREATE_FAIL";    

export const COMPANY_INTERACTION_REQUEST_GET_BYID = "COMPANY_INTERACTION_REQUEST_GET_BYID";
export const COMPANY_INTERACTION_SUCCESS_GET_BYID = "COMPANY_INTERACTION_SUCCESS_GET_BYID";
export const COMPANY_INTERACTION_FAIL_GET_BYID = "COMPANY_INTERACTION_FAIL_GET_BYID";    

export const COMPANY_INTERACTION_REQUEST_UPDATE_BYID = "COMPANY_INTERACTION_REQUEST_UPDATE_BYID";
export const COMPANY_INTERACTION_SUCCESS_UPDATE_BYID = "COMPANY_INTERACTION_SUCCESS_UPDATE_BYID";
export const COMPANY_INTERACTION_FAIL_UPDATE_BYID = "COMPANY_INTERACTION_FAIL_UPDATE_BYID"; 

export const COMPANY_INTERACTION_GET_TABLE_REQUEST = "COMPANY_INTERACTION_GET_TABLE_REQUEST";
export const COMPANY_INTERACTION_GET_TABLE_SUCCESS = "COMPANY_INTERACTION_GET_TABLE_SUCCESS";
export const COMPANY_INTERACTION_GET_TABLE_FAIL = "COMPANY_INTERACTION_GET_TABLE_FAIL"; 

export const COMPANY_PROFILE_INERACTION_REQUEST_GET_APPROVE = "COMPANY_PROFILE_INERACTION_REQUEST_GET_APPROVE";
export const COMPANY_PROFILE_INERACTION_SUCCESS_GET_APPROVE = "COMPANY_INTERACTION_GET_TABLE_SUCCESS";
export const COMPANY_PROFILE_INERACTION_REQUEST_GET_APPROVE_FAIL = "COMPANY_PROFILE_INERACTION_REQUEST_GET_APPROVE_FAIL";

export const COMPANY_INTERACT_REQUEST_GET_FILTER = "COMPANY_INTERACT_REQUEST_GET_FILTER";
export const COMPANY_INTERACT_SUCCESS_GET_FILTER = "COMPANY_INTERACT_SUCCESS_GET_FILTER";
export const COMPANY_INTERACT_GET_FAIL_FILTER = "COMPANY_INTERACT_GET_FAIL_FILTER"; 

export const COMPANY_INTERACTION_LICENSE_GET_REQUEST = "COMPANY_INTERACTION_LICENSE_GET_REQUEST";
export const COMPANY_INTERACTION_LICENSE_GET_SUCCESS = "COMPANY_INTERACTION_LICENSE_GET_SUCCESS";
export const COMPANY_INTERACTION_LICENSE_CREATE_FAIL = "COMPANY_INTERACTION_LICENSE_CREATE_FAIL"; 

export const COMPANY_INTERACTION_LIC_CREATE_REQUEST = "COMPANY_INTERACTION_LIC_CREATE_REQUEST";
export const COMPANY_INTERACTION_LIC_CREATE_SUCCESS = "COMPANY_INTERACTION_LIC_CREATE_SUCCESS";
export const COMPANY_INTERACTION_LIC_CREATE_FAIL = "COMPANY_INTERACTION_LIC_CREATE_FAIL"; 


export const COMPANY_INTERACTION_LIC_REQUEST_GET_BYID = "COMPANY_INTERACTION_LIC_REQUEST_GET_BYID";
export const COMPANY_INTERACTION_LIC_SUCCESS_GET_BYID = "COMPANY_INTERACTION_LIC_SUCCESS_GET_BYID";
export const COMPANY_INTERACTION_LIC_FAIL_GET_BYID = "COMPANY_INTERACTION_LIC_FAIL_GET_BYID"; 

export const COMPANY_INTERACTION_LIC_REQUEST_UPDATE_BYID = "COMPANY_INTERACTION_LIC_REQUEST_UPDATE_BYID";
export const COMPANY_INTERACTION_LIC_SUCCESS_UPDATE_BYID = "COMPANY_INTERACTION_LIC_CREATE_SUCCESS";
export const COMPANY_INTERACTION_LIC_FAIL_UPDATE_BYID = "COMPANY_INTERACTION_LIC_FAIL_UPDATE_BYID"; 

export const COMPANY_LICENSE_INERACTION_REQUEST_GET_APPROVE = "COMPANY_LICENSE_INERACTION_REQUEST_GET_APPROVE";
export const COMPANY_LICENSE_INERACTION_SUCCESS_GET_APPROVE = "COMPANY_LICENSE_INERACTION_SUCCESS_GET_APPROVE";
export const COMPANY_LICENSE_INERACTION_REQUEST_GET_APPROVE_FAIL = "COMPANY_LICENSE_INERACTION_REQUEST_GET_APPROVE_FAIL"; 

export const COMPANY_LICENSE_INTERACT_REQUEST_GET_FILTER = "COMPANY_LICENSE_INTERACT_REQUEST_GET_FILTER";
export const COMPANY_LICENSE_INTERACT_SUCCESS_GET_FILTER = "COMPANY_LICENSE_INTERACT_SUCCESS_GET_FILTER";
export const COMPANY_LICENSE_INTERACT_GET_FAIL_FILTER = "COMPANY_LICENSE_INTERACT_GET_FAIL_FILTER"

export const COMPANY_ASSIGN_CREATE_REQUEST = "COMPANY_ASSIGN_CREATE_REQUEST";
export const COMPANY_ASSIGN_CREATE_SUCCESS = "COMPANY_ASSIGN_CREATE_SUCCESS";
export const COMPANY_ASSIGN_CREATE_FAIL = "COMPANY_ASSIGN_CREATE_FAIL"; 

export const COMPANY_ASSIGNREQUEST_GET_BYID = "COMPANY_ASSIGNREQUEST_GET_BYID";
export const COMPANY_ASSIGNSUCCESS_GET_BYID = "COMPANY_ASSIGNSUCCESS_GET_BYID";
export const COMPANY_ASSIGNFAIL_GET_BYID = "COMPANY_ASSIGNFAIL_GET_BYID"; 

export const COMPANY_ASSIGN_REQUEST_UPDATE_BYID = "COMPANY_ASSIGN_REQUEST_UPDATE_BYID";
export const COMPANY_ASSIGN_SUCCESS_UPDATE_BYID = "COMPANY_ASSIGN_SUCCESS_UPDATE_BYID";
export const COMPANY_ASSIGN_FAIL_UPDATE_BYID = "COMPANY_ASSIGN_FAIL_UPDATE_BYID";

export const COMPANY_ASSIGN_GET_REQUEST = "COMPANY_ASSIGN_GET_REQUEST";
export const COMPANY_ASSIGN_GET_SUCCESS = "COMPANY_ASSIGN_GET_SUCCESS";
export const COMPANY_ASSIGN_TABLE_FAIL = "COMPANY_ASSIGN_TABLE_FAIL"; 

export const COMPANY_ASSIGN_ON_CREATE_GET_REQUEST = "COMPANY_ASSIGN_ON_CREATE_GET_REQUEST";
export const COMPANY_ASSIGN_ON_CREATE_GET_SUCCESS = "COMPANY_ASSIGN_ON_CREATE_GET_SUCCESS";
export const COMPANY_ASSIGN_ON_CREATE_CREATE_FAIL = "COMPANY_ASSIGN_ON_CREATE_CREATE_FAIL"; 

export const COMPANY_ASSIGN_REQUEST_GET_FILTER = "COMPANY_ASSIGN_REQUEST_GET_FILTER";
export const COMPANY_ASSIGN_SUCCESS_GET_FILTER = "COMPANY_ASSIGN_SUCCESS_GET_FILTER";
export const COMPANY_ASSIGN_GET_FAIL_FILTER = "COMPANY_ASSIGN_GET_FAIL_FILTER"; 

export const COMPANY_ASSIGNA_REQUEST_GET_FILTER = "COMPANY_ASSIGNA_REQUEST_GET_FILTER";
export const COMPANY_ASSIGNA_SUCCESS_GET_FILTER = "COMPANY_ASSIGNA_SUCCESS_GET_FILTER";
export const COMPANY_ASSIGNA_GET_FAIL_FILTER = "COMPANY_ASSIGNA_GET_FAIL_FILTER"; 

// src/redux/actionTypes.js
export const CHECKLIST_ADD_IN_AUDIT_REQUEST = 'CHECKLIST_ADD_IN_AUDIT_REQUEST';
export const CHECKLIST_ADD_IN_AUDIT_SUCCESS = 'CHECKLIST_ADD_IN_AUDIT_SUCCESS';
export const CHECKLIST_ADD_IN_AUDIT_FAILURE = 'CHECKLIST_ADD_IN_AUDIT_FAILURE';

export const FILE_UPLOADS_REQUEST = 'FILE_UPLOADS_REQUEST';
export const FILE_UPLOADS_SUCCESS = 'FILE_UPLOADS_SUCCESS';
export const FILE_UPLOADS_FAIL = 'FILE_UPLOADS_FAIL';


export const AUDIT_UPLOAD_STATUS_REQUEST = 'AUDIT_UPLOAD_STATUS_REQUEST';
export const AUDIT_UPLOAD_STATUS_SUCCESS = 'AUDIT_UPLOAD_STATUS_SUCCESS';
export const AUDIT_UPLOAD_STATUS_FAILURE = 'AUDIT_UPLOAD_STATUS_FAILURE';


export const NOTIFICATION_REQUEST = 'NOTIFICATION_REQUEST';
export const NOTIFICATION_SUCCESS = 'NOTIFICATION_SUCCESS';
export const NOTIFICATION_FAILURE = 'NOTIFICATION_FAILURE';


export const EXECUTIVECOMMON_REQUEST_GET = 'EXECUTIVECOMMON_REQUEST_GET';
export const EXECUTIVECOMMON_SUCCESS_GET = 'EXECUTIVECOMMON_SUCCESS_GET';
export const EXECUTIVECOMMON_GET_FAIL = 'EXECUTIVECOMMON_GET_FAIL';

export const COMPANYTAB1_UPDATE_REQUEST = "COMPANYTAB1_UPDATE_REQUEST";
export const COMPANYTAB1_UPDATE_SUCCESS = "COMPANYTAB1_UPDATE_SUCCESS";
export const COMPANYTAB1_UPDATE_FAIL = "COMPANYTAB1_UPDATE_FAIL";

export const COMPANYTAB2_UPDATE_REQUEST = "COMPANYTAB2_UPDATE_REQUEST";
export const COMPANYTAB2_UPDATE_SUCCESS = "COMPANYTAB2_UPDATE_SUCCESS";
export const COMPANYTAB2_UPDATE_FAIL = "COMPANYTAB2_UPDATE_FAIL";

export const COMPANYTAB3_UPDATE_REQUEST = "COMPANYTAB3_UPDATE_REQUEST";
export const COMPANYTAB3_UPDATE_SUCCESS = "COMPANYTAB3_UPDATE_SUCCESS";
export const COMPANYTAB3_UPDATE_FAIL = "COMPANYTAB3_UPDATE_FAIL";

export const COMPANYTAB4_UPDATE_REQUEST = "COMPANYTAB4_UPDATE_REQUEST";
export const COMPANYTAB4_UPDATE_SUCCESS = "COMPANYTAB4_UPDATE_SUCCESS";
export const COMPANYTAB4_UPDATE_FAIL = "COMPANYTAB4_UPDATE_FAIL";

export const COMPANYTAB5_UPDATE_REQUEST = "COMPANYTAB5_UPDATE_REQUEST";
export const COMPANYTAB5_UPDATE_SUCCESS = "COMPANYTAB5_UPDATE_SUCCESS";
export const COMPANYTAB5_UPDATE_FAIL = "COMPANYTAB5_UPDATE_FAIL";

export const COMPANYTAB6_UPDATE_REQUEST = "COMPANYTAB6_UPDATE_REQUEST";
export const COMPANYTAB6_UPDATE_SUCCESS = "COMPANYTAB6_UPDATE_SUCCESS";
export const COMPANYTAB6_UPDATE_FAIL = "COMPANYTAB6_UPDATE_FAIL";

export const COMPANYTAB7_UPDATE_REQUEST = "COMPANYTAB7_UPDATE_REQUEST";
export const COMPANYTAB7_UPDATE_SUCCESS = "COMPANYTAB7_UPDATE_SUCCESS";
export const COMPANYTAB7_UPDATE_FAIL = "COMPANYTAB7_UPDATE_FAIL";

export const UPDATE_AUDIT_FIELDS_REQUEST = 'UPDATE_AUDIT_FIELDS_REQUEST';
export const UPDATE_AUDIT_FIELDS_SUCCESS = 'UPDATE_AUDIT_FIELDS_SUCCESS';
export const UPDATE_AUDIT_FIELDS_FAILURE = 'UPDATE_AUDIT_FIELDS_FAILURE';

export const UPDATE_DUE_DATES_REQUEST = 'UPDATE_DUE_DATES_REQUEST';
export const UPDATE_DUE_DATES_SUCCESS = 'UPDATE_DUE_DATES_SUCCESS';
export const UPDATE_DUE_DATES_FAILURE = 'UPDATE_DUE_DATES_FAILURE';


export const BRANCHES_ADD_REQUEST = 'BRANCHES_ADD_REQUEST';
export const BRANCHES_ADD_SUCCESS = 'BRANCHES_ADD_SUCCESS';
export const BRANCHES_ADD_FAILURE = 'BRANCHES_ADD_FAILURE';


export const BRANCHES_GET_REQUEST = 'BRANCHES_GET_REQUEST';
export const BRANCHES_GET_SUCCESS = 'BRANCHES_GET_SUCCESS';
export const BRANCHES_GET_FAIL = 'BRANCHES_GET_FAIL';


export const BRANCHES_TABLE_GET_REQUEST = 'BRANCHES_TABLE_GET_REQUEST';
export const BRANCHES_TABLE_GET_SUCCESS = 'BRANCHES_TABLE_GET_SUCCESS';
export const BRANCHES_TABLE_GET_FAIL = 'BRANCHES_TABLE_GET_FAIL';


export const BRANCHES_EDIT_REQUEST = 'BRANCHES_EDIT_REQUEST';
export const BRANCHES_EDIT_SUCCESS = 'BRANCHES_EDIT_SUCCESS';
export const BRANCHES_EDIT_FAILURE = 'BRANCHES_EDIT_FAILURE';


export const BRANCHES_GET_BY_ID_REQUEST = 'BRANCHES_GET_BY_ID_REQUEST';
export const BRANCHES_GET_BY_ID_SUCCESS = 'BRANCHES_GET_BY_ID_SUCCESS';
export const BRANCHES_GET_BY_ID_FAILURE = 'BRANCHES_GET_BY_ID_FAILURE';

