import React,{useState,useEffect,useRef} from 'react';
import { FormGroup,styled,ImageListItem,ImageList } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon  from '@mui/icons-material/RemoveCircleOutline';

import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import {checklistCreate,stateGets,usersGet,categoryGet,branchGet,compliancesAllForChecklist,companyTableGet,checklistUpdateById,checklistGetByid} from "../../store/actions/otherActions";

//import { updatestatuswithremark } from '../../routes/api';
import { useForm, Form } from '../../components/useForm';
const ChecklistPopup = ({ addOrEdit,recordForEdit }) => {
//   alert(recordForEdit._id); 
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let defaultDate = new Date()
    defaultDate.setDate(defaultDate.getDate() )

    const [date, setDate] = useState(defaultDate)

    const onSetDate = (event) => {
        setDate(new Date(event.target.value))
    }
    const checklistAllComliance = useSelector((state) => state.checklistAllComliance);
    const { checklistAllComp } = checklistAllComliance; 
    console.log(checklistAllComp)
    const catGet = useSelector((state) => state.catGet);
    const { loading, categoryInfo,error } = catGet;  
    const getState = useSelector((state) => state.getState);
    const { loadings,stateInfo } = getState;  
    const userLogin = useSelector(state=>state.userLogin);
    const {userInfo} = userLogin;
    console.log(userInfo);
    const getBranch = useSelector((state) => state.getBranch);
    const { branchInfo } = getBranch; 
    const getCompanyTable = useSelector(state => state.getCompanyTable)
    const {loadingcompanytable, companyGetTableInfo } = getCompanyTable;
    const checklist = useSelector((state) => state.checklist);
    const { loadingChecklist,checklistInfo } = checklist; 
    const checklistId = useSelector((state) => state.checklistId);
    const { loadingg,checklistInfoId } = checklistId;
    // const myElementRefCompany = useRef(null);
    // const myElementRefBranch = useRef(null);
    const [category, setCategory] = useState();
    const [image,setImage] = useState('');
    const [document,setDocument] = useState('');
    const [fileName,setFileName] = useState('');
    const [state, setState] = useState('');
    const [categorys, setCategorys] = useState();
    const [company, setCompany] = useState('');
    const [compliance,setCompliance] = useState('');
    const [branch,setBranch] = useState('');
    const [rule,setRule] = useState('');
    const [question,setQuestion] = useState('');
    const [description,setDescription] = useState('');
    const [frequency,setFrequency] = useState('');
    const [risk,setRisk] = useState('');
    const [fileto,setFile] = useState('');
    const [documentto,setFileDocument] = useState('');
    const [inputList, setInputList] = useState([{ id: 1, value: '' }]);
    const [inputListQuestion, setInputListQuestion] = useState([{ id: 1, value: '' }]);
    const [inputListDescription, setInputListDescription] = useState([{ id: 1, value: '' }]);
    const [rulestype, setRules1] = useState([]);
    const [questionstype, setQuestions] = useState([]);
    const [desctype, setDesc] = useState([]);
    const [pdffound, setCheckPdf] = useState(false);
    const [filepdf,setPdf] = useState('');
    const [filepdf1,setPdf1] = useState('');
    const [filepdf2,setPdf2] = useState('');
  const [compliancetype, setCompliancetype] = useState("");
    

    //aj 


  const [act, setAct] = useState("");
  const [rules, setRules] = useState([
    { rule: "", docFile: "", questions: [] },
  ]);

  const [newRule, setNewRule] = useState({
    rule: "",
    docFile: "",
    questions: [],
  });
  const [newQuestion, setNewQuestion] = useState({
    question: "",
    docattachment: "",
    description: "",
    compliancetype: "",
    consequences: "",
    frequency: "",
    risk: "",
    startDate: "",
    dueDate: "",
    DateofCompletion: "",
    DelayinDays: "",
    CompliedStatus: "",
    RemarksStatus: "",
  });

    //aj
    const handleAddInput = () => {
        const newInputList = [...inputList];
        const newInput = { id: inputList.length + 1, value: '' };
        newInputList.push(newInput);
        setInputList(newInputList);
    };
    const handleInputChange = (id, event) => {
        const newInputList = inputList.map((item) =>
          item.id === id ? { ...item, value: event.target.value } : item
        );
        setInputList(newInputList);
    };
    const handleAddInputQuestion = () => {
        const newInputListQuestion = [...inputListQuestion];
        const newInputQuestion = { id: inputListQuestion.length + 1, value: '' };
        newInputListQuestion.push(newInputQuestion);
        setInputListQuestion(newInputListQuestion);
    };
    const handleInputChangeQuestion = (id, event) => {
        const newInputListQuestion = inputListQuestion.map((item) =>
          item.id === id ? { ...item, value: event.target.value } : item
        );
        setInputListQuestion(newInputListQuestion);
    };
    const handleAddInputDescription = () => {
        const newInputListDescription = [...inputListDescription];
        const newInputDescription = { id: inputListDescription.length + 1, value: '' };
        newInputListDescription.push(newInputDescription);
        setInputListDescription(newInputListDescription);
    };
    const handleInputChangeDescription = (id, event) => {
        const newInputListDescription = inputListDescription.map((item) =>
          item.id === id ? { ...item, value: event.target.value } : item
        );
        setInputListDescription(newInputListDescription);
    };
    const handleProductDocumentUpload= (e) => {
        const file = e.target.files[0];
        setFileDocument(e.target.files[0]);
        TransformFileDataDoc(file);
    };
    //reading image using The FileReader object lets web applications asynchronously read the contents of files (or raw data buffers) stored on the user's computer, using File or Blob objects to specify the file or data to read.
    const TransformFileDataDoc = (file) => {
        const reader = new FileReader();
        const fileType =file.type;
        let types = false; 
        if(fileType!=="application/pdf"){
            types = true; 
            alert('You can only upload PDF file!');
            return false;
        }
        else{
            types = false;
        }
        if(types===false){
           // alert('sdsds')
            if (file) {
              console.log(file);
              
              setDocument(file);
                // reader.readAsDataURL(file);
                // reader.onloadend = () => {
                //     setDocument(reader.result);
                // }
            }
        }
        else{
            setDocument("");
        }
      }; 
    const handleProductImageUpload = (e) => {
        const file = e.target.files[0];
    //    alert(JSON.stringify(file))
        setFile(file);
        setFileName(e.target.files[0].name);
        TransformFileData(file);
    };
    //reading image using The FileReader object lets web applications asynchronously read the contents of files (or raw data buffers) stored on the user's computer, using File or Blob objects to specify the file or data to read.
    const TransformFileData = (file) => {
        const reader = new FileReader();
        const fileType =file.type;
        let types = false; 
        if(fileType!=="image/jpeg" && fileType!=="image/bmp" && fileType!=="image/jpg" && fileType!=="image/png" && fileType!=="application/pdf"){
            types = true; 
            alert('You can only upload JPG/JPEG/PNG/BMP/PDF file!');
            return false;
        }
        else{
            types = false;
        }
        if(types===false){
         //   alert('sdsds')
            if (file) {
                reader.readAsDataURL(file);
                reader.onloadend = () => {
                  setImage(reader.result);
                }
            }
        }
        else{
            setImage("");
        }
    }; 
   
    const handleClose = () => {
       // setDescription('');
        addOrEdit();
        //setOpenPopup(false);
       // recordForEdit(false)
    };
   
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(rules);
        // alert(JSON.stringify(inputList))
        // let rules = "";
        // inputList.map((item, index) => (rules = rules + "\n" + item.value));
        // rules.slice(1);
        let questions = "";
        inputListQuestion.map(
          (item, index) => (questions = questions + "\n" + item.value)
        );
        questions.slice(1);
        let description = "";
        inputListDescription.map(
          (item, index) => (description = description + "\n" + item.value)
        );
        description.slice(1);
        //  alert(rules.slice(1));
        //  alert(questions.slice(1));
        //  alert( description.slice(1)); return;
        //  alerlett(risk); return;
        let formData = new FormData();
        // const rulesFromFormData = JSON.parse(formData.get("rule"));
    
        if (recordForEdit?.id !== undefined && recordForEdit?.id !== null) {
          formData.append("category", categorys);
          formData.append("state", state);
          formData.append("act", act);
          // formData.append("rule", JSON.stringify(rules));
          // formData.append("rule", JSON.stringify(rulesFromFormData)); // Use rulesFromFormData here
          rules.forEach((rule, index) => {
            formData.append(`rules[${index}][rule]`, rule.rule);
            formData.append(`rules[${index}][docFile]`, rule.docFile);
            rule.questions.forEach((question, qIndex) => {
              formData.append(
                `rules[${index}][questions][${qIndex}][question]`,
                question.question
              );
              formData.append(
                `rules[${index}][questions][${qIndex}][docattachment]`,
                question.docattachment
              );
              formData.append(
                `rules[${index}][questions][${qIndex}][description]`,
                question.description
              );
              formData.append(
                `rules[${index}][questions][${qIndex}][compliancetype]`,
                question.compliancetype
              );
              formData.append(
                `rules[${index}][questions][${qIndex}][categorycomp]`,
                question.categorycomp
              );
              formData.append(
                `rules[${index}][questions][${qIndex}][consequences]`,
                question.consequences
              );
              formData.append(
                `rules[${index}][questions][${qIndex}][frequency]`,
                question.frequency
              );
              formData.append(
                `rules[${index}][questions][${qIndex}][risk]`,
                question.risk
              );
              // formData.append(
                formData.append(
                  `rules[${index}][questions][${qIndex}][dueDate]`,
                  question.dueDate
                );
                formData.append(
                  `rules[${index}][questions][${qIndex}][startDate]`,
                  question.startDate
                );
              //   `rules[${index}][questions][${qIndex}][createdDate]`,
              //   question.createdDate
              // );
              // formData.append(
              //   `rules[${index}][questions][${qIndex}][updatedDate]`,
              //   question.updatedDate
              // );
              formData.append(
                `rules[${index}][questions][${qIndex}][DateofCompletion]`,
                question.DateofCompletion
              );
              formData.append(
                `rules[${index}][questions][${qIndex}][DelayinDays]`,
                question.DelayinDays
              );
              formData.append(
                `rules[${index}][questions][${qIndex}][CompliedStatus]`,
                question.CompliedStatus
              );
              formData.append(
                `rules[${index}][questions][${qIndex}][RemarksStatus]`,
                question.RemarksStatus
              );
            });
          });
          // formData.append("ruletype", rules);
          formData.append("question", questions.slice(1));
          formData.append("description", description.slice(1));
          formData.append("executive", userInfo._id); 
          formData.append("image", fileto);
          // formData.append("actDocument", documentto);
          formData.append("document", documentto);
    
            formData.append("docattachmenttype", document);
    
          formData.append("formtype", image);
          // formData.append("actDocument", actDocument);
          formData.append("compliancetype", compliancetype);
          // formData.append("duedate", duedate);
          formData.append("frequency", frequency);
          formData.append("risk", risk);
          formData.append("dates", date);
          // formData.append("status", status);
        //   dispatch(complianceCreate(formData));
          console.log("one",formData)

        dispatch(checklistUpdateById(formData,recordForEdit?.id));

          setCategorys('');
          setState('');
          setAct('');
          setRules('');
          setQuestion('');
          setDescription('');
          setCompliancetype('');
          setFrequency('');
          setRisk('');
          handleClose(loadingg);
        } else {
          formData.append("category", categorys);
          formData.append("state", state);
          formData.append("act", act);
          // formData.append("rule", JSON.stringify(rules));
          // formData.append("rule", JSON.stringify(rulesFromFormData)); // Use rulesFromFormData here
          rules.forEach((rule, index) => {
            formData.append(`rules[${index}][rule]`, rule.rule);
            formData.append(`rules[${index}][docFile]`, rule.docFile);
            rule.questions.forEach((question, qIndex) => {
              formData.append(
                `rules[${index}][questions][${qIndex}][question]`,
                question.question
              );
              formData.append(
                `rules[${index}][questions][${qIndex}][docattachment]`,
                question.docattachment
              );
              formData.append(
                `rules[${index}][questions][${qIndex}][description]`,
                question.description
              );
              formData.append(
                `rules[${index}][questions][${qIndex}][compliancetype]`,
                question.compliancetype
              );
              formData.append(
                `rules[${index}][questions][${qIndex}][categorycomp]`,
                question.categorycomp
              );
              formData.append(
                `rules[${index}][questions][${qIndex}][consequences]`,
                question.consequences
              );
              formData.append(
                `rules[${index}][questions][${qIndex}][frequency]`,
                question.frequency
              );
              formData.append(
                `rules[${index}][questions][${qIndex}][risk]`,
                question.risk
              );
              // formData.append(
                formData.append(
                  `rules[${index}][questions][${qIndex}][dueDate]`,
                  question.dueDate
                );
                formData.append(
                  `rules[${index}][questions][${qIndex}][startDate]`,
                  question.startDate
                );
              //   `rules[${index}][questions][${qIndex}][createdDate]`,
              //   question.createdDate
              // );
              // formData.append(
              //   `rules[${index}][questions][${qIndex}][updatedDate]`,
              //   question.updatedDate
              // );
              formData.append(
                `rules[${index}][questions][${qIndex}][DateofCompletion]`,
                question.DateofCompletion
              );
              formData.append(
                `rules[${index}][questions][${qIndex}][DelayinDays]`,
                question.DelayinDays
              );
              formData.append(
                `rules[${index}][questions][${qIndex}][CompliedStatus]`,
                question.CompliedStatus
              );
              formData.append(
                `rules[${index}][questions][${qIndex}][RemarksStatus]`,
                question.RemarksStatus
              );
            });
          });
          // formData.append("ruletype", rules);
          formData.append("question", questions.slice(1));
          formData.append("description", description.slice(1));
          formData.append("executive", userInfo._id);
          formData.append("image", fileto);
          // formData.append("actDocument", documentto);
          formData.append("document", documentto);
    
            formData.append("docattachmenttype", document);
    
          formData.append("formtype", image);
          // formData.append("actDocument", actDocument);
          formData.append("compliancetype", compliancetype);
          // formData.append("duedate", duedate);
          formData.append("frequency", frequency);
          formData.append("risk", risk);
          formData.append("dates", date);
          // formData.append("status", status);
        //   dispatch(complianceCreate(formData));
    console.log("two",formData)

        dispatch(checklistCreate(formData));
        setCategorys('');
        setState('');
        setAct('');
        setRules('');
        setQuestion('');
        setDescription('');
        setCompliancetype('');
        setFrequency('');
        setRisk('');
        handleClose(loadingChecklist);
        }
        };
      // console.log("form",handleSubmit)
    useEffect(()=>{
        dispatch(categoryGet());
        dispatch(stateGets());
        dispatch(usersGet());
        // const elementcompanybranch = myElementRefCompany.current;
        // const postBody = {
        //   id : elementcompanybranch.value
        // }
        // if (elementcompanybranch) {
        //   dispatch(branchGet(postBody));
        // }
        dispatch(compliancesAllForChecklist());
        dispatch(companyTableGet());
    },[dispatch]);
    // const getBbranch = (company) => {
    //     const elementcompanybranch = myElementRefCompany.current;
    //     const postBody = {
    //      id : elementcompanybranch.value
    //    }
    //     dispatch(branchGet(postBody));
    //   }
    useEffect(()=>{
        let categoryArr = [];
        if (typeof (categoryInfo) !== 'undefined' && categoryInfo?.length > 0 ) {
            //alert(categoryInfo?.length);
            categoryInfo.map((item, index) => {
                categoryArr.push({
                key:index,
                id: item._id,
                name: item.name
              })
          });
        }
        setCategory(categoryArr);
    },[categoryInfo]);
    useEffect(()=>{
        if(recordForEdit?.id !== undefined && recordForEdit?.id !== null) {
            dispatch(checklistGetByid(recordForEdit.id));
        }
        dispatch(categoryGet());
        dispatch(stateGets());
        dispatch(usersGet());
    },[dispatch]);
    useEffect(() => {
      if(recordForEdit?.id !== undefined && recordForEdit?.id !== null) {
          if(checklistInfoId !== undefined ) {
              setCompliance(checklistInfoId?.compliance);
              setCategorys(checklistInfoId?.category);
              setAct(checklistInfoId?.act);
              setState(checklistInfoId?.state);
              setBranch(checklistInfoId?.branchname);
              setCompany(checklistInfoId?.company);
              if (checklistInfoId.rules) {
                const ruleTypeArr = checklistInfoId.rules.map((rule, index) => ({
                  
                    id: index,
                    rule: rule.rule,
                  //   docFile: {(
                  //   // rule.docFile
                  //   if(rule?.docFile !==  undefined ){
                  //     setPdf(<a href={rule?.docFile} target="_blank">Click to show pdf</a>)
                  // })}
                  //   ,
                    docFile: rule.docFile,
                    questions: rule.questions.map((question, qIndex)=> ({
                      id: qIndex,
                        question: question.question,
                        description: question.description,
                        compliancetype: question.compliancetype,
                        categorycomp: question.categorycomp,
                        consequences: question.consequences,
                        frequency: question.frequency,
                        risk: question.risk,
                        
                        DelayinDays: question.DelayinDays,
                        DateofCompletion: question.DateofCompletion,
                        dueDate: question.dueDate,
                        startDate: question.startDate,
                        CompliedStatus: question.CompliedStatus,
                        RemarksStatus: question.RemarksStatus,
                        docattachment: question.docattachment
                    }))
                }));

                
                console.log("ruleTypeArr:", ruleTypeArr);
                setRules(ruleTypeArr);



                const pdfLinks = ruleTypeArr
                .filter(rule => rule.docFile && rule.docFile !== 'undefined')  // Check for both existence and string 'undefined'
                .map((rule, index) => (
                  <a key={index} href={rule.docFile} target="_blank" rel="noopener noreferrer">Click to show Rule Pdf</a>
                ));

              setPdf1(pdfLinks);
              console.log("pdfLinks:", pdfLinks);

              const questionPdfLinks = ruleTypeArr
              .flatMap((rule,index) => 
                rule.questions
                  .filter(question => question.docattachment && question.docattachment !== 'undefined')  // Check for valid docattachment
                  .map((question, qIndex) => (
                    <a key={`${index}-${qIndex}`} href={question.docattachment} target="_blank" rel="noopener noreferrer">Click to show Question Pdf</a>
                  ))
              );
    
            setPdf2(questionPdfLinks);  
            console.log("questionPdfLinks:", questionPdfLinks);



              }
              let questiontypeArr = [];
              if((checklistInfoId?.questiontype) !==  undefined) {
                  (checklistInfoId?.questiontype).map((item)=>{
                      if(item !== undefined) 
                      {
                          questiontypeArr.push({
                          id: item.id,
                          value: item.value
                          })
                      }
                      setQuestions(questiontypeArr)
                  });
              }
              setInputListQuestion(questionstype);
              let desctypeArr = [];
              if((checklistInfoId?.descriptiontype) !==  undefined) {
                  (checklistInfoId?.descriptiontype).map((item)=>{
                      if(item !== undefined) 
                      {
                          desctypeArr.push({
                          id: item.id,
                          value: item.value
                          })
                          
                      }
                      setDesc(desctypeArr)
                  });
              }
              if(checklistInfoId?.image !==  undefined ){
                  const imagedocsplit = (checklistInfoId?.image).split('/');
                  if(imagedocsplit[4]  !==  undefined ){
                      const fileext = ((imagedocsplit[4].indexOf('pdf')))
                     
                      if(fileext !=-1){
                          setPdf(<a href={checklistInfoId?.image} target="_blank">Click to show pdf</a>)
                          setCheckPdf(true);
                      }
                      else{
                          //setImageShow(<a href={complianceInfoId?.form} target="_blank">Click to show pdf</a>)
                          setImage(checklistInfoId?.image)
                      }
                  }
                  
              }
              if(checklistInfoId?.document !==  undefined ){
                  setPdf(<a href={checklistInfoId?.document} target="_blank">Click to show pdf</a>)
              }
               
              
              setInputListDescription(desctype);
              //setCompliancetype(checklistInfoId?.compliancetype);
              setFrequency(checklistInfoId?.frequency);
              setRisk(checklistInfoId?.risk);
              
          }
      }    
  },[checklistInfoId,recordForEdit])
    const tocategorypage = () => {
        navigate('/dashboard')
    };
    const handleAddRule = () => {
        setRules([...rules, newRule]);
        setNewRule({ rule: "", docFile: "", questions: [] });
      };
    
    const addQuestion = () => {
        const updatedRules = [...rules];
        updatedRules[updatedRules.length - 1].questions.push(newQuestion);
        setRules1(updatedRules);
        setNewQuestion({
          name: "",
          docattachment: null,
          description: "",
          compliancetype: "",
          consequences: "",
          categorycomp: "",
          compliancetype: "",
          // duedate: "",
          frequency: "",
          risk: "",
          startDate: "",
          dueDate: "",
          DateofCompletion: "",
          DelayinDays: "",
          CompliedStatus: "",
          RemarksStatus: "",
          // dates: "",
        });
        return updatedRules;
      };
      const removeQuestion = (rIndex, qIndex) => {
        const updatedRules = [...rules];
        updatedRules[rIndex].questions.splice(qIndex, 1);
        setRules1(updatedRules);
      };
    const handleRuleChange = (index, field, value) => {
        const updatedRules = [...rules];
        updatedRules[index][field] = value;
        setRules(updatedRules);
      };


      
      const handleQuestionChange = (rIndex, qIndex, field, value) => {
        console.log("value", value);
        const updatedRules = [...rules];
        updatedRules[rIndex].questions[qIndex][field] = value;
        setRules(updatedRules);
        console.log("updatedRules", updatedRules);

      };
      const handleQuestionChange1 = (rIndex, qIndex, field, value) => {
        console.log("value", value);
        const updatedRules = [...rules];
        updatedRules[rIndex].questions[qIndex][field] = value;
        setRules(updatedRules);
        console.log("updatedRules", updatedRules);

      };
      const handleQuestionChangeFile = (rIndex, qIndex, field, value) => {
        console.log("value", value);
        const updatedRules = [...rules];
        if (field === 'docattachment') {
          updatedRules[rIndex].questions[qIndex][field] = value;
        } else {
          updatedRules[rIndex].questions[qIndex][field] = value;
        }
        setRules(updatedRules);
        console.log("updatedRules", updatedRules);
      };


      const handleQuestionChange22 = (rIndex, qIndex, field, value) => {
        const updatedQuestions = [...rules];
        
        if (!updatedQuestions[rIndex]) {
          updatedQuestions[rIndex] = [];
        }
      
        if (!updatedQuestions[rIndex].questions[qIndex]) {
          updatedQuestions[rIndex].questions[qIndex] = {};
        }
      
        updatedQuestions[rIndex].questions[qIndex][field] = value;
      
        // Automatically set Due Date to 15 days from Start Date if Frequency is Fortnightly
        if (field === "frequency" && value === "Fortnightly") {
          const startDate = updatedQuestions[rIndex].questions[qIndex].startDate;
          if (startDate) {
            const startDateObj = new Date(startDate);
            const dueDateObj = new Date(startDateObj);
            dueDateObj.setDate(startDateObj.getDate() + 15);
            updatedQuestions[rIndex].questions[qIndex].dueDate = dueDateObj.toISOString().split('T')[0];
          }
        }
        if (field === "frequency" && value === "Monthly") {
          const startDate = updatedQuestions[rIndex].questions[qIndex].startDate;
          if (startDate) {
            const startDateObj = new Date(startDate);
            const dueDateObj = new Date(startDateObj);
            // dueDateObj.setDate(startDateObj.getDate() + 30);
            dueDateObj.setMonth(startDateObj.getMonth() + 1);
            updatedQuestions[rIndex].questions[qIndex].dueDate = dueDateObj.toISOString().split('T')[0];
          }
        }
        if (field === "frequency" && value === "Quarterly") {
          const startDate = updatedQuestions[rIndex].questions[qIndex].startDate;
          if (startDate) {
            const startDateObj = new Date(startDate);
            const dueDateObj = new Date(startDateObj);
            // dueDateObj.setDate(startDateObj.getDate() + 91);
            dueDateObj.setMonth(startDateObj.getMonth() + 3);
            updatedQuestions[rIndex].questions[qIndex].dueDate = dueDateObj.toISOString().split('T')[0];
          }
        }
        if (field === "frequency" && value === "Half Yearly") {
          const startDate = updatedQuestions[rIndex].questions[qIndex].startDate;
          if (startDate) {
            const startDateObj = new Date(startDate);
            const dueDateObj = new Date(startDateObj);
            // dueDateObj.setDate(startDateObj.getDate() + 182);
            dueDateObj.setMonth(startDateObj.getMonth() + 6);
            updatedQuestions[rIndex].questions[qIndex].dueDate = dueDateObj.toISOString().split('T')[0];
          }
        }
        if (field === "frequency" && value === "Yearly") {
          const startDate = updatedQuestions[rIndex].questions[qIndex].startDate;
          if (startDate) {
            const startDateObj = new Date(startDate);
            const dueDateObj = new Date(startDateObj);
            // dueDateObj.setDate(startDateObj.getDate() + 365);
          dueDateObj.setFullYear(startDateObj.getFullYear() + 1);
            updatedQuestions[rIndex].questions[qIndex].dueDate = dueDateObj.toISOString().split('T')[0];
          }
        }
        if (field === "frequency" && value === "Bi-Annual") {
          const startDate = updatedQuestions[rIndex].questions[qIndex].startDate;
          if (startDate) {
            const startDateObj = new Date(startDate);
            const dueDateObj = new Date(startDateObj);
            // dueDateObj.setDate(startDateObj.getDate() + 730);
            dueDateObj.setFullYear(startDateObj.getFullYear() + 2);
            updatedQuestions[rIndex].questions[qIndex].dueDate = dueDateObj.toISOString().split('T')[0];
          }
        }
      
        setQuestions(updatedQuestions);
      };
      
      const handleQuestionChange11 = (rIndex, qIndex, field, value) => {
        const updatedQuestions = [...rules];
      
        if (!updatedQuestions[rIndex]) {
          updatedQuestions[rIndex] = [];
        }
      
        if (!updatedQuestions[rIndex].questions[qIndex]) {
          updatedQuestions[rIndex].questions[qIndex] = {};
        }
      
        updatedQuestions[rIndex].questions[qIndex][field] = value;
      
        if (field === "startDate" && updatedQuestions[rIndex].questions[qIndex].frequency === "Fortnightly") {
          const startDateObj = new Date(value);
          const dueDateObj = new Date(startDateObj);
          dueDateObj.setDate(startDateObj.getDate() + 15);
          updatedQuestions[rIndex].questions[qIndex].dueDate = dueDateObj.toISOString().split('T')[0];
        }
        if (field === "startDate" && updatedQuestions[rIndex].questions[qIndex].frequency === "Monthly") {
          const startDateObj = new Date(value);
          const dueDateObj = new Date(startDateObj);
          // dueDateObj.setDate(startDateObj.getDate() + 30);
          dueDateObj.setMonth(startDateObj.getMonth() + 1);
          updatedQuestions[rIndex].questions[qIndex].dueDate = dueDateObj.toISOString().split('T')[0];
        }
        if (field === "startDate" && updatedQuestions[rIndex].questions[qIndex].frequency === "Quarterly") {
          const startDateObj = new Date(value);
          const dueDateObj = new Date(startDateObj);
          // dueDateObj.setDate(startDateObj.getDate() + 90);
          dueDateObj.setMonth(startDateObj.getMonth() + 3);
          updatedQuestions[rIndex].questions[qIndex].dueDate = dueDateObj.toISOString().split('T')[0];
        }
        if (field === "startDate" && updatedQuestions[rIndex].questions[qIndex].frequency === "Half Yearly") {
          const startDateObj = new Date(value);
          const dueDateObj = new Date(startDateObj);
          // dueDateObj.setDate(startDateObj.getDate() + 180);
          dueDateObj.setMonth(startDateObj.getMonth() + 6);
          updatedQuestions[rIndex].questions[qIndex].dueDate = dueDateObj.toISOString().split('T')[0];
        }
        if (field === "startDate" && updatedQuestions[rIndex].questions[qIndex].frequency === "Yearly") {
          const startDateObj = new Date(value);
          const dueDateObj = new Date(startDateObj);
          // dueDateObj.setDate(startDateObj.getDate() + 365);
          dueDateObj.setFullYear(startDateObj.getFullYear() + 1);

          updatedQuestions[rIndex].questions[qIndex].dueDate = dueDateObj.toISOString().split('T')[0];
        }
        if (field === "startDate" && updatedQuestions[rIndex].questions[qIndex].frequency === "Bi-Annual") {
          const startDateObj = new Date(value);
          const dueDateObj = new Date(startDateObj);
          // dueDateObj.setDate(startDateObj.getDate() + 730);
          dueDateObj.setFullYear(startDateObj.getFullYear() + 2);
          updatedQuestions[rIndex].questions[qIndex].dueDate = dueDateObj.toISOString().split('T')[0];
        }
      
        setQuestions(updatedQuestions);
      };



      const removeRule = (rIndex) => {
        const updatedRules = [...rules];
        updatedRules.splice(rIndex, 1);
        setRules(updatedRules);
      };
    return (
        <Container>
           
        <div>
            <form class="row g-3" method="post" enctype="multipart/form-data" onSubmit={handleSubmit}>
            <div class="col-md-12 col-lg-12 mb-2">
                <label for="" class="form-label">State *</label>
                <select className="form-select" aria-label="Default select example" id="state" name="state" value={state} onChange={(e)=>setState(e.target.value)} required>
                        <option value="">Select State</option>
                    {stateInfo != 'undefind' && stateInfo?.length > 0 && stateInfo.map(item => 
                        <option value={item._id}>{item.name}</option>
                    )};
                </select>
            </div>    
            <div class="col-md-12 col-lg-12 mb-2">
                <label for="cat" class="form-label">Category *</label>
                <select className="form-select" aria-label="Default select example" id="category" name="category" onChange={(e)=>setCategorys(e.target.value)} value={categorys} required>
                    <option value="">Select category</option>
                    {category != 'undefind' && category?.length > 0 && category.map(item => 
                        <option value={item.id}>{item.name}</option>
                    )};
                    
                </select>
            </div>
            {recordForEdit?.id !== undefined && recordForEdit?.id !== null ? (
            <div class="col-12 col-lg-12 col-md-12 mb-2">
              <label for="inputact" class="form-label">
                Act
              </label>
              <input
                type="text"
                class="form-control"
                id="act"
                name="act"
                value={act}
                onChange={(e) => setAct(e.target.value)}
                placeholder="Type act name"
                disabled
              />
            </div>
          ) : (
            <div class="col-12 col-lg-12 col-md-12 mb-2">
              <label for="inputact" class="form-label">
                Act *
              </label>
              <input
                type="text"
                class="form-control"
                id="act"
                name="act"
                value={act}
                onChange={(e) => setAct(e.target.value)}
                placeholder="Type act name"
                required
              />
            </div>
          )}

          {recordForEdit?.id !== undefined && recordForEdit?.id !== null ? (
            <>
              <div class="col-12 col-lg-12 col-md-12 mb-2">
                <label for="inputAddress" class="form-label">
                  Act Documents{" "}
                </label>
                <div>
                  <div class="form-group files">
                    <input
                      type="file"
                      name="document"
                      class="form-control"
                      multiple=""
                      accept="application/pdf"
                      id="input-file-now-custom-2"
                      className="file-upload"
                      dataHeight="500"
                      onChange={(e) => {
                        handleProductDocumentUpload(e);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-12 col-md-12 mb-2">
                <ImageList>
                  <ImagePreview>{filepdf}</ImagePreview>
                </ImageList>
              </div>
            </>
          ) : (
            <div class="col-12 col-lg-12 col-md-12 mb-2">
              <label for="inputAddress" class="form-label">
                Act Documents *
              </label>
              <div>
                <div class="form-group files">
                  <input
                    type="file"
                    name="document"
                    class="form-control"
                    multiple=""
                    accept="application/pdf"
                    id="input-file-now-custom-2"
                    className="file-upload"
                    dataHeight="500"
                    onChange={(e) => {
                      handleProductDocumentUpload(e);
                    }}
                    required
                  />
                </div>
              </div>
            </div>
          )}

          <div class="col-12 col-lg-12 col-md-12 mb-2">
            {/* <button class="btn btn-outline-primary"
              type="button"
              id="button-addon2" onClick={addRule}>Add Rule <AddCircleOutlineIcon /></button> */}
              
              {rules.length > 0 ? (
            rules.map((rule, rIndex) => (
                <div key={rIndex}>
                    <h3>Rule {rIndex + 1}</h3>
                    <label htmlFor={`rule-${rIndex}`} className="form-label">
                        Rule Name:
                        <textarea
                            type="text"
                            name="rule"
                            className="form-control desc"
                            placeholder="Type Rule Name"
                            aria-label="Type Rule Name"
                            aria-describedby="button-addon2"
                            id={`rule-${rIndex}`}
                            value={rule.rule}
                            onChange={(e) => handleRuleChange(rIndex, "rule", e.target.value)}
                        />
                    </label>
                {/* </div> */}
                {recordForEdit?.id !== undefined &&
                recordForEdit?.id !== null ? (
                  <>
                    <div class="col-12 col-lg-12 col-md-12 mb-2">
                      <label
                        for="inputAddress"
                        htmlFor={`docFile-${rIndex}`}
                        class="form-label"
                      >
                        {/* Form{" "} */}
                        Form *
                      </label>
                      <div>
                        <div class="form-group files">
                          Rule Document:
                          <input
                            type="file"
                            id={`docFile-${rIndex}`}
                            // value={rule.docFile}
                            onChange={(e) =>
                              handleRuleChange(
                                rIndex,
                                "docFile",
                                e.target.files[0]
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <br />
                    <div class="col-12 col-lg-12 col-md-12 mb-2">
                      <ImageList>
                        <ImagePreview>{filepdf1}</ImagePreview>
                      </ImageList>
                    </div>
                  </>
                ) : (
                  <>
                    <div class="col-12 col-lg-12 col-md-12 mb-2">
                      <label
                        for="inputAddress"
                        htmlFor={`docFile-${rIndex}`}
                        class="form-label"
                      >
                        Form *
                        
                        {/* Documents * */}
                      </label>
                      <div>
                        <div class="form-group files">
                          <input
                            // type="file"
                            name="docFile"
                            class="form-control"
                            multiple=""
                            accept="image/*,application/pdf"
                            // id="input-file-now-custom-2"
                            className="file-upload"
                            dataHeight="500"
                            type="file"
                            // value={rule.docFile}
                            id="input-file-now-custom-2"
                            onChange={(e) =>
                              handleRuleChange(
                                rIndex,
                                "docFile",
                                e.target.files[0]
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {/* <button 
            class="btn btn-outline-primary"
            type="button"
            id="button-addon2"
            onClick={() => addQuestion(ruleIndex)}>Add Question <AddCircleOutlineIcon /></button> */}

                {rule.questions.map((question, qIndex) => (
                  <div class="col-12 col-lg-12 col-md-12 mb-2" key={qIndex}>
                    <h4>Question {qIndex + 1}</h4>
                    <label
                      for="inputAddress"
                      htmlFor={`question-${rIndex}-${qIndex}`}
                      class="form-label"
                    >
                      Question Name:
                      <textarea
                        // type="text"
                        class="form-control desc"
                        // id="question"
                        name="question"
                        placeholder="Type Question Here"

                        // value={question.name}
                        type="text"
                        id={`question-${rIndex}-${qIndex}`}
                        value={question.question}
                        onChange={(e) =>
                          handleQuestionChange(
                            rIndex,
                            qIndex,
                            "question",
                            e.target.value
                          )
                        }
                      />
                    </label>
                    {recordForEdit?.id !== undefined && recordForEdit?.id !== null ? (
  <>
    <div className="col-12 col-lg-12 col-md-12 mb-2">
      <label
        htmlFor={`docFileQuestion-${rIndex}-${qIndex}`}
        className="form-label"
      >
        Form *
      </label>
      <div className="form-group files">
        <input
          type="file"
          id={`docFileQuestion-${rIndex}-${qIndex}`}
          onChange={(e) =>
            handleQuestionChangeFile(
              rIndex,
              qIndex,
              "docattachment",
              e.target.files[0]
            )
          }
        />
      </div>
    </div>

    <div className="col-12 col-lg-12 col-md-12 mb-2">
      {/* Render file preview for the current question */}
      {rule.questions[qIndex].docattachment && (
        <ImageList>
          <ImagePreview>
            <a href={rule.questions[qIndex].docattachment} target="_blank" rel="noopener noreferrer">
              Click to show Question Pdf
            </a>
          </ImagePreview>
        </ImageList>
      )}
    </div>
  </>
) : (
  <>
    <div className="col-12 col-lg-12 col-md-12 mb-2">
      <label
        htmlFor={`docFileQuestion-${rIndex}-${qIndex}`}
        className="form-label"
      >
        Form *
      </label>
      <div className="form-group files">
        <input
          type="file"
          id={`docFileQuestion-${rIndex}-${qIndex}`}
          onChange={(e) =>
            handleQuestionChangeFile(
              rIndex,
              qIndex,
              "docattachment",
              e.target.files[0]
            )
          }
        />
      </div>
    </div>

    <div className="col-12 col-lg-12 col-md-12 mb-2">
      {/* Render file preview for the current question if it has been uploaded */}
      {rule.questions[qIndex].docattachment && (
        <ImageList>
          <ImagePreview>
            <a href={rule.questions[qIndex].docattachment} target="_blank" rel="noopener noreferrer">
              Click to show Question Pdf
            </a>
          </ImagePreview>
        </ImageList>
      )}
    </div>
  </>
)}

                    {/* </label> */}
                    {/* <label> */}
                    <div class="col-12 col-lg-12 col-md-12 mb-2" >
                      <label
                        for=""
                        htmlFor={`description-${rIndex}-${qIndex}`}
                        class="form-label"
                        >
                        Description:
                        <textarea
                        width="200 !important"
                          id={`description-${rIndex}-${qIndex}`}
                          type="text"
                          // type="text"
                          class="form-control desc"
                          // id="inputdescription"
                          name="description"
                          placeholder="Type Description Here"

                          // value={question.description}
                          value={question.description}
                          onChange={(e) =>
                            handleQuestionChange(
                              rIndex,
                              qIndex,
                              "description",
                              e.target.value
                            )
                          }
                        />
                      </label>
                    </div>
                    <div class="col-12 col-lg-12 col-md-12 mb-2">
                      <label
                        for=""
                        htmlFor={`consequences-${rIndex}-${qIndex}`}
                        class="form-label"
                      >
                        Consequences
                      </label>
                      <textarea
                        type="text"
                        class="form-control"
                        // id="consequences"
                        name="consequences"
                        placeholder="Type Consequences Here"

                        // value={question.consequences}
                        id={`consequences-${rIndex}-${qIndex}`}
                        value={question.consequences}
                        onChange={(e) =>
                          handleQuestionChange(
                            rIndex,
                            qIndex,
                            "consequences",
                            e.target.value
                          )
                        }
                      />
                    </div>

                    <div class="col-12 col-lg-12 col-md-12 mb-2">
                      <label
                        for=""
                        htmlFor={`compliancetype-${rIndex}-${qIndex}`}
                        class="form-label"
                      >
                        Complinace Activity
                      </label>
                      <input
                        type="text"
                        id={`compliancetype-${rIndex}-${qIndex}`}
                        // type="text"
                        class="form-control"
                        // id="compliancetype"
                        name="compliancetype"
                        placeholder="Type Complinace Activity Here"

                        value={question.compliancetype}
                        onChange={(e) =>
                          handleQuestionChange(
                            rIndex,
                            qIndex,
                            "compliancetype",
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div class="col-md-12 col-lg-12 mb-2">
                      <label
                        for="cat"
                        htmlFor={`categorycomp-${rIndex}-${qIndex}`}
                        class="form-label"
                      >
                        Category of Compliance*
                      </label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        id="categorycomp"
                        name="categorycomp"
                        onChange={(e) =>
                          handleQuestionChange(
                            rIndex,
                            qIndex,
                            "categorycomp",
                            e.target.value
                          )
                        }
                        value={question.categorycomp}
                        required
                      >
                        <option value="">Select category</option>
                        {category != "undefind" &&
                          category?.length > 0 &&
                          category.map((item) => (
                            <option value={item.id}>{item.name}</option>
                          ))}
                        ;
                      </select>
                    </div>
                    <div className="col-12 col-lg-12 col-md-12 mb-2">
                      <label
                        htmlFor={`frequency-${rIndex}-${qIndex}`}
                        className="form-label"
                      >
                        Recurrence/Frequency *
                      </label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        id={`frequency-${rIndex}-${qIndex}`}
                        name="frequency"
                        value={question.frequency}
                        onChange={(e) =>
                          handleQuestionChange22(
                            rIndex,
                            qIndex,
                            "frequency",
                            e.target.value
                          )
                        }
                        required
                      >
                        <option value="">Select Recurrence/Frequency</option>
                        <option value="On-Going">On-Going</option>
                        <option value="One Time">One Time</option>
                        <option value="Fortnightly">Fortnightly</option>
                        <option value="Monthly">Monthly</option>
                        <option value="Quarterly">Quarterly</option>
                        <option value="Half Yearly">Half Yearly</option>
                        <option value="Yearly">Yearly</option>
                        <option value="Bi-Annual">Bi-Annual</option>
                        <option value="Recurring">Recurring</option>
                      </select>
                    </div>
                    <div class="col-12 col-lg-12 col-md-12 mb-2">
                      <label
                        for=""
                        htmlFor={`risk-${rIndex}-${qIndex}`}
                        class="form-label"
                      >
                        Risk *
                      </label>
                      <select
                        className="form-select"
                        // id="risk"
                        id={`risk-${rIndex}-${qIndex}`}
                        aria-label="Default select example"
                        name="risk"
                        value={question.risk}
                        onChange={(e) =>
                          handleQuestionChange(
                            rIndex,
                            qIndex,
                            "risk",
                            e.target.value
                          )
                        }
                        required
                      >
                        <option value="">Select Risk</option>
                        <option value="Low">Low</option>
                        <option value="Medium">Medium</option>
                        <option value="High">High</option>
                        <option value="Very High">Very High</option>
                      </select>
                    </div>

                    {/* {question.frequency !== "One Time" && question.frequency !== "Recurring" && question.frequency !== "On-Going" && ( */}
                   
                      <div className="col-12 col-lg-5 col-md-4 mb-2">
                        {console.log("question.startDate", question.startDate)}
                        <label htmlFor="" className="form-label">
                          Start Date *
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id={`startDate-${rIndex}-${qIndex}`}
                          name="startDate"
                          value={question.startDate}
                          onChange={(e) => handleQuestionChange11(rIndex, qIndex, 'startDate', e.target.value)}
                        />
                      </div>
                   
                      
                  {/* )} */}
                 

                  
                      <div className="col-12 col-lg-5 col-md-4 mb-2">
                        {console.log("question.dueDate", question.dueDate)}
                        <label htmlFor="" className="form-label">
                          Due Date *
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id={`dueDate-${rIndex}-${qIndex}`}
                          name="dueDate"
                          value={question.dueDate}
                          onChange={(e) => handleQuestionChange1(rIndex, qIndex, 'dueDate', e.target.value)}
                        />
                      </div>
                   
                     
               
                  
                   
                      {/* <div class="col-12 col-lg-5 col-md-4 mb-2">
                        {console.log("question.DateofCompletion", question.DateofCompletion)}
                        <label for="" className="form-label">
                          Date of Completion*
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id={`DateofCompletion-${rIndex}-${qIndex}`}
                          name="DateofCompletion"
                          value={question.DateofCompletion}
                          onChange={(e) =>
                            handleQuestionChange1(
                              rIndex,
                              qIndex,
                              "DateofCompletion",
                              e.target.value
                            )
                          }
                          // required
                        />
                      </div>
                  
                    <div class="col-12 col-lg-12 col-md-12 mb-2">
                      <label
                        for=""
                        htmlFor={`DelayinDays-${rIndex}-${qIndex}`}
                        class="form-label"
                      >
                        Delay in days:
                        <input
                          width="200 !important"
                          id={`DelayinDays-${rIndex}-${qIndex}`}
                          type="number"
                          // type="text"
                          class="form-control desc"
                          // id="inputDelayinDays"
                          name="DelayinDays"
                          placeholder="Type Delay in Days Here"
                          // value={question.DelayinDays}
                          value={question.DelayinDays}
                          onChange={(e) =>
                            handleQuestionChange(
                              rIndex,
                              qIndex,
                              "DelayinDays",
                              e.target.value
                            )
                          }
                        />
                      </label>
                    </div>

                    <div class="col-12 col-lg-12 col-md-12 mb-2">
                      <label
                        for=""
                        htmlFor={`CompliedStatus-${rIndex}-${qIndex}`}
                        class="form-label"
                      >
                        Status *
                      </label>
                      <select
                        className="form-select"
                        // id="CompliedStatus"
                        id={`CompliedStatus-${rIndex}-${qIndex}`}
                        aria-label="Default select example"
                        name="CompliedStatus"
                        value={question.CompliedStatus}
                        onChange={(e) =>
                          handleQuestionChange(
                            rIndex,
                            qIndex,
                            "CompliedStatus",
                            e.target.value
                          )
                        }
                        required
                      >
                        <option value="">Select Status</option>
                        <option value="Pending">Pending</option>
                        <option value="Pending for Descrepency">
                          Pending for Descrepency
                        </option>
                        <option value="Complied">Complied</option>
                        <option value="Partially Complied">
                          Partially Complied
                        </option>
                        <option value="Not Complied">Not Complied</option>
                      </select>
                    </div>

                    <div class="col-12 col-lg-12 col-md-12 mb-2">
                      <label
                        for=""
                        htmlFor={`RemarksStatus-${rIndex}-${qIndex}`}
                        class="form-label"
                      >
                        Remarks:
                        <textarea
                          width="200 !important"
                          id={`RemarksStatus-${rIndex}-${qIndex}`}
                          type="text"
                          // type="text"
                          class="form-control desc"
                          // id="inputdescription"
                          name="RemarksStatus"
                          placeholder="Type Remarks of Status Here"
                          // value={question.description}
                          value={question.RemarksStatus}
                          onChange={(e) =>
                            handleQuestionChange(
                              rIndex,
                              qIndex,
                              "RemarksStatus",
                              e.target.value
                            )
                          }
                        />
                      </label>
                    </div> */}
                    {/* {recordForEdit?.id !== undefined &&
                    recordForEdit?.id !== null ? (
                      <div class="col-12 col-lg-12 col-md-12 mb-2">
                        <label for="" className="form-label">
                          Updated Date *
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="dates"
                          name="dates"
                          value={date.toLocaleDateString("en-CA")}
                          onChange={onSetDate}
                          required
                        />
                      </div>
                    ) : (
                      <div class="col-12 col-lg-12 col-md-12 mb-2">
                        <label for="" className="form-label">
                          Creation Date *
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="dates"
                          name="dates"
                          value={date.toLocaleDateString("en-CA")}
                          onChange={onSetDate}
                          required
                        />
                      </div>
                    )} */}
                    <button
                      class="btn btn-outline-danger"
                      type="button"
                      id="button-addon2"
                      onClick={() => removeQuestion(rIndex, qIndex)}
                    >
                      Remove Question {qIndex + 1}
                      <RemoveCircleOutlineIcon  />
                    </button>
                  </div>
                ))}
                <button
                  class="btn btn-outline-primary"
                  type="button"
                  id="button-addon2"
                  onClick={() => addQuestion(rIndex)}
                >
                  Add Question <AddCircleOutlineIcon />
                </button>
                <br />
                <br />

                <button
                  // style={{marginTop:'10px'}}
                  class="btn btn-outline-danger"
                  type="button"
                  id="button-addon2"
                  onClick={() => removeRule(rIndex)}
                >
                  Remove Rule {rIndex + 1}
                  <RemoveCircleOutlineIcon  />
                </button>
              </div>
            
          ))
        ) : (
            <p>No rules available</p>
        )}
            <br />
            <button
              class="btn btn-outline-primary"
              type="button"
              id="button-addon2"
              onClick={handleAddRule}
            >
              Add New Rule <AddCircleOutlineIcon />
            </button>
            
          </div> 
            

        {recordForEdit?.id !== undefined && recordForEdit?.id !== null ? 
        (<div class="col-12 col-lg-12 col-md-12 mb-2">
            <label for="" className="form-label">Updated Date *</label>
            <input type="date" className="form-control" 
                id="dates"
                name="dates" 
                value={date.toLocaleDateString('en-CA')} 
                onChange={onSetDate}
                required
            />
        </div>):(<div class="col-12 col-lg-12 col-md-12 mb-2">
            <label for="" className="form-label">Creation Date *</label>
            <input type="date" className="form-control" 
                id="dates"
                name="dates" 
                value={date.toLocaleDateString('en-CA')} 
                onChange={onSetDate}
                required
            />
        </div>)}     
        {/* <div className="modal-footer justify-content-center">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            {/* <button type="button" className="btn btn-success">Edit</button> 
            <button type="submit" id="btnSave" className="btn btn-primary" >Save</button>
        </div> */}
            <div class="col-md-6">
                <button type="submit" class="w-100 btn btn-dark" id="cancel" onClick={tocategorypage}>Cancel</button>
            </div>
            <div class="col-md-6">
                <button type="submit" variant="contained" class="w-100 btn btn-primary" >Save</button>
            </div>
            </form>
            </div>
    </Container>    
    )
}

export default ChecklistPopup;
const Container = styled(FormGroup)`
width: 90%;
margin: 3% auto 0 0%;
& > div {
    margin-top:10px;
    
}
`
const ImagePreview = styled(ImageListItem)`
  margin: 0 0 0 0;
  border: 1px solid rgb(183, 183, 183);
  max-width: 400px;
  max-height: 400px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(78, 78, 78);

  img {
    width: 100%;
    height: 100%;
  }
`;
const Ptags =  styled('p')`
font-size:10px;
`