import React, { useEffect } from 'react';

const DynamicHTMLGeneratorF54TL = ({ formData, setFormData, rowIndex, noOfBranch }) => {
  useEffect(() => {
    // Initialize formData when the component mounts
    handlenumberOfPersonsChange();
  }, []);

  const handlenumberOfPersonsChange = () => {
    const numberOfPersons = noOfBranch;  // Ensure this is set to the correct number of branches
    const newFormData = [...formData];   // Copy the existing formData

    // If formData has fewer entries than noOfBranch, add new entries
    for (let i = newFormData.length; i < numberOfPersons; i++) {
      newFormData.push({
        licensenumber: '',
        licenseimage: '',
        licenseremark: '',
        dor: '',
        doe: '',
        doddr: '',
        issuingauth: '',
        issuingauthdetails: '',
        issuingauthimage: '',
        issuingauthremark: '',
      });
    }

    setFormData(newFormData);
  };

  const handleInputChange = (e, fieldName, rowIndex) => {
    let newFormData = [...formData];

    // Ensure formData[rowIndex] exists before trying to update it
    if (!newFormData[rowIndex]) {
      newFormData[rowIndex] = {
        licensenumber: '',
        licenseimage: '',
        licenseremark: '',
        dor: '',
        doe: '',
        doddr: '',
        issuingauth: '',
        issuingauthdetails: '',
        issuingauthimage: '',
        issuingauthremark: '',
      };
    }

    // Handle file inputs and regular inputs separately
    if (fieldName.indexOf('image') !== -1) {
      newFormData[rowIndex][fieldName] = e.target.files[0];
    } else {
      newFormData[rowIndex][fieldName] = e.target.value;
    }

    setFormData(newFormData);
  };

  const generateDirectorsForm = () => {
    return (
      <React.Fragment key={rowIndex}>
        <tr>
          <td>
            <label className="form-label">License Number</label>
            <input
              type="text"
              className="form-control"
              placeholder="Licence Number"
              value={formData[rowIndex]?.licensenumber || 'NA'}
              id={`licensenumber_${rowIndex}`}
              name={`licensenumber_${rowIndex}`}
              onChange={(e) => handleInputChange(e, 'licensenumber', rowIndex)}
            />
          </td>
          <td colSpan="2">
            <div>
              <div className="form-group files1">
                <input
                  type="file"
                  className="form-control"
                  multiple=""
                  accept="image/*,application/pdf"
                  id={`licenseimage_${rowIndex}`}
                  name={`licenseimage_${rowIndex}`}
                  onChange={(e) => handleInputChange(e, 'licenseimage', rowIndex)}
                />
              </div>
              {formData[rowIndex]?.licenseimage && (
                <div>
                  <a href={formData[rowIndex]?.licenseimage} target="_blank" rel="noopener noreferrer">
                    Open License Number File/Image
                  </a>
                </div>
              )}
            </div>
          </td>
          <td>
            <label>Remark</label>
            <input
              type="text"
              className="form-control"
              id={`licenseremark_${rowIndex}`}
              name={`licenseremark_${rowIndex}`}
              value={formData[rowIndex]?.licenseremark || 'NA'}
              onChange={(e) => handleInputChange(e, 'licenseremark', rowIndex)}
              placeholder="Write Here"
            />
          </td>
        </tr>
        <tr>
          <td colSpan="4">
            <label className="form-label">Date of Registration</label>
            <input
              type="date"
              className="form-control"
              placeholder="Date of Registration"
              value={formData[rowIndex]?.dor || 'NA'}
              onChange={(e) => handleInputChange(e, 'dor', rowIndex)}
              id={`dor_${rowIndex}`}
              name={`dor_${rowIndex}`}
              
            />
          </td>
        </tr>
        <tr>
          <td colSpan="4">
            <label className="form-label">Date of Expiry</label>
            <input
              type="date"
              className="form-control"
              placeholder="Date of Expiry"
              value={formData[rowIndex]?.doe || 'NA'}
              onChange={(e) => handleInputChange(e, 'doe', rowIndex)}
              id={`doe_${rowIndex}`}
              name={`doe_${rowIndex}`}
              
            />
          </td>
        </tr>
        <tr>
          <td colSpan="4">
            <label className="form-label">Due Date of Renewal</label>
            <input
              type="date"
              className="form-control"
              placeholder="Due Date of Renewal"
              value={formData[rowIndex]?.doddr || 'NA'}
              onChange={(e) => handleInputChange(e, 'doddr', rowIndex)}
              id={`doddr_${rowIndex}`}
              name={`doddr_${rowIndex}`}
              
            />
          </td>
        </tr>
        <tr>
          <td>
            <label className="form-label">Issuing Authority</label>
            <input
              type="text"
              className="form-control"
              placeholder="Issuing Authority"
              value={formData[rowIndex]?.issuingauth || 'NA'}
              onChange={(e) => handleInputChange(e, 'issuingauth', rowIndex)}
              name={`issuingauth_${rowIndex}`}
              id={`issuingauth_${rowIndex}`}
              
            />
          </td>
          <td>
            <div className="form-group files1">
              <input
                type="file"
                multiple=""
                accept="image/*,application/pdf"
                className="form-control"
                id={`issuingauthimage_${rowIndex}`}
                name={`issuingauthimage_${rowIndex}`}
                onChange={(e) => handleInputChange(e, 'issuingauthimage', rowIndex)}
              />
            </div>
            {formData[rowIndex]?.issuingauthimage && (
              <div>
                <a href={formData[rowIndex]?.issuingauthimage} target="_blank" rel="noopener noreferrer">
                  Open Issuing Authority File/Image
                </a>
              </div>
            )}
          </td>
          <td>
            <label className="form-label">Remarks</label>
            <input
              type="text"
              className="form-control"
              placeholder="Remark"
              value={formData[rowIndex]?.issuingauthremark || 'NA'}
              onChange={(e) => handleInputChange(e, 'issuingauthremark', rowIndex)}
              id={`issuingauthremark_${rowIndex}`}
              name={`issuingauthremark_${rowIndex}`}
            />
          </td>
        </tr>
      </React.Fragment>
    );
  };

  return (
    <table className="table creat_tbl">
      <tbody>
        <tr>
          <td colSpan="4">
            <input
              type="text"
              className="form-control"
              placeholder="Number of Subcodes"
              value={formData.length}
              onChange={handlenumberOfPersonsChange}
              style={{ display: 'none' }}
            />
          </td>
        </tr>
        {generateDirectorsForm()}
      </tbody>
    </table>
  );
};

export default DynamicHTMLGeneratorF54TL;
