import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { SearchOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table } from "antd";
import Popup from "../../components/Popup";
import Highlighter from "react-highlight-words";
import BranchForm from "./BranchForm";
import TestBranch from "./TestBranch";
import { useDispatch, useSelector } from "react-redux";
import { categoryCreate, categoryGet, AllbranchesGet, TableBranchesGet } from '../../store/actions/otherActions';
import * as XLSX from 'xlsx';

const BranchTables = ({ branches }) => {
  const searchInput = useRef(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [pageTitle, setPageTitle] = useState("");
  const [modalWidth, setModalWidth] = useState();
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const getState = useSelector((state) => state.getState);
  const { loadings, stateInfo} = getState;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(AllbranchesGet());
    dispatch(TableBranchesGet());
  }, [dispatch]);
  const formatDateToInput = (isoDate) => {
    if (!isoDate) return ""; // Return an empty string for empty or invalid dates
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero
    const day = String(date.getDate()).padStart(2, "0"); // Add leading zero
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    if (branches?.length > 0) {
      const branchArr = branches.map((branch, index) => ({
        key: index + 1,
        id: branch._id,
        createdAt: formatDateToInput(branch.createdAt),
        company: branch.company,
        name: branch.name,
        details: branch.details,
        branchimage: branch.branchimage,
        remarks: branch.remarks,
        branchaddress: branch.branchaddress,
        region: branch.region,
        branchstate: branch.branchstate,
        branchdistrict: branch.branchdistrict,
        branchpin: branch.branchpin,
        contractorAddBranchFimage: branch.contractorAddBranchFimage,
        contractorAddBranchFRemark: branch.contractorAddBranchFRemark,
        branchOpeningDateF: formatDateToInput(branch.branchOpeningDateF),
        noOfEmpBranchF: branch.noOfEmpBranchF,
        managerNameF1: branch.managerNameF1,
        managerNameF1image: branch.managerNameF1image,
        managerNameF1Remark: branch.managerNameF1Remark,
        managerMobNoF1: branch.managerMobNoF1,
        managerMobNoF1Remark: branch.managerMobNoF1Remark,
        managerEmailF1: branch.managerEmailF1,
        managerEmailF1Remark: branch.managerEmailF1Remark,
        managerAadharNoF1: branch.managerAadharNoF1,
        managerAadharNoF1image: branch.managerAadharNoF1image,
        managerAadharNoF1Remark: branch.managerAadharNoF1Remark,
        managerPanF1: branch.managerPanF1,
        managerPanF1image: branch.managerPanF1image,
        managerPanF1Remark: branch.managerPanF1Remark,
        shopsEstLicenseF2: branch.shopsEstLicenseF2,
        shopsEstLicenseF2image: branch.shopsEstLicenseF2image,
        shopsEstLicenseF2Remark: branch.shopsEstLicenseF2Remark,
        // contractLabRegNoF5: branch.contractLabRegNoF5,
        // contractLabRegNoF5image: branch.contractLabRegNoF5image,
        // contractLabRegNoF5Remark: branch.contractLabRegNoF5Remark,
        // regDateContractorF5: formatDateToInput(branch.regDateContractorF5),
        // coOfContractEmpF5: branch.coOfContractEmpF5,
        // noOfContractorsF5: branch.noOfContractorsF5,
        // contractorNameF51: branch.contractorNameF51,
        // contractorNameF51image: branch.contractorNameF51image,
        // contractorNameF51Remark: branch.contractorNameF51Remark,
        // establishmentNameF51: branch.establishmentNameF51,
        // establishmentNameF51image: branch.establishmentNameF51image,
        // establishmentNameF51Remark: branch.establishmentNameF51Remark,
        // regisocontractaddress: branch.regisocontractaddress,
        // regStateContractorF51: branch.regStateContractorF51,
        // regDistContractorF51: branch.regDistContractorF51,
        // regPinContractorF51: branch.regPinContractorF51,
        // regAddContractorF51image: branch.regAddContractorF51image,
        // regAddContractorF51Remark: branch.regAddContractorF51Remark,
        // expiryDateF52: formatDateToInput(branch.expiryDateF52),
        // renewalDateF52: formatDateToInput(branch.renewalDateF52),
        // natureOfWorkF52: branch.natureOfWorkF52,
        // natureOfWorkF52image: branch.natureOfWorkF52image,
        // natureOfWorkF52Remark: branch.natureOfWorkF52Remark,
        // noOfEmpDeployedF52: branch.noOfEmpDeployedF52,
        // companyTypeF53: branch.companyTypeF53,
        // companyTypeF53image: branch.companyTypeF53image,
        // companyTypeF53Remark: branch.companyTypeF53Remark,
        // contractLabLicNoF53: branch.contractLabLicNoF53,
        // contractLabLicNoF53image: branch.contractLabLicNoF53image,
        // contractLabLicNoF53Remark: branch.contractLabLicNoF53Remark,
        // licenseDateF53: formatDateToInput(branch.licenseDateF53),
        // expiryDateF53: formatDateToInput(branch.expiryDateF53),
        // renewalDateF53: formatDateToInput(branch.renewalDateF53),
        // noOfWorkerF53: branch.noOfWorkerF53,
        // panF53: branch.panF53,
        // panF53image: branch.panF53image,
        // panF53Remark: branch.panF53Remark,
        // gstF53: branch.gstF53,
        // gstF53image: branch.gstF53image,
        // gstF53Remark: branch.gstF53Remark,
        // esicRegF53: branch.esicRegF53,
        // esicRegF53image: branch.esicRegF53image,
        // esicRegF53Remark: branch.esicRegF53Remark,
        // pfRegContractorsE3: branch.pfRegContractorsE3,
        // pfRegContractorsE3image: branch.pfRegContractorsE3image,
        // pfRegContractorsE3Remark: branch.pfRegContractorsE3Remark,
        // shopsandEstContractorsE3: branch.shopsandEstContractorsE3,
        // shopsandEstContractorsE3image: branch.shopsandEstContractorsE3image,
        // shopsandEstContractorsE3Remark: branch.shopsandEstContractorsE3Remark,
        // lwfRegContractorsE3: branch.lwfRegContractorsE3,
        // lwfRegContractorsE3image: branch.lwfRegContractorsE3image,
        // lwfRegContractorsE3Remark: branch.lwfRegContractorsE3Remark,
        // profTaxContractorsE3: branch.profTaxContractorsE3,
        // profTaxContractorsE3image: branch.profTaxContractorsE3image,
        // profTaxContractorsE3Remark: branch.profTaxContractorsE3Remark,
        licensenumber: branch.licensenumber,
        licenseimage: branch.licenseimage,
        licenseremark: branch.licenseremark,
        lis_status_se: branch.lis_status_se,
        dor: formatDateToInput(branch.dor),
        doe: formatDateToInput(branch.doe),
        doddr: formatDateToInput(branch.doddr),
        managernamelicense: branch.managernamelicense,
        managerlicenseimage: branch.managerlicenseimage,
        managerlicenseremark: branch.managerlicenseremark,
        noe: branch.noe,
        nom: branch.nom,
        nof: branch.nof,
        issuingauth: branch.issuingauth,
        doeimage: branch.doeimage,
        issuingauthremark: branch.issuingauthremark,
        licensenumber1: branch.licensenumber1,
        licenseimage1: branch.licenseimage1,
        licenseremark1: branch.licenseremark1,
        lis_status_factory: branch.lis_status_factory,
        dor1: formatDateToInput(branch.dor1),
        doe1: formatDateToInput(branch.doe1),
        doddr1: formatDateToInput(branch.doddr1),
        managernamelicense1: branch.managernamelicense1,
        managerlicenseimage1: branch.managerlicenseimage1,
        managerlicenseremark1: branch.managerlicenseremark1,
        noe1: branch.noe1,
        nom1: branch.nom1,
        nof1: branch.nof1,
        issuingauth1: branch.issuingauth1,
        doeimage1: branch.doeimage1,
        issuingauthremark1: branch.issuingauthremark1,
        licensenumber2: branch.licensenumber2,
        licenseimage2: branch.licenseimage2,
        licenseremark2: branch.licenseremark2,
        dor2: formatDateToInput(branch.dor2),
        issuingauth2: branch.issuingauth2,
        doeimage2: branch.doeimage2,
        issuingauthremark2: branch.issuingauthremark2,
        dateofclosure: formatDateToInput(branch.dateofclosure),
        powerHP: branch.powerHP,
        powerHPremark2: branch.powerHPremark2,
        powerKW: branch.powerKW,
        powerKWremark2: branch.powerKWremark2,
        ApprovedPower: branch.ApprovedPower,
        ApprovedPowerremark2: branch.ApprovedPowerremark2,
        licensenumberNSP: branch.licensenumberNSP,
        licenseimageNSP: branch.licenseimageNSP,
        licenseremarkNSP: branch.licenseremarkNSP,
        dorNSP: formatDateToInput(branch.dorNSP),
        doeNSP: formatDateToInput(branch.doeNSP),
        doddrNSP: formatDateToInput(branch.doddrNSP),
        issuingauthNSP: branch.issuingauthNSP,
        issuingauthdetailsNSP: branch.issuingauthdetailsNSP,
        doeimageNSP: branch.doeimageNSP,
        issuingauthremarkNSP: branch.issuingauthremarkNSP,
        licensenumberOTP: branch.licensenumberOTP,
        licenseimageOTP: branch.licenseimageOTP,
        licenseremarkOTP: branch.licenseremarkOTP,
        dorOTP: formatDateToInput(branch.dorOTP),
        doeOTP: formatDateToInput(branch.doeOTP),
        doddrOTP: formatDateToInput(branch.doddrOTP),
        issuingauthOTP: branch.issuingauthOTP,
        issuingauthdetailsOTP: branch.issuingauthdetailsOTP,
        doeimageOTP: branch.doeimageOTP,
        issuingauthremarkOTP: branch.issuingauthremarkOTP,
        licensenumberTL: branch.licensenumberTL,
        licenseimageTL: branch.licenseimageTL,
        licenseremarkTL: branch.licenseremarkTL,
        dorTL: formatDateToInput(branch.dorTL),
        doeTL: formatDateToInput(branch.doeTL),
        doddrTL: formatDateToInput(branch.doddrTL),
        issuingauthTL: branch.issuingauthTL,
        issuingauthdetailsTL: branch.issuingauthdetailsTL,
        doeimageTL: branch.doeimageTL,
        issuingauthremarkTL: branch.issuingauthremarkTL,
        licensenumberWOE: branch.licensenumberWOE,
        licenseimageWOE: branch.licenseimageWOE,
        licenseremarkWOE: branch.licenseremarkWOE,
        dorWOE: formatDateToInput(branch.dorWOE),
        doeWOE: formatDateToInput(branch.doeWOE),
        doddrWOE: formatDateToInput(branch.doddrWOE),
        issuingauthWOE: branch.issuingauthWOE,
        issuingauthdetailsWOE: branch.issuingauthdetailsWOE,
        doeimageWOE: branch.doeimageWOE,
        issuingauthremarkWOE: branch.issuingauthremarkWOE,
        isFactoryOrSE: branch.isFactoryOrSE,
        isContracorsYesorNo: branch.isContracorsYesorNo,
        isNightShiftPermission: branch.isNightShiftPermission,
        isOTPermission: branch.isOTPermission,
        isWeeklyOffExemption: branch.isWeeklyOffExemption,
        isTradeLicense: branch.isTradeLicense,
        lis_statusNSP: branch.lis_statusNSP,
        lis_statusTL: branch.lis_statusTL,
        lis_statusOTP: branch.lis_statusOTP,
        lis_statusWOE: branch.lis_statusWOE,
        issuingauthimage: branch.issuingauthimage,
        issuingauthimage1: branch.issuingauthimage1,
        issuingauthimageNSP: branch.issuingauthimageNSP,
        issuingauthimageWOE: branch.issuingauthimageWOE,
        issuingauthimageOTP: branch.issuingauthimageOTP,
        issuingauthimageTL: branch.issuingauthimageTL,
      }));

      setDataSource(branchArr);
      setFilteredData(branchArr);
    }
  }, [branches]);


  const formatDate = (currentDate) => {
    const date = new Date(currentDate);
    return date.toLocaleString();
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const openInPopupForUpdate = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
    setPageTitle("Edit Branch");
    setModalWidth("800px");
  };

  const addOrEdit = (values) => {
    const updatedData = dataSource.map((branch) =>
      branch.id === values.id ? { ...branch, ...values } : branch
    );
    setDataSource(updatedData);
    setFilteredData(updatedData);
    setOpenPopup(false);
  };

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "key",
      key: "key",
      width: 80,
    },
    {
      title: "Branch Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      ...getColumnSearchProps("company"),
    },
    {
      title: "State",
      dataIndex: "branchstate",
      key: "branchstate",
      ...getColumnSearchProps("branchstate"),
    },

    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      key: "action",
      title: "Actions",
      width: 170,
      render: (record) => {
        return (
          <>
            <Link
              className="text-white btn btn-primary text-decoration-none mx-2"
              onClick={() => openInPopupForUpdate(record)}
            >
              View/Edit <EditOutlined />
            </Link>
          </>
        );
      },
    },
  ];



  return (
    <React.Fragment>
     
      <div className="card p-3">
        <div className="table-responsive">
          <Table
            columns={columns}
            dataSource={filteredData}
            pagination={{ pageSize: 50, showSizeChanger: false, position: ["bottomCenter"] }}
            scroll={{ x: 1000 }}
            sticky={true}
          />
        </div>
      </div>
      <Popup openPopup={openPopup} pageTitle={pageTitle} setOpenPopup={setOpenPopup} modalWidth={modalWidth}>
        {openPopup && <TestBranch addOrEdit={addOrEdit} recordForEdit={recordForEdit} setOpenPopup={setOpenPopup} />}
      </Popup>
    </React.Fragment>
  );
};

export default BranchTables;
