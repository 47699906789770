import React, { useState, useEffect, useRef } from 'react';
import { Space } from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { usersCreates } from '../../store/actions/otherActions';
import UsersTable from "./UsersTable";
import { usersGet } from "../../store/actions/otherActions";
import $ from 'jquery';
import * as Yup from 'yup'; // Yup is a JavaScript object schema validator.
import { useFormik } from 'formik'; //formik is third party React form library. It provides basic form programming and validation
import { branchGet } from "../../store/actions/otherActions";
const Users = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [values, setValues] = useState({ showPassword: false });
  const [valuesRe, setValuesRe] = useState({ showRePassword: false });
  const [showCompanyDropdown, setShowCompanyDropdown] = useState(false);
  const userCreate = useSelector((state) => state.userCreate);
  const { loading, userCreateInfo, error } = userCreate;
  const [companies, setCompanies] = useState([]);
  const myElementRefCompany = useRef(null);
  const [company, setCompany] = useState('');
  const [companyLogo, setCompanyLogo] = useState(null);
  // const myElementRefCompany = useRef(null);

  const getCompanyTable = useSelector(state => state.getCompanyTable)
  const { loadingcompanytable, companyGetTableInfo } = getCompanyTable;
  function generatePassword() {
    const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
    const specialCharacters = '!@#$%^&*()-_=+[{]};:,<.>?/';
    const numbers = '0123456789';

    let password = '';

    // Generate one uppercase letter
    password += uppercaseLetters[Math.floor(Math.random() * uppercaseLetters.length)];

    // Generate five random lowercase letters (6 in total with the uppercase)
    for (let i = 0; i < 5; i++) {
      password += lowercaseLetters[Math.floor(Math.random() * lowercaseLetters.length)];
    }

    // Generate one special character
    password += specialCharacters[Math.floor(Math.random() * specialCharacters.length)];

    // Generate one numeric character
    password += numbers[Math.floor(Math.random() * numbers.length)];

    // Shuffle the password to mix the characters
    password = password.split('').sort(() => Math.random() - 0.5).join('');

    // Check if the password length is less than 8
    if (password.length < 8) {
      const missingLength = 8 - password.length;
      const remainingCharacters = lowercaseLetters + specialCharacters + numbers;

      // Add missing characters to meet the length requirement
      for (let i = 0; i < missingLength; i++) {
        password += remainingCharacters[Math.floor(Math.random() * remainingCharacters.length)];
      }

      // Shuffle again to mix the added characters
      password = password.split('').sort(() => Math.random() - 0.5).join('');
    }

    return password;
  }
  let bothpassword = generatePassword();
  var initialValues = {
    firstname: '',
    lastname: '',
    role: '',
    email: '',
    password: bothpassword,
    repassword: bothpassword
  }
  const schema = Yup.object({
    firstname: Yup.string('')
      .required('First Name is required')
      .min(3, 'First Name must be minimum of 3 charactors')
      .max(30, 'First Name Name must be maximum of 30 charactors'),
    // lastname: Yup.string('')
    //     .required('Last Name is required')
    //     .min(3, 'Last Name must be minimum of 3 charactors')
    //     .max(30, 'Last Name Name must be maximum of 30 charactors'),        
    role: Yup.string('')
      .required('Role is required'),
    email: Yup.string('')
      .required("Email is required!")
      .email("Email is invalid!")
      .min(6, 'Email should have of minimum 6 characters length!')
      .max(50, 'Email should be of maximum 50 characters length'),
    password: Yup.string()
      .min(6, 'Password should be of minimum 6 characters length')
      .max(15, 'Password should be of minimum 15 characters length')
      .required('Password is required'), //in editing password is not required initially because it comes from database and we will not check its required
    // .matches(/^(?=.*\d)(?=.*[@#\-_$%^&+=ยง!\?])(?=.*[a-z])(?=.*[A-Z])[0-9A-Za-z@#\-_$%^&+=ยง!\?]+$/, 'Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number and a special characters.'),
    repassword: Yup.string('')
      .min(6, 'Password should be of minimum 6 characters length')
      .max(15, 'Password should be of minimum 15 characters length')
      .required('Re Password is required') //.required('Password is required') in editing password is not required initially because it comes from database  and we will not check its required
      // .matches(/^(?=.*\d)(?=.*[@#\-_$%^&+=ยง!\?])(?=.*[a-z])(?=.*[A-Z])[0-9A-Za-z@#\-_$%^&+=ยง!\?]+$/, 'Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number and a special characters.')
      .when('password', {
        is: (val) => !!(val && val.length > 0),
        then: Yup.string().oneOf(
          [Yup.ref('password')],
          'Password and Retype Password do not match')
      }),
  });

  //for inline validations via Yup and formik
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (values, action) => {
      onUserCreate(values, action);
    }
  }
  );
  const tocategorypage = () => {
    navigate('/dashboard')
  };

  const onUserCreate = async (val, action) => {
    const postBody = {
      firstName: val.firstname,
      lastName: val.lastname,
      role: val.role,
      email: val.email,
      password: val.password,
    };

    if (val.role === 'Company') {
      postBody.company = company;
      postBody.regLisStatus = val.regLisStatus || false;
      postBody.compileStatus = val.compileStatus || false;
      postBody.compileStatusLCA = val.compileStatusLCA || false;
      postBody.compileStatusPA = val.compileStatusPA || false;
      postBody.inspectLisStatus = val.inspectLisStatus || false;
      postBody.dueDateStatus = val.dueDateStatus || false;
      postBody.isTradeLicenseStatus = val.isTradeLicenseStatus || false;
      postBody.isFactoryStatus = val.isFactoryStatus || false;
      postBody.isSEStatus = val.isSEStatus || false;
      postBody.isContracorsYesorNoStatus = val.isContracorsYesorNoStatus || false;
      postBody.isNightShiftPermissionStatus = val.isNightShiftPermissionStatus || false;
      postBody.isOTPermissionStatus = val.isOTPermissionStatus || false;
      postBody.isWeeklyOffExemptionStatus = val.isWeeklyOffExemptionStatus || false;
      postBody.isPFStatus = val.isPFStatus || false;
      postBody.isESIStatus = val.isESIStatus || false;
      postBody.isLWFStatus = val.isLWFStatus || false;
      postBody.isPTRStatus = val.isPTRStatus || false;
      postBody.isPTEStatus = val.isPTEStatus || false;
      postBody.isMSMEStatus = val.isMSMEStatus || false;
      postBody.isBOCWStatus = val.isBOCWStatus || false;
      postBody.isISMWStatus = val.isISMWStatus || false;
      postBody.isFASSAIStatus = val.isFASSAIStatus || false;
      postBody.isSBStatus = val.isSBStatus || false;

    }

    // API call        
    dispatch(usersCreates(postBody));
    action.resetForm();
  };


  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleClickShowRePassword = () => {
    setValuesRe({ ...valuesRe, showRePassword: !valuesRe.showRePassword });
  };
  useEffect(() => {
    if (userCreateInfo) {
      //navigate('/usercreate')
    }

  }, [])
  const calling = () => {
    dispatch(usersGet());
  }
  const createnew = () => {
    // dispatch(usersCreates());
  }
  const handleClickGeneratePassword = () => {
    const newPassword = generatePassword();
    formik.setFieldValue('password', newPassword);
    formik.setFieldValue('repassword', newPassword);
  };
  const handleRoleChange = (event) => {
    const role = event.target.value;
    formik.setFieldValue('role', role);
    if (role === 'Company') {
      setShowCompanyDropdown(true);
    } else {
      setShowCompanyDropdown(false);
    }
  };
  const getBbranch = (company) => {
    const elementcompanybranch = myElementRefCompany.current;
    const postBody = {
      id: elementcompanybranch.value
    }
    dispatch(branchGet(postBody));
  }
  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      setCompanyLogo(event.target.files[0]);
    }
  };

  return (

    <React.Fragment>
      <div className='dashboard_wrapper'>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <ul class="nav nav-pills mb-3 bg-light" id="pills-tab" role="tablist">
                <li class="nav-item col-md-6 col-lg-6 col-12" role="presentation">
                  <button class="nav-link w-100 active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true" onClick={calling}>View All</button>
                </li>
                <li class="nav-item col-md-6 col-lg-6 col-12" role="presentation">
                  <button class="nav-link w-100" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false" onClick={createnew}>Create New</button>
                </li>
              </ul>
              <div class="tab-content" id="pills-tabContent" >
                <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" >
                  <div class="row">
                    <div class="col-12 col-lg-12">
                      <UsersTable />
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                  <form class="row g-3" onSubmit={formik.handleSubmit}>
                    <div class="col-md-4">
                      <label for="" class="form-label">First Name *</label>
                      <input type="text" class="form-control" placeholder='Firstname Name'
                        // required='required'
                        value={formik.values.firstname}
                        id="firstname"
                        name="firstname"
                        onChange={formik.handleChange}
                        maxlength="30"
                      />
                      {formik.touched.firstname && formik.errors.firstname && (
                        <div className="error">{formik.errors.firstname}</div>
                      )}
                    </div>
                    <div class="col-md-4">
                      <label class="form-label">Last Name </label>
                      <input type="text" class="form-control" placeholder='Lastname Name'
                        value={formik.values.lastname}
                        id="lastname"
                        name="lastname"
                        onChange={formik.handleChange}
                        maxlength="30"
                      />
                      {/* {formik.touched.lastname && formik.errors.lastname && (
                        <div className="error">{formik.errors.lastname}</div>
                      )} */}
                    </div>
                    <div class="col-md-4">
                      <label for="" class="form-label">Select Role *</label><br />
                      <select id="role" name="role" class="form-control"
                        value={formik.values.role}
                        onChange={handleRoleChange}
                      >
                        <option value="" >Select Role</option>
                        <option value="Executive(Matrix)" >Executive(Matrix)</option>
                        <option value="Companey CEO" >Companey CEO</option>
                        <option value="Executive" >Executive</option>
                        <option value="Auditor" >Auditor</option>
                        <option value="Company">Company</option>
                      </select>
                      {formik.touched.role && formik.errors.role && (
                        <div className="error">{formik.errors.role}</div>
                      )}

                    </div>
                    {showCompanyDropdown && (
                      <div className="col-md-4">
                        <div className="mb-4">
                          <label for="company" class="form-label">Company</label>
                          <select className="form-select" aria-label="Default select example" id="companies" name="company" ref={myElementRefCompany} value={company} onChange={(e) => { setCompany(e.target.value); getBbranch(e.target.value) }} required>
                            <option value="">Select Company</option>
                            {companyGetTableInfo != 'undefind' && companyGetTableInfo?.length > 0 && companyGetTableInfo.map(item =>
                              <option value={item._id}>{item.companyname}</option>
                            )};
                          </select>
                          {formik.errors.company && formik.touched.company ?
                            (<p className='text-danger'>{formik.errors.company}</p>)
                            : null}
                        </div>
                      </div>
                    )}
                    <div class="col-md-4">
                      <label for="" class="form-label">Email ID *</label>
                      <input type="text" class="form-control" placeholder='d@h.co'
                        // required='required'
                        value={formik.values.email}
                        id="email"
                        name="email"
                        onChange={formik.handleChange}
                      />
                      {formik.touched.email && formik.errors.email && (
                        <div className="error">{formik.errors.email}</div>
                      )}
                    </div>
                    <div class="col-md-4">
                      <label class="form-label">Password *</label>
                      <input class="form-control" placeholder='Password'
                        value={formik.values.password}
                        id="password"
                        name="password"
                        onChange={formik.handleChange}
                        maxlength="30"
                        type={values.showPassword ? 'text' : 'password'}
                      />
                      <span className="d-flex align-items-center" onClick={handleClickShowPassword} style={{ justifyContent: "right", marginTop: '-18px' ,marginRight:'-20px'}}>
                        {values.showPassword ? (
                          <EyeOutlined className="d-flex mr-10" size={25}
                            style={{ position: "absolute", width: "3%", color: "#094D72",  }} />
                        ) : (

                          <EyeInvisibleOutlined className="d-flex mr-10" size={25}
                            style={{ position: "absolute", width: "3%", color: "#094D72",  }} />
                        )}
                      </span>
                      {formik.touched.password && formik.errors.password && (
                        <div className="error">{formik.errors.password}</div>
                      )}
                    </div>
                    <div className="col-md-4">
                      <label className="form-label">Confirm Password *</label>
                      <input
                        className="form-control"
                        placeholder="Confirm Password"
                        value={formik.values.repassword}
                        id="repassword"
                        name="repassword"
                        onChange={formik.handleChange}
                        maxLength="30"
                        type={valuesRe.showRePassword ? "text" : "password"}
                      />
                      <span className="position-relative" onClick={handleClickShowRePassword} style={{ float: "right", marginTop: "-30px", marginRight:'10px' }}>
                        {valuesRe.showRePassword ? (
                          <EyeOutlined size={25} style={{ color: "#094D72" }} />
                        ) : (
                          <EyeInvisibleOutlined size={25} style={{ color: "#094D72" }} />
                        )}
                      </span>
                      {formik.touched.repassword && formik.errors.repassword && (
                        <div className="error">{formik.errors.repassword}</div>
                      )}
                    </div>

                    <div className="col-md-8">
                      {formik.values.role === "Company" && (
                        <table className="table creat_tbl" style={{borderRadius:'10px !important'}}>
                          <tbody>
                            <tr>
                              <td className='form-control'>
                                <h5>Company Status Settings</h5>
                                {[
                                  { id: "regLisStatus", label: "Registration License Status" },
                                  { id: "compileStatus", label: "Compliance Status Regular" },
                                  { id: "compileStatusLCA", label: "Compliance Status LCA" },
                                  { id: "compileStatusPA", label: "Compliance Status PA" },
                                  { id: "inspectLisStatus", label: "Inspection License Status" },
                                  { id: "dueDateStatus", label: "Due Date Status" },
                                ].map(({ id, label }) => (
                                  <div className="form-check form-switch" key={id}>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id={id}
                                      name={id}
                                      checked={formik.values[id] || false}
                                      onChange={() => formik.setFieldValue(id, !formik.values[id])}
                                    />
                                    <label className="form-check-label" htmlFor={id}>{label}</label>
                                  </div>
                                ))}
                              </td>
                              {formik.values.regLisStatus === true && (

                              <td>
                                <h5>License Status</h5>
                                {[
                                  { id: "isTradeLicenseStatus", label: "Trade License Status" },
                                  { id: "isFactoryStatus", label: "Factory Status" },
                                  { id: "isSEStatus", label: "SE Status" },
                                  { id: "isContracorsYesorNoStatus", label: "Contractors Yes/No Status" },
                                  { id: "isNightShiftPermissionStatus", label: "Night Shift Permission Status" },
                                  { id: "isOTPermissionStatus", label: "OT Permission Status" },
                                  { id: "isWeeklyOffExemptionStatus", label: "Weekly Off Exemption Status" },
                                  { id: "isPFStatus", label: "PF Status" },
                                  { id: "isESIStatus", label: "ESI Status" },
                                  { id: "isLWFStatus", label: "LWF Status" },
                                  { id: "isPTRStatus", label: "PTR Status" },
                                  { id: "isPTEStatus", label: "PTE Status" },
                                  { id: "isMSMEStatus", label: "MSME Status" },
                                  { id: "isBOCWStatus", label: "BOCW Status" },
                                  { id: "isISMWStatus", label: "ISMW Status" },
                                  { id: "isFASSAIStatus", label: "FASSAI Status" },
                                  { id: "isSBStatus", label: "SB Status" },
                                ].map(({ id, label }) => (
                                  <div className="form-check form-switch" key={id}>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id={id}
                                      name={id}
                                      checked={formik.values[id] || false}
                                      onChange={() => formik.setFieldValue(id, !formik.values[id])}
                                    />
                                    <label className="form-check-label" htmlFor={id}>{label}</label>
                                  </div>
                                ))}
                              </td>
                             )}
                            </tr>
                            <tr>
                              <td colSpan="4">
                              <input type="file" accept="image/*" onChange={handleFileChange} />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      )}
                    </div>


                    {/* <div class="col-md-6"> */}
                    <button type="button" class="btn btn-secondary" onClick={handleClickGeneratePassword}>Generate New Password</button>
                    {/* </div> */}
                    <div class="col-md-6 mb-2">
                      <button type="submit" class="w-100 btn btn-dark" id="cancel" style={{ marginTop: '230px' }} onClick={tocategorypage}>Cancel</button>
                    </div>
                    <div class="col-md-6 mb-2">
                      <button type="submit" class="w-100 btn btn-primary" style={{ marginTop: '230px' }}>Save</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </React.Fragment>
  )
}

export default Users