import React, { useEffect, useState } from "react";
import { Table, Pagination, DatePicker, Select, Spin } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchDueDates, stateGets, branchGet, DueDatesDeleteById, getLabourContractAgreementTable, deleteLabourContractAgreementById, companyTableGet } from '../../../store/actions/otherActions';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2'; // Import SweetAlert2
import CreateLabourCont from './CreateLabourCont';
import Popup from "../../../components/Popup";
import CreateAgreeLabCont from "./CreateAgreeLabCont";

const { RangePicker } = DatePicker;
const { Option } = Select;

const ViewAllAgree = () => {
  const dispatch = useDispatch();
  // const { dueDates, loading, totalCount, totalPages, currentPage } = useSelector(state => state.dueDateRed);

  const getState = useSelector((state) => state.getState);
  const { stateInfo } = getState;
  const { getLabourContractAgreementInfoData, loadingLA } = useSelector((state) => state.getLabourContractAgreementRed);
  // const { getLabourContractAgreementInfo } = getLabourContractAgreementRed;
  // const { getLabourContractAgreementInfoData } = getLabourContractAgreementRed;
  const getCompanyTable = useSelector(state => state.getCompanyTable)
  const { loadingcompanytable, companyGetTableInfo } = getCompanyTable;
  const getBranch = useSelector((state) => state.getBranch);
  const { branchInfo = [], loading } = getBranch;
  const { contractorNameInfo } = useSelector((state) => state.ContractorNameRed);

  // console.log("getLabourContractAgreementInfoData", getLabourContractAgreementInfoData);

  const [dateRange, setDateRange] = useState([null, null]);
  const [state, setState] = useState("");
  const [company, setCompany] = useState("");
  const [labourContractor, setLabourContractor] = useState("");
  const [branch, setBranch] = useState("");
  const [pageSize, setPageSize] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [pageTitle, setPageTitle] = useState("");
  const [modalWidth, setModalWidth] = useState();
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  // console.log("recordForEdit", recordForEdit);

  useEffect(() => {
    dispatch(stateGets());
    dispatch(getLabourContractAgreementTable());
    dispatch(companyTableGet());
  }, [dispatch]);

  const formatDateToInput = (isoDate) => {
    if (!isoDate) return ""; // Return an empty string for empty or invalid dates
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero
    const day = String(date.getDate()).padStart(2, "0"); // Add leading zero
    return `${day}/${month}/${year}`;
  };

  const handleDelete = async (id) => {
    if (!id) return;

    try {
      // Custom confirmation dialog
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true,
      });

      if (result.isConfirmed) {
        // Proceed with deletion
        await dispatch(deleteLabourContractAgreementById(id));

        Swal.fire({
          icon: 'success',
          title: 'Deleted!',
          text: 'Labour Agreement deleted successfully.',
        });

        // ✅ Re-fetch data using **current filters**
        dispatch(getLabourContractAgreementTable({
          state: state || "", // ✅ Ensure the current state filter is used
          company: company || "", // ✅ Ensure the current state filter is used
          branch: branch || "",
          labourContractor: labourContractor || "",
          // page: currentPage,  // ✅ Keep the same page number
          limit: 50,          // ✅ Maintain the same pagination limit
        }));
      }
    } catch (error) {
      console.error("Delete Error:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: error?.message || 'An unexpected error occurred.',
      });
    }
  };

  const getBbranchs = (company, state) => {
    // const elementcompanybranch = myElementRefCompany1.current;
    // alert(company);return;
    const postBody = {
      id: company,
      state: state || ''
    }
    dispatch(branchGet(postBody));
  }

  const handlePaginationChange = (page, pageSize = 50) => {
    dispatch(getLabourContractAgreementTable({
      state: state || "", // ✅ Send only ONE state ID
      company: company || "", // ✅ Send only ONE state ID
      branch: branch || "",
      labourContractor: labourContractor || "",
      page,
      limit: pageSize,
    }));
  };




  const formattedLCInfo = getLabourContractAgreementInfoData?.map((item, index) => ({
    ...item,
    key: index + 1, // Ensure unique keys
  })) || [];



  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "key",
      key: "key",
      width: 80,
    },
    {
      title: "Agreement No.",
      dataIndex: "agreementNumber",
      key: "agreementNumber",
    },
    {
      title: "Labour Contractor",
      dataIndex: "labourContractor",
      key: "labourContractor",
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      width: 150,

    },
    {
      title: "State",
      dataIndex: "states",
      width: 180, // Increased width for better visibility
      key: "states",
      render: (states) => (
        <div style={{ maxHeight: "100px", overflowY: "auto", border: "1px solid #ddd", padding: "5px" }}>
          <ol style={{ paddingLeft: "15px", margin: 0 }}>
            {states.map((state) => (
              <li key={state._id}>{state.name}</li>
            ))}
          </ol>
        </div>
      ),
    },
    {
      title: "Branches",
      dataIndex: "branches",
      key: "branches",
      width: 200,
      render: (text, record) => {
        if (record.isApplicableToAllBranches) {
          return <span>All Branches</span>;
        }
        return (
          <div style={{ maxHeight: "100px", overflowY: "auto", border: "1px solid #ddd", padding: "5px" }}>
            <ol style={{ paddingLeft: "15px", margin: 0 }}>
              {record.branches.map((branch) => (
                <li key={branch._id}>{branch.name}</li>
              ))}
            </ol>
          </div>
        );
      },
    },

    {
      title: "Start Date",
      dataIndex: "agreementStartDate",
      key: "agreementStartDate",
      render: (agreementStartDate) => formatDateToInput(agreementStartDate),
    },
    {
      title: "End Date",
      dataIndex: "agreementEndDate",
      key: "agreementEndDate",
      render: (agreementEndDate) => formatDateToInput(agreementEndDate),
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => formatDateToInput(createdAt),
    },
    {
      key: "action",
      title: "Actions",
      width: 250,
      render: (record) => (
        <>
          <Link
            className="text-white btn btn-primary text-decoration-none mx-2"
            onClick={() => openInPopupForUpdate(record)}
          >
            View/<EditOutlined />
          </Link>
          <Link className="btn btn-danger mx-2" onClick={() => handleDelete(record._id)}>
            <DeleteOutlined />
          </Link>
        </>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getLabourContractAgreementTable({
      state: state || "", // ✅ Ensure only one state ID is sent
      company: company || "", // ✅ Ensure only one state ID is sent
      branch: branch || "",
      labourContractor: labourContractor || "",
      // page: currentPage,
      limit: 50,
    }));
  }, [dispatch, state, company]);


  const openInPopupForUpdate = (item) => {
    console.log("item", item);

    setRecordForEdit(item);
    setOpenPopup(true);
    setPageTitle("View/Edit Labour Contractors");
    setModalWidth("1000px");
  };

  return (
    <React.Fragment>
      <div className="container-fluid">
        {/* Filters Section */}
        <div className="row g-3 mb-3 pt-1 align-items-end">
          {/* State Filter */}
          <div className="col-md-3 col-sm-3">
            <label htmlFor="state" className="form-label fw-semibold">Company Filter</label>
            <select
              className="form-select"
              id="state"
              value={company}
              onChange={(e) => {
                const selectedCompany = e.target.value;
                setCompany(selectedCompany);
                getBbranchs(selectedCompany, state); // ✅ Store only one state ID
                handlePaginationChange(1, 50); // ✅ Reset pagination to page 1
              }}
            >
              <option value="">Select Company</option>
              {companyGetTableInfo?.map((item) => (
                <option key={item._id} value={item._id}>
                  {item.companyname}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-3 col-sm-3">
            <label htmlFor="state" className="form-label fw-semibold">State Filter</label>
            <select
              className="form-select"
              id="state"
              value={state}
              onChange={(e) => {
                const selectedState = e.target.value;
                setState(selectedState);
                getBbranchs(company, selectedState); // ✅ Store only one state ID
                handlePaginationChange(1, 50); // ✅ Reset pagination to page 1
              }}
            >
              <option value="">Select State</option>
              {stateInfo?.map((item) => (
                <option key={item._id} value={item._id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>


        {/* </div>
        <div className="row g-3 mb-3 pt-1 align-items-end"> */}
          {/* State Filter */}
          <div className="col-md-3 col-sm-3">
            <label htmlFor="state" className="form-label fw-semibold">Branch Filter</label>
            <select
              className="form-select"
              id="state"
              value={branch}
              onChange={(e) => {
                setBranch(e.target.value || ""); // ✅ Store only one state ID
                handlePaginationChange(1, 50); // ✅ Reset pagination to page 1
              }}
              disabled={!state}
            >
              <option value="">Select Branch</option>
              {branchInfo?.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-3 col-sm-3">
            <label htmlFor="state" className="form-label fw-semibold">Labour Contractor Filter</label>
            <select
              className="form-select"
              id="state"
              value={labourContractor}
              onChange={(e) => {
                setLabourContractor(e.target.value || ""); // ✅ Store only one state ID
                handlePaginationChange(1, 50); // ✅ Reset pagination to page 1
              }}
            >
              <option value="">Select Labour Contractor</option>
              {contractorNameInfo?.map((item) => (
                <option key={item._id} value={item._id}>
                  {item.contractorName}
                </option>
              ))}
            </select>
          </div>


        </div>

        {/* Table Section */}
        <div className="table-responsive">
          {loadingLA ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "500px" }}>
              <Spin size="large" />
            </div>
          ) : (
            <Table
              columns={columns}
              dataSource={formattedLCInfo}
              pagination={false}
              rowKey="key"
              scroll={{ x: 1400 }}
              sticky
            />
          )}
        </div>

        {/* Pagination */}
        <div className="d-flex justify-content-center mt-3">
          <Pagination
            // current={currentPage}
            // total={totalCount}
            pageSize={50}  // Ensure it's set
            onChange={(page) => handlePaginationChange(page, 50)}
            showSizeChanger={false}

          />
        </div>

      </div>
      <Popup openPopup={openPopup} pageTitle={pageTitle} setOpenPopup={setOpenPopup} modalWidth={modalWidth}>
        {openPopup && <CreateAgreeLabCont recordForEdit={recordForEdit} setOpenPopup={setOpenPopup} />}
      </Popup>
    </React.Fragment>
  );
};

export default ViewAllAgree;
