import React, { useEffect } from 'react';

const  DynamicHTMLGeneratorF54NSP = ({ formData, setFormData, rowIndex, noOfBranch }) =>{

  useEffect(() => {
    // Call handlenumberOfPersonsChange when the component mounts
    handlenumberOfPersonsChange({ target: { value: formData.length } });
    // console.log('rIndex: => ', rowIndex)
    // console.log('branch: => ', noOfBranch)
  }, []);
  const handlenumberOfPersonsChange = (e) => {
    // console.log(noOfBranch, ':::')
      const numberOfPersons = 1; //parseInt(e.target.value);
      const newFormData = Array.from({ length: noOfBranch }, () => ({
      licensenumber: '',
      licenseimage: '',
      licenseremark: '',
      dor: '',
      doe: '',
      doddr: '',
      issuingauth: '',
      issuingauthdetails: '',
      issuingauthimage: '',
      issuingauthremark: '',
    }));
    setFormData(newFormData);
    // console.log(newFormData);
  };

  const handleInputChange = (e, fieldName, index) => {
    let newFormData = [...formData];
    
    if((fieldName).indexOf('image') !== -1)
    {
      newFormData[index][fieldName] = e.target.files[0];
      setFormData(newFormData);
    }
    else{
      // newFormData = [...formData];
      newFormData[index][fieldName] = e.target.value;
      setFormData(newFormData);
    }
  };

  const generateDirectorsForm = () => {
    return (
      <React.Fragment key={rowIndex}>
        <tr>
          <td>
              <label className="form-label">License Number</label>
                <input
                type="text"
                className="form-control"
                placeholder="Licence Number"
                value={formData[rowIndex]?.licensenumber || ''}
                id={`licensenumber_${rowIndex}`}  
                name={`licensenumber_${rowIndex}`} 
                onChange={(e)=>handleInputChange(e, 'licensenumber', rowIndex)}
                />
            </td>
            <td colspan="2"> 
            <div>
                    <div class="form-group files1">
                        <input type="file" class="form-control" multiple="" accept="image/*,application/pdf" style={{ height:'10px' }}
                        id={`licenseimage_${rowIndex}`}  
                        name={`licenseimage_${rowIndex}`} 
                        onChange={(e) => handleInputChange(e,'licenseimage', rowIndex)} 
                        />
                    </div>
                    {formData[rowIndex]?.licenseimage && (
                      <div>
                        <a href={formData[rowIndex].licenseimage} target="_blank" rel="noopener noreferrer">
                          Open License Number File/Image
                          </a>
                      </div>
                    )}
                </div>
            </td>
            <td>
            <label for="">Remark</label>
            <input type="text" class="form-control"  
            id={`licenseremark_${rowIndex}`}  
            name={`licenseremark_${rowIndex}`} 
            value={formData[rowIndex]?.licenseremark || ''} 
            onChange={(e) => handleInputChange(e,'licenseremark', rowIndex)} placeholder="Write Here"/>
            </td>
        </tr>
        <tr>
          <td colspan="4">
            <label className="form-label">Date of Registration</label>
            <input
              type="date"
              className="form-control"
              placeholder="Date of Registration"
              value={formData[rowIndex]?.dor || ''}
              onChange={(e) => handleInputChange(e, 'dor', rowIndex)}
              id={`dor_${rowIndex}`}  
              name={`dor_${rowIndex}`} 
              
            />
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <label className="form-label">Date of Expiry</label>
            <input
              type="date"
              className="form-control"
              placeholder="Date of Expiry"
              value={formData[rowIndex]?.doe || ''}
              onChange={(e) => handleInputChange(e, 'doe', rowIndex)}
              id={`doe_${rowIndex}`}  
              name={`doe_${rowIndex}`} 
              
            />
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <label className="form-label">Due Date of Renewal</label>
            <input
              type="date"
              className="form-control"
              placeholder="Due Date of Renewal"
              value={formData[rowIndex]?.doddr || ''}
              onChange={(e) => handleInputChange(e, 'doddr', rowIndex)}
              id={`doddr_${rowIndex}`}  
              name={`doddr_${rowIndex}`} 
              
            />
          </td>
        </tr>
        <tr>
          <td colspan="2">
                <label className="form-label">Issuing Authority</label>
                <input type="text" class="form-control" 
                placeholder="Issuing Authority" 
                value={formData[rowIndex]?.issuingauth || ''}
                onChange={(e)=>handleInputChange(e, 'issuingauth', rowIndex)} 
                name={`issuingauth_${rowIndex}`}  
                id={`issuingauth_${rowIndex}`}  
                />
          </td>
          {/* <td>
            <label className="form-label">Details</label>
            <input
              type="text"
              className="form-control"
              placeholder="Details"
              value={formData[rowIndex]?.issuingauthdetails || ''}
              onChange={(e) => handleInputChange(e, 'issuingauthdetails', index)}
              id={`issuingauthdetails_${index}`}  
              name={`issuingauthdetails_${index}`} 
            />
          </td>  */}
          <td>
            <div className="form-group files1">
              <input
                type="file"
                multiple=""
                accept="image/*,application/pdf"
                className="form-control"
                style={{ height: '10px' }}
                onChange={(e) => handleInputChange(e, 'issuingauthimage', rowIndex)}
                id={`issuingauthimage_${rowIndex}`}  
                name={`issuingauthimage_${rowIndex}`} 
                
              />
            </div>
            {formData[rowIndex]?.issuingauthimage && (
                      <div>
                        <a href={formData[rowIndex]?.issuingauthimage} target="_blank" rel="noopener noreferrer">
                          Open Issuing Authority File/Image
                          </a>
                      </div>
                    )}
          </td>
          <td>
            <label className="form-label">Remarks</label>
            <input
              type="text"
              className="form-control"
              placeholder="Remark"
              value={formData[rowIndex]?.issuingauthremark || ''}
              onChange={(e) => handleInputChange(e, 'issuingauthremark', rowIndex)}
              id={`issuingauthremark_${rowIndex}`}  
              name={`issuingauthremark_${rowIndex}`} 
            />
          </td>
        </tr>
      </React.Fragment>
      )
    
  };

  return (
    <table className="table  creat_tbl">
      <tbody>
      <tr>
          <td colSpan="4">
            {/* <label className="form-label">Number</label> */}
            <input
              type="text"
              className="form-control"
              placeholder="Number of Subcodes"
              value={formData.length}
              onChange={handlenumberOfPersonsChange}
              style={{ display:'none' }}
            />
          </td>
        </tr>
        {generateDirectorsForm()}
      </tbody>
    </table>
  );
}

export default DynamicHTMLGeneratorF54NSP;
