import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import {
  bulkBranches,
  bulkBranchesEdit,
  AllbranchesGet,
  TableBranchesGet,
  generateExcelDownload,
  stateGets,
  companyTableGet,
} from "../../store/actions/otherActions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  Button,
  Typography,
  Paper,
  Input,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  CircularProgress,
  Card,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import GetAppIcon from "@mui/icons-material/GetApp";

const DownloadExcel = () => {
  const BranchesAddBulkRed = useSelector((state) => state.BranchesAddBulkRed);
  const { loadingtabBulk } = BranchesAddBulkRed;
  const { loadingtabBulkEdit, branchesEditBulkInfo, errorEdit } = useSelector(
    (state) => state.BranchesEditBulkRed
  );
  const { loadingExcelDownload, branchesDownloadInfo, errorBranchesDownload } =
    useSelector((state) => state.ExcelDownloadRed);
  const getCompanyTable = useSelector((state) => state.getCompanyTable);
  const { loadingcompanytable, companyGetTableInfo } = getCompanyTable;
  const getState = useSelector((state) => state.getState);
  const { loadings, stateInfo } = getState;

  const [companyId, setCompanyId] = useState("");
  const [stateId, setStateId] = useState("");
  const [file, setFile] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(companyTableGet());
    dispatch(stateGets());
  }, [dispatch]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = () => {
    if (file) {
      dispatch(bulkBranches(file));
      dispatch(AllbranchesGet());
      dispatch(TableBranchesGet());
    } else {
      toast.error("Please select a file first");
    }
  };
  const handleFileChangeEdit = (e) => {
    setFile(e.target.files[0]);
  };
  const handleUploadEdit = () => {
    if (file) {
      dispatch(bulkBranchesEdit(file));
      dispatch(AllbranchesGet());
      dispatch(TableBranchesGet());
    } else {
      toast.error("Please select a file first");
    }
  };

  const handleDownload = () => {
    if (companyId && stateId) {
      dispatch(generateExcelDownload({ companyId, stateId }));
    } else {
      toast.error("Please provide both Company ID and State ID");
    }
  };
  const handleCompanyChange = (event) => {
    setCompanyId(event.target.value); // Set selected company ID
  };

  const handleStateChange = (event) => {
    setStateId(event.target.value); // Set selected state ID
  };

  const branchData = [
    {
      company: "Name of Company",
      name: "Name of Branch",
      region: "choose one = SouthRegion, NorthRegion,WestRegion, EastRegion",
      isFactoryOrSE: "S&E",
      branchaddress: "123 Street",
      branchstate: "Name of State",
      branchdistrict: "District 1",
      branchpin: "123456",
      branchOpeningDateF: "2025-01-20",
      noOfEmpBranchF: 100,
      managerNameF1: "Manager 1",
      managerMobNoF1: "1234567890",
      managerEmailF1: "manager@example.com",
      managerAadharNoF1: "1234 5678 9101",
      managerPanF1: "ABCDE1234F",
      shopsEstLicenseF2: "License Number",
      // noOfContractorsF5: 5,
      lis_status_se: 1,
      dor: "2025-01-01",
      doe: "2025-12-31",
      doddr: "2025-01-01",
      managernamelicense: "Manager License Name",
      noe: 10,
      nom: 15,
      nof: 20,
      issuingauth: "Auth 1",
      isNightShiftPermission: "YES/NO",
      licensenumberNSP: "NSP License Number",
      dorNSP: "2025-01-01",
      doeNSP: "2025-12-31",
      doddrNSP: "2025-01-01",
      lis_statusNSP: 1,
      issuingauthNSP: "NSP Auth",
      isOTPermission: "YES/NO",
      licensenumberOTP: "OTP License Number",
      dorOTP: "2025-01-01",
      doeOTP: "2025-12-31",
      doddrOTP: "2025-01-01",
      lis_statusOTP: 1,
      issuingauthOTP: "OTP Auth",
      isTradeLicense: "YES/NO",
      licensenumberTL: "TL License Number",
      dorTL: "2025-01-01",
      doeTL: "2025-12-31",
      doddrTL: "2025-01-01",
      lis_statusTL: 1,
      issuingauthTL: "TL Auth",
      isWeeklyOffExemption: "YES/NO",
      licensenumberWOE: "WOE License Number",
      dorWOE: "2025-01-01",
      doeWOE: "2025-12-31",
      doddrWOE: "2025-01-01",
      lis_statusWOE: 1,
      issuingauthWOE: "WOE Auth",
    },
  ];

  const exportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Branches");

    // Define headers directly from branchData1 keys (no formatting)
    const headers = Object.keys(branchData[0]).map((key) => ({
      key,
      name: key, // Keep the exact key name from branchData1
    }));

    // Add header row with exact field names
    worksheet.addRow(headers.map((header) => header.name));

    // Apply styles (specific columns in red)
    worksheet.getRow(1).eachCell((cell, colNumber) => {
      const redColumns = [1, 2, 3, 4, 6, 4, 21, 26, 31, 33, 38, 40, 45, 47, 52]; // A=1, G=7, D=4, K=11, W=23, AG=33

      if (redColumns.includes(colNumber)) {
        cell.font = { bold: false, color: { argb: "FF0000" } }; // Red font for specific columns
      } else {
        cell.font = { bold: false }; // No bold for others
      }
      cell.alignment = { horizontal: "center", vertical: "middle" };
    });

    // Add data rows
    branchData.forEach((data) => {
      const rowData = headers.map((header) => data[header.key] || "");
      worksheet.addRow(rowData);
    });

    // Add comments to specific headers
    worksheet.getCell("A1").note = "It is Mandatory";
    worksheet.getCell("B1").note = "It is Mandatory";
    worksheet.getCell("C1").note = "It is Mandatory";
    worksheet.getCell("D1").note = "It is Mandatory and should be S&E";
    worksheet.getCell("E1").note = "Address of the Branch";
    worksheet.getCell("F1").note = "It is Mandatory";
    worksheet.getCell("G1").note = "District of the Branch";
    worksheet.getCell("H1").note = "Pincdoe of the Branch";
    worksheet.getCell("I1").note = "Opening Date of the Branch";
    worksheet.getCell("J1").note = "Total No. of Employee in the Branch";
    worksheet.getCell("K1").note = "Name of the Branch Manager";
    worksheet.getCell("L1").note = "Contact No. of the Branch Manager";
    worksheet.getCell("M1").note = "Email of the Branch Manager";
    worksheet.getCell("N1").note = "Aadhar No. of the Branch Manager";
    worksheet.getCell("O1").note = "PAN No. of the Branch Manager";
    worksheet.getCell("P1").note = "S&E License Number";
    worksheet.getCell("Q1").note = "S&E License Status";
    worksheet.getCell("R1").note = "S&E Date of Registration";
    worksheet.getCell("S1").note = "S&E Date of Expiry";
    worksheet.getCell("T1").note = "S&E Due Date for Renewal";
    worksheet.getCell("U1").note = "S&E Manager Name of the License";
    worksheet.getCell("V1").note = "S&E Number of the Employees";
    worksheet.getCell("W1").note = "S&E Male of the Employees";
    worksheet.getCell("X1").note = "S&E Female of the Employees";
    worksheet.getCell("Y1").note = "S&E Issuing Authority";
    worksheet.getCell("Z1").note = "YES OR NO For Night Shift Permission(NSP)";
    worksheet.getCell("AA1").note = "NSP License Number";
    worksheet.getCell("AB1").note = "NSP Date of Registration";
    worksheet.getCell("AC1").note = "NSP Date of Expiry";
    worksheet.getCell("AD1").note = "NSP Due Data for Renewal";
    worksheet.getCell("AE1").note =
      "1=Not In Scope 2=License Applied 3=License Not-Applied 4=Lifetime";
    worksheet.getCell("AF1").note = "NSP Issuing Authority";
    worksheet.getCell("AG1").note = "YES OR NO For Over Time Permission(OTP)";
    worksheet.getCell("AH1").note = "OTP License Number";
    worksheet.getCell("AI1").note = "OTP Date of Registration";
    worksheet.getCell("AJ1").note = "OTP Date of Expiry";
    worksheet.getCell("AK1").note = "OTP Due Data for Renewal";
    worksheet.getCell("AL1").note =
      "1=Not In Scope 2=License Applied 3=License Not-Applied 4=Lifetime";
    worksheet.getCell("AM1").note = "OTP Issuing Authority";
    worksheet.getCell("AN1").note = "YES OR NO For Over Time Permission(WOE)";
    worksheet.getCell("AO1").note = "WOE License Number";
    worksheet.getCell("AP1").note = "WOE Date of Registration";
    worksheet.getCell("AQ1").note = "WOE Date of Expiry";
    worksheet.getCell("AR1").note = "WOE Due Data for Renewal";
    worksheet.getCell("AS1").note =
      "1=Not In Scope 2=License Applied 3=License Not-Applied 4=Lifetime";
    worksheet.getCell("AT1").note = "WOE Issuing Authority";
    worksheet.getCell("AU1").note = "YES OR NO For Over Time Permission(TL) ";
    worksheet.getCell("AV1").note = "TL License Number";
    worksheet.getCell("AW1").note = "TL Date of Registration";
    worksheet.getCell("AX1").note = "TL Date of Expiry";
    worksheet.getCell("AY1").note = "TL Due Data for Renewal";
    worksheet.getCell("AZ1").note =
      "1=Not In Scope 2=License Applied 3=License Not-Applied 4=Lifetime";
    worksheet.getCell("BA1").note = "TL Issuing Authority";

    // Create file and trigger download
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "BranchDataSE.xlsx");
  };

  const branchData1 = [
    {
      company: "Name of Company",
      name: "Name of Branch",
      region: "choose one = SouthRegion, NorthRegion,WestRegion, EastRegion",
      isFactoryOrSE: "Factory",
      branchaddress: "123 Street",
      branchstate: "Name of State",
      branchdistrict: "District 1",
      branchpin: "123456",
      branchOpeningDateF: "2025-01-20",
      noOfEmpBranchF: 100,
      managerNameF1: "Manager 1",
      managerMobNoF1: "1234567890",
      managerEmailF1: "manager@example.com",
      managerAadharNoF1: "1234 5678 9101",
      // managerPanF1: "ABCDE1234F",
      // shopsEstLicenseF2: "License Number",
      // noOfContractorsF5: 5,
      licensenumber1: "License Number 1",
      lis_status_factory: 1,
      dor1: "2025-01-01",
      doe1: "2025-12-31",
      doddr1: "2025-01-01",
      managernamelicense1: "Manager License Name 1",
      noe1: 10,
      nom1: 15,
      nof1: 20,
      issuingauth1: "Auth 2",
      licensenumber2: "License Number 2",
      dor2: "2025-01-01",
      issuingauth2: "Auth 3",
      powerHP: "100 HP",
      powerKW: "75 KW",
      ApprovedPower: "Approved Power",
      isNightShiftPermission: "YES/NO",
      licensenumberNSP: "NSP License Number",
      dorNSP: "2025-01-01",
      doeNSP: "2025-12-31",
      doddrNSP: "2025-01-01",
      lis_statusNSP: 1,
      issuingauthNSP: "NSP Auth",
      isOTPermission: "YES/NO",
      licensenumberOTP: "OTP License Number",
      dorOTP: "2025-01-01",
      doeOTP: "2025-12-31",
      doddrOTP: "2025-01-01",
      lis_statusOTP: 1,
      issuingauthOTP: "OTP Auth",
      isTradeLicense: "YES/NO",
      licensenumberTL: "TL License Number",
      dorTL: "2025-01-01",
      doeTL: "2025-12-31",
      doddrTL: "2025-01-01",
      lis_statusTL: 1,
      issuingauthTL: "TL Auth",
      isWeeklyOffExemption: "YES/NO",
      licensenumberWOE: "WOE License Number",
      dorWOE: "2025-01-01",
      doeWOE: "2025-12-31",
      doddrWOE: "2025-01-01",
      lis_statusWOE: 1,
      issuingauthWOE: "WOE Auth",
    },
  ];

  const exportToExcel1 = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Branches");

    // Define headers directly from branchData1 keys (no formatting)
    const headers = Object.keys(branchData1[0]).map((key) => ({
      key,
      name: key, // Keep the exact key name from branchData1
    }));

    // Add header row with exact field names
    worksheet.addRow(headers.map((header) => header.name));

    // Apply styles (specific columns in red)
    worksheet.getRow(1).eachCell((cell, colNumber) => {
      const redColumns = [
        1, 2, 3, 4, 6, 4, 11, 16, 20, 31, 36, 38, 43, 45, 50, 52, 57,
      ]; // A=1, G=7, D=4, K=11, W=23, AG=33

      if (redColumns.includes(colNumber)) {
        cell.font = { bold: true, color: { argb: "FF0000" } }; // Red font for specific columns
      } else {
        cell.font = { bold: false }; // No bold for others
      }
      cell.alignment = { horizontal: "center", vertical: "middle" };
    });

    // Add data rows
    branchData1.forEach((data) => {
      const rowData = headers.map((header) => data[header.key] || "");
      worksheet.addRow(rowData);
    });

    worksheet.getCell("A1").note = "It is Mandatory";
    worksheet.getCell("B1").note = "It is Mandatory";
    worksheet.getCell("C1").note = "It is Mandatory";
    worksheet.getCell("D1").note = "It is Mandatory and should be S&E";
    worksheet.getCell("E1").note = "Address of the Branch";
    worksheet.getCell("F1").note = "It is Mandatory";
    worksheet.getCell("G1").note = "District of the Branch";
    worksheet.getCell("H1").note = "Pincdoe of the Branch";
    worksheet.getCell("I1").note = "Opening Date of the Branch";
    worksheet.getCell("J1").note = "Total No. of Employee in the Branch";
    worksheet.getCell("K1").note = "Name of the Branch Manager";
    worksheet.getCell("L1").note = "Contact No. of the Branch Manager";
    worksheet.getCell("M1").note = "Email of the Branch Manager";
    worksheet.getCell("N1").note = "Aadhar No. of the Branch Manager";
    worksheet.getCell("O1").note = "Factory License Number";
    worksheet.getCell("P1").note = "Factory License Status";
    worksheet.getCell("Q1").note = "Factory Date of Registration";
    worksheet.getCell("R1").note = "Factory Date of Expiry";
    worksheet.getCell("S1").note = "Factory Due Date for Renewal";
    worksheet.getCell("T1").note = "Factory Manager Name of the License";
    worksheet.getCell("U1").note = "Factory Number of the Employees";
    worksheet.getCell("V1").note = "Factory Male of the Employees";
    worksheet.getCell("W1").note = "Factory Female of the Employees";
    worksheet.getCell("X1").note = "Factory Issuing Authority";
    worksheet.getCell("Y1").note = "Factory Plan License Number";
    worksheet.getCell("Z1").note = "Factory Plan Date of Registration";
    worksheet.getCell("AA1").note = "Factory Plan Issuing Authority";
    worksheet.getCell("AB1").note = "Factory Plan Power in HP";
    worksheet.getCell("AC1").note = "Factory Plan Power in KW";
    worksheet.getCell("AD1").note = "Factory Plan Approved Plan";
    worksheet.getCell("AE1").note = "YES OR NO For Night Shift Permission(NSP)";
    worksheet.getCell("AF1").note = "NSP License Number";
    worksheet.getCell("AG1").note = "NSP Date of Registration";
    worksheet.getCell("AH1").note = "NSP Date of Expiry";
    worksheet.getCell("AI1").note = "NSP Due Data for Renewal";
    worksheet.getCell("AJ1").note =
      "1=Not In Scope 2=License Applied 3=License Not-Applied 4=Lifetime";
    worksheet.getCell("AK1").note = "NSP Issuing Authority";
    worksheet.getCell("AL1").note = "YES OR NO For Over Time Permission(OTP)";
    worksheet.getCell("AM1").note = "OTP License Number";
    worksheet.getCell("AN1").note = "OTP Date of Registration";
    worksheet.getCell("AO1").note = "OTP Date of Expiry";
    worksheet.getCell("AP1").note = "OTP Due Data for Renewal";
    worksheet.getCell("AQ1").note =
      "1=Not In Scope 2=License Applied 3=License Not-Applied 4=Lifetime";
    worksheet.getCell("AR1").note = "OTP Issuing Authority";
    worksheet.getCell("AS1").note = "YES OR NO For Over Time Permission(WOE)";
    worksheet.getCell("AT1").note = "WOE License Number";
    worksheet.getCell("AU1").note = "WOE Date of Registration";
    worksheet.getCell("AV1").note = "WOE Date of Expiry";
    worksheet.getCell("AW1").note = "WOE Due Data for Renewal";
    worksheet.getCell("AX1").note =
      "1=Not In Scope 2=License Applied 3=License Not-Applied 4=Lifetime";
    worksheet.getCell("AY1").note = "WOE Issuing Authority";
    worksheet.getCell("AZ1").note = "YES OR NO For Over Time Permission(TL)";
    worksheet.getCell("BA1").note = "TL License Number";
    worksheet.getCell("BB1").note = "TL Date of Registration";
    worksheet.getCell("BC1").note = "TL Date of Expiry";
    worksheet.getCell("BD1").note = "TL Due Data for Renewal";
    worksheet.getCell("BE1").note =
      "1=Not In Scope 2=License Applied 3=License Not-Applied 4=Lifetime";
    worksheet.getCell("BF1").note = "TL Issuing Authority";

    // Create file and trigger download
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "BranchDataFactory.xlsx");
  };

  return (
    <>
      <div
        className="flex"
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "20px",
        }}
      >
        <div>
          {/* Bulk Branches Upload Paper */}
          <Paper
            elevation={3}
            sx={{ padding: 3, maxWidth: 800, margin: "auto", mt: 4, height: 730 }}
          >
            <Typography variant="h5" align="center" gutterBottom>
              Bulk Branches Upload
            </Typography>

            <Card
              variant="outlined"
              sx={{ background: "#fafafa", padding: 2, marginBottom: 3 }}
            >
              <Typography variant="h6">Upload Instructions</Typography>
              <Typography variant="body1">
                Please follow the guidelines below before uploading:
              </Typography>
              <ul>
                <li>
                  Headings with font <span style={{ color: "red" }}>RED</span> in
                  colour are Mandatory.
                </li>
                <li>
                  Download the sample Excel file to understand the required
                  format.
                </li>
                <li>Ensure that all required fields are filled correctly.</li>
                <li>
                  The file must be in{" "}
                  <strong style={{ color: "red" }}>.xlsx</strong> format.
                </li>
              </ul>
            </Card>

            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="success"
                        startIcon={<GetAppIcon />}
                        onClick={exportToExcel}
                        fullWidth
                      >
                        Download Sample Excel for S&E
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="success"
                        startIcon={<GetAppIcon />}
                        onClick={exportToExcel1}
                        fullWidth
                      >
                        Download Sample Excel for Factory
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Input
                        type="file"
                        onChange={handleFileChange}
                        fullWidth
                        sx={{
                          padding: "10px",
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                          marginTop: "10px",
                        }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Button
                        variant="contained"
                        startIcon={<CloudUploadIcon />}
                        onClick={handleUpload}
                        fullWidth
                        disabled={loadingtabBulk}
                        style={{ backgroundColor: "#013879", color: "white" }}
                      >
                        {loadingtabBulk ? (
                          <CircularProgress size={24} />
                        ) : (
                          "Upload"
                        )}
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
        <div>

          {/* Bulk Branches Edit Paper */}
          <Paper
            elevation={3}
            sx={{ padding: 3, maxWidth: 800, margin: "auto", mt: 4 }}
          >
            <Typography variant="h5" align="center" gutterBottom>
              Bulk Branches Edit
            </Typography>

            <Card
              variant="outlined"
              sx={{ background: "#fafafa", padding: 2, marginBottom: 3 }}
            >
              <Typography variant="h6">Upload Instructions</Typography>
              <Typography variant="body1">
                Please follow the guidelines below before uploading:
              </Typography>
              <ul>
                <li>
                  Headings with font <span style={{ color: "red" }}>RED</span> in
                  colour are Mandatory.
                </li>
                <li>Ensure that all required fields are filled correctly.</li>
                <li>
                  The file must be in{" "}
                  <strong style={{ color: "red" }}>.xlsx</strong> format.
                </li>
                <li style={{color:'red'}}>Please Download the Branch Details from below<br/> <strong style={{color:"rgb(1, 56, 121)",}}>Download Option</strong> for Updated Current Data</li>
              </ul>
            </Card>



            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Input
                        type="file"
                        onChange={handleFileChangeEdit}
                        fullWidth
                        sx={{
                          padding: "10px",
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                          marginTop: "10px",
                        }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Button
                        variant="contained"
                        startIcon={<CloudUploadIcon />}
                        onClick={handleUploadEdit}
                        fullWidth
                        disabled={loadingtabBulkEdit}
                        style={{ backgroundColor: "#013879", color: "white" }}
                      >
                        {loadingtabBulkEdit ? (
                          <CircularProgress size={24} />
                        ) : (
                          "Upload"
                        )}
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
<br/>
          <Paper>

            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <div className="flex flex-col items-center space-y-6 p-6 bg-gray-50 rounded-lg shadow-lg max-w-md mx-auto">
                        <Typography variant="h5" align="center" gutterBottom className="text-blue-600 font-semibold">
                          Branches Download for Edit/Report
                        </Typography>

                        {/* Company Dropdown */}
                        <FormControl fullWidth variant="outlined" disabled={loadingcompanytable} sx={{ minWidth: 200 }}>
                          <InputLabel>Company</InputLabel>
                          <Select
                            value={companyId}
                            onChange={handleCompanyChange}
                            label="Company"
                            className="shadow-md"
                          >
                            {loadingcompanytable ? (
                              <MenuItem disabled>
                                <CircularProgress size={24} />
                              </MenuItem>
                            ) : (
                              companyGetTableInfo?.map((company) => (
                                <MenuItem key={company._id} value={company._id}>
                                  {company.companyname}
                                </MenuItem>
                              ))
                            )}
                          </Select>
                        </FormControl>

                        {/* State Dropdown */}
                        <FormControl fullWidth variant="outlined" disabled={loadings} sx={{ minWidth: 200 }}>
                          <InputLabel>State</InputLabel>
                          <Select
                            value={stateId}
                            onChange={handleStateChange}
                            label="State"
                            className="shadow-md"
                          >
                            {loadings ? (
                              <MenuItem disabled>
                                <CircularProgress size={24} />
                              </MenuItem>
                            ) : (
                              stateInfo?.map((state) => (
                                <MenuItem key={state._id} value={state._id}>
                                  {state.name}
                                </MenuItem>
                              ))
                            )}
                          </Select>
                        </FormControl>

                        {/* Download Button */}
                        <Button
                          style={{ width: '100%' }}
                          variant="contained"
                          color="primary"
                          onClick={handleDownload}
                          disabled={!companyId || !stateId}
                          className="mt-4"
                        >
                          {loadingcompanytable || loadings ? <CircularProgress size={24} /> : "Download Excel"}
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <br/>
        </div>
      </div>
    </>
  );
};

export default DownloadExcel;
