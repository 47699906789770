import { SearchOutlined } from "@ant-design/icons";
import React, { useRef, useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import Highlighter from "react-highlight-words";
import { Button, Input, Space, Table, Modal, Form, Switch } from "antd";

import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import {
  usersGet,
  userEdit,
  deleteUser,
} from "../../store/actions/otherActions";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import "antd/dist/reset.css";
const UsersTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userGet = useSelector((state) => state.userGet);
  const { loading, usersInfo, error } = userGet;
  const userEdits = useSelector((state) => state.userEdits);
  const { loadings, userEditInfo } = userEdits;
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const getCompanyTable = useSelector((state) => state.getCompanyTable);
  const { loadingcompanytable, companyGetTableInfo } = getCompanyTable;
  const myElementRefCompany = useRef(null);
  const [company, setCompany] = useState("");

  const [isEditing, setIsEditing] = useState(false);
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState();
  const [editingUser, setEditingUser] = useState(null);
  let defaultDate = new Date();
  defaultDate.setDate(defaultDate.getDate());
  //   defaultDate.setDateUpdate(defaultDate.getDate() )
  const [date, setDate] = useState(defaultDate);
  const [visible, setVisible] = useState(false);
  const onSetDate = (date) => {
    setDate(new Date(date));
  };
  //  alert(catGetInfo);
  useEffect(() => {
    dispatch(usersGet());
  }, [dispatch]);
  useEffect(() => {
    let userArr = [];
    if (usersInfo?.length > 0) {
      usersInfo.map((item, index) => {
        userArr.push({
          key: index + 1,
          id: item._id,
          userName: item.userName,
          role1: (
            <div>
              {item.role}
              {item.companyName ? (
                <div>
                  <span>-({item.companyName})</span>
                </div>
              ) : (
                null
              )}
            </div>
          ),
          role: item.role,
          email: item.email,
          password: item.realpassword,
          companyName: item.companyName,
          company: item.company,
          compileStatus: item.compileStatus,
          compileStatusLCA: item.compileStatusLCA,
          compileStatusPA: item.compileStatusPA,
          inspectLisStatus: item.inspectLisStatus,
          dueDateStatus: item.dueDateStatus,
          regLisStatus: item.regLisStatus,
          isTradeLicenseStatus: item.isTradeLicenseStatus,
          isFactoryStatus: item.isFactoryStatus,
          isSEStatus: item.isSEStatus,
          isContracorsYesorNoStatus: item.isContracorsYesorNoStatus,
          isNightShiftPermissionStatus: item.isNightShiftPermissionStatus,
          isOTPermissionStatus: item.isOTPermissionStatus,
          isWeeklyOffExemptionStatus: item.isWeeklyOffExemptionStatus,
          isPFStatus: item.isPFStatus,
          isESIStatus: item.isESIStatus,
          isLFWStatus: item.isLFWStatus,
          isPTRStatus: item.isPTRStatus,
          isPTEStatus: item.isPTEStatus,
          isMSMEStatus: item.isMSMEStatus,
          isBOCWStatus: item.isBOCWStatus,
          isISMWStatus: item.isISMWStatus,
          isFASSAIStatus: item.isFASSAIStatus,
          isSBStatus: item.isSBStatus,
          createdAt: formatDate(item.createdAt),
          updatedAt: formatDate(item.updatedAt),
        });
      });
    }
    setDataSource(userArr);
  }, [usersInfo]);
  const formatDate = (currentDate) => {
    const dates = new Date(currentDate);
    const year = dates.getFullYear();
    const month = String(dates.getMonth() + 1).padStart(2, "0");
    const date = String(dates.getDate()).padStart(2, "0");
    const hours = String(dates.getHours()).padStart(2, "0");
    const minutes = String(dates.getMinutes()).padStart(2, "0");
    const seconds = String(dates.getSeconds()).padStart(2, "0");

    const formattedDateTime = `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`;
    return formattedDateTime;
  };
  const onDeleteUer = (record) => {
    Modal.confirm({
      title: "Are you sure, you want to delete this user record?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        setDataSource((pre) => {
          // alert(record.id);return;
          dispatch(deleteUser(record.id));
          return pre.filter((category) => category.id !== record.id);
        });
      },
    });
  };
  const onEditUser = (record) => {
    setIsEditing(true);
    setEditingUser({ ...record });
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const resetEditing = () => {
    setIsEditing(false);
    setEditingUser(null);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const PasswordCell = ({ password }) => {
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };

    return (
      <Space>
        {showPassword ? (
          <EyeOutlined onClick={togglePasswordVisibility} />
        ) : (
          <EyeInvisibleOutlined onClick={togglePasswordVisibility} />
        )}
        {showPassword && password?.length !== undefined
          ? password
          : "*".repeat(password?.length)}
      </Space>
    );
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "key",
      key: "key",
      width: 40,
      // ...getColumnSearchProps('key'),
      // sorter: (a, b) => a.key.length - b.key.length,
      // sortDirections: ['descend', 'ascend']
    },
    {
      title: "User Name",
      dataIndex: "userName",
      key: "userName",
      width: 70,
      ...getColumnSearchProps("userName"),
      sorter: (a, b) => a.userName.length - b.userName.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Role",
      dataIndex: "role1",
      key: "role1",
      width: 20,
      ...getColumnSearchProps("role1"),
      sorter: (a, b) => a.role1.length - b.role1.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Email Id",
      dataIndex: "email",
      key: "email",
      width: 100,
      ...getColumnSearchProps("email"),
      sorter: (a, b) => a.email.length - b.email.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Password",

      width: "10%",
      render: (text, record) => <PasswordCell password={text} />,
      dataIndex: "password",
      key: "password",
      //...getColumnSearchProps('password'),
      //sorter: (a, b) => a.password.length - b.password.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Create Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 70,
      ...getColumnSearchProps("createdAt"),
      sorter: (a, b) => a.createdAt.length - b.createdAt.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Reset Date",
      dataIndex: "updatedAt",
      key: "updatedAt",
      width: 70,
      ...getColumnSearchProps("updatedAt"),
      sorter: (a, b) => a.updatedAt.length - b.updatedAt.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      key: "action",
      title: "Actions",
      width: 150,
      render: (record) => {
        //console.log(JSON.stringify(record))
        return (
          <>
            <Link
              className="text-white btn btn-primary text-decoration-none mx-2"
              onClick={() => {
                onEditUser(record);
              }}
            >
              {" "}
              <EditIcon fontSize="mediam" />{" "}
            </Link>
            {/* <EditOutlined
                onClick={() => {
                  onEditUser(record);
                }}
              /> */}
            <Link
              className="text-white btn btn-danger text-decoration-none mx-2"
              onClick={(e) => {
                onDeleteUer(record);
              }}
              style={{ backgroundColor: "red" }}
            >
              {" "}
              <DeleteOutlined />{" "}
            </Link>
            {/* <DeleteOutlined
                onClick={(e) => {
                  onDeleteUer(record);
                }}
                style={{ color: "red", marginLeft: 12 }}
              /> */}
          </>
        );
      },
    },
  ];
  const handleSubmit = (values) => {
    console.log(values);
  };
  return (
    <React.Fragment>
      <Table
        columns={columns}
        dataSource={dataSource}
        style={{ overflow: "-moz-hidden-unscrollable" }}
        pagination={{
          pageSize: 4,
          /*total: 50,*/ showSizeChanger: false,
          position: ["bottomCenter"],
        }}
        scroll={{ x: 1600 }}
      />

      <Modal
        okButtonProps={{
          style: { width: "220px", backgroundColor: "#293094", color: "white" },
        }}
        cancelButtonProps={{
          style: { width: "220px", backgroundColor: "#050505", color: "white" },
        }}
        title="Edit User"
        visible={isEditing}
        okText="Save"
        onCancel={() => {
          resetEditing();
        }}
        onOk={() => {
          setDataSource((prev) => {
            return prev.map((user) => {
              if (user.id === editingUser.id) {
                const names = editingUser.userName.split(" ");
                let firstname = names[0] || "";
                let lastname = names[1] || "";

                const updatedUser = {
                  firstName: firstname,
                  lastName: lastname,
                  userName: editingUser.userName,
                  role: editingUser.role,
                  email: editingUser.email,
                  password: editingUser.password,
                  regLisStatus: editingUser.regLisStatus || false,  // Ensure default value
                  compileStatus: editingUser.compileStatus || false,
                  compileStatusLCA: editingUser.compileStatusLCA || false,
                  compileStatusPA: editingUser.compileStatusPA || false,
                  inspectLisStatus: editingUser.inspectLisStatus || false,
                  dueDateStatus: editingUser.dueDateStatus || false,
                  isTradeLicenseStatus: editingUser.isTradeLicenseStatus || false,
                  isFactoryStatus: editingUser.isFactoryStatus || false,
                  isSEStatus: editingUser.isSEStatus || false,
                  isContracorsYesorNoStatus: editingUser.isContracorsYesorNoStatus || false,
                  isNightShiftPermissionStatus: editingUser.isNightShiftPermissionStatus || false,
                  isOTPermissionStatus: editingUser.isOTPermissionStatus || false,
                  isWeeklyOffExemptionStatus: editingUser.isWeeklyOffExemptionStatus || false,
                  isPFStatus: editingUser.isPFStatus || false,
                  isESIStatus: editingUser.isESIStatus || false,
                  isLFWStatus: editingUser.isLFWStatus || false,
                  isPTRStatus: editingUser.isPTRStatus || false,
                  isPTEStatus: editingUser.isPTEStatus || false,
                  isMSMEStatus: editingUser.isMSMEStatus || false,
                  isBOCWStatus: editingUser.isBOCWStatus || false,
                  isISMWStatus: editingUser.isISMWStatus || false,
                  isFASSAIStatus: editingUser.isFASSAIStatus || false,
                  isSBStatus: editingUser.isSBStatus || false,
                };

                console.log("Dispatching update with:", updatedUser);

                dispatch(userEdit(updatedUser, editingUser.id));
                return updatedUser;
              }
              return user;
            });
          });

          resetEditing();
          dispatch(usersGet());

        }}

      >
        <label className="form-label">User Name</label>
        <br />
        <input
          style={{ width: "350px", marginBottom: "10px" }}
          value={editingUser?.userName}
          onChange={(e) =>
            setEditingUser((pre) => ({ ...pre, userName: e.target.value }))
          }
          type="text"
          className="form-control"
          required
        />
        <br />
        <label className="form-label">Role</label>
        <br />
        <select
          className="form-control"
          style={{ width: "350px" }}
          disabled
          value={editingUser?.role}
          onChange={(e) =>
            setEditingUser((pre) => ({ ...pre, role: e.target.value }))
          }
          required
        >
          <option value="Executive(Matrix)">Executive(Matrix)</option>
          <option value="Companey CEO">Companey CEO</option>
          <option value="Company">Company</option>
          <option value="Executive">Executive</option>
          <option value="Auditor">Auditor</option>
        </select>
        <br />
        {editingUser?.role === "Company" && (
          <>
            <label className="form-label">Company</label>
            <input
              name="company"
              value={editingUser?.companyName}
              disabled
              className="form-control"
              type="text"
            />
            <br />
          </>
        )}
        <br />
        <label className="form-label">Email</label>
        <br />
        <input
          style={{ width: "350px", marginBottom: "10px" }}
          value={editingUser?.email}
          onChange={(e) =>
            setEditingUser((pre) => ({ ...pre, email: e.target.value }))
          }
          type="text"
          className="form-control"
          required
        />
        <br />
        <label className="form-label">Password</label>
        <br />
        <input
          style={{ width: "350px", marginBottom: "10px" }}
          value={editingUser?.password}
          onChange={(e) =>
            setEditingUser((pre) => ({ ...pre, password: e.target.value }))
          }
          type="text"
          className="form-control"
          required
        />
        <br />
        <div className="row">
          <div className="col-sm-6 col-md-6">{/* Conditionally Render Boolean Fields */}
            {editingUser?.role === "Company" && editingUser?.regLisStatus !== undefined && (
              <>
                <label className="form-label">Reg Lis Status</label>
                <br />
                <Switch
                  checked={editingUser?.regLisStatus ?? false}
                  onChange={(checked) =>
                    setEditingUser((pre) => ({ ...pre, regLisStatus: checked }))
                  }
                />
                <br />
                <br />
              </>
            )}

            {editingUser?.role === "Company" && editingUser?.compileStatus !== undefined && (
              <>
                <label className="form-label">Compile Status</label>
                <br />
                <Switch
                  checked={editingUser?.compileStatus}
                  onChange={(checked) =>
                    setEditingUser((pre) => ({ ...pre, compileStatus: checked }))
                  }
                />
                <br />
                <br />
              </>
            )}
            {editingUser?.role === "Company" && editingUser?.compileStatusLCA !== undefined && (
              <>
                <label className="form-label">Compile Status LCA</label>
                <br />
                <Switch
                  checked={editingUser?.compileStatusLCA}
                  onChange={(checked) =>
                    setEditingUser((pre) => ({ ...pre, compileStatusLCA: checked }))
                  }
                />
                <br />
                <br />
              </>
            )}
            {editingUser?.role === "Company" && editingUser?.compileStatusPA !== undefined && (
              <>
                <label className="form-label">Compile Status PA</label>
                <br />
                <Switch
                  checked={editingUser?.compileStatusPA}
                  onChange={(checked) =>
                    setEditingUser((pre) => ({ ...pre, compileStatusPA: checked }))
                  }
                />
                <br />
                <br />
              </>
            )}

            {editingUser?.role === "Company" && editingUser?.inspectLisStatus !== undefined && (
              <>
                <label className="form-label">Inspect Lis Status</label>
                <br />
                <Switch
                  checked={editingUser?.inspectLisStatus}
                  onChange={(checked) =>
                    setEditingUser((pre) => ({ ...pre, inspectLisStatus: checked }))
                  }
                />
                <br />
                <br />
              </>
            )}

            {editingUser?.role === "Company" && editingUser?.dueDateStatus !== undefined && (
              <>
                <label className="form-label">Due Date Status</label>
                <br />
                <Switch
                  checked={editingUser?.dueDateStatus}
                  onChange={(checked) =>
                    setEditingUser((pre) => ({ ...pre, dueDateStatus: checked }))
                  }
                />
                <br />
                <br />
              </>
            )}</div>
          <div className="col-sm-6 col-md-6">
            <>
              {editingUser?.role === "Company" && editingUser?.regLisStatus === true && (
                <div>
                  <h5>License Status</h5>
                  {[
                    { id: "isTradeLicenseStatus", label: "Trade License Status" },
                    { id: "isFactoryStatus", label: "Factory Status" },
                    { id: "isSEStatus", label: "SE Status" },
                    { id: "isContracorsYesorNoStatus", label: "Contractors Yes/No Status" },
                    { id: "isNightShiftPermissionStatus", label: "Night Shift Permission Status" },
                    { id: "isOTPermissionStatus", label: "OT Permission Status" },
                    { id: "isWeeklyOffExemptionStatus", label: "Weekly Off Exemption Status" },
                    { id: "isPFStatus", label: "PF Status" },
                    { id: "isESIStatus", label: "ESI Status" },
                    { id: "isLFWStatus", label: "LFW Status" },
                    { id: "isPTRStatus", label: "PTR Status" },
                    { id: "isPTEStatus", label: "PTE Status" },
                    { id: "isMSMEStatus", label: "MSME Status" },
                    { id: "isBOCWStatus", label: "BOCW Status" },
                    { id: "isISMWStatus", label: "ISMW Status" },
                    { id: "isFASSAIStatus", label: "FASSAI Status" },
                    { id: "isSBStatus", label: "SB Status" },
                  ].map(({ id, label }) =>
                    editingUser?.[id] !== undefined ? (
                      <div key={id}>
                        <label className="form-label">{label}</label>
                        <br />
                        <Switch
                          checked={editingUser?.[id] ?? false}
                          onChange={(checked) =>
                            setEditingUser((prev) => ({ ...prev, [id]: checked }))
                          }
                        />
                        <br />
                      </div>
                    ) : null
                  )}
                </div>

              )}
            </>
          </div>

        </div>
      </Modal>

    </React.Fragment>
  );
};
export default UsersTable;
