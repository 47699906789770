import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select, Form, Spin } from "antd";
import {
  createBranches,
  stateGets,
  companyTableGet,
  AllbranchesGet,
  TableBranchesGet
} from "../../store/actions/otherActions"; // Redux actions
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom"; // Import useNavigate for redirection
import './BranchCss.css';

const BranchForm = (addOrEdit, recordForEdit) => {
  const navigate = useNavigate(); // Initialize useNavigate
  const getCompanyTable = useSelector((state) => state.getCompanyTable);
  const { loadingcompanytable, companyGetTableInfo } = getCompanyTable;
  const getState = useSelector((state) => state.getState);
  const { loadings, stateInfo } = getState;
  const dispatch = useDispatch();
  // const [selectedOption, setSelectedOption] = useState(""); // "Factory" or "S&E"

  useEffect(() => {
    dispatch(companyTableGet());
    dispatch(stateGets());
  }, [dispatch]);

  const [formData, setFormData] = useState({
    company: "",
    name: "",
    details: "",
    region: "",
    branchimage: "",
    remarks: "",
    branchaddress: "",
    branchstate: "",
    branchdistrict: "",
    branchpin: "",
    contractorAddBranchFimage: "",
    contractorAddBranchFRemark: "",
    branchOpeningDateF: "",
    dateofclosureFile: "",
    dateofclosureRemark: "",
    noOfEmpBranchF: "",
    managerNameF1: "",
    managerNameF1image: "",
    managerNameF1Remark: "",
    managerMobNoF1: "",
    managerMobNoF1Remark: "",
    managerEmailF1: "",
    managerEmailF1Remark: "",
    managerAadharNoF1: "",
    managerAadharNoF1image: "",
    managerAadharNoF1Remark: "",
    managerPanF1: "",
    managerPanF1image: "",
    managerPanF1Remark: "",
    shopsEstLicenseF2: "",
    shopsEstLicenseF2image: "",
    shopsEstLicenseF2Remark: "",
    contractLabRegNoF5: "",
    contractLabRegNoF5image: "",
    contractLabRegNoF5Remark: "",
    regDateContractorF5: "",
    coOfContractEmpF5: "",
    noOfContractorsF5: "",
    contractorNameF51: "",
    contractorNameF51image: "",
    contractorNameF51Remark: "",
    establishmentNameF51: "",
    establishmentNameF51image: "",
    establishmentNameF51Remark: "",
    regisocontractaddress: "",
    regStateContractorF51: "",
    regDistContractorF51: "",
    regPinContractorF51: "",
    regAddContractorF51image: "",
    regAddContractorF51Remark: "",
    expiryDateF52: "",
    renewalDateF52: "",
    natureOfWorkF52: "",
    natureOfWorkF52image: "",
    natureOfWorkF52Remark: "",
    noOfEmpDeployedF52: "",
    companyTypeF53: "",
    companyTypeF53image: "",
    companyTypeF53Remark: "",
    contractLabLicNoF53: "",
    contractLabLicNoF53image: "",
    contractLabLicNoF53Remark: "",
    licenseDateF53: "",
    expiryDateF53: "",
    renewalDateF53: "",
    noOfWorkerF53: "",
    panF53: "",
    panF53image: "",
    panF53Remark: "",
    gstF53: "",
    gstF53image: "",
    gstF53Remark: "",
    esicRegF53: "",
    esicRegF53image: "",
    esicRegF53Remark: "",
    pfRegContractorsE3: "",
    pfRegContractorsE3image: "",
    pfRegContractorsE3Remark: "",
    shopsandEstContractorsE3: "",
    shopsandEstContractorsE3image: "",
    shopsandEstContractorsE3Remark: "",
    lwfRegContractorsE3: "",
    lwfRegContractorsE3image: "",
    lwfRegContractorsE3Remark: "",
    profTaxContractorsE3: "",
    profTaxContractorsE3image: "",
    profTaxContractorsE3Remark: "",
    licensenumber: "",
    licenseimage: "",
    licenseremark: "",
    lis_status_se: 0,
    dor: "",
    doe: "",
    doddr: "",
    managernamelicense: "",
    managerlicenseimage: "",
    managerlicenseremark: "",
    noe: "",
    nom: "",
    nof: "",
    issuingauth: "",
    issuingauthimage: "",
    issuingauthremark: "",
    licensenumber1: "",
    licenseimage1: "",
    licenseremark1: "",
    lis_status_factory: 0,
    dor1: "",
    doe1: "",
    doddr1: "",
    managernamelicense1: "",
    managerlicenseimage1: "",
    managerlicenseremark1: "",
    noe1: "",
    nom1: "",
    nof1: "",
    issuingauth1: "",
    issuingauthimage1: "",
    issuingauthremark1: "",
    licensenumber2: "",
    licenseimage2: "",
    licenseremark2: "",
    dor2: "",
    issuingauth2: "",
    issuingauthimage2: "",
    issuingauthremark2: "",
    dateofclosure: "",
    // Additional fields
    powerHP: "",
    powerHPremark2: "",
    powerKW: "",
    powerKWremark2: "",
    ApprovedPower: "",
    ApprovedPowerremark2: "",
    // NSP
    licensenumberNSP: "",
    licenseimageNSP: "",
    licenseremarkNSP: "",
    lis_statusNSP: 0,
    lis_statusTL: 0,
    lis_statusOTP: 0,
    lis_statusWOE: 0,
    dorNSP: "",
    doeNSP: "",
    doddrNSP: "",
    issuingauthNSP: "",
    issuingauthdetailsNSP: "",
    issuingauthimageNSP: "",
    issuingauthremarkNSP: "",
    //OTP
    licensenumberOTP: "",
    licenseimageOTP: "",
    licenseremarkOTP: "",
    dorOTP: "",
    doeOTP: "",
    doddrOTP: "",
    issuingauthOTP: "",
    issuingauthdetailsOTP: "",
    issuingauthimageOTP: "",
    issuingauthremarkOTP: "",
    //TL
    licensenumberTL: "",
    licenseimageTL: "",
    licenseremarkTL: "",
    dorTL: "",
    doeTL: "",
    doddrTL: "",
    issuingauthTL: "",
    issuingauthdetailsTL: "",
    issuingauthimageTL: "",
    issuingauthremarkTL: "",
    //WOE
    licensenumberWOE: "",
    licenseimageWOE: "",
    licenseremarkWOE: "",
    dorWOE: "",
    doeWOE: "",
    doddrWOE: "",
    issuingauthWOE: "",
    issuingauthdetailsWOE: "",
    issuingauthimageWOE: "",
    issuingauthremarkWOE: "",
    // PF
    lis_status_pf: 0,
    pfnumber: "",
    pfaddress: "",
    pfimage: "",
    pfdremark: "",
    doc: "",
    pfstate: "",
    pfdistrict: "",
    pfpin: "",
    pfaddressimage: "",
    pfaddressremark: "",
    //ESI
    lis_status_esi: 0,
    esinumber: "",
    esiimage: "",
    esidremark: "",
    doc: "",
    esistate: "",
    esidistrict: "",
    esipin: "",
    esiaddressimage: "",
    esiaddressremark: "",
    //LWF
    registrationlwfD3: "",
    registrationlwfD3image: "",
    registrationlwfD3remark: "",
    doregistrationlwfD3: "",
    lis_status_lwf: 0,
    //PTR
    lis_status_ptr: 0,
    registrationptrD3: "",
    registrationptrD3image: "",
    registrationptrD3remark: "",
    doregistrationptrD3: "",
    //PTE
    lis_status_pte: 0,
    registrationPTED3: "",
    registrationPTED3image: "",
    registrationPTED3remark: "",
    doregistrationPTED3: "",
    //MSME
    lis_status_msme: 0,
    licensenumberMSME: "",
    licenseimageMSME: "",
    licenseremarkMSME: "",
    dorMSME: "",
    issuingauthMSME: "",
    issuingauthimageMSME: "",
    issuingauthremarkMSME: "",
    //BOCW
    lis_status_bocw: 0,
    licensenumberBOCW: "",
    licenseimageBOCW: "",
    licenseremarkBOCW: "",
    dorBOCW: "",
    doeBOCW: "",
    ackFileBOCW: "",
    reasonBOCW: "",
    doddrBOCW: "",
    noeBOCW: "",
    nomBOCW: "",
    nofBOCW: "",
    issuingauthBOCW: "",
    issuingauthimageBOCW: "",
    issuingauthremarkBOCW: "",
    //ISMW
    lis_status_ismw: 0,
    licensenumberISMW: "",
    licenseimageISMW: "",
    licenseremarkISMW: "",
    dorISMW: "",
    ackFileISMW: "",
    reasonISMW: "",
    doeISMW: "",
    doddrISMW: "",
    noeISMW: "",
    nomISMW: "",
    nofISMW: "",
    issuingauthISMW: "",
    issuingauthdetailsISMW: "",
    issuingauthimageISMW: "",
    issuingauthremarkISMW: "",
    //FASSAI
    lis_status_fassai: 0,
    licensenumberFASSAI: "",
    licenseimageFASSAI: "",
    licenseremarkFASSAI: "",
    dorFASSAI: "",
    doeFASSAI: "",
    doddrFASSAI: "",
    issuingauthFASSAI: "",
    issuingauthdetailsFASSAI: "",
    ackFileFASSAI: "",
    reasonFASSAI: "",
    issuingauthremarkFASSAI: "",
    //SB
    lis_status_sb: 0,
    licensenumberSB: "",
    licenseimageSB: "",
    licenseremarkSB: "",
    dorSB: "",
    issuingauthSB: "",
    issuingauthdetailsSB: "",
    ackFileSB: "",
    reasonSB: "",
    issuingauthremarkSB: "",
    //isConditions
    isFactoryOrSE: "",
    isContracorsYesorNo: "",
    isNightShiftPermission: "",
    isOTPermission: "NO",
    isWeeklyOffExemption: "NO",
    isTradeLicense: "NO",
    isPF: "NO",
    isESI: "NO",
    isLWF: "NO",
    isLWF: "NO",
    isPTR: "NO",
    isPTE: "NO",
    isMSME: "NO",
    isBOCW: "NO",
    isISMW: "NO",
    isFASSAI: "NO",
    isSB: "NO",
    //is-Contract
    is_contract: false,
    contractLabourRegistration: '',
    contractLabourRegistrationFile: '',
    contractLabourRegistrationRemarks: '',
    contractLabourRegistrationNumber: '',
    dateOfContractReg: '',
    nOofContractors: '',
    nOofContractEmp: '',
    dateofContractSur: '',

  });
  const [imagePreview, setImagePreview] = useState("");
  const [selectedOption, setSelectedOption] = useState(""); // To track the selected option ("Factory" or "S&E")
  const [isContractLabourEngaged, setIsContractLabourEngaged] = useState("NO"); // Initially set to "NO"
  const [isNSPEngaged, setIsNSPEngaged] = useState("NO"); // Initially set to "NO"
  const [isWOEEngaged, setIsWOEEngaged] = useState("NO"); // Initially set to "NO"
  const [isTLEngaged, setIsTLEngaged] = useState("NO"); // Initially set to "NO"
  const [isPFEngaged, setIsPFEngaged] = useState("NO"); // Initially set to "NO"
  const [isOTPEngaged, setIsOTPEngaged] = useState("NO"); // Initially set to "NO"
  const [isESIEngaged, setIsESIEngaged] = useState("NO"); // Initially set to "NO"
  const [isLWFEngaged, setIsLWFEngaged] = useState("NO"); // Initially set to "NO"
  const [isPTREngaged, setIsPTREngaged] = useState("NO"); // Initially set to "NO"
  const [isPTEEngaged, setIsPTEEngaged] = useState("NO"); // Initially set to "NO"
  const [isMSMEEngaged, setIsMSMEEngaged] = useState("NO"); // Initially set to "NO"
  const [isBOCWEngaged, setisBOCWEngaged] = useState("NO"); // Initially set to "NO"
  const [isISMWEngaged, setIsISMWEngaged] = useState("NO"); // Initially set to "NO"
  const [isFASSAIEngaged, setIsFASSAIEngaged] = useState("NO"); // Initially set to "NO"
  const [isSBEngaged, setIsSBEngaged] = useState("NO"); // Initially set to "NO"
  const [is_contractEngaged, setIs_contractEngaged] = useState(false); // Initially set to "NO"

  const [loading, setLoading] = useState(false);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle file uploads
  const handleFileChange = (e, fieldName) => {
    const file = e.target.files[0];
    if (file) {
      // Set binary file in formData
      setFormData((prev) => ({ ...prev, [fieldName]: file }));

      // Generate preview for images
      if (file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onload = () => {
          setImagePreview((prev) => ({ ...prev, [fieldName]: reader.result }));
        };
        reader.readAsDataURL(file);
      } else {
        setImagePreview((prev) => ({ ...prev, [fieldName]: "" })); // No preview for non-image files
      }
    }
  };
  console.log("Selected Company:", formData.company);

  // Handle company select
  const handleCompanySelect = (value) => {
    const companyId = value.target.value;
    setFormData((prev) => ({ ...prev, company: companyId }));
    console.log("Selected company ID:", companyId);
  };

  useEffect(() => {
    if (formData.isFactoryOrSE) {
      setSelectedOption(formData.isFactoryOrSE);
    }
  }, [formData.isFactoryOrSE]);
  useEffect(() => {
    if (formData.isContracorsYesorNo) {
      setIsContractLabourEngaged(formData.isContracorsYesorNo);
    }
  }, [formData.isContracorsYesorNo]);
  // useEffect(() => {
  //   if (formData.isNightShiftPermission) {
  //     setIsNSPEngaged(formData.isNightShiftPermission);
  //   }
  // }, [formData.isNightShiftPermission]);
  // useEffect(() => {
  //   if (formData.isOTPermission) {
  //     setIsOTPEngaged(formData.isOTPermission);
  //   }
  // }, [formData.isOTPermission]);
  // useEffect(() => {
  //   if (formData.isWeeklyOffExemption) {
  //     setIsWOEEngaged(formData.isWeeklyOffExemption);
  //   }
  // }, [formData.isWeeklyOffExemption]);
  // useEffect(() => {
  //   if (formData.isTradeLicense) {
  //     setIsTLEngaged(formData.isTradeLicense);
  //   }
  // }, [formData.isTradeLicense]);

  // Handle option selection for Factory or S&E
  const handleOptionChange = (option) => {
    setSelectedOption(option);
    setFormData((prev) => ({
      ...prev,
      isFactoryOrSE: option,
      // Reset all the fields related to Factory or S&E
      branchaddress: "",
      region: "",
      branchstate: "",
      branchdistrict: "",
      branchpin: "",
      contractorAddBranchFimage: "", // Reset file input
      contractorAddBranchFRemark: "",
      branchOpeningDateF: "",
      noOfEmpBranchF: "",
      // Reset fields related to Manager information
      managerNameF1: "",
      managerNameF1image: "", // Reset file input
      managerNameF1Remark: "",
      managerMobNoF1: "",
      managerMobNoF1Remark: "",
      managerEmailF1: "",
      managerEmailF1Remark: "",
      managerAadharNoF1: "",
      managerAadharNoF1image: "", // Reset file input
      managerPanF1: "",
      managerPanF1image: "", // Reset file input
    }));
  };

  // const handleOptionChange = (option) => {
  //   setSelectedOption(option);
  //   setFormData((prev) => ({
  //     ...prev,
  //     isFactoryOrSE: option,
  //   }));
  // };

  const handleSelectionChange = (value) => {
    setIsContractLabourEngaged(value);

    if (value === "YES") {
      // Set isContracorsYesorNo to "YES" without resetting other fields
      setFormData((prev) => ({
        ...prev,
        isContracorsYesorNo: "YES",
        isNightShiftPermission: "NO",
        isOTPermission: "NO",
        isWeeklyOffExemption: "NO",
        isTradeLicense: "NO",
      }));
    } else if (value === "NO") {
      // Reset specific fields when value is "NO"
      setFormData((prev) => ({
        ...prev,
        isContracorsYesorNo: "NO",
        contractLabRegNoF5: "",
        contractLabRegNoF5image: "",
        contractLabRegNoF5Remark: "",
        regDateContractorF5: "",
        coOfContractEmpF5: "",
        noOfContractorsF5: "",
        contractorNameF51: "",
        contractorNameF51image: "",
        contractorNameF51Remark: "",
        establishmentNameF51: "",
        establishmentNameF51image: "",
        establishmentNameF51Remark: "",
        regisocontractaddress: "",
        regStateContractorF51: "",
        regDistContractorF51: "",
        regPinContractorF51: "",
        regAddContractorF51image: "",
        regAddContractorF51Remark: "",
        expiryDateF52: "",
        renewalDateF52: "",
        natureOfWorkF52: "",
        natureOfWorkF52image: "",
        natureOfWorkF52Remark: "",
        noOfEmpDeployedF52: "",
        companyTypeF53: "",
        companyTypeF53image: "",
        companyTypeF53Remark: "",
        contractLabLicNoF53: "",
        contractLabLicNoF53image: "",
        contractLabLicNoF53Remark: "",
        licenseDateF53: "",
        expiryDateF53: "",
        renewalDateF53: "",
        noOfWorkerF53: "",
        panF53: "",
        panF53image: "",
        panF53Remark: "",
        gstF53: "",
        gstF53image: "",
        gstF53Remark: "",
        pfRegContractorsE3: "",
        pfRegContractorsE3image: "",
        pfRegContractorsE3Remark: "",
        esicRegF53: "",
        esicRegF53image: "",
        esicRegF53Remark: "",
        shopsandEstContractorsE3: "",
        shopsandEstContractorsE3image: "",
        shopsandEstContractorsE3Remark: "",
        lwfRegContractorsE3: "",
        lwfRegContractorsE3image: "",
        lwfRegContractorsE3Remark: "",
        profTaxContractorsE3: "",
        profTaxContractorsE3image: "",
        profTaxContractorsE3Remark: "",
      }));
    }
  };
  const handleSelectionChangeNSP = (value) => {
    setIsNSPEngaged(value);

    if (value === "YES") {
      // Set isNightShiftPermission to "YES" without resetting other fields
      setFormData((prev) => ({
        ...prev,
        isNightShiftPermission: "YES",
      }));
    } else if (value === "NO") {
      // Reset specific fields when value is "NO"
      setFormData((prev) => ({
        ...prev,
        isNightShiftPermission: "NO",
        licensenumberNSP: "",
        licenseimageNSP: "",
        licenseremarkNSP: "",
        lis_statusNSP: 0,
        dorNSP: "",
        doeNSP: "",
        doddrNSP: "",
        issuingauthNSP: "",
        issuingauthdetailsNSP: "",
        issuingauthimageNSP: "",
        issuingauthremarkNSP: "",
      }));
    }
  };
  const handleSelectionChangeOTP = (value) => {
    setIsOTPEngaged(value);

    if (value === "YES") {
      // Set isNightShiftPermission to "YES" without resetting other fields
      setFormData((prev) => ({
        ...prev,
        isOTPermission: "YES",
      }));
    } else if (value === "NO") {
      // Reset specific fields when value is "NO"
      setFormData((prev) => ({
        ...prev,
        isOTPermission: "NO",
        licensenumberOTP: "",
        licenseimageOTP: "",
        licenseremarkOTP: "",
        lis_statusOTP: 0,
        dorOTP: "",
        doeOTP: "",
        doddrOTP: "",
        issuingauthOTP: "",
        issuingauthdetailsOTP: "",
        issuingauthimageOTP: "",
        issuingauthremarkOTP: "",
      }));
    }
  };
  const handleSelectionChangeWOE = (value) => {
    setIsWOEEngaged(value);

    if (value === "YES") {
      // Set isNightShiftPermission to "YES" without resetting other fields
      setFormData((prev) => ({
        ...prev,
        isWeeklyOffExemption: "YES",
      }));
    } else if (value === "NO") {
      // Reset specific fields when value is "NO"
      setFormData((prev) => ({
        ...prev,
        isWeeklyOffExemption: "NO",
        licensenumberWOE: "",
        licenseimageWOE: "",
        licenseremarkWOE: "",
        lis_statusWOE: 0,
        dorWOE: "",
        doeWOE: "",
        doddrWOE: "",
        issuingauthWOE: "",
        issuingauthdetailsWOE: "",
        issuingauthimageWOE: "",
        issuingauthremarkWOE: "",
      }));
    }
  };
  const handleSelectionChangeTL = (value) => {
    setIsTLEngaged(value);

    if (value === "YES") {
      // Set isNightShiftPermission to "YES" without resetting other fields
      setFormData((prev) => ({
        ...prev,
        isTradeLicense: "YES",
      }));
    } else if (value === "NO") {
      // Reset specific fields when value is "NO"
      setFormData((prev) => ({
        ...prev,
        isTradeLicense: "NO",
        licensenumberTL: "",
        licenseimageTL: "",
        licenseremarkTL: "",
        lis_statusTL: 0,
        dorTL: "",
        doeTL: "",
        doddrTL: "",
        issuingauthTL: "",
        issuingauthdetailsTL: "",
        issuingauthimageTL: "",
        issuingauthremarkTL: "",
      }));
    }
  };
  const handleSelectionChangePF = (value) => {
    setIsPFEngaged(value);

    if (value === "YES") {
      // Set isNightShiftPermission to "YES" without resetting other fields
      setFormData((prev) => ({
        ...prev,
        isPF: "YES",
      }));
    } else if (value === "NO") {
      // Reset specific fields when value is "NO"
      setFormData((prev) => ({
        ...prev,
        isPF: "NO",
        lis_status_pf: 0,
        pfnumber: "",
        pfaddress: "",
        pfimage: "",
        pfdremark: "",
        doc: "",
        pfstate: "",
        pfdistrict: "",
        pfpin: "",
        pfaddressimage: "",
        pfaddressremark: "",
      }));
    }
  };
  const handleSelectionChangeESI = (value) => {
    setIsESIEngaged(value);

    if (value === "YES") {
      // Set isNightShiftPermission to "YES" without resetting other fields
      setFormData((prev) => ({
        ...prev,
        isESI: "YES",
      }));
    } else if (value === "NO") {
      // Reset specific fields when value is "NO"
      setFormData((prev) => ({
        ...prev,
        isESI: "NO",
        lis_status_esi: 0,
        esinumber: "",
        esiimage: "",
        esidremark: "",
        doc: "",
        esistate: "",
        esidistrict: "",
        esipin: "",
        esiaddressimage: "",
        esiaddressremark: "",
      }));
    }
  };
  const handleSelectionChangeLWF = (value) => {
    setIsLWFEngaged(value);

    if (value === "YES") {
      // Set isNightShiftPermission to "YES" without resetting other fields
      setFormData((prev) => ({
        ...prev,
        isLWF: "YES",
      }));
    } else if (value === "NO") {
      // Reset specific fields when value is "NO"
      setFormData((prev) => ({
        ...prev,
        isLWF: "NO",
        lis_status_lwf: 0,
        registrationlwfD3: "",
        registrationlwfD3image: "",
        registrationlwfD3remark: "",
        doregistrationlwfD3: "",
      }));
    }
  };
  const handleSelectionChangePTR = (value) => {
    setIsPTREngaged(value);

    if (value === "YES") {
      // Set isNightShiftPermission to "YES" without resetting other fields
      setFormData((prev) => ({
        ...prev,
        isPTR: "YES",
      }));
    } else if (value === "NO") {
      // Reset specific fields when value is "NO"
      setFormData((prev) => ({
        ...prev,
        isPTR: "NO",
        lis_status_ptr: 0,
        registrationptrD3: "",
        registrationptrD3image: "",
        registrationptrD3remark: "",
        doregistrationptrD3: "",
      }));
    }
  };
  const handleSelectionChangePTE = (value) => {
    setIsPTEEngaged(value);

    if (value === "YES") {
      // Set isNightShiftPermission to "YES" without resetting other fields
      setFormData((prev) => ({
        ...prev,
        isPTE: "YES",
      }));
    } else if (value === "NO") {
      // Reset specific fields when value is "NO"
      setFormData((prev) => ({
        ...prev,
        isPTE: "NO",
        lis_status_pte: 0,
        registrationPTED3: "",
        registrationPTED3image: "",
        registrationPTED3remark: "",
        doregistrationPTED3: "",
      }));
    }
  };
  const handleSelectionChangeMSME = (value) => {
    setIsMSMEEngaged(value);

    if (value === "YES") {
      // Set isNightShiftPermission to "YES" without resetting other fields
      setFormData((prev) => ({
        ...prev,
        isMSME: "YES",
      }));
    } else if (value === "NO") {
      // Reset specific fields when value is "NO"
      setFormData((prev) => ({
        ...prev,
        isMSME: "NO",
        lis_status_msme: 0,
        licensenumberMSME: "",
        licenseimageMSME: "",
        licenseremarkMSME: "",
        dorMSME: "",
        issuingauthMSME: "",
        issuingauthimageMSME: "",
        issuingauthremarkMSME: "",
      }));
    }
  };
  const handleSelectionChangeBOCW = (value) => {
    setisBOCWEngaged(value);

    if (value === "YES") {
      // Set isNightShiftPermission to "YES" without resetting other fields
      setFormData((prev) => ({
        ...prev,
        isBOCW: "YES",
      }));
    } else if (value === "NO") {
      // Reset specific fields when value is "NO"
      setFormData((prev) => ({
        ...prev,
        isBOCW: "NO",
        lis_status_bocw: 0,
        licensenumberBOCW: "",
        licenseimageBOCW: "",
        licenseremarkBOCW: "",
        dorBOCW: "",
        doeBOCW: "",
        ackFileBOCW: "",
        reasonBOCW: "",
        doddrBOCW: "",
        noeBOCW: "",
        nomBOCW: "",
        nofBOCW: "",
        issuingauthBOCW: "",
        issuingauthimageBOCW: "",
        issuingauthremarkBOCW: "",
      }));
    }
  };
  const handleSelectionChangeISMW = (value) => {
    setIsISMWEngaged(value);

    if (value === "YES") {
      // Set isNightShiftPermission to "YES" without resetting other fields
      setFormData((prev) => ({
        ...prev,
        isISMW: "YES",
      }));
    } else if (value === "NO") {
      // Reset specific fields when value is "NO"
      setFormData((prev) => ({
        ...prev,
        isISMW: "NO",
        lis_status_ismw: 0,
        licensenumberISMW: "",
        licenseimageISMW: "",
        licenseremarkISMW: "",
        dorISMW: "",
        ackFileISMW: "",
        reasonISMW: "",
        doeISMW: "",
        doddrISMW: "",
        noeISMW: "",
        nomISMW: "",
        nofISMW: "",
        issuingauthISMW: "",
        issuingauthdetailsISMW: "",
        issuingauthimageISMW: "",
        issuingauthremarkISMW: "",
      }));
    }
  };
  const handleSelectionChangeFASSAI = (value) => {
    setIsFASSAIEngaged(value);

    if (value === "YES") {
      // Set isNightShiftPermission to "YES" without resetting other fields
      setFormData((prev) => ({
        ...prev,
        isFASSAI: "YES",
      }));
    } else if (value === "NO") {
      // Reset specific fields when value is "NO"
      setFormData((prev) => ({
        ...prev,
        isFASSAI: "NO",
        lis_status_fassai: 0,
        licensenumberFASSAI: "",
        licenseimageFASSAI: "",
        licenseremarkFASSAI: "",
        dorFASSAI: "",
        doeFASSAI: "",
        doddrFASSAI: "",
        issuingauthFASSAI: "",
        issuingauthdetailsFASSAI: "",
        ackFileFASSAI: "",
        reasonFASSAI: "",
        issuingauthremarkFASSAI: "",
      }));
    }
  };
  const handleSelectionChangeSB = (value) => {
    setIsSBEngaged(value);

    if (value === "YES") {
      // Set isNightShiftPermission to "YES" without resetting other fields
      setFormData((prev) => ({
        ...prev,
        isSB: "YES",
      }));
    } else if (value === "NO") {
      // Reset specific fields when value is "NO"
      setFormData((prev) => ({
        ...prev,
        isSB: "NO",
        lis_status_sb: 0,
        licensenumberSB: "",
        licenseimageSB: "",
        licenseremarkSB: "",
        dorSB: "",
        doeSB: "",
        doddrSB: "",
        issuingauthSB: "",
        issuingauthdetailsSB: "",
        ackFileSB: "",
        reasonSB: "",
        issuingauthremarkSB: "",
      }));
    }
  };
  const handleSelectionChangeCont = (value) => {
    setIs_contractEngaged(value);

    if (value === true) {
      // Set isNightShiftPermission to 1 without resetting other fields
      setFormData((prev) => ({
        ...prev,
        is_contract: true,
      }));
    } else if (value === false) {
      // Reset specific fields when value is 0
      setFormData((prev) => ({
        ...prev,
        is_contract: false,
        contractLabourRegistration: '',
        contractLabourRegistrationFile: '',
        contractLabourRegistrationRemarks: '',
        contractLabourRegistrationNumber: '',
        dateOfContractReg: '',
        nOofContractors: '',
        nOofContractEmp: '',
        dateofContractSur: '',

      }));
    }
  };

  // Reset the form
  const resetForm = () => {
    setFormData({
      company: "",
      name: "",
      details: "",
      region: "",
      branchimage: "",
      remarks: "",
      branchaddress: "",
      branchstate: "",
      branchdistrict: "",
      branchpin: "",
      contractorAddBranchFimage: "",
      contractorAddBranchFRemark: "",
      branchOpeningDateF: "",
      dateofclosure: "",
      dateofclosureFile: "",
      dateofclosureRemark: "",
      noOfEmpBranchF: "",
      managerNameF1: "",
      managerNameF1image: "",
      managerNameF1Remark: "",
      managerMobNoF1: "",
      managerMobNoF1Remark: "",
      managerEmailF1: "",
      managerEmailF1Remark: "",
      managerAadharNoF1: "",
      managerAadharNoF1image: "",
      managerAadharNoF1Remark: "",
      managerPanF1: "",
      managerPanF1image: "",
      managerPanF1Remark: "",
      shopsEstLicenseF2: "",
      shopsEstLicenseF2image: "",
      shopsEstLicenseF2Remark: "",
      contractLabRegNoF5: "",
      contractLabRegNoF5image: "",
      contractLabRegNoF5Remark: "",
      regDateContractorF5: "",
      coOfContractEmpF5: "",
      noOfContractorsF5: "",
      contractorNameF51: "",
      contractorNameF51image: "",
      contractorNameF51Remark: "",
      establishmentNameF51: "",
      establishmentNameF51image: "",
      establishmentNameF51Remark: "",
      regisocontractaddress: "",
      regStateContractorF51: "",
      regDistContractorF51: "",
      regPinContractorF51: "",
      regAddContractorF51image: "",
      regAddContractorF51Remark: "",
      expiryDateF52: "",
      renewalDateF52: "",
      natureOfWorkF52: "",
      natureOfWorkF52image: "",
      natureOfWorkF52Remark: "",
      noOfEmpDeployedF52: "",
      companyTypeF53: "",
      companyTypeF53image: "",
      companyTypeF53Remark: "",
      contractLabLicNoF53: "",
      contractLabLicNoF53image: "",
      contractLabLicNoF53Remark: "",
      licenseDateF53: "",
      expiryDateF53: "",
      renewalDateF53: "",
      noOfWorkerF53: "",
      panF53: "",
      panF53image: "",
      panF53Remark: "",
      gstF53: "",
      gstF53image: "",
      gstF53Remark: "",
      esicRegF53: "",
      esicRegF53image: "",
      esicRegF53Remark: "",
      pfRegContractorsE3: "",
      pfRegContractorsE3image: "",
      pfRegContractorsE3Remark: "",
      shopsandEstContractorsE3: "",
      shopsandEstContractorsE3image: "",
      shopsandEstContractorsE3Remark: "",
      lwfRegContractorsE3: "",
      lwfRegContractorsE3image: "",
      lwfRegContractorsE3Remark: "",
      profTaxContractorsE3: "",
      profTaxContractorsE3image: "",
      profTaxContractorsE3Remark: "",
      licensenumber: "",
      licenseimage: "",
      licenseremark: "",
      lis_status_se: 0,
      dor: "",
      doe: "",
      doddr: "",
      managernamelicense: "",
      managerlicenseimage: "",
      managerlicenseremark: "",
      noe: "",
      nom: "",
      nof: "",
      issuingauth: "",
      issuingauthimage: "",
      issuingauthremark: "",
      licensenumber1: "",
      licenseimage1: "",
      licenseremark1: "",
      lis_status_factory: 0,
      dor1: "",
      doe1: "",
      doddr1: "",
      managernamelicense1: "",
      managerlicenseimage1: "",
      managerlicenseremark1: "",
      noe1: "",
      nom1: "",
      nof1: "",
      issuingauth1: "",
      issuingauthimage1: "",
      issuingauthremark1: "",
      licensenumber2: "",
      licenseimage2: "",
      licenseremark2: "",
      dor2: "",
      issuingauth2: "",
      issuingauthimage2: "",
      issuingauthremark2: "",
      // Additional fields
      powerHP: "",
      powerHPremark2: "",
      powerKW: "",
      powerKWremark2: "",
      ApprovedPower: "",
      ApprovedPowerremark2: "",
      // NSP
      licensenumberNSP: "",
      licenseimageNSP: "",
      licenseremarkNSP: "",
      lis_statusNSP: 0,
      lis_statusTL: 0,
      lis_statusOTP: 0,
      lis_statusWOE: 0,
      dorNSP: "",
      doeNSP: "",
      doddrNSP: "",
      issuingauthNSP: "",
      issuingauthdetailsNSP: "",
      issuingauthimageNSP: "",
      issuingauthremarkNSP: "",
      //OTP
      licensenumberOTP: "",
      licenseimageOTP: "",
      licenseremarkOTP: "",
      dorOTP: "",
      doeOTP: "",
      doddrOTP: "",
      issuingauthOTP: "",
      issuingauthdetailsOTP: "",
      issuingauthimageOTP: "",
      issuingauthremarkOTP: "",
      //TL
      licensenumberTL: "",
      licenseimageTL: "",
      licenseremarkTL: "",
      dorTL: "",
      doeTL: "",
      doddrTL: "",
      issuingauthTL: "",
      issuingauthdetailsTL: "",
      issuingauthimageTL: "",
      issuingauthremarkTL: "",
      //WOE
      licensenumberWOE: "",
      licenseimageWOE: "",
      licenseremarkWOE: "",
      dorWOE: "",
      doeWOE: "",
      doddrWOE: "",
      issuingauthWOE: "",
      issuingauthdetailsWOE: "",
      issuingauthimageWOE: "",
      issuingauthremarkWOE: "",
      // PF
      lis_status_pf: 0,
      pfnumber: "",
      pfaddress: "",
      pfimage: "",
      pfdremark: "",
      doc: "",
      pfstate: "",
      pfdistrict: "",
      pfpin: "",
      pfaddressimage: "",
      pfaddressremark: "",
      //ESI
      lis_status_esi: 0,
      esinumber: "",
      esiimage: "",
      esidremark: "",
      doc: "",
      esistate: "",
      esidistrict: "",
      esipin: "",
      esiaddressimage: "",
      esiaddressremark: "",
      //LWF
      registrationlwfD3: "",
      registrationlwfD3image: "",
      registrationlwfD3remark: "",
      doregistrationlwfD3: "",
      lis_status_lwf: 0,
      //PTR
      lis_status_ptr: 0,
      registrationptrD3: "",
      registrationptrD3image: "",
      registrationptrD3remark: "",
      doregistrationptrD3: "",
      //PTE
      lis_status_pte: 0,
      registrationPTED3: "",
      registrationPTED3image: "",
      registrationPTED3remark: "",
      doregistrationPTED3: "",
      //MSME
      lis_status_msme: 0,
      licensenumberMSME: "",
      licenseimageMSME: "",
      licenseremarkMSME: "",
      dorMSME: "",
      issuingauthMSME: "",
      issuingauthimageMSME: "",
      issuingauthremarkMSME: "",
      //BOCW
      lis_status_bocw: 0,
      licensenumberBOCW: "",
      licenseimageBOCW: "",
      licenseremarkBOCW: "",
      dorBOCW: "",
      doeBOCW: "",
      ackFileBOCW: "",
      reasonBOCW: "",
      doddrBOCW: "",
      noeBOCW: "",
      nomBOCW: "",
      nofBOCW: "",
      issuingauthBOCW: "",
      issuingauthimageBOCW: "",
      issuingauthremarkBOCW: "",
      //ISMW
      lis_status_ismw: 0,
      licensenumberISMW: "",
      licenseimageISMW: "",
      licenseremarkISMW: "",
      dorISMW: "",
      ackFileISMW: "",
      reasonISMW: "",
      doeISMW: "",
      doddrISMW: "",
      noeISMW: "",
      nomISMW: "",
      nofISMW: "",
      issuingauthISMW: "",
      issuingauthdetailsISMW: "",
      issuingauthimageISMW: "",
      issuingauthremarkISMW: "",
      //FASSAI
      lis_status_fassai: 0,
      licensenumberFASSAI: "",
      licenseimageFASSAI: "",
      licenseremarkFASSAI: "",
      dorFASSAI: "",
      doeFASSAI: "",
      doddrFASSAI: "",
      issuingauthFASSAI: "",
      issuingauthdetailsFASSAI: "",
      ackFileFASSAI: "",
      reasonFASSAI: "",
      issuingauthremarkFASSAI: "",
      //SB
      lis_status_sb: 0,
      licensenumberSB: "",
      licenseimageSB: "",
      licenseremarkSB: "",
      dorSB: "",
      doeSB: "",
      doddrSB: "",
      issuingauthSB: "",
      issuingauthdetailsSB: "",
      ackFileSB: "",
      reasonSB: "",
      issuingauthremarkSB: "",
      //isConditions
      isFactoryOrSE: "",
      isContracorsYesorNo: "",
      isNightShiftPermission: "",
      isOTPermission: "NO",
      isWeeklyOffExemption: "NO",
      isTradeLicense: "NO",
      isPF: "NO",
      isESI: "NO",
      isLWF: "NO",
      // isLWF: "NO",
      isPTR: "NO",
      isPTE: "NO",
      isMSME: "NO",
      isBOCW: "NO",
      isISMW: "NO",
      isFASSAI: "NO",
      isSB: "NO",

      is_contract: false,
      contractLabourRegistration: '',
      contractLabourRegistrationFile: '',
      contractLabourRegistrationRemarks: '',
      contractLabourRegistrationNumber: '',
      dateOfContractReg: '',
      nOofContractors: '',
      nOofContractEmp: '',
      dateofContractSur: '',



    });
    setImagePreview("");
    // Reset file inputs explicitly
    // const fileInputs = document.querySelectorAll("input[type='file']");
    // fileInputs.forEach((input) => {
    //   input.value = ""; // Clear the file input
    // });
  };

  // Form submission
  const handleSubmit = async () => {
    const form = new FormData();

    // Append form fields
    Object.keys(formData).forEach((key) => {
      form.append(key, formData[key]);
    });

    try {
      setLoading(true);
      await dispatch(createBranches(form)); // Dispatch your existing action

      // toast.success("Form submitted successfully!", {
      //   position: "bottom-right",
      // });
      dispatch(AllbranchesGet())
      dispatch(TableBranchesGet())
      resetForm();

      // Redirect to BranchForm component
      navigate("/branches"); // Replace with the actual route for redirection
    } catch (error) {
      toast.error("Failed to submit form.", {
        position: "bottom-right",
      });
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit} onKeyDown={(e) => {
      if (e.key === "Enter") e.preventDefault();
    }}>
      <table className="table  creat_tbl">
        <tbody>
          {loadingcompanytable ? (
            <Spin tip="Loading companies..." />
          ) : (
            <Form.Item label="Select Company" required>
              <select
                id="company"
                className="custom-select"
                value={formData.company}
                onChange={handleCompanySelect}
              >
                <option value="" disabled>
                  Select a Company
                </option>
                {companyGetTableInfo?.map((company) => (
                  <option key={company._id} value={company._id}>
                    {company.companyname}
                  </option>
                ))}
              </select>
            </Form.Item>
          )}
          <br />
          <tr>
            <td>
              <label className="form-label">Branch Name/ID</label>
              <input
                type="text"
                className="form-control"
                name="name"
                placeholder="Enter branch name"
                value={formData.name}
                onChange={handleInputChange}
                required
              //
              />
            </td>
            <td>
              <div className="form-group files1">
                <input
                  type="file"
                  multiple=""
                  name="branchimage"
                  accept="image/*,application/pdf"
                  onChange={(e) => handleFileChange(e, "branchimage")}
                  className="form-control"
                  style={{ height: "10px" }}
                  placeholder="Enter branch branchimage"
                />
              </div>
            </td>
            <td>
              <label className="form-label">Remarks</label>
              <input
                type="text"
                className="form-control"
                placeholder="Remark"
                value={formData.remarks || ""}
                onChange={handleInputChange}
                id="remarks"
                name="remarks"
              />
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <h4>Details of the Branch </h4>
              <div>
                <table className="table creat_tbl">
                  <tr className="align-middle">
                    <td colSpan="3">
                      <label htmlFor="">Is Factory or S&E</label>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3" style={{ width: "100%" }}>
                      <button
                        type="button"
                        style={{
                          backgroundColor: "rgb(206, 233, 242)",
                          width: "20%",
                        }}
                        className={`btn ${selectedOption === "Factory"
                          ? "btn-primary text-white"
                          : "btn-light"
                          }`}
                        onClick={() => handleOptionChange("Factory")}
                      >
                        Factory
                      </button>
                      <button
                        type="button"
                        style={{
                          backgroundColor: "rgb(206, 233, 242)",
                          width: "20%",
                        }}
                        className={`btn ${selectedOption === "S&E"
                          ? "btn-primary text-white"
                          : "btn-light"
                          }`}
                        onClick={() => handleOptionChange("S&E")}
                      >
                        S&E
                      </button>
                    </td>
                  </tr>
                </table>
                <div>
                  {/** div for Is Factory or S&E starts */}
                  <table className="table creat_tbl">
                    <tr>
                      <td>
                        <table className="table creat_tbl">
                          <tr>
                            <td>
                              <label for="">
                                Registered Address of the Branch
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                name="branchaddress"
                                id="branchaddress"
                                value={formData.branchaddress || ""}
                                onChange={handleInputChange}
                                placeholder="Address"
                              // ref={inputRefs.inputRef61}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <label for="">Region</label>
                              <select
                                className="form-select"
                                aria-label="Region select example"
                                name="region"
                                id="region"
                                value={formData.region || ""}
                                onChange={handleInputChange}
                                required
                              >
                                <option value="">Select Region</option>
                                <option value="SouthRegion">
                                  South Region
                                </option>
                                <option value="NorthRegion">
                                  North Region
                                </option>
                                <option value="WestRegion">West Region</option>
                                <option value="EastRegion">East Region</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                name="branchstate"
                                id="branchstate"
                                value={formData.branchstate || ""}
                                onChange={handleInputChange}
                                // ref={inputRefs.inputRef62}
                                required
                              >
                                <option value="">Select State</option>
                                {stateInfo != "undefined" &&
                                  stateInfo?.length > 0 &&
                                  stateInfo.map((item) => (
                                    <option value={item._id}>
                                      {item.name}
                                    </option>
                                  ))}
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <input
                                type="text"
                                class="form-control"
                                name="branchdistrict"
                                id="branchdistrict"
                                value={formData.branchdistrict || ""}
                                onChange={handleInputChange}
                                placeholder="District"
                              // ref={inputRefs.inputRef63}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <input
                                type="number"
                                class="form-control"
                                name="branchpin"
                                id="branchpin"
                                value={formData.branchpin || ""}
                                onChange={handleInputChange}
                                placeholder="PIN"
                              // ref={inputRefs.inputRef64}
                              />
                            </td>
                          </tr>
                        </table>
                      </td>
                      <td>
                        <div>
                          <div class="form-group files1">
                            <input
                              type="file"
                              name="contractorAddBranchFimage"
                              id="contractorAddBranchFimage"
                              class="form-control"
                              multiple=""
                              accept="image/*,application/pdf"
                              style={{ height: "10px" }}
                              onChange={(e) =>
                                handleFileChange(e, "contractorAddBranchFimage")
                              }

                            // ref={inputRefs.inputRef67}
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <label for="">Remark</label>
                        <input
                          type="text"
                          class="form-control"
                          value={formData.contractorAddBranchFRemark || ""}
                          name="contractorAddBranchFRemark"
                          id="contractorAddBranchFRemark"
                          onChange={handleInputChange}
                          placeholder="Write Here"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3">
                        <label for="" className="form-label">Date of Opening</label>
                        <input
                          type="date"
                          class="form-control"
                          value={formData.branchOpeningDateF || ""}
                          name="branchOpeningDateF"
                          id="branchOpeningDateF"
                          onChange={handleInputChange}
                          placeholder="Date of Opening"
                        // ref={inputRefs.inputRef68}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label className="form-label">
                          Date of Closure
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          // placeholder="Remark"
                          value={formData.dateofclosure || ""}
                          onChange={handleInputChange}
                          id="dateofclosure"
                          name="dateofclosure"
                        />
                      </td>

                      <td>
                        <div>
                          <label className="form-label">
                            Upload Closure Acknowlegement
                          </label>
                          <div class="form-group files1">
                            <input
                              type="file"
                              name="dateofclosureFile"
                              id="dateofclosureFile"
                              class="form-control"
                              multiple=""
                              accept="image/*,application/pdf"
                              style={{ height: "10px" }}
                              onChange={(e) =>
                                handleFileChange(e, "dateofclosureFile")
                              }

                            // ref={inputRefs.inputRef67}
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <label for="">Remark</label>
                        <input
                          type="text"
                          class="form-control"
                          value={formData.dateofclosureRemark || ""}
                          name="dateofclosureRemark"
                          id="dateofclosureRemark"
                          onChange={handleInputChange}
                          placeholder="Write Here"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3">
                        <label for="">Number of Employees</label>
                        <input
                          type="number"
                          class="form-control"
                          name="noOfEmpBranchF"
                          id="noOfEmpBranchF"
                          value={formData.noOfEmpBranchF || ""}
                          // min="0"
                          // onKeyPress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                          onChange={handleInputChange}
                        // ref={inputRefs.inputRef69}
                        />
                      </td>
                    </tr>
                  </table>
                </div>
                <h4>Manager Details</h4>
                <table className="table creat_tbl">
                  <tr>
                    <td>
                      <label for="">Name of the Manager</label>
                      <input
                        type="text"
                        class="form-control"
                        name="managerNameF1"
                        id="managerNameF1"
                        value={formData.managerNameF1 || ""}
                        onChange={handleInputChange}
                        placeholder="Manager Details"
                      />
                    </td>
                    <td>
                      <div>
                        <label for="">Manager Photo</label>
                        <div class="form-group files1">
                          <input
                            type="file"
                            name="managerNameF1image"
                            // value={formData.managerNameF1image || " "}
                            id="managerNameF1image"
                            class="form-control"
                            multiple=""
                            accept="image/*,application/pdf"
                            style={{ height: "10px" }}
                            onChange={(e) =>
                              handleFileChange(e, "managerNameF1image")
                            }
                          />
                        </div>
                        {/* {managerNameF1image && ( */}
                      </div>
                    </td>
                    <td>
                      <label for="">Remark</label>
                      <input
                        type="text"
                        class="form-control"
                        value={formData.managerNameF1Remark || ""}
                        name="managerNameF1Remark"
                        id="managerNameF1Remark"
                        onChange={handleInputChange}
                        placeholder="Write Here"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="">Mobile Number</label>
                      <input
                        type="number"
                        class="form-control"
                        name="managerMobNoF1"
                        id="managerMobNoF1"
                        value={formData.managerMobNoF1}
                        onChange={handleInputChange}
                        placeholder="Mobile Number"
                      />
                    </td>
                    <td colspan="1">
                      <label for="">Remark</label>
                      <input
                        type="text"
                        class="form-control"
                        value={formData.managerMobNoF1Remark || ""}
                        name="managerMobNoF1Remark"
                        id="managerMobNoF1Remark"
                        onChange={handleInputChange}
                        placeholder="Write Here"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="">Email</label>
                      <input
                        type="email"
                        class="form-control"
                        name="managerEmailF1"
                        id="managerEmailF1"
                        value={formData.managerEmailF1 || ""}
                        onChange={handleInputChange}
                        placeholder="Email"
                      />
                    </td>
                    <td>
                      <label for="">Remark</label>
                      <input
                        type="text"
                        class="form-control"
                        value={formData.managerEmailF1Remark || ""}
                        name="managerEmailF1Remark"
                        id="managerEmailF1Remark"
                        onChange={handleInputChange}
                        placeholder="Write Here"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="">Aadhaar Number</label>
                      <input
                        type="text"
                        class="form-control"
                        name="managerAadharNoF1"
                        id="managerAadharNoF1"
                        value={formData.managerAadharNoF1 || ""}
                        onChange={handleInputChange}
                        placeholder="Aadhar Number"
                      />
                    </td>
                    <td>
                      <div>
                        <label for="">Aadhaar Upload</label>
                        <div class="form-group files1">
                          <input
                            type="file"
                            name="managerAadharNoF1image"
                            // value={formData.managerAadharNoF1image || " "}
                            id="managerAadharNoF1image"
                            class="form-control"
                            multiple=""
                            accept="image/*,application/pdf"
                            style={{ height: "10px" }}
                            onChange={(e) =>
                              handleFileChange(e, "managerAadharNoF1image")
                            }
                          />
                        </div>
                        {/* {managerAadharNoF1image && ( */}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="">Pan Number</label>
                      <input
                        type="text"
                        class="form-control"
                        name="managerPanF1"
                        id="managerPanF1"
                        value={formData.managerPanF1 || ""}
                        onChange={handleInputChange}
                        placeholder="Aadhar Number"
                      />
                    </td>
                    <td>
                      <div>
                        <label for="">Pan Upload</label>
                        <div class="form-group files1">
                          <input
                            type="file"
                            name="managerPanF1image"
                            // value={formData.managerPanF1image || " "}
                            id="managerPanF1image"
                            class="form-control"
                            multiple=""
                            accept="image/*,application/pdf"
                            style={{ height: "10px" }}
                            onChange={(e) =>
                              handleFileChange(e, "managerPanF1image")
                            }
                          />
                        </div>
                        {/* {managerPanF1image && ( */}
                      </div>
                    </td>
                  </tr>
                </table>
                {selectedOption === "S&E" && (
                  <div>
                    <h4>Shops & Establishments Registration</h4>
                    <table className="table creat_tbl">
                      <tbody>
                        {/* <tr>
                          <td>
                            <label htmlFor={`shopsEstLicenseF2`}>
                              Shops and Establishment License
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="shopsEstLicenseF2"
                              id="shopsEstLicenseF2"
                              value={formData.shopsEstLicenseF2 || ""}
                              onChange={handleInputChange}
                              placeholder="Shops and Establishment License"
                            />
                          </td>
                          <td>
                            <div className="form-group files1">
                              <input
                                type="file"
                                name="shopsEstLicenseF2image" // Make sure name is not empty
                                id="shopsEstLicenseF2image" // Unique id for each file input
                                className="form-control"
                                multiple=""
                                accept="image/*,application/pdf"
                                style={{
                                  height: "10px" ,
                                }}
                                onChange={(e) =>
                                  handleFileChange(e, "shopsEstLicenseF2image")
                                }

                              />
                            </div>
                          </td>
                          <td>
                            <label htmlFor="shopsEstLicenseF2Remark">
                              Remark
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={formData.shopsEstLicenseF2Remark || ""}
                              name="shopsEstLicenseF2Remark"
                              id="shopsEstLicenseF2Remark"
                              onChange={handleInputChange}
                              placeholder="Write Here"
                            />
                          </td>
                        </tr> */}

                        <tr>
                          <td>
                            <label className="form-label">License Number</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Licence Number"
                              value={formData.licensenumber || ""}
                              id="licensenumber"
                              name="licensenumber"
                              onChange={handleInputChange}
                            />
                          </td>
                          <td colspan="2">
                            <>
                              <label htmlFor="v">Upload License</label>
                              <div>
                                <div class="form-group files1">
                                  <input
                                    type="file"
                                    class="form-control"
                                    multiple=""
                                    accept="image/*,application/pdf"
                                    style={{
                                      height: "10px" /*,display: 'inline'*/,
                                    }}
                                    id="licenseimage"
                                    name="licenseimage"
                                    onChange={(e) =>
                                      handleFileChange(e, "licenseimage")
                                    }
                                  />
                                </div>
                              </div>
                            </>
                          </td>
                          <td>
                            <label for="">Remark</label>
                            <input
                              type="text"
                              class="form-control"
                              id="licenseremark"
                              name="licenseremark"
                              value={formData.licenseremark || ""}
                              onChange={handleInputChange}
                              placeholder="Write Here"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colspan="">
                            <label for="" className="form-label">License Status</label>
                            <select
                              className="form-select"
                              aria-label="Status select example"
                              name="lis_status_se"
                              id="lis_status_se"
                              value={formData.lis_status_se || 0}
                              onChange={handleInputChange}
                              required
                            >
                              <option value={0}>Select License Status</option>
                              <option value={1}>Not In Scope</option>
                              <option value={2}>License Applied</option>
                              <option value={3}>License Not-Applied</option>
                              <option value={4}>Life-Time License</option>
                            </select>
                          </td>
                          {formData.lis_status_se === "2" && (
                            <td colSpan="2">
                              <>
                                <label htmlFor="issuingauthimage">
                                  Upload Acknowledgement
                                </label>
                                <div className="form-group files1">
                                  <input
                                    type="file"
                                    multiple=""
                                    accept="image/*,application/pdf"
                                    className="form-control"
                                    style={{
                                      height: "10px" /*,display: 'inline'*/,
                                    }}
                                    onChange={(e) =>
                                      handleFileChange(e, "issuingauthimage")
                                    }
                                    id="issuingauthimage"
                                    name="issuingauthimage"
                                  // ref={inputRefsfactoryRefClicknotshow.inputRef14}
                                  />
                                </div>
                              </>
                            </td>
                          )}
                          {formData.lis_status_se === "3" && (
                            <td colSpan="3">
                              <label className="form-label">Remarks</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Remark"
                                value={formData.issuingauthremark || ""}
                                onChange={handleInputChange}
                                id="issuingauthremark"
                                name="issuingauthremark"
                              />
                            </td>
                          )}
                        </tr>
                        <tr>
                          <td colspan="4">
                            <label className="form-label">
                              Date of Registration
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              placeholder="Date of Registration"
                              value={formData.dor || ""}
                              onChange={handleInputChange}
                              id="dor"
                              name="dor"
                            // ref={inputRefsfactoryRefClicknotshow.inputRef5}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            <label className="form-label">Date of Expiry</label>
                            <input
                              type="date"
                              className="form-control"
                              placeholder="Date of Expiry"
                              value={formData.doe || ""}
                              onChange={handleInputChange}
                              id="doe"
                              name="doe"
                              disabled={formData.lis_status_se === "4"}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colspan="4">
                            <label className="form-label">
                              Due Date of Renewal
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              placeholder="Due Date of Renewal"
                              value={formData.doddr || ""}
                              onChange={handleInputChange}
                              id="doddr"
                              name="doddr"
                            // ref={inputRefsfactoryRefClicknotshow.inputRef7}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            <label className="form-label">
                              Manager Name of the License
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Manager Name of the License"
                              value={formData.managernamelicense || ""}
                              onChange={handleInputChange}
                              name="managernamelicense"
                              id="managernamelicense"
                            // ref={inputRefsfactoryRefClicknotshow.inputRef8}
                            />
                          </td>
                          <td>
                            <div className="form-group files1">
                              <input
                                type="file"
                                multiple=""
                                accept="image/*,application/pdf"
                                className="form-control"
                                style={{
                                  height: "10px" /*,display: 'inline'*/,
                                }}
                                onChange={(e) =>
                                  handleFileChange(e, "managerlicenseimage")
                                }
                                id="managerlicenseimage"
                                name="managerlicenseimage"
                              // ref={inputRefsfactoryRefClicknotshow.inputRef9}
                              />
                            </div>
                            {/* {managerlicenseimage && ( */}
                          </td>
                          <td>
                            <label className="form-label">Remarks</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Remark"
                              value={formData.managerlicenseremark || ""}
                              onChange={handleInputChange}
                              id="managerlicenseremark"
                              name="managerlicenseremark"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colspan="4">
                            <label className="form-label">
                              Number of the Employees
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Number of the Employees"
                              value={formData.noe || ""}
                              onChange={handleInputChange}
                              // min="0"
                              // onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                              id="noe"
                              name="noe"
                            // ref={inputRefsfactoryRefClicknotshow.inputRef10}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colspan="4">
                            <label className="form-label">Male</label>
                            {/* {isVisible && ( */}
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Male"
                              value={formData.nom || ""}
                              onChange={handleInputChange}
                              min="0"
                              onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                              id="nom"
                              name="nom"
                            // ref={inputRefsfactoryRefClicknotshow.inputRef11}
                            />
                            {/* )} */}
                          </td>
                        </tr>
                        <tr>
                          <td colspan="4">
                            <label className="form-label">Female</label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Female"
                              value={formData.nof || ""}
                              onChange={handleInputChange}
                              min="0"
                              onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                              id="nof"
                              name="nof"
                            // ref={inputRefsfactoryRefClicknotshow.inputRef12}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            <label className="form-label">
                              Issuing Authority
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Issuing Authority"
                              value={formData.issuingauth || ""}
                              onChange={handleInputChange}
                              name="issuingauth"
                              id="issuingauth"
                            // ref={inputRefsfactoryRefClicknotshow.inputRef13}
                            />
                          </td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                )}
                {selectedOption === "Factory" && (
                  <div>
                    <h4>Factory License </h4>
                    <table className="table  creat_tbl">
                      <tr>
                        <td>
                          <label className="form-label">License Number</label>

                          <input
                            type="text"
                            className="form-control"
                            placeholder="Licence Number"
                            value={formData.licensenumber1 || ""}
                            id="licensenumber1"
                            name="licensenumber1"
                            onChange={handleInputChange}
                          // ref={inputRefsfactoryRefClicknotshow.inputRef15}
                          />
                        </td>
                        <td colspan="2">
                          <div>
                            <label for="licenseimage1">Upload License</label>
                            <div class="form-group files1">
                              <input
                                type="file"
                                class="form-control"
                                multiple=""
                                accept="image/*,application/pdf"
                                style={{
                                  height: "10px" /*,display: 'inline'*/,
                                }}
                                id="licenseimage1"
                                name="licenseimage1"
                                onChange={(e) =>
                                  handleFileChange(e, "licenseimage1")
                                }
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <label for="">Remark</label>
                          <input
                            type="text"
                            class="form-control"
                            id="licenseremark1"
                            name="licenseremark1"
                            value={formData.licenseremark1 || ""}
                            onChange={handleInputChange}
                            placeholder="Write Here"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="">
                          <label for="" className="form-label">License Status</label>
                          <select
                            className="form-select"
                            aria-label="Status select example"
                            name="lis_status_factory"
                            id="lis_status_factory"
                            value={formData.lis_status_factory || 0}
                            onChange={handleInputChange}
                            required
                          >
                            <option value={0}>Select License Status</option>
                            <option value={1}>Not In Scope</option>
                            <option value={2}>License Applied</option>
                            <option value={3}>License Not-Applied</option>
                            <option value={4}>Life-Time License</option>

                          </select>
                        </td>
                        {formData.lis_status_factory === "2" && (
                          <td colSpan="2">
                            <>
                              <label htmlFor="issuingauthimage1">
                                Upload Acknowledgement
                              </label>

                              <div className="form-group files1">
                                <input
                                  type="file"
                                  multiple=""
                                  accept="image/*,application/pdf"
                                  className="form-control"
                                  style={{
                                    height: "10px" /*,display: 'inline'*/,
                                  }}
                                  onChange={(e) =>
                                    handleFileChange(e, "issuingauthimage1")
                                  }
                                  id="issuingauthimage1"
                                  name="issuingauthimage1"
                                // ref={inputRefsfactoryRefClicknotshow.inputRef26}
                                />
                              </div>
                            </>
                          </td>
                        )}
                        {formData.lis_status_factory === "3" && (
                          <td colSpan="3">
                            <label className="form-label">Remarks</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Remark"
                              value={formData.issuingauthremark1 || ""}
                              onChange={handleInputChange}
                              id="issuingauthremark1"
                              name="issuingauthremark1"
                            />
                          </td>
                        )}
                      </tr>
                      <tr>
                        <td colspan="4">
                          <label className="form-label">
                            Date of Registration
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Date of Registration"
                            value={formData.dor1 || ""}
                            onChange={handleInputChange}
                            id="dor1"
                            name="dor1"
                          // ref={inputRefsfactoryRefClicknotshow.inputRef17}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="4">
                          <label className="form-label">Date of Expiry</label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Date of Expiry"
                            value={formData.doe1 || ""}
                            onChange={handleInputChange}
                            id="doe1"
                            name="doe1"
                            disabled={formData.lis_status_factory === 4}
                          // ref={inputRefsfactoryRefClicknotshow.inputRef18}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="4">
                          <label className="form-label">
                            Due Date of Renewal
                          </label>
                          {/* {isVisible && ( */}
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Due Date of Renewal"
                            value={formData.doddr1 || ""}
                            onChange={handleInputChange}
                            id="doddr1"
                            name="doddr1"
                          // ref={inputRefsfactoryRefClicknotshow.inputRef19}
                          />
                          {/* )} */}
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <label className="form-label">
                            Manager Name of the License
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Manager Name of the License"
                            value={formData.managernamelicense1 || ""}
                            onChange={handleInputChange}
                            name="managernamelicense1"
                            id="managernamelicense1"
                          // ref={inputRefsfactoryRefClicknotshow.inputRef20}
                          />
                        </td>
                        <td>
                          <div className="form-group files1">
                            <input
                              type="file"
                              multiple=""
                              accept="image/*,application/pdf"
                              className="form-control"
                              style={{ height: "10px" /*,display: 'inline'*/ }}
                              onChange={(e) =>
                                handleFileChange(e, "managerlicenseimage1")
                              }
                              id="managerlicenseimage1"
                              name="managerlicenseimage1"
                            // ref={inputRefsfactoryRefClicknotshow.inputRef21}
                            />
                          </div>
                        </td>
                        <td>
                          <label className="form-label">Remarks</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Remark"
                            value={formData.managerlicenseremark1 || ""}
                            onChange={handleInputChange}
                            id="managerlicenseremark1"
                            name="managerlicenseremark1"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="4">
                          <label className="form-label">
                            Number of the Employees
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Number of the Employees"
                            value={formData.noe1 || ""}
                            onChange={handleInputChange}
                            // min="0"
                            // onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                            id="noe1"
                            name="noe1"
                          // ref={inputRefsfactoryRefClicknotshow.inputRef22}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="4">
                          <label className="form-label">Male</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Male"
                            value={formData.nom1 || ""}
                            onChange={handleInputChange}
                            min="0"
                            onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                            id="nom1"
                            name="nom1"
                          // ref={inputRefsfactoryRefClicknotshow.inputRef23}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="4">
                          <label className="form-label">Female</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Female"
                            value={formData.nof1 || ""}
                            onChange={handleInputChange}
                            min="0"
                            onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                            id="nof1"
                            name="nof1"
                          // ref={inputRefsfactoryRefClicknotshow.inputRef24}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <label className="form-label">
                            Issuing Authority
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Issuing Authority"
                            value={formData.issuingauth1 || ""}
                            onChange={handleInputChange}
                            name="issuingauth1"
                            id="issuingauth1"
                          // ref={inputRefsfactoryRefClicknotshow.inputRef25}
                          />
                        </td>
                      </tr>
                    </table>

                    <h4>Factory Plan</h4>
                    <table className="table  creat_tbl">
                      <tr>
                        <td>
                          <label className="form-label">License Number</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Licence Number"
                            value={formData.licensenumber2 || ""}
                            id="licensenumber2"
                            name="licensenumber2"
                            onChange={handleInputChange}
                          // ref={inputRefsfactoryRefClicknotshow.inputRef27}
                          />
                        </td>
                        <td colspan="2">
                          <div>
                            <div class="form-group files1">
                              <input
                                type="file"
                                class="form-control"
                                multiple=""
                                accept="image/*,application/pdf"
                                style={{
                                  height: "10px" /*,display: 'inline'*/,
                                }}
                                id="licenseimage2"
                                name="licenseimage2"
                                onChange={(e) =>
                                  handleFileChange(e, "licenseimage2")
                                }

                              // ref={inputRefsfactoryRefClicknotshow.inputRef28}
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <label for="">Remark</label>
                          <input
                            type="text"
                            class="form-control"
                            id="licenseremark2"
                            name="licenseremark2"
                            value={formData.licenseremark2 || ""}
                            onChange={handleInputChange}
                            placeholder="Write Here"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="4">
                          <label className="form-label">
                            Date of Registration
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Date of Registration"
                            value={formData.dor2 || ""}
                            onChange={handleInputChange}
                            id="dor2"
                            name="dor2"
                          // ref={inputRefsfactoryRefClicknotshow.inputRef29}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <label className="form-label">
                            Issuing Authority
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Issuing Authority"
                            value={formData.issuingauth2 || ""}
                            onChange={handleInputChange}
                            name="issuingauth2"
                            id="issuingauth2"
                          // ref={inputRefsfactoryRefClicknotshow.inputRef30}
                          />
                        </td>
                        <td>
                          <div className="form-group files1">
                            <input
                              type="file"
                              multiple=""
                              accept="image/*,application/pdf"
                              className="form-control"
                              style={{ height: "10px" /*,display: 'inline'*/ }}
                              onChange={(e) =>
                                handleFileChange(e, "issuingauthimage2")
                              }
                              id="issuingauthimage2"
                              name="issuingauthimage2"
                            // ref={inputRefsfactoryRefClicknotshow.inputRef31}
                            />
                          </div>
                        </td>
                        <td>
                          <label className="form-label">Remarks</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Remark"
                            value={formData.issuingauthremark2 || ""}
                            onChange={handleInputChange}
                            id="issuingauthremark2"
                            name="issuingauthremark2"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <label className="form-label">Power-KW</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Power-KW"
                            value={formData.powerKW || ""}
                            onChange={handleInputChange}
                            name="powerKW"
                            id="powerKW"
                          // ref={inputRefsfactoryRefClicknotshow.inputRef85}
                          />
                        </td>
                        <td>
                          <label className="form-label">Remarks</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Remark"
                            value={formData.powerKWremark2 || ""}
                            onChange={handleInputChange}
                            id="powerKWremark2"
                            name="powerKWremark2"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <label className="form-label">Power-HP</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Power-HP"
                            value={formData.powerHP || ""}
                            onChange={handleInputChange}
                            name="powerHP"
                            id="powerHP"
                          // ref={inputRefsfactoryRefClicknotshow.inputRef86}
                          />
                        </td>
                        <td>
                          <label className="form-label">Remarks</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Remark"
                            value={formData.powerHPremark2 || ""}
                            onChange={handleInputChange}
                            id="powerHPremark2"
                            name="powerHPremark2"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <label className="form-label">
                            Approved Power Details
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Approved Power"
                            value={formData.ApprovedPower || ""}
                            onChange={handleInputChange}
                            name="ApprovedPower"
                            id="ApprovedPower"
                          // ref={inputRefsfactoryRefClicknotshow.inputRef87}
                          />
                        </td>
                        <td>
                          <label className="form-label">Remarks</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Remark"
                            value={formData.ApprovedPowerremark2 || ""}
                            onChange={handleInputChange}
                            id="ApprovedPowerremark2"
                            name="ApprovedPowerremark2"
                          />
                        </td>
                      </tr>
                    </table>
                  </div>
                )}
                <div>
                  {/* <h4>F.5. Details of the Labour Contractors</h4>
                  <table className="table creat_tbl">
                    <tr className="align-middle">
                      <td colSpan="3">
                        <label>Is Contract labour Engaged(Yes/No)</label>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3" style={{ width: "100%" }}>
                        <button
                          type="button"
                          style={{
                            backgroundColor: "rgb(206, 233, 242)",
                            width: "20%",
                          }}
                          className={`btn ${isContractLabourEngaged === "YES"
                            ? "btn-primary text-white"
                            : "btn-light"
                            }`}
                          onClick={() => handleSelectionChange("YES")}
                        >
                          YES
                        </button>
                        <button
                          type="button"
                          style={{
                            backgroundColor: "rgb(206, 233, 242)",
                            width: "20%",
                          }}
                          className={`btn ${isContractLabourEngaged === "NO"
                            ? "btn-primary text-white"
                            : "btn-light"
                            }`}
                          onClick={() => handleSelectionChange("NO")}
                        >
                          NO
                        </button>
                      </td>
                    </tr>
                  </table>
                  {isContractLabourEngaged === "YES" && (
                    <div>
                      <table className="table  creat_tbl">
                        <tr>
                          <td>
                            <label htmlFor="">
                              Contract Labour Registration Number
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="contractLabRegNoF5"
                              id="contractLabRegNoF5"
                              value={formData.contractLabRegNoF5 || ""}
                              onChange={handleInputChange}
                              placeholder="Contract Labour Registration Number"
                            />
                          </td>
                          <td>
                            <div>
                              <div class="form-group files1">
                                <input
                                  type="file"
                                  name="contractLabRegNoF5image"
                                  id="contractLabRegNoF5image"
                                  class="form-control"
                                  multiple=""
                                  accept="image/*,application/pdf"
                                  style={{ height: "10px" }}
                                  onChange={(e) =>
                                    handleFileChange(
                                      e,
                                      "contractLabRegNoF5image"
                                    )
                                  }

                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <label for="">Remark</label>
                            <input
                              type="text"
                              class="form-control"
                              value={formData.contractLabRegNoF5Remark || ""}
                              name="contractLabRegNoF5Remark"
                              id="contractLabRegNoF5Remark"
                              onChange={handleInputChange}
                              placeholder="Write Here"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3">
                            <label for="">Date of Registration</label>
                            <input
                              type="date"
                              class="form-control"
                              value={formData.regDateContractorF5 || ""}
                              name="regDateContractorF5"
                              id="regDateContractorF5"
                              onChange={handleInputChange}
                              placeholder="Type here"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3">
                            <label for="">Number of Contract Employee</label>
                            <input
                              type="number"
                              class="form-control"
                              name="coOfContractEmpF5"
                              id="coOfContractEmpF5"
                              value={formData.coOfContractEmpF5 || ""}
                              onChange={handleInputChange}
                              placeholder="Number of Contract Employee"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3">
                            <label for="">Number of Contractors</label>
                            <input
                              type="number"
                              class="form-control"
                              name="noOfContractorsF5"
                              id="noOfContractorsF5"
                              value={formData.noOfContractorsF5 || ""}
                              onChange={handleInputChange}
                              placeholder="Number of Contractors"
                            />
                          </td>
                        </tr>
                      </table>
                      <h4>F.5.1. Details of the Labour Contractors</h4>
                      <table className="table  creat_tbl">
                        <tr>
                          <td>
                            <label for="">Name of the Contractor</label>
                            <input
                              type="text"
                              class="form-control"
                              name="contractorNameF51"
                              id="contractorNameF51"
                              value={formData.contractorNameF51 || ""}
                              onChange={handleInputChange}
                              placeholder="Name of the Contractor"
                            />
                          </td>
                          <td>
                            <div>
                              <div class="form-group files1">
                                <input
                                  type="file"
                                  name="contractorNameF51image"
                                  id="contractorNameF51image"
                                  class="form-control"
                                  multiple=""
                                  accept="image/*,application/pdf"
                                  style={{ height: "10px" }}
                                  onChange={(e) =>
                                    handleFileChange(
                                      e,
                                      "contractorNameF51image"
                                    )
                                  }

                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <label for="">Remark</label>
                            <input
                              type="text"
                              class="form-control"
                              value={formData.contractorNameF51Remark || ""}
                              name="contractorNameF51Remark"
                              id="contractorNameF51Remark"
                              onChange={handleInputChange}
                              placeholder="Write Here"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label for="">Name of the Establishment</label>
                            <input
                              type="text"
                              class="form-control"
                              value={formData.establishmentNameF51 || ""}
                              name="establishmentNameF51"
                              id="establishmentNameF51"
                              onChange={handleInputChange}
                              placeholder="Name of the Establishment"
                            />
                          </td>
                          <td>
                            <div>
                              <div class="form-group files1">
                                <input
                                  type="file"
                                  name="establishmentNameF51image"
                                  id="establishmentNameF51image"
                                  class="form-control"
                                  multiple=""
                                  accept="image/*,application/pdf"
                                  style={{ height: "10px" }}
                                  onChange={(e) =>
                                    handleFileChange(
                                      e,
                                      "establishmentNameF51image"
                                    )
                                  }

                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <label for="">Remark</label>
                            <input
                              type="text"
                              class="form-control"
                              value={formData.establishmentNameF51Remark || ""}
                              name="establishmentNameF51Remark"
                              id="establishmentNameF51Remark"
                              onChange={handleInputChange}
                              placeholder="Write Here"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label for="">
                              Registered Address of the Contractor
                            </label>
                            <table className="table  creat_tbl">
                              <tr>
                                <td>
                                  <input
                                    type="text"
                                    class="form-control"
                                    name="regisocontractaddress"
                                    id="regisocontractaddress"
                                    value={formData.regisocontractaddress || ""}
                                    onChange={handleInputChange}
                                    placeholder="Address"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <select
                                    className="form-select"
                                    aria-label="Default select example"
                                    name="regStateContractorF51"
                                    id="regStateContractorF51"
                                    value={formData.regStateContractorF51 || ""}
                                    onChange={handleInputChange}

                                  >
                                    <option value="">Select State</option>
                                    {stateInfo != "undefind" &&
                                      stateInfo?.length > 0 &&
                                      stateInfo.map((item) => (
                                        <option value={item._id}>
                                          {item.name}
                                        </option>
                                      ))}
                                    ;
                                  </select>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <input
                                    type="text"
                                    class="form-control"
                                    value={formData.regDistContractorF51 || ""}
                                    name="regDistContractorF51"
                                    id="regDistContractorF51"
                                    onChange={handleInputChange}
                                    placeholder="District"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <input
                                    type="number"
                                    class="form-control"
                                    name="regPinContractorF51"
                                    id="regPinContractorF51"
                                    value={formData.regPinContractorF51 || ""}
                                    onChange={handleInputChange}
                                    placeholder="PIN"
                                  />
                                </td>
                              </tr>
                            </table>
                          </td>

                          <td>
                            <div>
                              <div class="form-group files1">
                                <input
                                  type="file"
                                  name="regAddContractorF51image"
                                  id="regAddContractorF51image"
                                  class="form-control"
                                  multiple=""
                                  accept="image/*,application/pdf"
                                  style={{ height: "10px" }}
                                  onChange={(e) =>
                                    handleFileChange(
                                      e,
                                      "regAddContractorF51image"
                                    )
                                  }

                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <label for="">Remark</label>
                            <input
                              type="text"
                              class="form-control"
                              value={formData.regAddContractorF51Remark || ""}
                              name="regAddContractorF51Remark"
                              id="regAddContractorF51Remark"
                              onChange={handleInputChange}
                              placeholder="Write Here"
                            />
                          </td>
                        </tr>
                      </table>
                      <h4>F.5.2. Agreement Date</h4>
                      <table className="table  creat_tbl">
                        <tr>
                          <td colspan="3">
                            <label for="">Date of Expiry</label>
                            <input
                              type="date"
                              class="form-control"
                              value={formData.expiryDateF52 || ""}
                              name="expiryDateF52"
                              id="expiryDateF52"
                              onChange={handleInputChange}
                              placeholder="Date of Expiry"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3">
                            <label for="">Due Date for Renewal</label>
                            <input
                              type="date"
                              class="form-control"
                              name="renewalDateF52"
                              id="renewalDateF52"
                              value={formData.renewalDateF52 || ""}
                              onChange={handleInputChange}
                              placeholder="Due Date for Renewal"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label for="">Nature of Work</label>
                            <input
                              type="text"
                              class="form-control"
                              name="natureOfWorkF52"
                              id="natureOfWorkF52"
                              value={formData.natureOfWorkF52 || ""}
                              onChange={handleInputChange}
                              placeholder="Type here"
                            />
                          </td>
                          <td>
                            <div>
                              <div class="form-group files1">
                                <input
                                  type="file"
                                  name="natureOfWorkF52image"
                                  id="natureOfWorkF52image"
                                  class="form-control"
                                  multiple=""
                                  accept="image/*,application/pdf"
                                  style={{ height: "10px" }}
                                  onChange={(e) =>
                                    handleFileChange(e, "natureOfWorkF52image")
                                  }

                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <label for="">Remark</label>
                            <input
                              type="text"
                              class="form-control"
                              value={formData.natureOfWorkF52Remark || ""}
                              name="natureOfWorkF52Remark"
                              id="natureOfWorkF52Remark"
                              onChange={handleInputChange}
                              placeholder="Write Here"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3">
                            <label for="">Number of Employees Deployed</label>
                            <input
                              type="number"
                              class="form-control"
                              name="noOfEmpDeployedF52"
                              id="noOfEmpDeployedF52"
                              value={formData.noOfEmpDeployedF52 || ""}
                              onChange={handleInputChange}
                              placeholder="Type here"
                            />
                          </td>
                        </tr>
                      </table>
                      <h4>F.5.3. Contractors Registration Details</h4>
                      <table className="table  creat_tbl">
                        <tr>
                          <td>
                            <label for="">Type of the Company</label>
                            <select
                              class="form-select"
                              name="companyTypeF53"
                              id="companyTypeF53"
                              value={formData.companyTypeF53 || ""}
                              onChange={handleInputChange}
                              placeholder="Type of the Company"
                            >
                              <option value="">Type of the Company</option>
                              <option value="1">Private Limited Company</option>
                              <option value="2">Public Limited Company</option>
                              <option value="3">Sole Proprietorship</option>
                              <option value="4">Partnership</option>
                              <option value="5">
                                Limited Liability Partnership (LLP)
                              </option>
                              <option value="6">
                                Non-Government Organization (NGO)
                              </option>
                              <option value="7">
                                One Person Company (OPC)
                              </option>
                              <option value="8">Others</option>
                            </select>
                          </td>
                          <td>
                            <div>
                              <div class="form-group files1">
                                <input
                                  type="file"
                                  name="companyTypeF53image"
                                  id="companyTypeF53image"
                                  class="form-control"
                                  multiple=""
                                  accept="image/*,application/pdf"
                                  style={{ height: "10px" }}
                                  onChange={(e) =>
                                    handleFileChange(e, "companyTypeF53image")
                                  }

                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <label for="">Remark</label>
                            <input
                              type="text"
                              class="form-control"
                              name="companyTypeF53Remark"
                              id="companyTypeF53Remark"
                              value={formData.companyTypeF53Remark || ""}
                              onChange={handleInputChange}
                              placeholder="Write Here"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3">

                            <table className="table creat_tbl">
                              <tr>
                                <td>
                                  <label for="">
                                    Contract Labour License Number
                                  </label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    value={formData.contractLabLicNoF53 || ""}
                                    name="contractLabLicNoF53"
                                    id="contractLabLicNoF53"
                                    onChange={handleInputChange}
                                    placeholder="Contract Labour License Number"
                                  />
                                </td>
                                <td>
                                  <div>
                                    <div class="form-group files1">
                                      <input
                                        type="file"
                                        name="contractLabLicNoF53image"
                                        id="contractLabLicNoF53image"
                                        class="form-control"
                                        multiple=""
                                        accept="image/*,application/pdf"
                                        style={{
                                          height: "10px" 
                                        }}
                                        onChange={(e) =>
                                          handleFileChange(
                                            e,
                                            "contractLabLicNoF53image"
                                          )
                                        }

                                      />
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <label for="">Remark</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    nname="contractLabLicNoF53Remark"
                                    value={
                                      formData.contractLabLicNoF53Remark || ""
                                    }
                                    name="contractLabLicNoF53Remark"
                                    id="contractLabLicNoF53Remark"
                                    onChange={handleInputChange}
                                    placeholder="Write Here"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td colspan="3">
                                  <label for="">License Date</label>
                                  <input
                                    type="date"
                                    class="form-control"
                                    name="licenseDateF53"
                                    id="licenseDateF53"
                                    value={formData.licenseDateF53 || ""}
                                    onChange={handleInputChange}
                                    placeholder="License Date"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td colspan="3">
                                  <label for="">Date of Expiry</label>
                                  <input
                                    type="date"
                                    class="form-control"
                                    value={formData.expiryDateF53 || ""}
                                    name="expiryDateF53"
                                    id="expiryDateF53"
                                    onChange={handleInputChange}
                                    placeholder="Date of Expiry"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td colspan="3">
                                  <label for="">Due Date for Renewal</label>
                                  <input
                                    type="date"
                                    class="form-control"
                                    value={formData.renewalDateF53 || ""}
                                    name="renewalDateF53"
                                    id="renewalDateF53"
                                    onChange={handleInputChange}
                                    placeholder="Due Date for Renewal"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td colspan="3">
                                  <label for="">
                                    Number of Workers as per the License
                                  </label>
                                  <input
                                    type="number"
                                    class="form-control"
                                    value={formData.noOfWorkerF53 || ""}
                                    name="noOfWorkerF53"
                                    id="noOfWorkerF53"
                                    onChange={handleInputChange}
                                    placeholder="Number of Workers as per the License"
                                  />
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label for="">PAN</label>
                            <input
                              type="text"
                              class="form-control"
                              value={formData.panF53 || ""}
                              name="panF53"
                              id="panF53"
                              onChange={handleInputChange}
                              placeholder="PAN"
                            />
                          </td>
                          <td>
                            <div>
                              <div class="form-group files1">
                                <input
                                  type="file"
                                  name="panF53image"
                                  id="panF53image"
                                  class="form-control"
                                  multiple=""
                                  accept="image/*,application/pdf"
                                  style={{ height: "10px" }}
                                  onChange={(e) =>
                                    handleFileChange(e, "panF53image")
                                  }

                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <label for="">Remark</label>
                            <input
                              type="text"
                              class="form-control"
                              value={formData.panF53Remark || ""}
                              name="panF53Remark"
                              id="panF53Remark"
                              placeholder="Remark"
                              onChange={handleInputChange}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label for="">GST</label>
                            <input
                              type="text"
                              class="form-control"
                              value={formData.gstF53 || ""}
                              name="gstF53"
                              id="gstF53"
                              onChange={handleInputChange}
                              placeholder="GST"
                            />
                          </td>
                          <td>
                            <div>
                              <div class="form-group files1">
                                <input
                                  type="file"
                                  name="gstF53image"
                                  id="gstF53image"
                                  class="form-control"
                                  multiple=""
                                  accept="image/*,application/pdf"
                                  style={{ height: "10px" }}
                                  onChange={(e) =>
                                    handleFileChange(e, "gstF53image")
                                  }

                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <label for="">Remark</label>
                            <input
                              type="text"
                              class="form-control"
                              value={formData.gstF53Remark || ""}
                              name="gstF53Remark"
                              id="gstF53Remark"
                              onChange={handleInputChange}
                              placeholder="Write Here"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label for="">PF Registration</label>
                            <input
                              type="text"
                              class="form-control"
                              value={formData.pfRegContractorsE3 || ""}
                              name="pfRegContractorsE3"
                              id="pfRegContractorsE3"
                              onChange={handleInputChange}
                              placeholder="PF Registration"
                            />
                          </td>
                          <td>
                            <div>
                              <div class="form-group files1">
                                <input
                                  type="file"
                                  name="pfRegContractorsE3image"
                                  id="pfRegContractorsE3image"
                                  class="form-control"
                                  multiple=""
                                  accept="image/*,application/pdf"
                                  style={{ height: "10px" }}
                                  onChange={(e) =>
                                    handleFileChange(
                                      e,
                                      "pfRegContractorsE3image"
                                    )
                                  }

                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <label for="">Remark</label>
                            <input
                              type="text"
                              class="form-control"
                              value={formData.pfRegContractorsE3Remark || ""}
                              name="pfRegContractorsE3Remark"
                              id="pfRegContractorsE3Remark"
                              onChange={handleInputChange}
                              placeholder="Write Here"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label for="">ESIC Registration</label>
                            <input
                              type="text"
                              class="form-control"
                              value={formData.esicRegF53 || ""}
                              name="esicRegF53"
                              id="esicRegF53"
                              onChange={handleInputChange}
                              placeholder="ESIC Registration"
                            />
                          </td>
                          <td>
                            <div>
                              <div class="form-group files1">
                                <input
                                  type="file"
                                  name="esicRegF53image"
                                  id="esicRegF53image"
                                  class="form-control"
                                  multiple=""
                                  accept="image/*,application/pdf"
                                  style={{ height: "10px" }}
                                  onChange={(e) =>
                                    handleFileChange(e, "esicRegF53image")
                                  }

                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <label for="">Remark</label>
                            <input
                              type="text"
                              class="form-control"
                              value={formData.esicRegF53Remark || ""}
                              name="esicRegF53Remark"
                              id="esicRegF53Remark"
                              onChange={handleInputChange}
                              placeholder="Write Here"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label for="">Shops and Establishment</label>
                            <input
                              type="text"
                              class="form-control"
                              value={formData.shopsandEstContractorsE3 || ""}
                              name="shopsandEstContractorsE3"
                              id="shopsandEstContractorsE3"
                              onChange={handleInputChange}
                              placeholder="Shops and Establishment"
                            />
                          </td>
                          <td>
                            <div>
                              <div class="form-group files1">
                                <input
                                  type="file"
                                  name="shopsandEstContractorsE3image"
                                  id="shopsandEstContractorsE3image"
                                  class="form-control"
                                  multiple=""
                                  accept="image/*,application/pdf"
                                  style={{ height: "10px" }}
                                  onChange={(e) =>
                                    handleFileChange(
                                      e,
                                      "shopsandEstContractorsE3image"
                                    )
                                  }

                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <label for="">Remark</label>
                            <input
                              type="text"
                              class="form-control"
                              value={
                                formData.shopsandEstContractorsE3Remark || ""
                              }
                              name="shopsandEstContractorsE3Remark"
                              id="shopsandEstContractorsE3Remark"
                              onChange={handleInputChange}
                              placeholder="Write Here"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label for="">LWF Registration</label>
                            <input
                              type="text"
                              class="form-control"
                              value={formData.lwfRegContractorsE3 || ""}
                              name="lwfRegContractorsE3"
                              id="lwfRegContractorsE3"
                              onChange={handleInputChange}
                              placeholder="LWF Registration"
                            />
                          </td>
                          <td>
                            <div>
                              <div class="form-group files1">
                                <input
                                  type="file"
                                  name="lwfRegContractorsE3image"
                                  id="lwfRegContractorsE3image"
                                  class="form-control"
                                  multiple=""
                                  accept="image/*,application/pdf"
                                  style={{ height: "10px" }}
                                  onChange={(e) =>
                                    handleFileChange(
                                      e,
                                      "lwfRegContractorsE3image"
                                    )
                                  }

                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <label for="">Remark</label>
                            <input
                              type="text"
                              class="form-control"
                              value={formData.lwfRegContractorsE3Remark || ""}
                              name="lwfRegContractorsE3Remark"
                              id="lwfRegContractorsE3Remark"
                              onChange={handleInputChange}
                              placeholder="Write Here"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label for="">Professional Tax</label>
                            <input
                              type="text"
                              class="form-control"
                              value={formData.profTaxContractorsE3 || ""}
                              name="profTaxContractorsE3"
                              id="profTaxContractorsE3"
                              onChange={handleInputChange}
                              placeholder="Professional Tax"
                            />
                          </td>
                          <td>
                            <div>
                              <div class="form-group files1">
                                <input
                                  type="file"
                                  name="profTaxContractorsE3image"
                                  id="profTaxContractorsE3image"
                                  class="form-control"
                                  multiple=""
                                  accept="image/*,application/pdf"
                                  style={{ height: "10px" }}
                                  onChange={(e) =>
                                    handleFileChange(
                                      e,
                                      "profTaxContractorsE3image"
                                    )
                                  }

                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <label for="">Remark</label>
                            <input
                              type="text"
                              class="form-control"
                              value={formData.profTaxContractorsE3Remark || ""}
                              name="profTaxContractorsE3Remark"
                              id="profTaxContractorsE3Remark"
                              onChange={handleInputChange}
                              placeholder="Write Here"
                            />
                          </td>
                        </tr>
                      </table>
                    </div>
                  )} */}
                </div>
                <h4>Night Shift Permission</h4>

                <table className="table  creat_tbl">
                  <tr>
                    <td colSpan="3">
                      <label>Is Night Shift Permission Granted? (Yes/No)</label>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3" style={{ width: "100%" }}>
                      <button
                        type="button"
                        style={{
                          backgroundColor: "rgb(206, 233, 242)",
                          width: "20%",
                        }}
                        className={`btn ${isNSPEngaged === "YES"
                          ? "btn-primary text-white"
                          : "btn-light"
                          }`}
                        onClick={() => handleSelectionChangeNSP("YES")}
                      >
                        YES
                      </button>
                      <button
                        type="button"
                        style={{
                          backgroundColor: "rgb(206, 233, 242)",
                          width: "20%",
                        }}
                        className={`btn ${isNSPEngaged === "NO"
                          ? "btn-primary text-white"
                          : "btn-light"
                          }`}
                        onClick={() => handleSelectionChangeNSP("NO")}
                      >
                        NO
                      </button>
                    </td>
                  </tr>
                </table>
                {isNSPEngaged === "YES" && (
                  <table className="table creat_tbl">
                    <tr>
                      <td>
                        <label className="form-label">License Number</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Licence Number"
                          value={formData?.licensenumberNSP || ""}
                          id="licensenumberNSP"
                          name="licensenumberNSP"
                          onChange={handleInputChange}
                        />
                      </td>
                      <td colspan="5">
                        <div>
                          <label htmlFor="licenseimageNSP">
                            Upload License
                          </label>

                          <div class="form-group files1">
                            <input
                              type="file"
                              class="form-control"
                              multiple=""
                              accept="image/*,application/pdf"
                              style={{ height: "10px" }}
                              id="licenseimageNSP"
                              name="licenseimageNSP"
                              onChange={(e) =>
                                handleFileChange(e, "licenseimageNSP")
                              }
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <label for="">Remark</label>
                        <input
                          type="text"
                          class="form-control"
                          id="licenseremarkNSP"
                          name="licenseremarkNSP"
                          value={formData?.licenseremarkNSP || ""}
                          onChange={handleInputChange}
                          placeholder="Write Here"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colspan="">
                        <label for="" className="form-label">License Status</label>
                        <select
                          className="form-select"
                          aria-label="Status select example"
                          name="lis_statusNSP"
                          id="lis_statusNSP"
                          value={formData.lis_statusNSP || 0}
                          onChange={handleInputChange}
                          required
                        >
                          <option value={0}>Select License Status</option>
                          <option value={1}>Not In Scope</option>
                          <option value={2}>License Applied</option>
                          <option value={3}>License Not-Applied</option>
                          <option value={4}>Life-Time License</option>
                        </select>
                      </td>
                      {formData.lis_statusNSP === "2" && (
                        <td colspan="2">
                          <>
                            <label htmlFor="issuingauthimageNSP">
                              Upload Acknowledgement
                            </label>

                            <div className="form-group files1">
                              <input
                                type="file"
                                multiple=""
                                accept="image/*,application/pdf"
                                className="form-control"
                                style={{ height: "10px" }}
                                onChange={(e) =>
                                  handleFileChange(e, "issuingauthimageNSP")
                                }
                                id="issuingauthimageNSP"
                                name="issuingauthimageNSP"
                              />
                            </div>
                          </>
                        </td>
                      )}
                      {formData.lis_statusNSP === "3" && (
                        <td>
                          <label className="form-label">Remarks</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Remark"
                            value={formData?.issuingauthremarkNSP || ""}
                            onChange={handleInputChange}
                            id="issuingauthremarkNSP"
                            name="issuingauthremarkNSP"
                          />
                        </td>
                      )}
                    </tr>
                    <tr>
                      <td colspan="8">
                        <label className="form-label">
                          Date of Registration
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          placeholder="Date of Registration"
                          value={formData?.dorNSP || ""}
                          onChange={handleInputChange}
                          id="dorNSP"
                          name="dorNSP"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <label className="form-label">Date of Expiry</label>
                        <input
                          type="date"
                          className="form-control"
                          placeholder="Date of Expiry"
                          value={formData?.doeNSP || ""}
                          onChange={handleInputChange}
                          id="doeNSP"
                          name="doeNSP"
                          disabled={formData.lis_statusNSP === "4"}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colspan="8">
                        <label className="form-label">
                          Due Date of Renewal
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          placeholder="Due Date of Renewal"
                          value={formData?.doddrNSP || ""}
                          onChange={handleInputChange}
                          id="doddrNSP"
                          name="doddrNSP"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <label className="form-label">Issuing Authority</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Issuing Authority"
                          value={formData?.issuingauthNSP || ""}
                          onChange={handleInputChange}
                          name="issuingauthNSP"
                          id="issuingauthNSP"
                        />
                      </td>
                    </tr>
                  </table>
                )}

                {/* OT Permission */}
                <h4>OT Permission</h4>
                <table className="table creat_tbl">
                  <tr>
                    <td colSpan="3">
                      <label>Is OT Permission Granted? (Yes/No)</label>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3" style={{ width: "100%" }}>
                      <button
                        type="button"
                        style={{
                          backgroundColor: "rgb(206, 233, 242)",
                          width: "20%",
                        }}
                        className={`btn ${isOTPEngaged === "YES"
                          ? "btn-primary text-white"
                          : "btn-light"
                          }`}
                        onClick={() => handleSelectionChangeOTP("YES")}
                      >
                        YES
                      </button>
                      <button
                        type="button"
                        style={{
                          backgroundColor: "rgb(206, 233, 242)",
                          width: "20%",
                        }}
                        className={`btn ${isOTPEngaged === "NO"
                          ? "btn-primary text-white"
                          : "btn-light"
                          }`}
                        onClick={() => handleSelectionChangeOTP("NO")}
                      >
                        NO
                      </button>
                    </td>
                  </tr>
                </table>
                {isOTPEngaged === "YES" && (
                  <table className="table creat_tbl">
                    <tr>
                      <td>
                        <label className="form-label">License Number</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Licence Number"
                          value={formData?.licensenumberOTP || ""}
                          id="licensenumberOTP"
                          name="licensenumberOTP"
                          onChange={handleInputChange}
                        />
                      </td>
                      <td colSpan="5">
                        <>
                          <label className="licenseimageOTP">
                            Upload License
                          </label>
                          <div className="form-group files1">
                            <input
                              type="file"
                              className="form-control"
                              multiple=""
                              accept="image/*,application/pdf"
                              style={{ height: "10px" }}
                              id="licenseimageOTP"
                              name="licenseimageOTP"
                              onChange={(e) =>
                                handleFileChange(e, "licenseimageOTP")
                              }
                            />
                          </div>
                        </>
                      </td>
                      <td>
                        <label>Remark</label>
                        <input
                          type="text"
                          className="form-control"
                          id="licenseremarkOTP"
                          name="licenseremarkOTP"
                          value={formData?.licenseremarkOTP || ""}
                          onChange={handleInputChange}
                          placeholder="Write Here"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colspan="">
                        <label for="" className="form-label">License Status</label>
                        <select
                          className="form-select"
                          aria-label="Status select example"
                          name="lis_statusOTP"
                          id="lis_statusOTP"
                          value={formData.lis_statusOTP || 0}
                          onChange={handleInputChange}
                          required
                        >
                          <option value={0}>Select License Status</option>
                          <option value={1}>Not In Scope</option>
                          <option value={2}>License Applied</option>
                          <option value={3}>License Not-Applied</option>
                          <option value={4}>Life-Time License</option>

                        </select>
                      </td>
                      {formData.lis_statusOTP === "2" && (
                        <td colSpan="2">
                          <>
                            <label htmlFor="issuingauthimageOTP">
                              Upload Acknowledgement
                            </label>

                            <div className="form-group files1">
                              <input
                                type="file"
                                multiple=""
                                accept="image/*,application/pdf"
                                className="form-control"
                                style={{ height: "10px" }}
                                onChange={(e) =>
                                  handleFileChange(e, "issuingauthimageOTP")
                                }
                                id="issuingauthimageOTP"
                                name="issuingauthimageOTP"
                              />
                            </div>
                          </>
                        </td>
                      )}
                      {formData.lis_statusOTP === "3" && (
                        <td colSpan="3">
                          <label className="form-label">
                            Reason For Not Applied?
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Reason"
                            value={formData?.issuingauthremarkOTP || ""}
                            onChange={handleInputChange}
                            id="issuingauthremarkOTP"
                            name="issuingauthremarkOTP"
                          />
                        </td>
                      )}
                    </tr>

                    {/* Other fields like Date of Registration, Expiry, Renewal */}
                    <tr>
                      <td colSpan="8">
                        <label className="form-label">
                          Date of Registration
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          value={formData?.dorOTP || ""}
                          onChange={handleInputChange}
                          id="dorOTP"
                          name="dorOTP"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <label className="form-label">Date of Expiry</label>
                        <input
                          type="date"
                          className="form-control"
                          value={formData?.doeOTP || ""}
                          onChange={handleInputChange}
                          id="doeOTP"
                          name="doeOTP"
                          disabled={formData.lis_statusOTP === "4"}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="8">
                        <label className="form-label">
                          Due Date of Renewal
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          value={formData?.doddrOTP || ""}
                          onChange={handleInputChange}
                          id="doddrOTP"
                          name="doddrOTP"
                        />
                      </td>
                    </tr>
                    {/* Issuing Authority */}
                    <tr>
                      <td colSpan="2">
                        <label className="form-label">Issuing Authority</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Issuing Authority"
                          value={formData?.issuingauthOTP || ""}
                          onChange={handleInputChange}
                          name="issuingauthOTP"
                          id="issuingauthOTP"
                        />
                      </td>
                    </tr>
                  </table>
                )}

                {/* Weekly Off Exemption */}
                <h4>Weekly Off Exemption</h4>
                <table className="table creat_tbl">
                  <tr>
                    <td colSpan="3">
                      <label>Is Weekly Off Exemption Granted? (Yes/No)</label>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3" style={{ width: "100%" }}>
                      <button
                        type="button"
                        style={{
                          backgroundColor: "rgb(206, 233, 242)",
                          width: "20%",
                        }}
                        className={`btn ${isWOEEngaged === "YES"
                          ? "btn-primary text-white"
                          : "btn-light"
                          }`}
                        onClick={() => handleSelectionChangeWOE("YES")}
                      >
                        YES
                      </button>
                      <button
                        type="button"
                        style={{
                          backgroundColor: "rgb(206, 233, 242)",
                          width: "20%",
                        }}
                        className={`btn ${isWOEEngaged === "NO"
                          ? "btn-primary text-white"
                          : "btn-light"
                          }`}
                        onClick={() => handleSelectionChangeWOE("NO")}
                      >
                        NO
                      </button>
                    </td>
                  </tr>
                </table>
                {isWOEEngaged === "YES" && (
                  <table className="table creat_tbl">
                    <tr>
                      <td>
                        <label className="form-label">License Number</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Licence Number"
                          value={formData?.licensenumberWOE || ""}
                          id="licensenumberWOE"
                          name="licensenumberWOE"
                          onChange={handleInputChange}
                        />
                      </td>
                      <td colSpan="5">
                        <>
                          <label className="licenseimageWOE">
                            Upload License
                          </label>
                          <div className="form-group files1">
                            <input
                              type="file"
                              className="form-control"
                              multiple=""
                              accept="image/*,application/pdf"
                              style={{ height: "10px" }}
                              id="licenseimageWOE"
                              name="licenseimageWOE"
                              onChange={(e) =>
                                handleFileChange(e, "licenseimageWOE")
                              }
                            />
                          </div>
                        </>
                      </td>
                      <td>
                        <label>Remark</label>
                        <input
                          type="text"
                          className="form-control"
                          id="licenseremarkWOE"
                          name="licenseremarkWOE"
                          value={formData?.licenseremarkWOE || ""}
                          onChange={handleInputChange}
                          placeholder="Write Here"
                        />
                      </td>
                    </tr>
                    {/* Other fields like Date of Registration, Expiry, Renewal */}
                    <tr>
                      <td colspan="2">
                        <label for="" className="form-label">License Status</label>
                        <select
                          className="form-select"
                          aria-label="Status select example"
                          name="lis_statusWOE"
                          id="lis_statusWOE"
                          value={formData.lis_statusWOE || 0}
                          onChange={handleInputChange}
                          required
                        >
                          <option value={0}>Select License Status</option>
                          <option value={1}>Not In Scope</option>
                          <option value={2}>License Applied</option>
                          <option value={3}>License Not-Applied</option>
                          <option value={4}>Life-Time License</option>
                        </select>
                      </td>
                      {formData.lis_statusWOE === "2" && (
                        <td colSpan="2">
                          <>
                            <label htmlFor="issuingauthimageWOE">
                              Upload Acknowledgement
                            </label>{" "}
                            <div className="form-group files1">
                              <input
                                type="file"
                                multiple=""
                                accept="image/*,application/pdf"
                                className="form-control"
                                style={{ height: "10px" }}
                                onChange={(e) =>
                                  handleFileChange(e, "issuingauthimageWOE")
                                }
                                id="issuingauthimageWOE"
                                name="issuingauthimageWOE"
                              />
                            </div>
                          </>
                        </td>
                      )}
                      {formData.lis_statusWOE === "3" && (
                        <td>
                          <label className="form-label">Remarks</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Remark"
                            value={formData?.issuingauthremarkWOE || ""}
                            onChange={handleInputChange}
                            id="issuingauthremarkWOE"
                            name="issuingauthremarkWOE"
                          />
                        </td>
                      )}
                    </tr>
                    <tr>
                      <td colSpan="8">
                        <label className="form-label">
                          Date of Registration
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          value={formData?.dorWOE || ""}
                          onChange={handleInputChange}
                          id="dorWOE"
                          name="dorWOE"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <label className="form-label">Date of Expiry</label>
                        <input
                          type="date"
                          className="form-control"
                          value={formData?.doeWOE || ""}
                          onChange={handleInputChange}
                          id="doeWOE"
                          name="doeWOE"
                          disabled={formData.lis_statusWOE === "4"}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="8">
                        <label className="form-label">
                          Due Date of Renewal
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          value={formData?.doddrWOE || ""}
                          onChange={handleInputChange}
                          id="doddrWOE"
                          name="doddrWOE"
                        />
                      </td>
                    </tr>
                    {/* Issuing Authority */}
                    <tr>
                      <td colSpan="2">
                        <label className="form-label">Issuing Authority</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Issuing Authority"
                          value={formData?.issuingauthWOE || ""}
                          onChange={handleInputChange}
                          name="issuingauthWOE"
                          id="issuingauthWOE"
                        />
                      </td>
                    </tr>
                  </table>
                )}

                <h4>Trade License</h4>
                <table className="table creat_tbl">
                  <tr>
                    <td colSpan="3">
                      <label>Is Trade License Available? (Yes/No)</label>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3" style={{ width: "100%" }}>
                      <button
                        type="button"
                        style={{
                          backgroundColor: "rgb(206, 233, 242)",
                          width: "20%",
                        }}
                        className={`btn ${isTLEngaged === "YES"
                          ? "btn-primary text-white"
                          : "btn-light"
                          }`}
                        onClick={() => handleSelectionChangeTL("YES")}
                      >
                        YES
                      </button>
                      <button
                        type="button"
                        style={{
                          backgroundColor: "rgb(206, 233, 242)",
                          width: "20%",
                        }}
                        className={`btn ${isTLEngaged === "NO"
                          ? "btn-primary text-white"
                          : "btn-light"
                          }`}
                        onClick={() => handleSelectionChangeTL("NO")}
                      >
                        NO
                      </button>
                    </td>
                  </tr>
                </table>
                {isTLEngaged === "YES" && (
                  <table className="table creat_tbl">
                    <tr>
                      <td>
                        <label className="form-label">License Number</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Licence Number"
                          value={formData?.licensenumberTL || ""}
                          id="licensenumberTL"
                          name="licensenumberTL"
                          onChange={handleInputChange}
                        />
                      </td>
                      <td colSpan="5">
                        <>
                          <label className="licenseimageTL">
                            Upload License
                          </label>

                          <div className="form-group files1">
                            <input
                              type="file"
                              className="form-control"
                              multiple=""
                              accept="image/*,application/pdf"
                              style={{ height: "10px" }}
                              id="licenseimageTL"
                              name="licenseimageTL"
                              onChange={(e) =>
                                handleFileChange(e, "licenseimageTL")
                              }
                            />
                          </div>
                        </>
                      </td>
                      <td>
                        <label>Remark</label>
                        <input
                          type="text"
                          className="form-control"
                          id="licenseremarkTL"
                          name="licenseremarkTL"
                          value={formData?.licenseremarkTL || ""}
                          onChange={handleInputChange}
                          placeholder="Write Here"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <label for="" className="form-label">License Status</label>
                        <select
                          className="form-select"
                          aria-label="Status select example"
                          name="lis_statusTL"
                          id="lis_statusTL"
                          value={formData.lis_statusTL || 0}
                          onChange={handleInputChange}
                          required
                        >
                          <option value={0}>Select License Status</option>
                          <option value={1}>Not In Scope</option>
                          <option value={2}>License Applied</option>
                          <option value={3}>License Not-Applied</option>
                          <option value={4}>Life-Time License</option>

                        </select>
                      </td>
                      {formData.lis_statusTL === "2" && (
                        <td colSpan="2">
                          <>
                            <label htmlFor="issuingauthimageTL">
                              Upload Acknowledgement
                            </label>
                            <div className="form-group files1">
                              <input
                                type="file"
                                multiple=""
                                accept="image/*,application/pdf"
                                className="form-control"
                                style={{ height: "10px" }}
                                onChange={(e) =>
                                  handleFileChange(e, "issuingauthimageTL")
                                }
                                id="issuingauthimageTL"
                                name="issuingauthimageTL"
                              />
                            </div>
                          </>
                        </td>
                      )}
                      {formData.lis_statusTL === "3" && (
                        <td colSpan="5">
                          <label className="form-label">
                            Reason For Not Applied?
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Reason"
                            value={formData?.issuingauthremarkTL || ""}
                            onChange={handleInputChange}
                            id="issuingauthremarkTL"
                            name="issuingauthremarkTL"
                          />
                        </td>
                      )}
                    </tr>
                    <tr>
                      <td colSpan="8">
                        <label className="form-label">
                          Date of Registration
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          placeholder="Date of Registration"
                          value={formData?.dorTL || ""}
                          onChange={handleInputChange}
                          id="dorTL"
                          name="dorTL"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <label className="form-label">Date of Expiry</label>
                        <input
                          type="date"
                          className="form-control"
                          placeholder="Date of Expiry"
                          value={formData?.doeTL || ""}
                          onChange={handleInputChange}
                          id="doeTL"
                          name="doeTL"
                          disabled={formData.lis_statusTL === "4"}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="8">
                        <label className="form-label">
                          Due Date of Renewal
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          placeholder="Due Date of Renewal"
                          value={formData?.doddrTL || ""}
                          onChange={handleInputChange}
                          id="doddrTL"
                          name="doddrTL"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <label className="form-label">Issuing Authority</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Issuing Authority"
                          value={formData?.issuingauthTL || ""}
                          onChange={handleInputChange}
                          name="issuingauthTL"
                          id="issuingauthTL"
                        />
                      </td>
                    </tr>
                  </table>
                )}
                <div>
                  <h4>P F</h4>
                  <table className="table creat_tbl">
                    <tr>
                      <td colSpan="3">
                        <label>Is PF Available? (Yes/No)</label>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3" style={{ width: "100%" }}>
                        <button
                          type="button"
                          style={{
                            backgroundColor: "rgb(206, 233, 242)",
                            width: "20%",
                          }}
                          className={`btn ${isPFEngaged === "YES"
                            ? "btn-primary text-white"
                            : "btn-light"
                            }`}
                          onClick={() => handleSelectionChangePF("YES")}
                        >
                          YES
                        </button>
                        <button
                          type="button"
                          style={{
                            backgroundColor: "rgb(206, 233, 242)",
                            width: "20%",
                          }}
                          className={`btn ${isPFEngaged === "NO"
                            ? "btn-primary text-white"
                            : "btn-light"
                            }`}
                          onClick={() => handleSelectionChangePF("NO")}
                        >
                          NO
                        </button>
                      </td>
                    </tr>
                  </table>
                  {isPFEngaged === "YES" && (
                    <table className="table  creat_tbl">
                      <tr>
                        <td>
                          <label for="" className="form-label">Regsitration Number</label>
                          <input type="text" class="form-control" name="pfnumber" id="pfnumber"
                            value={formData.pfnumber}
                            onChange={handleInputChange} placeholder="Registration Number" required />
                        </td>
                        <td>
                          <div>
                            <div class="form-group files1">
                              <input type="file" name="pfimage" class="form-control" multiple="" accept="image/*,application/pdf" id="pfimage" style={{ height: '10px' }}

                                onChange={(e) =>
                                  handleFileChange(e, "pfimage")
                                } />
                            </div>
                          </div>
                        </td>
                        <td>
                          <label for="" className="form-label">Remark</label>
                          <input type="text" class="form-control" name="pfdremark" id="pfdremark" value={formData.pfdremark}
                            onChange={handleInputChange} placeholder="Write Here" />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="">
                          <label for="" className="form-label">License Status</label>
                          <select
                            className="form-select"
                            aria-label="Status select example"
                            name="lis_status_pf"
                            id="lis_status_pf"
                            value={formData.lis_status_pf || 0}
                            onChange={handleInputChange}
                            required
                          >
                            <option value={0}>Select License Status</option>
                            <option value={1}>Not In Scope</option>
                            <option value={2}>License Applied</option>
                            <option value={3}>License Not-Applied</option>
                            <option value={4}>Life-Time License</option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="3">
                          <label for="">Date of Coverage</label>
                          <input type="date" class="form-control" name="doc" id="doc"
                            value={formData.doc}
                            onChange={handleInputChange}
                            placeholder="Date of Agreement and validity" required />
                        </td>
                      </tr>
                      <tr >

                        <td>
                          <label for="">Office Address as per Registration</label>

                          <table>
                            <tr>
                              <td>
                                <input type="text" class="form-control" placeholder="Address" name="pfaddress" value={formData.pfaddress} id="pfaddress" onChange={handleInputChange} required />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <select
                                  className="form-select"
                                  aria-label="Default select example"
                                  name="pfstate"
                                  id="pfstate"
                                  value={formData.pfstate || ""}
                                  onChange={handleInputChange}
                                  // ref={inputRefs.inputRef62}
                                  required
                                >
                                  <option value="">Select State</option>
                                  {stateInfo != "undefined" &&
                                    stateInfo?.length > 0 &&
                                    stateInfo.map((item) => (
                                      <option value={item._id}>
                                        {item.name}
                                      </option>
                                    ))}
                                </select>
                              </td>
                            </tr>
                            <tr>
                              <td><input type="text" class="form-control" placeholder="District" name="pfdistrict" id="pfdistrict" value={formData.pfdistrict} onChange={handleInputChange} required />
                              </td>
                            </tr>
                            <tr>
                              <td><input type="number" class="form-control" placeholder="PIN" name="pfpin" id="pfpin" value={formData.pfpin} onChange={handleInputChange} required />
                              </td>
                            </tr>
                          </table>
                        </td>

                        <td>
                          <label class="form-label">Remark</label>
                          <input type="text" class="form-control" placeholder="Write here" name="pfaddressremark" id="pfaddressremark" value={formData.pfaddressremark}
                            onChange={handleInputChange} />
                        </td>
                      </tr>


                    </table>
                  )}
                </div>
                <div>
                  <h4>E S I</h4>
                  <table className="table creat_tbl">
                    <tr>
                      <td colSpan="3">
                        <label>Is ESI Available? (Yes/No)</label>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3" style={{ width: "100%" }}>
                        <button
                          type="button"
                          style={{
                            backgroundColor: "rgb(206, 233, 242)",
                            width: "20%",
                          }}
                          className={`btn ${isESIEngaged === "YES"
                            ? "btn-primary text-white"
                            : "btn-light"
                            }`}
                          onClick={() => handleSelectionChangeESI("YES")}
                        >
                          YES
                        </button>
                        <button
                          type="button"
                          style={{
                            backgroundColor: "rgb(206, 233, 242)",
                            width: "20%",
                          }}
                          className={`btn ${isESIEngaged === "NO"
                            ? "btn-primary text-white"
                            : "btn-light"
                            }`}
                          onClick={() => handleSelectionChangeESI("NO")}
                        >
                          NO
                        </button>
                      </td>
                    </tr>
                  </table>
                  {isESIEngaged === "YES" && (
                    <table className="table  creat_tbl">
                      <tr>
                        <td>
                          <label for="" className="form-label">Regsitration Number</label>
                          <input type="text" class="form-control" name="esinumber" id="esinumber"
                            value={formData.esinumber}
                            onChange={handleInputChange} placeholder="Registration Number" required />
                        </td>
                        <td>
                          <div>
                            <div class="form-group files1">
                              <input type="file" name="esiimage" class="form-control" multiple="" accept="image/*,application/pdf" id="esiimage" style={{ height: '10px' }}

                                onChange={(e) =>
                                  handleFileChange(e, "esiimage")
                                } />
                            </div>
                          </div>
                        </td>
                        <td>
                          <label for="" className="form-label">Remark</label>
                          <input type="text" class="form-control" name="esidremark" id="esidremark" value={formData.esidremark}
                            onChange={handleInputChange} placeholder="Write Here" />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="">
                          <label for="" className="form-label">License Status</label>
                          <select
                            className="form-select"
                            aria-label="Status select example"
                            name="lis_status_esi"
                            id="lis_status_esi"
                            value={formData.lis_status_esi || 0}
                            onChange={handleInputChange}
                            required
                          >
                            <option value={0}>Select License Status</option>
                            <option value={1}>Not In Scope</option>
                            <option value={2}>License Applied</option>
                            <option value={3}>License Not-Applied</option>
                            <option value={4}>Life-Time License</option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="3">
                          <label for="">Date of Coverage</label>
                          <input type="date" class="form-control" name="esidoc" id="esidoc"
                            value={formData.esidoc}
                            onChange={handleInputChange}
                            placeholder="Date of Agreement and validity" required />
                        </td>
                      </tr>
                      <tr >

                        <td>
                          <label for="">Office Address as per Registration</label>

                          <table>
                            <tr>
                              <td>
                                <input type="text" class="form-control" placeholder="Address" name="esiaddress" value={formData.esiaddress} id="esiaddress" onChange={handleInputChange} required />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <select className="form-select" aria-label="Default select example" id="esistate" name="esistate" value={formData.esistate} onChange={handleInputChange} required>
                                  <option value="">Select State</option>
                                  {stateInfo != 'undefind' && stateInfo?.length > 0 && stateInfo.map(item =>
                                    <option value={item._id}>{item.name}</option>
                                  )};
                                </select>
                              </td>
                            </tr>
                            <tr>
                              <td><input type="text" class="form-control" placeholder="District" name="esidistrict" id="esidistrict" value={formData.esidistrict} onChange={handleInputChange} required />
                              </td>
                            </tr>
                            <tr>
                              <td><input type="number" class="form-control" placeholder="PIN" name="esipin" id="esipin" value={formData.esipin} onChange={handleInputChange} required />
                              </td>
                            </tr>
                          </table>
                        </td>

                        <td>
                          <label class="form-label">Remark</label>
                          <input type="text" class="form-control" placeholder="Write here" name="esiaddressremark" id="esiaddressremark" value={formData.esiaddressremark}
                            onChange={handleInputChange} />
                        </td>
                      </tr>


                    </table>
                  )}
                </div>
                <div>
                  <h4>LWF Registration</h4>
                  <table className="table creat_tbl">
                    <tr>
                      <td colSpan="3">
                        <label>Is LWF Available? (Yes/No)</label>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3" style={{ width: "100%" }}>
                        <button
                          type="button"
                          style={{
                            backgroundColor: "rgb(206, 233, 242)",
                            width: "20%",
                          }}
                          className={`btn ${isLWFEngaged === "YES"
                            ? "btn-primary text-white"
                            : "btn-light"
                            }`}
                          onClick={() => handleSelectionChangeLWF("YES")}
                        >
                          YES
                        </button>
                        <button
                          type="button"
                          style={{
                            backgroundColor: "rgb(206, 233, 242)",
                            width: "20%",
                          }}
                          className={`btn ${isLWFEngaged === "NO"
                            ? "btn-primary text-white"
                            : "btn-light"
                            }`}
                          onClick={() => handleSelectionChangeLWF("NO")}
                        >
                          NO
                        </button>
                      </td>
                    </tr>
                  </table>
                  {isLWFEngaged === "YES" && (
                    <table className="table  creat_tbl">
                      <tr>
                        <td>
                          <label for="" className="form-label">Regsitration Number</label>
                          <input type="text" class="form-control" name="registrationlwfD3" id="registrationlwfD3"
                            value={formData.registrationlwfD3}
                            onChange={handleInputChange} placeholder="Registration Number" required />
                        </td>
                        <td>
                          <div>
                            <div class="form-group files1">
                              <input type="file" name="registrationlwfD3image" class="form-control" multiple="" accept="image/*,application/pdf" id="registrationlwfD3image" style={{ height: '10px' }}

                                onChange={(e) =>
                                  handleFileChange(e, "registrationlwfD3image")
                                } />
                            </div>
                          </div>
                        </td>
                        <td>
                          <label for="" className="form-label">Remark</label>
                          <input type="text" class="form-control" name="registrationlwfD3remark" id="registrationlwfD3remark" value={formData.registrationlwfD3remark}
                            onChange={handleInputChange} placeholder="Write Here" />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="">
                          <label for="" className="form-label">License Status</label>
                          <select
                            className="form-select"
                            aria-label="Status select example"
                            name="lis_status_lwf"
                            id="lis_status_lwf"
                            value={formData.lis_status_lwf || 0}
                            onChange={handleInputChange}
                            required
                          >
                            <option value={0}>Select License Status</option>
                            <option value={1}>Not In Scope</option>
                            <option value={2}>License Applied</option>
                            <option value={3}>License Not-Applied</option>
                            <option value={4}>Life-Time License</option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="3">
                          <label for="">Date of Coverage</label>
                          <input type="date" class="form-control" name="doregistrationlwfD3" id="doregistrationlwfD3"
                            value={formData.doregistrationlwfD3}
                            onChange={handleInputChange}
                            placeholder="Date of Registration" />
                        </td>
                      </tr>
                    </table>
                  )}
                </div>
                <div>
                  <h4>Professtional Tax Registration</h4>
                  <table className="table creat_tbl">
                    <tr>
                      <td colSpan="3">
                        <label>Is PTR Available? (Yes/No)</label>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3" style={{ width: "100%" }}>
                        <button
                          type="button"
                          style={{
                            backgroundColor: "rgb(206, 233, 242)",
                            width: "20%",
                          }}
                          className={`btn ${isPTREngaged === "YES"
                            ? "btn-primary text-white"
                            : "btn-light"
                            }`}
                          onClick={() => handleSelectionChangePTR("YES")}
                        >
                          YES
                        </button>
                        <button
                          type="button"
                          style={{
                            backgroundColor: "rgb(206, 233, 242)",
                            width: "20%",
                          }}
                          className={`btn ${isPTREngaged === "NO"
                            ? "btn-primary text-white"
                            : "btn-light"
                            }`}
                          onClick={() => handleSelectionChangePTR("NO")}
                        >
                          NO
                        </button>
                      </td>
                    </tr>
                  </table>
                  {isPTREngaged === "YES" && (
                    <table className="table  creat_tbl">
                      <tr>
                        <td>
                          <label for="" className="form-label">Regsitration Number</label>
                          <input type="text" class="form-control" name="registrationptrD3" id="registrationptrD3"
                            value={formData.registrationptrD3}
                            onChange={handleInputChange} placeholder="Registration Number" required />
                        </td>
                        <td>
                          <div>
                            <div class="form-group files1">
                              <input type="file" name="registrationptrD3image" class="form-control" multiple="" accept="image/*,application/pdf" id="registrationptrD3image" style={{ height: '10px' }}

                                onChange={(e) =>
                                  handleFileChange(e, "registrationptrD3image")
                                } />
                            </div>
                          </div>
                        </td>
                        <td>
                          <label for="" className="form-label">Remark</label>
                          <input type="text" class="form-control" name="registrationptrD3remark" id="registrationptrD3remark" value={formData.registrationptrD3remark}
                            onChange={handleInputChange} placeholder="Write Here" />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="">
                          <label for="" className="form-label">License Status</label>
                          <select
                            className="form-select"
                            aria-label="Status select example"
                            name="lis_status_ptr"
                            id="lis_status_ptr"
                            value={formData.lis_status_ptr || 0}
                            onChange={handleInputChange}
                            required
                          >
                            <option value={0}>Select License Status</option>
                            <option value={1}>Not In Scope</option>
                            <option value={2}>License Applied</option>
                            <option value={3}>License Not-Applied</option>
                            <option value={4}>Life-Time License</option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="3">
                          <label for="">Date of Registration</label>
                          <input type="date" class="form-control" name="doregistrationptrD3" id="doregistrationptrD3"
                            value={formData.doregistrationptrD3}
                            onChange={handleInputChange}
                            placeholder="Date of Registration" />
                        </td>
                      </tr>
                    </table>
                  )}
                </div>
                <div>
                  <h4>Professional Tax Enrollment</h4>
                  <table className="table creat_tbl">
                    <tr>
                      <td colSpan="3">
                        <label>Is PTE Available? (Yes/No)</label>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3" style={{ width: "100%" }}>
                        <button
                          type="button"
                          style={{
                            backgroundColor: "rgb(206, 233, 242)",
                            width: "20%",
                          }}
                          className={`btn ${isPTEEngaged === "YES"
                            ? "btn-primary text-white"
                            : "btn-light"
                            }`}
                          onClick={() => handleSelectionChangePTE("YES")}
                        >
                          YES
                        </button>
                        <button
                          type="button"
                          style={{
                            backgroundColor: "rgb(206, 233, 242)",
                            width: "20%",
                          }}
                          className={`btn ${isPTEEngaged === "NO"
                            ? "btn-primary text-white"
                            : "btn-light"
                            }`}
                          onClick={() => handleSelectionChangePTE("NO")}
                        >
                          NO
                        </button>
                      </td>
                    </tr>
                  </table>
                  {isPTEEngaged === "YES" && (
                    <table className="table  creat_tbl">
                      <tr>
                        <td>
                          <label for="" className="form-label">Regsitration Number</label>
                          <input type="text" class="form-control" name="registrationPTED3" id="registrationPTED3"
                            value={formData.registrationPTED3}
                            onChange={handleInputChange} placeholder="Registration Number" required />
                        </td>
                        <td>
                          <div>
                            <div class="form-group files1">
                              <input type="file" name="registrationPTED3image" class="form-control" multiple="" accept="image/*,application/pdf" id="registrationPTED3image" style={{ height: '10px' }}

                                onChange={(e) =>
                                  handleFileChange(e, "registrationPTED3image")
                                } />
                            </div>
                          </div>
                        </td>
                        <td>
                          <label for="" className="form-label">Remark</label>
                          <input type="text" class="form-control" name="registrationPTED3remark" id="registrationPTED3remark" value={formData.registrationPTED3remark}
                            onChange={handleInputChange} placeholder="Write Here" />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="">
                          <label for="" className="form-label">License Status</label>
                          <select
                            className="form-select"
                            aria-label="Status select example"
                            name="lis_status_pte"
                            id="lis_status_pte"
                            value={formData.lis_status_pte || 0}
                            onChange={handleInputChange}
                            required
                          >
                            <option value={0}>Select License Status</option>
                            <option value={1}>Not In Scope</option>
                            <option value={2}>License Applied</option>
                            <option value={3}>License Not-Applied</option>
                            <option value={4}>Life-Time License</option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="3">
                          <label for="">Date of Enrollment</label>
                          <input type="date" class="form-control" name="doregistrationPTED3" id="doregistrationPTED3"
                            value={formData.doregistrationPTED3}
                            onChange={handleInputChange}
                            placeholder="Date of Registration" />
                        </td>
                      </tr>
                    </table>
                  )}
                </div>
                <div>
                  <h4>MSME License (Micro, Small and Medium Enterprises)</h4>
                  <table className="table creat_tbl">
                    <tr>
                      <td colSpan="3">
                        <label>Is MSME Available? (Yes/No)</label>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3" style={{ width: "100%" }}>
                        <button
                          type="button"
                          style={{
                            backgroundColor: "rgb(206, 233, 242)",
                            width: "20%",
                          }}
                          className={`btn ${isMSMEEngaged === "YES"
                            ? "btn-primary text-white"
                            : "btn-light"
                            }`}
                          onClick={() => handleSelectionChangeMSME("YES")}
                        >
                          YES
                        </button>
                        <button
                          type="button"
                          style={{
                            backgroundColor: "rgb(206, 233, 242)",
                            width: "20%",
                          }}
                          className={`btn ${isMSMEEngaged === "NO"
                            ? "btn-primary text-white"
                            : "btn-light"
                            }`}
                          onClick={() => handleSelectionChangeMSME("NO")}
                        >
                          NO
                        </button>
                      </td>
                    </tr>
                  </table>
                  {isMSMEEngaged === "YES" && (
                    <table className="table  creat_tbl">
                      <tr>
                        <td>
                          <label className="form-label">License Number</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Licence Number"
                            value={formData.licensenumberMSME || ''}
                            id="licensenumberMSME"
                            name="licensenumberMSME"
                            onChange={handleInputChange}
                            required />
                        </td>
                        <td colspan="2">
                          <div>
                            <div class="form-group files1">
                              <input type="file" class="form-control" multiple="" accept="image/*,application/pdf" style={{ height: '10px' }}
                                id="licenseimageMSME"
                                name="licenseimageMSME"
                                onChange={(e) =>
                                  handleFileChange(e, "licenseimageMSME")
                                } />
                            </div>
                            {formData?.licenseimageMSME && (
                              <div style={{ marginTop: "10px" }}>
                                <a
                                  href={
                                    typeof formData?.licenseimageMSME ===
                                      "string"
                                      ? formData?.licenseimageMSME
                                      : URL.createObjectURL(
                                        formData?.licenseimageMSME
                                      )
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    color: "blue",
                                    textDecoration: "underline",
                                  }}
                                >
                                  View Uploaded File/Image
                                </a>
                              </div>
                            )}
                          </div>
                        </td>
                        <td>
                          <label for="">Remark</label>
                          <input type="text" class="form-control"
                            id="licenseremarkMSME"
                            name="licenseremarkMSME"
                            value={formData.licenseremarkMSME || ''}
                            onChange={handleInputChange} placeholder="Write Here" />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="">
                          <label for="" className="form-label">License Status</label>
                          <select
                            className="form-select"
                            aria-label="Status select example"
                            name="lis_status_msme"
                            id="lis_status_msme"
                            value={formData.lis_status_msme || 0}
                            onChange={handleInputChange}
                            required
                          >
                            <option value={0}>Select License Status</option>
                            <option value={1}>Not In Scope</option>
                            <option value={2}>License Applied</option>
                            <option value={3}>License Not-Applied</option>
                            <option value={4}>Life-Time License</option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="4">
                          <label className="form-label">Date of Registrations</label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Date of Registration"
                            value={formData.dorMSME || ''}
                            onChange={handleInputChange}
                            id="dorMSME"
                            name="dorMSME"
                            required
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <label className="form-label">Issuing Authority</label>
                          <input type="text" class="form-control"
                            placeholder="Issuing Authority"
                            value={formData.issuingauthMSME || ''}
                            onChange={handleInputChange}
                            name="issuingauthMSME"
                            id="issuingauthMSME"
                            required />
                        </td>
                        <td>
                          <div className="form-group files1">
                            <input
                              type="file"
                              multiple=""
                              accept="image/*,application/pdf"
                              className="form-control"
                              style={{ height: '10px' }}
                              onChange={(e) =>
                                handleFileChange(e, "issuingauthimageMSME")
                              } id="issuingauthimageMSME"
                              name="issuingauthimageMSME"

                            />
                          </div>
                          {formData.issuingauthimageMSME && (
                            <div>
                              <a href={formData.issuingauthimageMSME} target="_blank" rel="noopener noreferrer">
                                Open Issuing Authority File/Image
                              </a>
                            </div>
                          )}
                        </td>
                        <td>
                          <label className="form-label">Remarks</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Remark"
                            value={formData.issuingauthremarkMSME || ''}
                            onChange={handleInputChange}
                            id="issuingauthremarkMSME"
                            name="issuingauthremarkMSME"
                          />
                        </td>
                      </tr>
                    </table>
                  )}
                </div>
                <div>
                  <h4>BOCW License (Building and Other Construction Workers)</h4>
                  <table className="table creat_tbl">
                    <tr>
                      <td colSpan="3">
                        <label>Is BOCW Available? (Yes/No)</label>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3" style={{ width: "100%" }}>
                        <button
                          type="button"
                          style={{
                            backgroundColor: "rgb(206, 233, 242)",
                            width: "20%",
                          }}
                          className={`btn ${isBOCWEngaged === "YES"
                            ? "btn-primary text-white"
                            : "btn-light"
                            }`}
                          onClick={() => handleSelectionChangeBOCW("YES")}
                        >
                          YES
                        </button>
                        <button
                          type="button"
                          style={{
                            backgroundColor: "rgb(206, 233, 242)",
                            width: "20%",
                          }}
                          className={`btn ${isBOCWEngaged === "NO"
                            ? "btn-primary text-white"
                            : "btn-light"
                            }`}
                          onClick={() => handleSelectionChangeBOCW("NO")}
                        >
                          NO
                        </button>
                      </td>
                    </tr>
                  </table>
                  {isBOCWEngaged === "YES" && (
                    <table className="table  creat_tbl">
                      <tr>
                        <td>
                          <label className="form-label">License Number</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Licence Number"
                            value={formData.licensenumberBOCW || ''}
                            id="licensenumberBOCW"
                            name="licensenumberBOCW"
                            onChange={handleInputChange}
                            required />
                        </td>
                        <td colspan="2">
                          <div>
                            <div class="form-group files1">
                              <input type="file" class="form-control" multiple="" accept="image/*,application/pdf" style={{ height: '10px' }}
                                id="licenseimageBOCW"
                                name="licenseimageBOCW"
                                onChange={(e) =>
                                  handleFileChange(e, "licenseimageBOCW")
                                } />
                            </div>
                            {formData.licenseimage && (
                              <div>
                                <a href={formData.licenseimageBOCW} target="_blank" rel="noopener noreferrer">
                                  Open License Number File/Image
                                </a>
                              </div>
                            )}
                          </div>
                        </td>
                        <td>
                          <label for="">Remark</label>
                          <input type="text" class="form-control"
                            id="licenseremarkBOCW"
                            name="licenseremarkBOCW"
                            value={formData.licenseremarkBOCW || ''}
                            onChange={handleInputChange} placeholder="Write Here" />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="4">
                          <label className="form-label">Date of Registrations</label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Date of Registration"
                            value={formData.dorBOCW || ''}
                            onChange={handleInputChange}
                            id="dorBOCW"
                            name="dorBOCW"
                            required
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="">
                          <label for="" className="form-label">License Status</label>
                          <select
                            className="form-select"
                            aria-label="Status select example"
                            name="lis_status_bocw"
                            id="lis_status_bocw"
                            value={formData.lis_status_bocw || 0}
                            onChange={handleInputChange}
                            required
                          >
                            <option value={0}>Select License Status</option>
                            <option value={1}>Not In Scope</option>
                            <option value={2}>License Applied</option>
                            <option value={3}>License Not-Applied</option>
                            <option value={4}>Life-Time License</option>
                          </select>
                        </td>
                        {formData.lis_status_bocw === "2" && (
                          <td colSpan="2">
                            <>
                              <label htmlFor="ackFileBOCW">
                                Upload Acknowledgement
                              </label>
                              <div className="form-group files1">
                                <input
                                  type="file"
                                  multiple=""
                                  accept="image/*,application/pdf"
                                  className="form-control"
                                  style={{
                                    height: "10px" /*,display: 'inline'*/,
                                  }}
                                  onChange={(e) =>
                                    handleFileChange(e, "ackFileBOCW")
                                  }
                                  id="ackFileBOCW"
                                  name="ackFileBOCW"
                                // ref={inputRefsfactoryRefClicknotshow.inputRef14}
                                />
                              </div>
                            </>
                          </td>
                        )}
                        {formData.lis_status_bocw === "3" && (
                          <td colSpan="3">
                            <label className="form-label">Reason?</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Remark"
                              value={formData.reasonBOCW || ""}
                              onChange={handleInputChange}
                              id="reasonBOCW"
                              name="reasonBOCW"
                            />
                          </td>
                        )}
                      </tr>
                      <tr>
                        <td colspan="2">
                          <label className="form-label">Date of Expiry</label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Date of Expiry"
                            value={formData.doeBOCW || ""}
                            onChange={handleInputChange}
                            id="doeBOCW"
                            name="doeBOCW"
                            disabled={formData.lis_status_bocw === "4"}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="4">
                          <label className="form-label">
                            Due Date of Renewal
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Due Date of Renewal"
                            value={formData.doddrBOCW || ""}
                            onChange={handleInputChange}
                            id="doddrBOCW"
                            name="doddrBOCW"
                          // ref={inputRefsfactoryRefClicknotshow.inputRef7}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="4">
                          <label className="form-label">
                            Number of the Employees
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Number of the Employees"
                            value={formData.noeBOCW || ""}
                            onChange={handleInputChange}
                            // min="0"
                            // onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                            id="noeBOCW"
                            name="noeBOCW"
                          // ref={inputRefsfactoryRefClicknotshow.inputRef10}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="4">
                          <label className="form-label">Male</label>
                          {/* {isVisible && ( */}
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Male"
                            value={formData.nomBOCW || ""}
                            onChange={handleInputChange}
                            min="0"
                            onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                            id="nomBOCW"
                            name="nomBOCW"
                          // ref={inputRefsfactoryRefClicknotshow.inputRef11}
                          />
                          {/* )} */}
                        </td>
                      </tr>
                      <tr>
                        <td colspan="4">
                          <label className="form-label">Female</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Female"
                            value={formData.nofBOCW || ""}
                            onChange={handleInputChange}
                            min="0"
                            onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                            id="nofBOCW"
                            name="nofBOCW"
                          // ref={inputRefsfactoryRefClicknotshow.inputRef12}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <label className="form-label">Issuing Authority</label>
                          <input type="text" class="form-control"
                            placeholder="Issuing Authority"
                            value={formData.issuingauthBOCW || ''}
                            onChange={handleInputChange}
                            name="issuingauthBOCW"
                            id="issuingauthBOCW"
                            required />
                        </td>
                        <td>
                          <div className="form-group files1">
                            <input
                              type="file"
                              multiple=""
                              accept="image/*,application/pdf"
                              className="form-control"
                              style={{ height: '10px' }}
                              onChange={(e) =>
                                handleFileChange(e, "issuingauthimageBOCW")
                              } id="issuingauthimageBOCW"
                              name="issuingauthimageBOCW"

                            />
                          </div>
                          {formData.issuingauthimageBOCW && (
                            <div>
                              <a href={formData.issuingauthimageBOCW} target="_blank" rel="noopener noreferrer">
                                Open Issuing Authority File/Image
                              </a>
                            </div>
                          )}
                        </td>
                        <td>
                          <label className="form-label">Remarks</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Remark"
                            value={formData.issuingauthremarkBOCW || ''}
                            onChange={handleInputChange}
                            id="issuingauthremarkBOCW"
                            name="issuingauthremarkBOCW"
                          />
                        </td>
                      </tr>
                    </table>
                  )}
                </div>
                <div>
                  <h4>ISMW License (Interstate Migrant Workmen)</h4>
                  <table className="table creat_tbl">
                    <tr>
                      <td colSpan="3">
                        <label>Is ISMW Available? (Yes/No)</label>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3" style={{ width: "100%" }}>
                        <button
                          type="button"
                          style={{
                            backgroundColor: "rgb(206, 233, 242)",
                            width: "20%",
                          }}
                          className={`btn ${isISMWEngaged === "YES"
                            ? "btn-primary text-white"
                            : "btn-light"
                            }`}
                          onClick={() => handleSelectionChangeISMW("YES")}
                        >
                          YES
                        </button>
                        <button
                          type="button"
                          style={{
                            backgroundColor: "rgb(206, 233, 242)",
                            width: "20%",
                          }}
                          className={`btn ${isISMWEngaged === "NO"
                            ? "btn-primary text-white"
                            : "btn-light"
                            }`}
                          onClick={() => handleSelectionChangeISMW("NO")}
                        >
                          NO
                        </button>
                      </td>
                    </tr>
                  </table>
                  {isISMWEngaged === "YES" && (
                    <table className="table  creat_tbl">
                      <tr>
                        <td>
                          <label className="form-label">License Number</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Licence Number"
                            value={formData.licensenumberISMW || ''}
                            id="licensenumberISMW"
                            name="licensenumberISMW"
                            onChange={handleInputChange}
                            required />
                        </td>
                        <td colspan="2">
                          <div>
                            <div class="form-group files1">
                              <input type="file" class="form-control" multiple="" accept="image/*,application/pdf" style={{ height: '10px' }}
                                id="licenseimageISMW"
                                name="licenseimageISMW"
                                onChange={(e) =>
                                  handleFileChange(e, "licenseimageISMW")
                                } />
                            </div>
                            {formData.licenseimage && (
                              <div>
                                <a href={formData.licenseimageISMW} target="_blank" rel="noopener noreferrer">
                                  Open License Number File/Image
                                </a>
                              </div>
                            )}
                          </div>
                        </td>
                        <td>
                          <label for="">Remark</label>
                          <input type="text" class="form-control"
                            id="licenseremarkISMW"
                            name="licenseremarkISMW"
                            value={formData.licenseremarkISMW || ''}
                            onChange={handleInputChange} placeholder="Write Here" />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="4">
                          <label className="form-label">Date of Registrations</label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Date of Registration"
                            value={formData.dorISMW || ''}
                            onChange={handleInputChange}
                            id="dorISMW"
                            name="dorISMW"
                            required
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="">
                          <label for="" className="form-label">License Status</label>
                          <select
                            className="form-select"
                            aria-label="Status select example"
                            name="lis_status_ismw"
                            id="lis_status_ismw"
                            value={formData.lis_status_ismw || 0}
                            onChange={handleInputChange}
                            required
                          >
                            <option value={0}>Select License Status</option>
                            <option value={1}>Not In Scope</option>
                            <option value={2}>License Applied</option>
                            <option value={3}>License Not-Applied</option>
                            <option value={4}>Life-Time License</option>
                          </select>
                        </td>
                        {formData.lis_status_ismw === "2" && (
                          <td colSpan="2">
                            <>
                              <label htmlFor="ackFileISMW">
                                Upload Acknowledgement
                              </label>
                              <div className="form-group files1">
                                <input
                                  type="file"
                                  multiple=""
                                  accept="image/*,application/pdf"
                                  className="form-control"
                                  style={{
                                    height: "10px" /*,display: 'inline'*/,
                                  }}
                                  onChange={(e) =>
                                    handleFileChange(e, "ackFileISMW")
                                  }
                                  id="ackFileISMW"
                                  name="ackFileISMW"
                                // ref={inputRefsfactoryRefClicknotshow.inputRef14}
                                />
                              </div>
                            </>
                          </td>
                        )}
                        {formData.lis_status_ismw === "3" && (
                          <td colSpan="3">
                            <label className="form-label">Reason?</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Write Reason Here"
                              value={formData.reasonISMW || ""}
                              onChange={handleInputChange}
                              id="reasonISMW"
                              name="reasonISMW"
                            />
                          </td>
                        )}
                      </tr>
                      <tr>
                        <td colspan="2">
                          <label className="form-label">Date of Expiry</label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Date of Expiry"
                            value={formData.doeISMW || ""}
                            onChange={handleInputChange}
                            id="doeISMW"
                            name="doeISMW"
                            disabled={formData.lis_status_ismw === "4"}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="4">
                          <label className="form-label">
                            Due Date of Renewal
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Due Date of Renewal"
                            value={formData.doddrISMW || ""}
                            onChange={handleInputChange}
                            id="doddrISMW"
                            name="doddrISMW"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="4">
                          <label className="form-label">
                            Number of the Employees
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Number of the Employees"
                            value={formData.noeISMW || ""}
                            onChange={handleInputChange}
                            // min="0"
                            // onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                            id="noeISMW"
                            name="noeISMW"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="4">
                          <label className="form-label">Male</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Male"
                            value={formData.nomISMW || ""}
                            onChange={handleInputChange}
                            min="0"
                            onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                            id="nomISMW"
                            name="nomISMW"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="4">
                          <label className="form-label">Female</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Female"
                            value={formData.nofISMW || ""}
                            onChange={handleInputChange}
                            min="0"
                            onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                            id="nofISMW"
                            name="nofISMW"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <label className="form-label">Issuing Authority</label>
                          <input type="text" class="form-control"
                            placeholder="Issuing Authority"
                            value={formData.issuingauthISMW || ''}
                            onChange={handleInputChange}
                            name="issuingauthISMW"
                            id="issuingauthISMW"
                            required />
                        </td>
                        <td>
                          <div className="form-group files1">
                            <input
                              type="file"
                              multiple=""
                              accept="image/*,application/pdf"
                              className="form-control"
                              style={{ height: '10px' }}
                              onChange={(e) =>
                                handleFileChange(e, "issuingauthimageISMW")
                              }
                              id="issuingauthimageISMW"
                              name="issuingauthimageISMW"

                            />
                          </div>
                          {formData.issuingauthimageISMW && (
                            <div>
                              <a href={formData.issuingauthimageISMW} target="_blank" rel="noopener noreferrer">
                                Open Issuing Authority File/Image
                              </a>
                            </div>
                          )}
                        </td>
                        <td>
                          <label className="form-label">Remarks</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Remark"
                            value={formData.issuingauthremarkISMW || ''}
                            onChange={handleInputChange}
                            id="issuingauthremarkISMW"
                            name="issuingauthremarkISMW"
                          />
                        </td>
                      </tr>
                    </table>
                  )}
                </div>
                <div>
                  <h4>FASSAI License (Food Safety and Standards Authority of India)</h4>
                  <table className="table creat_tbl">
                    <tr>
                      <td colSpan="3">
                        <label>Is FASSAI License Available? (Yes/No)</label>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3" style={{ width: "100%" }}>
                        <button
                          type="button"
                          style={{
                            backgroundColor: "rgb(206, 233, 242)",
                            width: "20%",
                          }}
                          className={`btn ${isFASSAIEngaged === "YES"
                            ? "btn-primary text-white"
                            : "btn-light"
                            }`}
                          onClick={() => handleSelectionChangeFASSAI("YES")}
                        >
                          YES
                        </button>
                        <button
                          type="button"
                          style={{
                            backgroundColor: "rgb(206, 233, 242)",
                            width: "20%",
                          }}
                          className={`btn ${isFASSAIEngaged === "NO"
                            ? "btn-primary text-white"
                            : "btn-light"
                            }`}
                          onClick={() => handleSelectionChangeFASSAI("NO")}
                        >
                          NO
                        </button>
                      </td>
                    </tr>
                  </table>
                  {isFASSAIEngaged === "YES" && (
                    <table className="table creat_tbl">
                      <tr>
                        <td>
                          <label className="form-label">License Number</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Licence Number"
                            value={formData?.licensenumberFASSAI || ""}
                            id="licensenumberFASSAI"
                            name="licensenumberFASSAI"
                            onChange={handleInputChange}
                          />
                        </td>
                        <td colSpan="5">
                          <>
                            <label className="licenseimageFASSAI">
                              Upload License
                            </label>

                            <div className="form-group files1">
                              <input
                                type="file"
                                className="form-control"
                                multiple=""
                                accept="image/*,application/pdf"
                                style={{ height: "10px" }}
                                id="licenseimageFASSAI"
                                name="licenseimageFASSAI"
                                onChange={(e) =>
                                  handleFileChange(e, "licenseimageFASSAI")
                                }
                              />
                            </div>
                          </>
                        </td>
                        <td>
                          <label>Remark</label>
                          <input
                            type="text"
                            className="form-control"
                            id="licenseremarkFASSAI"
                            name="licenseremarkFASSAI"
                            value={formData?.licenseremarkFASSAI || ""}
                            onChange={handleInputChange}
                            placeholder="Write Here"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <label for="" className="form-label">License Status</label>
                          <select
                            className="form-select"
                            aria-label="Status select example"
                            name="lis_status_fassai"
                            id="lis_status_fassai"
                            value={formData.lis_status_fassai || 0}
                            onChange={handleInputChange}
                            required
                          >
                            <option value={0}>Select License Status</option>
                            <option value={1}>Not In Scope</option>
                            <option value={2}>License Applied</option>
                            <option value={3}>License Not-Applied</option>
                            <option value={4}>Life-Time License</option>

                          </select>
                        </td>
                        {formData.lis_status_fassai === "2" && (
                          <td colSpan="2">
                            <>
                              <label htmlFor="issuingauthimageFASSAI">
                                Upload Acknowledgement
                              </label>
                              <div className="form-group files1">
                                <input
                                  type="file"
                                  multiple=""
                                  accept="image/*,application/pdf"
                                  className="form-control"
                                  style={{ height: "10px" }}
                                  onChange={(e) =>
                                    handleFileChange(e, "issuingauthimageFASSAI")
                                  }
                                  id="issuingauthimageFASSAI"
                                  name="issuingauthimageFASSAI"
                                />
                              </div>
                            </>
                          </td>
                        )}
                        {formData.lis_status_fassai === "3" && (
                          <td colSpan="5">
                            <label className="form-label">
                              Reason For Not Applied?
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Reason"
                              value={formData?.issuingauthremarkFASSAI || ""}
                              onChange={handleInputChange}
                              id="issuingauthremarkFASSAI"
                              name="issuingauthremarkFASSAI"
                            />
                          </td>
                        )}
                      </tr>
                      <tr>
                        <td colSpan="8">
                          <label className="form-label">
                            Date of Registration
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Date of Registration"
                            value={formData?.dorFASSAI || ""}
                            onChange={handleInputChange}
                            id="dorFASSAI"
                            name="dorFASSAI"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2">
                          <label className="form-label">Date of Expiry</label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Date of Expiry"
                            value={formData?.doeFASSAI || ""}
                            onChange={handleInputChange}
                            id="doeFASSAI"
                            name="doeFASSAI"
                            disabled={formData.lis_status_fassai === "4"}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="8">
                          <label className="form-label">
                            Due Date of Renewal
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Due Date of Renewal"
                            value={formData?.doddrFASSAI || ""}
                            onChange={handleInputChange}
                            id="doddrFASSAI"
                            name="doddrFASSAI"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2">
                          <label className="form-label">Issuing Authority</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Issuing Authority"
                            value={formData?.issuingauthFASSAI || ""}
                            onChange={handleInputChange}
                            name="issuingauthFASSAI"
                            id="issuingauthFASSAI"
                          />
                        </td>
                      </tr>
                    </table>
                  )}
                </div>
                <div>
                  <h4>SB License (Signage Board)</h4>
                  <table className="table creat_tbl">
                    <tr>
                      <td colSpan="3">
                        <label>Is SB License Available? (Yes/No)</label>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3" style={{ width: "100%" }}>
                        <button
                          type="button"
                          style={{
                            backgroundColor: "rgb(206, 233, 242)",
                            width: "20%",
                          }}
                          className={`btn ${isSBEngaged === "YES"
                            ? "btn-primary text-white"
                            : "btn-light"
                            }`}
                          onClick={() => handleSelectionChangeSB("YES")}
                        >
                          YES
                        </button>
                        <button
                          type="button"
                          style={{
                            backgroundColor: "rgb(206, 233, 242)",
                            width: "20%",
                          }}
                          className={`btn ${isSBEngaged === "NO"
                            ? "btn-primary text-white"
                            : "btn-light"
                            }`}
                          onClick={() => handleSelectionChangeSB("NO")}
                        >
                          NO
                        </button>
                      </td>
                    </tr>
                  </table>
                  {isSBEngaged === "YES" && (
                    <table className="table creat_tbl">
                      <tr>
                        <td>
                          <label className="form-label">License Number</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Licence Number"
                            value={formData?.licensenumberSB || ""}
                            id="licensenumberSB"
                            name="licensenumberSB"
                            onChange={handleInputChange}
                          />
                        </td>
                        <td colSpan="5">
                          <>
                            <label className="licenseimageSB">
                              Upload License
                            </label>

                            <div className="form-group files1">
                              <input
                                type="file"
                                className="form-control"
                                multiple=""
                                accept="image/*,application/pdf"
                                style={{ height: "10px" }}
                                id="licenseimageSB"
                                name="licenseimageSB"
                                onChange={(e) =>
                                  handleFileChange(e, "licenseimageSB")
                                }
                              />
                            </div>
                          </>
                        </td>
                        <td>
                          <label>Remark</label>
                          <input
                            type="text"
                            className="form-control"
                            id="licenseremarkSB"
                            name="licenseremarkSB"
                            value={formData?.licenseremarkSB || ""}
                            onChange={handleInputChange}
                            placeholder="Write Here"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <label for="" className="form-label">License Status</label>
                          <select
                            className="form-select"
                            aria-label="Status select example"
                            name="lis_status_sb"
                            id="lis_status_sb"
                            value={formData.lis_status_sb || 0}
                            onChange={handleInputChange}
                            required
                          >
                            <option value={0}>Select License Status</option>
                            <option value={1}>Not In Scope</option>
                            <option value={2}>License Applied</option>
                            <option value={3}>License Not-Applied</option>
                            <option value={4}>Life-Time License</option>

                          </select>
                        </td>
                        {formData.lis_status_sb === "2" && (
                          <td colSpan="2">
                            <>
                              <label htmlFor="ackFileSB">
                                Upload Acknowledgement
                              </label>
                              <div className="form-group files1">
                                <input
                                  type="file"
                                  multiple=""
                                  accept="image/*,application/pdf"
                                  className="form-control"
                                  style={{ height: "10px" }}
                                  onChange={(e) =>
                                    handleFileChange(e, "ackFileSB")
                                  }
                                  id="ackFileSB"
                                  name="ackFileSB"
                                />
                              </div>
                            </>
                          </td>
                        )}
                        {formData.lis_status_sb === "3" && (
                          <td colSpan="5">
                            <label className="form-label">
                              Reason For Not Applied?
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Reason"
                              value={formData?.issuingauthremarkSB || ""}
                              onChange={handleInputChange}
                              id="issuingauthremarkSB"
                              name="issuingauthremarkSB"
                            />
                          </td>
                        )}
                      </tr>
                      <tr>
                        <td colSpan="8">
                          <label className="form-label">
                            Date of Registration
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Date of Registration"
                            value={formData?.dorSB || ""}
                            onChange={handleInputChange}
                            id="dorSB"
                            name="dorSB"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2">
                          <label className="form-label">Date of Expiry</label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Date of Expiry"
                            value={formData?.doeSB || ""}
                            onChange={handleInputChange}
                            id="doeSB"
                            name="doeSB"
                            disabled={formData.lis_status_sb === "4"}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="8">
                          <label className="form-label">
                            Due Date of Renewal
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Due Date of Renewal"
                            value={formData?.doddrSB || ""}
                            onChange={handleInputChange}
                            id="doddrSB"
                            name="doddrSB"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2">
                          <label className="form-label">Issuing Authority</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Issuing Authority"
                            value={formData?.issuingauthSB || ""}
                            onChange={handleInputChange}
                            name="issuingauthSB"
                            id="issuingauthSB"
                          />
                        </td>
                      </tr>
                    </table>
                  )}
                </div>
                <div>
                  <h4>Contract Labour Registration</h4>
                  <table className="table creat_tbl">
                    <tr>
                      <td colSpan="3">
                        <label>Is Contract Labour Registraion Available? (Yes/No)</label>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3" style={{ width: "100%" }}>
                        <button
                          type="button"
                          style={{
                            backgroundColor: "rgb(206, 233, 242)",
                            width: "20%",
                          }}
                          className={`btn ${is_contractEngaged === true
                            ? "btn-primary text-white"
                            : "btn-light"
                            }`}
                          onClick={() => handleSelectionChangeCont(true)}
                        >
                          YES
                        </button>
                        <button
                          type="button"
                          style={{
                            backgroundColor: "rgb(206, 233, 242)",
                            width: "20%",
                          }}
                          className={`btn ${is_contractEngaged === false
                            ? "btn-primary text-white"
                            : "btn-light"
                            }`}
                          onClick={() => handleSelectionChangeCont(false)}
                        >
                          NO
                        </button>
                      </td>
                    </tr>
                  </table>
                  {(is_contractEngaged === true) && (
                    <table className="table creat_tbl">
                      <tr>
                        <td>
                          <label className="form-label">Contract Labour Registration</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Contract Labour Registration"
                            value={formData?.contractLabourRegistration || ""}
                            id="contractLabourRegistration"
                            name="contractLabourRegistration"
                            onChange={handleInputChange}
                          />
                        </td>
                        <td colSpan="5">
                          <>
                            <label className="form-label">
                              Upload Registration
                            </label>

                            <div className="form-group files1">
                              <input
                                type="file"
                                className="form-control"
                                multiple=""
                                accept="image/*,application/pdf"
                                style={{ height: "10px" }}
                                id="contractLabourRegistrationFile"
                                name="contractLabourRegistrationFile"
                                onChange={(e) =>
                                  handleFileChange(e, "contractLabourRegistrationFile")
                                }
                              />
                            </div>
                          </>
                        </td>
                        <td>
                          <label className="form-label">Remark</label>
                          <input
                            type="text"
                            className="form-control"
                            id="contractLabourRegistrationRemarks"
                            name="contractLabourRegistrationRemarks"
                            value={formData?.contractLabourRegistrationRemarks || ""}
                            onChange={handleInputChange}
                            placeholder="Remarks"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={"4"}>
                          <label className="form-label">Registration Number</label>
                          <input
                            type="text"
                            className="form-control"
                            id="contractLabourRegistrationNumber"
                            name="contractLabourRegistrationNumber"
                            value={formData?.contractLabourRegistrationNumber || ""}
                            onChange={handleInputChange}
                            placeholder="Registration Number"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="4">
                          <label className="form-label">
                            Date of Registration
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Date of Registration"
                            value={formData?.dateOfContractReg || ""}
                            onChange={handleInputChange}
                            id="dateOfContractReg"
                            name="dateOfContractReg"
                          />
                        </td>
                        {/* </tr>
                      <tr> */}
                        <td colSpan="4">
                          <label className="form-label">Date of Surrender</label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Date of Surrender"
                            value={formData?.dateofContractSur || ""}
                            onChange={handleInputChange}
                            id="dateofContractSur"
                            name="dateofContractSur"
                            disabled={formData.lis_status_sb === "4"}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="8">
                          <label className="form-label">
                            Number of Contractors
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Number of Contractors"
                            value={formData?.nOofContractors || ""}
                            onChange={handleInputChange}
                            id="nOofContractors"
                            name="nOofContractors"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="8">
                          <label className="form-label">
                            Number of Contract Employees
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Number of Contract Employees"
                            value={formData?.nOofContractEmp || ""}
                            onChange={handleInputChange}
                            id="nOofContractEmp"
                            name="nOofContractEmp"
                          />
                        </td>
                      </tr>
                    </table>
                  )}
                </div>
              </div>{" "}
            </td>
          </tr>
        </tbody>
        <button
          style={{
            backgroundColor: "#013879",
            color: "white",
            width: "235%",
            borderRadius: "4px",
            height: "40px",
          }}
          // type="button"
          onSubmit="submit"
          loading={loading}
          disabled={loading}
        >
          Submit
        </button>
      </table>
    </Form>
  );
};

export default BranchForm;
