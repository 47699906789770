import React, { useState, useEffect } from "react";
import { Form, Input, Select, Button, notification } from 'antd';
import { stateGets } from '../../store/actions/otherActions';
import { useDispatch, useSelector } from "react-redux";
import { createDueDates, fetchDueDates } from '../../store/actions/otherActions';

const { Option } = Select;

const DueDateCreate = () => {
    const dispatch = useDispatch();
    const getState = useSelector((state) => state.getState);
    const { stateInfo } = getState;

    useEffect(() => {
        dispatch(stateGets());
    }, [dispatch]);

    const [form] = Form.useForm();
    const [state, setState] = useState("");
    const [updatedDueDate, setUpdatedDueDate] = useState("");
    const [updatedDueDateForPayload, setUpdatedDueDateForPayload] = useState("");
    const [isEndOfMonth, setIsEndOfMonth] = useState(false);
    const [showCheckbox, setShowCheckbox] = useState(false);
    
    // Format date as "DD/MM/YYYY"
    const formatDateToDisplay = (isoDate) => {
        if (!isoDate) return "";
        const date = new Date(isoDate);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const formatDateForPayload = (dateObj) => {
        const year = dateObj.getFullYear();
        const month = String(dateObj.getMonth() + 1).padStart(2, "0");
        const day = String(dateObj.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    };

    // const handleDueDateChange = (e) => {
    //     const dueDate = e.target.value;
    //     const frequency = Number(form.getFieldValue("frequency"));

    //     if (!dueDate || !frequency) return;

    //     const dueDateObj = new Date(dueDate + "T00:00:00"); // Ensure correct local date
    //     const updatedDueDateObj = new Date(dueDateObj);

    //     switch (frequency) {
    //         case 2: updatedDueDateObj.setDate(dueDateObj.getDate() + 15); break;
    //         case 3: updatedDueDateObj.setMonth(dueDateObj.getMonth() + 1); break;
    //         case 4: updatedDueDateObj.setMonth(dueDateObj.getMonth() + 3); break;
    //         case 5: updatedDueDateObj.setMonth(dueDateObj.getMonth() + 6); break;
    //         case 6: updatedDueDateObj.setFullYear(dueDateObj.getFullYear() + 1); break;
    //         case 7: updatedDueDateObj.setFullYear(dueDateObj.getFullYear() + 2); break;
    //         default: break;
    //     }

    //     const formattedDisplayDate = formatDateToDisplay(updatedDueDateObj);
    //     const formattedPayloadDate = formatDateForPayload(updatedDueDateObj);

    //     setUpdatedDueDate(formattedDisplayDate);
    //     setUpdatedDueDateForPayload(formattedPayloadDate);
    //     form.setFieldsValue({ updatedDueDate: formattedDisplayDate });

    //     // Extract the local date correctly
    //     const day = updatedDueDateObj.getDate();
    //     setShowCheckbox([28, 29, 30, 31].includes(day));
    // };


    const handleDueDateChange = (e) => {
        const dueDate = e.target.value;
        const frequency = Number(form.getFieldValue("frequency"));
    
        if (!dueDate || !frequency) return;
    
        const dueDateObj = new Date(dueDate + "T00:00:00"); // Ensure correct local date
        let updatedDueDateObj = new Date(dueDateObj);
    
        switch (frequency) {
            case 2:
                updatedDueDateObj.setDate(dueDateObj.getDate() + 15);
                break;
            case 3:
                updatedDueDateObj.setMonth(dueDateObj.getMonth() + 1);
                break;
            case 4:
                updatedDueDateObj.setMonth(dueDateObj.getMonth() + 3);
                break;
            case 5:
                updatedDueDateObj.setMonth(dueDateObj.getMonth() + 6);
                break;
            case 6:
                updatedDueDateObj.setFullYear(dueDateObj.getFullYear() + 1);
                break;
            case 7:
                updatedDueDateObj.setFullYear(dueDateObj.getFullYear() + 2);
                break;
            default:
                break;
        }
    
        const formattedDisplayDate = formatDateToDisplay(updatedDueDateObj);
        const formattedPayloadDate = formatDateForPayload(updatedDueDateObj);
    
        setUpdatedDueDate(formattedDisplayDate);
        setUpdatedDueDateForPayload(formattedPayloadDate);
        form.setFieldsValue({ updatedDueDate: formattedDisplayDate });
    
        // ✅ Only use dueDate to determine checkbox visibility
        const selectedDay = new Date(dueDate + "T00:00:00").getDate();
        setShowCheckbox([28, 29, 30, 31].includes(selectedDay));
    
        console.log("Selected Due Date:", dueDate);
        console.log("Selected Day:", selectedDay);
        console.log("Show Checkbox:", [28, 29, 30, 31].includes(selectedDay));
    };
    

    

    const handleSubmit = async (values) => {
        const formData = {
            state: state,
            act: values.Act,
            compliance: values.compliance,
            formType: values.FormType,
            frequency: values.frequency,
            date: values.dueDate,
            dueDate: values.dueDate,
            updatedDueDate: updatedDueDateForPayload,
            isEndOfMonth: showCheckbox ? isEndOfMonth : false,
        };

        try {
            await dispatch(createDueDates(formData));
            dispatch(fetchDueDates());
            form.resetFields();
            setState("");
            setUpdatedDueDate("");
            setUpdatedDueDateForPayload("");
            setShowCheckbox(false);
            setIsEndOfMonth(false);
        } catch (error) {
            console.error("Error creating due date", error);
        }
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            style={{ maxWidth: '1000px', margin: '0 auto' }}
        >
            <table className="table creat_tbl">
                <tbody>
                    <tr>
                        <td colSpan="8">
                            <div className="form-group">
                                <label htmlFor="state">State</label>
                                <select
                                    className="form-select"
                                    id="state"
                                    name="state"
                                    value={state}
                                    onChange={(e) => setState(e.target.value)}
                                    required
                                >
                                    <option value="">Select State</option>
                                    {stateInfo?.length > 0 &&
                                        stateInfo.map((item) => (
                                            <option key={item._id} value={item._id}>
                                                {item.name}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2">
                            <Form.Item
                                label="Act"
                                name="Act"
                                rules={[{ required: true, message: 'Please enter the Act name!' }]}
                            >
                                <Input placeholder="Enter Act name" />
                            </Form.Item>
                        </td>
                        <td colSpan="1">
                            <Form.Item
                                label="Compliance"
                                name="compliance"
                                rules={[{ required: true, message: 'Please enter the compliance name!' }]}
                            >
                                <Input placeholder="Enter compliance name" />
                            </Form.Item>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2">
                            <Form.Item
                                label="Form Type"
                                name="FormType"
                            >
                                <Input placeholder="Enter Form Type name" />
                            </Form.Item>
                        </td>

                        <td colSpan="1">
                            <Form.Item
                                label="Frequency"
                                name="frequency"
                                rules={[{ required: true, message: 'Please select the Frequency!' }]}
                            >

                                <select className="custom-select">
                                    <option value={null}>Select Frequency</option>
                                    <option value={1}>One Time</option>
                                    <option value={2}>Fortnightly</option>
                                    <option value={3}>Monthly</option>
                                    <option value={4}>Quarterly</option>
                                    <option value={5}>Half Yearly</option>
                                    <option value={6}>Yearly</option>
                                    <option value={7}>Bi-Annual</option>
                                </select>
                            </Form.Item>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="8">
                            <Form.Item
                                label="Due Date"
                                name="dueDate"
                                rules={[{ required: true, message: 'Please select the date!' }]}
                            >
                                <input
                                    className="form-control"
                                    type="date"
                                    style={{ width: '100%' }}
                                    onChange={handleDueDateChange}
                                />
                            </Form.Item>
                            {showCheckbox && (
                                <Form.Item>
                                    <label>
                                        <input type="checkbox" checked={isEndOfMonth} onChange={(e) => setIsEndOfMonth(e.target.checked)} />
                                        &nbsp; Mark as End of Month
                                    </label>
                                </Form.Item>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="8">
                            <Form.Item label="Updated Due Date" name="updatedDueDate">
                                <Input placeholder="Updated Due Date" value={updatedDueDate} disabled />
                            </Form.Item>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="8">
                            <Form.Item>
                                <Button htmlType="submit" style={{ marginRight: '8px', backgroundColor: '#013879', color: 'white' }}>
                                    Submit
                                </Button>
                                <Button htmlType="button" onClick={() => form.resetFields()}>
                                    Reset
                                </Button>
                            </Form.Item>
                        </td>
                    </tr>
                </tbody>
            </table>
        </Form>
    );
};

export default DueDateCreate;
