import React, { useEffect, useState } from "react";
import { Table, Pagination, DatePicker, Select, Spin } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchDueDates, stateGets, DueDatesDeleteById } from '../../store/actions/otherActions';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2'; // Import SweetAlert2

const { RangePicker } = DatePicker;
const { Option } = Select;

const DueDateTable = () => {
    const dispatch = useDispatch();
    const { dueDates, loading, totalCount, totalPages, currentPage } = useSelector(state => state.dueDateRed);

    const getState = useSelector((state) => state.getState);
    const { stateInfo } = getState;

    const [dateRange, setDateRange] = useState([null, null]);
    const [state, setState] = useState("");
    const [pageSize, setPageSize] = useState("");

    useEffect(() => {
        console.log("State Changed to:", state);  // Should log after state updates
        if (state) {
            handlePaginationChange(1, state); // Fetch data when state changes
        }
    }, [state]); // Runs when `state` is updated


    useEffect(() => {
        const [fromDate, toDate] = dateRange;
        dispatch(stateGets());
        // Send dates as ISO strings, or null if no date range is selected
        dispatch(fetchDueDates(currentPage, 20, 'desc', fromDate ? fromDate.toISOString() : null, toDate ? toDate.toISOString() : null));
    }, [dispatch, currentPage, dateRange]);

    const formatDateToInput = (isoDate) => {
        if (!isoDate) return ""; // Return an empty string for empty or invalid dates
        const date = new Date(isoDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero
        const day = String(date.getDate()).padStart(2, "0"); // Add leading zero
        return `${day}/${month}/${year}`;
    };

    const handleDelete = async (id) => {
        console.log("id", id);

        if (!id) return;

        try {
            // Use Swal.fire for a custom confirmation dialog
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!',
                reverseButtons: true, // Optional, to reverse the order of buttons
            });

            if (result.isConfirmed) {
                // Proceed with deleting due date
                await dispatch(DueDatesDeleteById(id)); // Ensure delete is completed

                Swal.fire({
                    icon: 'success',
                    title: 'Deleted!',
                    text: 'Due date deleted successfully.',
                });

                // Re-fetch due dates after deletion
                const [fromDate, toDate] = dateRange;
                dispatch(fetchDueDates(currentPage, 20, 'desc', fromDate ? fromDate.toISOString() : null, toDate ? toDate.toISOString() : null));
            }
        } catch (error) {
            console.error("Swal Error:", error); // Log Swal error
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: error?.message || 'An unexpected error occurred.',
            });
        }
    };

    const mapFrequencyValue = (value) => {
        const frequencyMap = {
            1: "One Time",
            2: "Fortnightly",
            3: "Monthly",
            4: "Quarterly",
            5: "Half Yearly",
            6: "Yearly",
            7: "Bi-Annual",
        };
        return frequencyMap[value] || "Unknown"; // Default to "Unknown" if value is not found
    };

    const handleDateChange = (dates) => {
        setDateRange(dates);  // Update the date range state when the user selects a date range
    };

    const handlePaginationChange = (page, pageSize = 20, selectedState = state) => {
        const [fromDate, toDate] = dateRange;

        dispatch(fetchDueDates(
            page,
            pageSize, // Ensure default is 20
            'desc',
            fromDate ? fromDate.toISOString() : null,
            toDate ? toDate.toISOString() : null,
            selectedState || ""
        ));
    };


    const formattedDueDates = dueDates.map((item, index) => ({
        ...item,
        key: index + 1, // Set Sr. No. as a sequential index (1-based)
    }));


    const columns = [
        {
            title: "Sr. No.",
            dataIndex: "key",
            key: "key",
            width: 80,
        },

        {
            title: "State",
            dataIndex: "state",
            key: "state",
        },
        {
            title: "Act",
            dataIndex: "act",
            key: "act",
        },
        {
            title: "Form",
            dataIndex: "formType",
            key: "formType",
        },
        {
            title: "Compliance",
            dataIndex: "compliance",
            key: "compliance",
        },
        {
            title: "Due Date",
            dataIndex: "dueDate",
            key: "dueDate",
            render: (dueDate) => formatDateToInput(dueDate),
        },
        {
            title: "Update Due Date",
            dataIndex: "updatedDueDate",
            key: "updatedDueDate",
            render: (updatedDueDate) => formatDateToInput(updatedDueDate),
        },
        {
            title: "Frequency",
            dataIndex: "frequency",
            key: "frequency",
            render: (value) => mapFrequencyValue(value),
        },
        {
            title: "Created Date",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (createdAt) => formatDateToInput(createdAt),
        },
        {
            key: "action",
            title: "Actions",
            width: 100,
            render: (record) => (
                <>
                    {/* <Link className="btn btn-primary mx-2">
                        View/Edit <EditOutlined />
                    </Link> */}
                    <Link className="btn btn-danger mx-2" onClick={() => handleDelete(record._id)}>
                        <DeleteOutlined />
                    </Link>
                </>
            ),
        },
    ];

    return (
        <React.Fragment>
            <div className="container-fluid">
                {/* Filters Section */}
                <div className="row g-3 mb-3 pt-1 align-items-end">
                    {/* State Filter */}
                    <div className="col-md-6">
                        <label htmlFor="state" className="form-label fw-semibold">State Filter</label>
                        <select
                            className="form-select"
                            id="state"
                            name="state"
                            value={state}
                            onChange={(e) => {
                                const selectedState = e.target.value || ""; // Ensure empty string is set if "Select State" is chosen
                                setState(selectedState); // Update state value
                                handlePaginationChange(1, selectedState); // Fetch data based on selection
                            }}
                            required
                        >
                            <option value={null}>Select State</option>
                            {stateInfo?.map((item) => (
                                <option key={item._id} value={item._id}>
                                    {item.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Date Range Filter */}
                    <div className="col-md-6">
                        <label className="form-label fw-semibold">Date Filter</label>
                        <RangePicker
                            onChange={handleDateChange}
                            format="DD/MM/YYYY"
                            className="w-100"
                        />
                    </div>
                </div>

                {/* Table Section */}
                <div className="table-responsive">
                    {loading ? (
                        <div className="d-flex justify-content-center align-items-center" style={{ height: "200px" }}>
                            <Spin size="large" />
                        </div>
                    ) : (
                        <Table
                            columns={columns}
                            dataSource={formattedDueDates}
                            pagination={false}
                            rowKey="key"
                            sticky
                        />
                    )}
                </div>

                {/* Pagination */}
                <div className="d-flex justify-content-center mt-3">
                    <Pagination
                        current={currentPage}
                        total={totalCount}
                        pageSize={20}  // Ensure it's set
                        onChange={(page) => handlePaginationChange(page, 20)}
                        showSizeChanger={false}
                    />
                </div>
            </div>

        </React.Fragment>
    );
};

export default DueDateTable;
