import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { FormLabel, styled } from "@mui/material";
import Highlighter from "react-highlight-words";
import { Checkmark } from "react-checkmark";
// import Kio from '../../css/Kio.css'
import Kio from "../../css/Kio.css";
import { DownOutlined } from "@ant-design/icons";
import {
  Button,
  Input,
  Space,
  Table,
  Modal,
  Form,
  message,
  Alert,
  Dropdown,
  Menu,
  Spin
} from "antd";
import {
  CloudUploadOutlined,
  UploadOutlined,
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  stateGets,
  usersGet,
  branchGet,
  companyGet,
  categoryGet,
  auditorGet,
  auditGetDataAllPA,
  auditAllFiltersLCA,
  companyTableGet,
  fileUploadInAuditQuestion,
  auditUploadStatusValue,
  updateAuditFields,
  updateAuditDueDates
} from "../../store/actions/otherActions";
import { useDispatch, useSelector } from "react-redux";
import { Select } from 'antd';
import { red } from "@mui/material/colors";

const AuditForPA = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchInput = useRef(null);
  const [dataSource, setDataSource] = useState();
  const [searchText, setSearchText] = useState("");
  const [dateStart, setStartDate] = useState("");
  const [dateEnd, setEndDate] = useState("");
  const [dateOverDue, setOverDueDate] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [user, setUser] = useState();
  const [auditor, setAuditor] = useState();
  const [state, setState] = useState("");
  const [branch, setBranch] = useState("");
  const [company, setCompany] = useState("");
  const [risk, setRisk] = useState("");
  const [status, setStatus] = useState("");
  const [name, setName] = useState("");
  const myElementRefOverdue = useRef(null);
  const myElementRefState = useRef(null);
  const myElementRefStartdate = useRef(null);
  const myElementRefEnddate = useRef(null);
  const myElementRefRisk = useRef(null);
  const myElementRefAuditStatus = useRef(null);
  const myElementRefExecutive = useRef(null);
  const myElementRefAuditor = useRef(null);
  const myElementRefCompany = useRef(null);
  const myElementRefBranch = useRef(null);
  const getState = useSelector((state) => state.getState);
  const { loadings, stateInfo } = getState;
  //console.log(stateInfo);
  const userGet = useSelector((state) => state.userGet);
  const { usersInfo } = userGet;
  const getBranch = useSelector((state) => state.getBranch);
  const { branchInfo } = getBranch;
  const getCompanyTable = useSelector((state) => state.getCompanyTable);
  const { loadingcompanytable, companyGetTableInfo } = getCompanyTable;
  const getAuditor = useSelector((state) => state.getAuditor);
  const { auditorInfo } = getAuditor;
  const allAuditGetPA = useSelector((state) => state.allAuditGetPA);
  const { loadingallAuditPA, getAllAuditPA } = allAuditGetPA;
  const auditDueDateUpdate = useSelector((state) => state.auditDueDateUpdate);
  const { loadingDueDateAudit, dueDateUpdate } = auditDueDateUpdate;

  const [fileUploads, setFileUploads] = useState({});
  const [checklist, setChecklist] = useState([]); //aj

  const [file, setFile] = useState({});

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentEdit, setCurrentEdit] = useState(null);
  const [auditFormData, setAuditFormData] = useState([]);

  const [image, setImage] = useState("");

  const [rules, setRules] = useState([]);

  const [selectedFiles, setSelectedFiles] = useState({});
  const [uploadStatus, setUploadStatus] = useState(null);

  const [checkboxlist, setCheckboxList] = useState(getAllAuditPA);
  const [isRulesModalVisible, setIsRulesModalVisible] = useState(false);
  const [selectedAudit, setSelectedAudit] = useState(null);
  const [selectedAuditIndex, setSelectedAuditIndex] = useState(null);

  const openRulesModal = (auditData, index) => {
    setSelectedAudit(auditData);
    setSelectedAuditIndex(index);
    setIsRulesModalVisible(true);
  }
  // 🔹 Function to close the rules modal
  const closeRulesModal = () => {
    setIsRulesModalVisible(false);
    setSelectedAudit(null);
    setSelectedAuditIndex(null);
  };

  const TransformFileDataDoc = (file) => {
    const reader = new FileReader();
    const updatedRules = getAllAuditPA;

    const fileType = file.type;
    let types = false;
    if (fileType !== "application/pdf") {
      types = true;
      alert("You can only upload PDF file!");
      return false;
    } else {
      types = false;
    }
    if (types === false) {
      // alert('sdsds')
      if (updatedRules) {
        reader.readAsDataURL(updatedRules);
        reader.onloadend = () => {
          setImage(reader.result);
        };
      }
    } else {
      setImage("");
    }
  };

  const [document, setDocument] = useState("");
  const [fileName, setFileName] = useState("");

  const filterAllAuditPA = useSelector((state) => state.filterAllAuditPA);
  const { auditAllFilterInfoPA } = filterAllAuditPA;
  // console.log(auditAllFilterInfoPA);
  useEffect(() => {
    dispatch(categoryGet());
    dispatch(stateGets());
    dispatch(usersGet());
    const elementcompanybranch = myElementRefCompany.current;
    const postBody = {
      id: elementcompanybranch.value,
      state: state || ''
    };
    if (elementcompanybranch) {
      dispatch(branchGet(postBody));
    }
    dispatch(companyTableGet());
    dispatch(auditorGet());
    dispatch(auditGetDataAllPA());
  }, [dispatch]);

  const getBbranch = (company, state) => {
    // const elementcompanybranch = myElementRefCompany1.current;
    // alert(company);return;
    const postBody = {
      id: company,
      state: state || ''
    }
    dispatch(branchGet(postBody));
  }

  const handleFileChange = (
    auditIndex,
    checklistIndex,
    ruleIndex,
    questionIndex,
    file
  ) => {
    const updatedRules = [...getAllAuditPA];
    updatedRules[auditIndex].checkboxlist[checklistIndex].rules[
      ruleIndex
    ].questions[questionIndex].questionDoc = file;
    console.log("updatedRules", updatedRules);
    setDocument(file);
    setFileName(file.name);
    // TransformFileDataDoc(file);
  };
  const handleSubmitNew = async (e) => {
    e.preventDefault();
    const formElement = e.target;
    const formData = new FormData(formElement);

    getAllAuditPA.forEach((audit, auditIndex) => {
      audit.checkboxlist.forEach((checklist, checklistIndex) => {
        checklist.rules.forEach((rule, ruleIndex) => {
          rule.questions.forEach((question, questionIndex) => {
            if (question.questionDoc) {
              formData.append(
                `audit[${auditIndex}]checklist[${checklistIndex}]rule[${ruleIndex}][question][${questionIndex}][questionDoc]`,
                question.questionDoc
              );
            }
            if (question.CompliedStatus) {
              formData.append(
                `audit[${auditIndex}]checklist[${checklistIndex}]rule[${ruleIndex}][question][${questionIndex}][CompliedStatus]`,
                question.CompliedStatus
              );
            }
            if (question.DelayinDays) {
              formData.append(
                `audit[${auditIndex}]checklist[${checklistIndex}]rule[${ruleIndex}][question][${questionIndex}][DelayinDays]`,
                question.DelayinDays
              );
            }
            if (question.RemarksStatus) {
              formData.append(
                `audit[${auditIndex}]checklist[${checklistIndex}]rule[${ruleIndex}][question][${questionIndex}][RemarksStatus]`,
                question.RemarksStatus
              );
            }
            if (question.DateofCompletion) {
              formData.append(
                `audit[${auditIndex}]checklist[${checklistIndex}]rule[${ruleIndex}][question][${questionIndex}][DateofCompletion]`,
                question.DateofCompletion
              );
            }
          });
        });
      });
    });

    try {
      await dispatch(fileUploadInAuditQuestion(formData));
      setUploadStatus("success");
    } catch (error) {
      setUploadStatus("error");
    }
  };
  useEffect(() => {
    dispatch(auditGetDataAllPA());
    // dispatch(updateAuditDueDates());
  }, [dispatch]);
  useEffect(() => {
    dispatch(updateAuditDueDates());
  }, [dispatch]);
  // const AuditUploadStatus = () => {

  //     // Extract the selected audit status from the menu item key
  //     const postBody = {
  //       // approvedate: defaultDate,
  //       // status:1,
  //       audituploadstatus: 0,
  //       // id:selectedRowIds1
  //   }
  //   dispatch(auditGetDataAllPA());//relodreport
  //     // relodreport();
  //     console.log("postBody-0",postBody);

  //     // const selectedStatus = e.key;
  //     // uploadAuditStatus(selectedStatus);
  //   };
  const handleDelayChange = (auditIndex, checklistIndex, ruleIndex, questionIndex, field, value) => {
    console.log("value", value);
    const updatedRules = [...getAllAuditPA];
    updatedRules[auditIndex].checkboxlist[checklistIndex].rules[ruleIndex].questions[questionIndex][field] = value;
    setRules(updatedRules);
    console.log("updatedRules", updatedRules);

  };
  const [expandedRules, setExpandedRules] = useState({});
  const [expandedQues, setExpandedQues] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const toggleExpand = (auditIndex, checklistIndex, ruleIndex) => {
    const key = `${auditIndex}-${checklistIndex}-${ruleIndex}`;
    setExpandedRules((prevState) => {
      const newExpandedRules = { ...prevState, [key]: !prevState[key] };
      // console.log("Toggling expand state:", key, newExpandedRules[key]);
      return newExpandedRules;
    });
  };
  useEffect(() => {
    // Make sure getAllAuditPA is defined and has the expected structure
    if (getAllAuditPA && getAllAuditPA.auditArrAll && getAllAuditPA.auditArrAll.checkboxlist) {
      const initialExpandedQues = {};

      // Loop through the rules and questions
      getAllAuditPA.auditArrAll.checkboxlist.rules.forEach((ruleItem, ruleIndex) => {
        ruleItem.questions.forEach((questionItem, questionIndex) => {
          const key = `${ruleIndex}-${questionIndex}`;
          initialExpandedQues[key] = false;  // Initially set to collapsed
        });
      });

      // Set the initial state for expanded questions
      setExpandedQues(initialExpandedQues);
    }
  }, [getAllAuditPA]);


  const toggleExpandQues = (auditIndex, checklistIndex, ruleIndex, questionIndex) => {
    const key = `${auditIndex}-${checklistIndex}-${ruleIndex}-${questionIndex}`;
    setExpandedQues((prevState) => {
      const newState = { ...prevState, [key]: !prevState[key] };
      console.log("newState", newState);  // Log updated state to check if it's changing correctly
      return newState;
    });
    dispatch(auditGetDataAllPA()); // Trigger the useEffect manually here
  };
  console.log("expandedQues", expandedQues);



  const [isExpanded, setIsExpanded] = useState(false);

  const toggleView = () => {
    setIsExpanded(!isExpanded);
    dispatch(auditGetDataAllPA()); // Trigger the useEffect manually here

  };



  const handleEditClick = (auditId, auditIndex, checklistIndex, ruleIndex, questionIndex) => {
    setCurrentEdit({ auditId, auditIndex, checklistIndex, ruleIndex, questionIndex });
    setIsModalOpen(true);
  };

  // Function to handle modal form submission
  const handleModalSubmit = async (values) => {
    const { DelayinDays, DateofCompletion, CompliedStatus, RemarksStatus, docattachment } = values;

    // Debugging currentEdit to check its structure
    console.log('currentEdit:', currentEdit);

    if (currentEdit) {
      const { auditId, checklistIndex, ruleIndex, questionIndex } = currentEdit;

      // Debugging line to ensure auditId is not undefined
      console.log('auditId:', auditId);

      // Prepare data to be sent to the API
      const auditData = {
        checklistIndex,
        ruleIndex,
        questionIndex,
        DelayinDays,
        DateofCompletion,
        CompliedStatus,
        RemarksStatus,
        // docattachment,
      };
      // 
      try {
        // Set loading state to true
        setIsLoading(true);

        // Dispatch the updateAuditFields action to call the API
        await dispatch(updateAuditFields(auditId, auditData));

        // Fetch the updated audit data
        // await const postBody = { isLBAOrPA: 2 };
        dispatch(auditGetDataAllPA());

        // Close the modal
        setIsModalOpen(false);
        dispatch(auditGetDataAllPA()); // Trigger the useEffect manually here


      } catch (error) {
        console.error("Error updating audit:", error);
        // Handle error (e.g., show error message to the user)
      } finally {
        // Set loading state to false
        setIsLoading(false);
      }
    }
  };



  // const AuditUploadStatus = (auditIndex, status) => {
  //   const auditId = getAllAuditPA[auditIndex]._id;
  //   const postBody = {
  //     audituploadstatus: status,
  //     id: auditId,
  //   };
  //   dispatch(auditUploadStatusValue(postBody));
  //   console.log("postBody", postBody);
  // };
  const AuditUploadStatus = async (auditIndex, status) => {
    try {
      const auditId = getAllAuditPA[auditIndex]._id;
      const postBody = {
        audituploadstatus: status,
        id: auditId,
      };

      // Dispatch the first API call
      await dispatch(auditUploadStatusValue(postBody)); // Assuming this returns a promise
      // console.log("postBody", postBody);

      // Call the next action after the first API is successful
      dispatch(auditGetDataAllPA()); // Trigger the useEffect manually here
    } catch (error) {
      console.error("Error updating audit upload status:", error);
      // Optionally, show an error message to the user
    }
  };

  // const mapDispatchToProps = (dispatch) => ({
  //   uploadAuditStatus: (audituploadstatus) => dispatch(auditGetDataAllPA(audituploadstatus)),
  // });

  const menu = (auditIndex) => (
    <Menu>
      <Menu.Item onClick={() => AuditUploadStatus(auditIndex, 0)}>
        <Button
          style={{
            backgroundColor: "#5E6BCB",
            color: "white",
            width: "100px !important",
          }}
        >
          Sent to Auditor
        </Button>
      </Menu.Item>
      <Menu.Item onClick={() => AuditUploadStatus(auditIndex, 1)}>
        <Button style={{ backgroundColor: "#34953D", color: "white" }}>
          Audit Completed
        </Button>
      </Menu.Item>
      <Menu.Item onClick={() => AuditUploadStatus(auditIndex, 2)}>
        <Button style={{ backgroundColor: "yellow", color: "black" }}>
          Audit in progress
        </Button>
      </Menu.Item>
      <Menu.Item onClick={() => AuditUploadStatus(auditIndex, 3)}>
        <Button style={{ backgroundColor: "red", color: "white" }}>
          Audit Canceled
        </Button>
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    let auditArrAll = [];
    if (typeof getAllAuditPA !== "undefined" && getAllAuditPA?.length > 0) {
      //alert(categoryInfo?.length);
      // console.log("here", getAllAuditPA);
      getAllAuditPA.map((item, auditIndex) => {
        auditArrAll.push({
          key: auditIndex + 1,
          id: item._id,
          title: item.title,
          company: item.company,
          branch: Array.isArray(item.branch) ? (
            <ol>
              {item.branch.map((branchItem, index) => (
                <li key={index}>{branchItem}</li>
              ))}
            </ol>
          ) : (
            item.branch
          ),

          state: item.state,
          compliance: item.compliance,
          rules: (
            <Button
              type="primary"
              onClick={() => openRulesModal(item, auditIndex)}
              style={{ backgroundColor: "#013879", color: "white" }}
            >
              View Act, Rules and Questions
            </Button>
          ),
          //       });
          //     });
          //   }
          // }
          //         ),
          // compliance: item.compliance,
          executive: item.ExecutiveCommon,
          isLBAOrPA: item.isLBAOrPA,
          PAgreement: item.PAgreement,
          auditor: item.auditor,
          start_date: formatDate(item.start_date),
          end_date: formatDate(item.end_date),
          overdue: item.overdue,
          auditstatus:
            item.auditstatus == "1" ? (
              <div style={{ color: "#D89D13" }}>Ongoing</div>
            ) : item.auditstatus == "2" ? (
              <div style={{ color: "#5E6BCB" }}>Submitted</div>
            ) : item.auditstatus == "3" ? (
              <div style={{ color: "#34953D" }}>Approved</div>
            ) : (
              <div style={{ color: "red" }}>Rejected</div>
            ),
          risk:
            item.risk == "Low" ? (
              <div style={{ color: "#34953D" }}>{item.risk}</div>
            ) : item.risk == "High" ? (
              <div style={{ color: "#DF8787" }}>{item.risk}</div>
            ) : item.risk == "Medium" ? (
              <div style={{ color: "#D89D13" }}>{item.risk}</div>
            ) : item.risk == "Very High" ? (
              <div style={{ color: "red" }}>{item.risk}</div>
            ) : (
              <div style={{ color: "red" }}>{item.risk}</div>
            ),

          audituploadstatus: (
            <div>
              {item.audituploadstatus == "0" ? (
                <Button style={{ backgroundColor: "#5E6BCB", color: "white" }}>
                  Sent to Auditor
                </Button>
              ) : item.audituploadstatus == "1" ? (
                <Button style={{ backgroundColor: "#34953D", color: "white" }}>
                  Audit Completed
                </Button>
              ) : item.audituploadstatus == "2" ? (
                <Button style={{ backgroundColor: "#D89D13", color: "black" }}>
                  Audit in progress
                </Button>
              ) : item.audituploadstatus == "3" ? (
                <Button style={{ backgroundColor: "red", color: "white" }}>
                  Audit Canceled
                </Button>
              ) : (
                <Button style={{ backgroundColor: "Blue", color: "white" }}>
                  Audit Start?
                </Button>
              )}
              <div>
                <br />
                <Dropdown overlay={menu(auditIndex)} trigger={["click"]}>
                  <Button>
                    Select Status <DownOutlined />
                  </Button>
                </Dropdown>
              </div>
            </div>
          ),

          score: item.score,
          // documentup: (
          //   <div>
          //     <input
          //     className='w-30'
          //       type="file"
          //       onChange={(e) => handleFileChange(e, item._id)}
          //     />
          //     <button class="w-30 btn btn-primary" onClick={handleSubmit}>Submit</button>
          //   </div>
          // ),
          document: (
            <a href={item.document} target="_blank">
              Document
            </a>
          ),
        });
      });
    }
    setDataSource(auditArrAll);
  }, [getAllAuditPA, expandedRules]);
  useEffect(() => {
    setCompany("");
    setBranch("");
    setState("");
    setStartDate("");
    setEndDate("");
    setAuditor("");
    setUser("");
    setOverDueDate("");
    setStatus("");
    setRisk("");
  }, [getAllAuditPA]);
  useEffect(() => {
    let auditArrFilterAll = [];
    if (
      typeof auditAllFilterInfoPA !== "undefined" &&
      auditAllFilterInfoPA?.length > 0
    ) {
      //alert(categoryInfo?.length);
      auditAllFilterInfoPA.map((item, auditIndex) => {
        auditArrFilterAll.push({
          key: auditIndex + 1,
          id: item._id,
          title: item.title,
          company: item.company,
          branch: Array.isArray(item.branch) ? (
            <ol>
              {item.branch.map((branchItem, index) => (
                <li key={index}>{branchItem}</li>
              ))}
            </ol>
          ) : (
            item.branch
          ),
          state: item.state,
          compliance: item.compliance,
          rules: (
            <Button
              type="primary"
              onClick={() => openRulesModal(item, auditIndex)}
              style={{ backgroundColor: "#013879", color: "white" }}
            >
              View Act, Rules and Questions
            </Button>
          ),
          //       });
          //     });
          //   }
          // }
          //         ),
          // compliance: item.compliance,
          executive: item.executive,
          isLBAOrPA: item.isLBAOrPA,
          PAgreement: item.PAgreement,
          auditor: item.auditor,
          start_date: formatDate(item.start_date),
          end_date: formatDate(item.end_date),
          overdue: item.overdue,
          auditstatus:
            item.auditstatus == "1" ? (
              <div style={{ color: "#D89D13" }}>Ongoing</div>
            ) : item.auditstatus == "2" ? (
              <div style={{ color: "#5E6BCB" }}>Submitted</div>
            ) : item.auditstatus == "3" ? (
              <div style={{ color: "#34953D" }}>Approved</div>
            ) : (
              <div style={{ color: "red" }}>Rejected</div>
            ),
          risk:
            item.risk == "Low" ? (
              <div style={{ color: "#34953D" }}>{item.risk}</div>
            ) : item.risk == "High" ? (
              <div style={{ color: "#DF8787" }}>{item.risk}</div>
            ) : item.risk == "Medium" ? (
              <div style={{ color: "#D89D13" }}>{item.risk}</div>
            ) : item.risk == "Very High" ? (
              <div style={{ color: "red" }}>{item.risk}</div>
            ) : (
              <div style={{ color: "red" }}>{item.risk}</div>
            ),

          audituploadstatus: (
            <div>
              {item.audituploadstatus == "0" ? (
                <Button style={{ backgroundColor: "#5E6BCB", color: "white" }}>
                  Sent to Auditor
                </Button>
              ) : item.audituploadstatus == "1" ? (
                <Button style={{ backgroundColor: "#34953D", color: "white" }}>
                  Audit Completed
                </Button>
              ) : item.audituploadstatus == "2" ? (
                <Button style={{ backgroundColor: "#D89D13", color: "black" }}>
                  Audit in progress
                </Button>
              ) : item.audituploadstatus == "3" ? (
                <Button style={{ backgroundColor: "red", color: "white" }}>
                  Audit Canceled
                </Button>
              ) : (
                <Button style={{ backgroundColor: "Blue", color: "white" }}>
                  Audit Start?
                </Button>
              )}
              <div>
                <br />
                <Dropdown overlay={menu(auditIndex)} trigger={["click"]}>
                  <Button>
                    Select Status <DownOutlined />
                  </Button>
                </Dropdown>
              </div>
            </div>
          ),

          score: item.score,
          // documentup: (
          //   <div>
          //     <input
          //     className='w-30'
          //       type="file"
          //       onChange={(e) => handleFileChange(e, item._id)}
          //     />
          //     <button class="w-30 btn btn-primary" onClick={handleSubmit}>Submit</button>
          //   </div>
          // ),
          document: (
            <a href={item.document} target="_blank">
              Document
            </a>
          ),
        });
      });
    }
    setDataSource(auditArrFilterAll);
  }, [auditAllFilterInfoPA]);
  const formatDate = (currentDate) => {
    const dates = new Date(currentDate);
    const year = dates.getFullYear();
    const month = String(dates.getMonth() + 1).padStart(2, "0");
    const date = String(dates.getDate()).padStart(2, "0");
    const hours = String(dates.getHours()).padStart(2, "0");
    const minutes = String(dates.getMinutes()).padStart(2, "0");
    const seconds = String(dates.getSeconds()).padStart(2, "0");

    const formattedDateTime = `${date}-${month}-${year}`
    // ${hours}:${minutes}:${seconds};
    return formattedDateTime;
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  //aj
  // const handleSubmit = () => {
  //   // Assuming getAllAuditPA is updated here after form submission
  //   const updatedAuditData = getAllAuditPA.map((item) => {
  //     return {
  //       ...item,
  //       documentup: files[item._id],
  //       document: files[item._id],
  //     };
  //   });
  //   console.log(updatedAuditData); // Log or send updatedAuditData to backend
  // };
  const columns = [
    {
      title: "Title/ID",
      dataIndex: "title",
      key: "title",
      width: 60,
      className: "align-top-cell",

      // ...getColumnSearchProps('key'),
      // sorter: (a, b) => a.key.length - b.key.length,
      // sortDirections: ['descend', 'ascend']
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      width: 100,
      className: "align-top-cell",
      // ...getColumnSearchProps('company'),
      // sorter: (a, b) => a.company.length - b.company.length,
      // sortDirections: ['descend', 'ascend']
    },
    {
      title: "Labour-Contract Agreement",
      dataIndex: "LCAgreement",
      key: "LCAgreement",
      width: 100,
      className: "align-top-cell",
      ...getColumnSearchProps('LCAgreement'),
      sorter: (a, b) => a.LCAgreement.length - b.LCAgreement.length,
      sortDirections: ['descend', 'ascend']
    },
    {
      title: "Audit Upload Status",
      dataIndex: "audituploadstatus",
      key: "audituploadstatus",
      width: 100,
      className: "align-top-cell",

      // ...getColumnSearchProps('company'),
      // sorter: (a, b) => a.company.length - b.company.length,
      // sortDirections: ['descend', 'ascend']
    },
    {
      title: <div>{"State"}</div>,
      dataIndex: "state",
      key: "state",
      width: 100,
      className: "align-top-cell",

      ...getColumnSearchProps("state"),
      sorter: (a, b) => a.state.length - b.state.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Branch Name",
      dataIndex: "branch",
      key: "branch",
      width: 100,
      className: "align-top-cell",

      // ...getColumnSearchProps('branchname'),
      // sorter: (a, b) => a.branchname.length - b.branchname.length,
      // sortDirections: ['descend', 'ascend']
    },
    //   {
    //     title: 'Act',
    //     dataIndex: 'act',
    //     key: 'act',
    //     width: 100,
    //     // ...getColumnSearchProps('branchname'),
    //     // sorter: (a, b) => a.branchname.length - b.branchname.length,
    //     // sortDirections: ['descend', 'ascend']
    // },
    {
      title: "Act & Rules",
      dataIndex: "rules",
      key: "rules",
      width: 100,
      className: "align-top-cell",

      // className: "align-top-cell",

      // ...getColumnSearchProps('branchname'),
      // sorter: (a, b) => a.branchname.length - b.branchname.length,
      // sortDirections: ['descend', 'ascend']
    },
    {
      title: "Executive",
      dataIndex: "executive",
      key: "executive",
      width: 100,
      className: "align-top-cell",

      //   ...getColumnSearchProps('act'),
      //   sorter: (a, b) => a.act.length - b.act.length,
      //   sortDirections: ['descend', 'ascend']
    },
    {
      title: "Auditor",
      dataIndex: "auditor",
      key: "auditor",
      width: 100,
      className: "align-top-cell",

      //   ...getColumnSearchProps('act'),
      //   sorter: (a, b) => a.act.length - b.act.length,
      //   sortDirections: ['descend', 'ascend']
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      width: 100,
      className: "align-top-cell",

      // ...getColumnSearchProps('createdAt'),
      // sorter: (a, b) => a.createdAt.length - b.createdAt.length,
      // sortDirections: ['descend', 'ascend']
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      width: 100,
      className: "align-top-cell",

      // ...getColumnSearchProps('createdAt'),
      // sorter: (a, b) => a.createdAt.length - b.createdAt.length,
      // sortDirections: ['descend', 'ascend']
    },

    // {
    //     key: "action",
    //     title: "Actions",
    //     width: 100,
    //     render: (record) => {
    //         //console.log(JSON.stringify(record))
    //       return (
    //           <>
    //           <Link className='text-white btn btn-primary text-decoration-none mx-2' onClick={() => openInPopupForUpdate(record)}> Edit <EditIcon fontSize='mediam' /> </Link>
    //           {/* <DeleteOutlined
    //             onClick={(e) => {
    //               onDeleteUer(record);
    //             }}
    //             style={{ color: "red", marginLeft: 12 }}
    //           /> */}
    //         </>
    //       );
    //     },
    // },
  ];
  const filter = () => {
    // const elementstate = myElementRefState.current;
    // const elementcompany = myElementRefCompany.current;
    // const elementbranch = myElementRefBranch.current;
    // const elementexecuitve = myElementRefExecutive.current;
    // const elementauditor = myElementRefAuditor.current;
    // const elementstartdate = myElementRefStartdate.current;
    // const elementenddate = myElementRefEnddate.current;
    // const elementoverdue = myElementRefOverdue.current;
    // const elementstatus = myElementRefAuditStatus.current;
    // const elementrisk = myElementRefRisk.current;
    const postBody = {
      company: myElementRefCompany.current.value,
      state: myElementRefState.current.value,
      branch: myElementRefBranch.current.value,
      executive: myElementRefExecutive.current.value,
      auditor: myElementRefAuditor.current.value,
      start_date: myElementRefStartdate.current.value,
      end_date: myElementRefEnddate.current.value,
      isLBAOrPA: 2,
      // overdue: myElementRefOverdue.current.value,
      // auditstatus: myElementRefAuditStatus.current.value,
      // risk: myElementRefRisk.current.value,
    };
    dispatch(auditAllFiltersLCA(postBody));
  };
  let day = [
    "0 Days",
    "1 Days",
    "2 Days",
    "3 Days",
    "4 Days",
    "5 Days",
    "6 Days",
    "7 Days",
    "8 Days",
    "9 Days",
    "10 Days",
    "11 Days",
    "12 Days",
    "13 Days",
    "14 Days",
    "15 Days",
  ];
  return (
    <React.Fragment>
      <h3 className="form-heading" style={{ textAlign: 'center', color: '#013879' }}>Principle Contract Agreement Audit</h3>
      <div className="row">
        <div className="row g-3">
          <div className="col-md-15 col-lg-15">
            <select
              className="form-select"
              ref={myElementRefCompany}
              aria-label="Default select example"
              id="company"
              name="company"
              value={company}
              onChange={(e) => {
                setCompany(e.target.value);
                filter();
                const selectedCompany = e.target.value;
                getBbranch(selectedCompany, state); // Pass current state value
              }}
              required
            >
              <option value="">Select Company</option>
              {companyGetTableInfo != "undefind" &&
                companyGetTableInfo?.length > 0 &&
                companyGetTableInfo.map((item) => (
                  <option value={item._id}>{item.companyname}</option>
                ))}
              ;
            </select>
          </div>
          <div className="col-md-15 col-lg-15">
            <select
              className="form-select"
              ref={myElementRefState}
              aria-label="Default select example"
              id="state"
              name="state"
              value={state}
              onChange={(e) => {
                setState(e.target.value);
                const selectedState = e.target.value;
                getBbranch(company, selectedState); // Pass current company value
                // Pass current state value
                filter();
              }}
              required
            >
              <option value="">Select State</option>
              {stateInfo != "undefind" &&
                stateInfo?.length > 0 &&
                stateInfo.map((item) => (
                  <option value={item._id}>{item.name}</option>
                ))}
              ;
            </select>
          </div>
          <div className="col-md-15 col-lg-15">
            <select
              className="form-select"
              ref={myElementRefBranch}
              aria-label="Default select example"
              id="branch"
              name="branch"
              onChange={(e) => {
                setBranch(e.target.value); // Update branch state with the selected branch ID
                filter(); // Call the filter function to include branch ID in the payload
              }}
              value={branch}
              required
            >
              <option value="">Select Branch</option>
              {branchInfo != "undefind" &&
                branchInfo?.length > 0 &&
                branchInfo.map((item) => (
                  <option value={item.id} >
                    {item.name}
                  </option>
                ))}
            </select>

          </div>
          <div className="col-md-15 col-lg-15">
            <select
              className="form-select"
              ref={myElementRefExecutive}
              aria-label="Default select example"
              name="users"
              value={user}
              onChange={(e) => {
                setUser(e.target.value);
                filter();
              }}
            >
              <option value="">Select Executive</option>
              {usersInfo != "undefind" &&
                usersInfo?.length > 0 &&
                usersInfo.map((item) => (
                  <option value={item._id}>
                    {item.firstName} {item.lastName}
                  </option>
                ))}
              ;
            </select>
          </div>
          <div className="col-md-15 col-lg-15">
            <select
              className="form-select"
              ref={myElementRefAuditor}
              aria-label="Default select example"
              name="users"
              value={auditor}
              onChange={(e) => {
                setAuditor(e.target.value);
                filter();
              }}
            >
              <option value="">Select Auditor</option>
              {auditorInfo != "undefind" &&
                auditorInfo?.length > 0 &&
                auditorInfo.map((item) => (
                  <option value={item._id}>
                    {item.firstName} {item.lastName}
                  </option>
                ))}
              ;
            </select>
          </div>
          <div className="col-md-15 col-lg-15">
            <label
              for=""
              class="form-label"
              style={{ fontSize: "13px", fontWeight: "bold" }}
            >
              Start Date
            </label>
            <input
              type="date"
              className="form-control"
              ref={myElementRefStartdate}
              id="startDate"
              placeholder="start Date"
              value={dateStart}
              onChange={(e) => {
                setStartDate(e.target.value);
                filter();
              }}
            />
          </div>
          <div className="col-md-15 col-lg-15">
            <label
              for=""
              class="form-label"
              style={{ fontSize: "13px", fontWeight: "bold" }}
            >
              End Date
            </label>
            <input
              type="date"
              className="form-control"
              ref={myElementRefEnddate}
              id="endDate"
              placeholder="End Date"
              value={dateEnd}
              onChange={(e) => {
                setEndDate(e.target.value);
                filter();
              }}
            />
          </div>
          {/* <div className="col-md-15 col-lg-15">
            <label
              for=""
              class="form-label"
              style={{ fontSize: "13px", fontWeight: "bold" }}
            >
              Overdue Days
            </label>
            <select
              type="date"
              className="form-select"
              ref={myElementRefOverdue}
              id="overDueDate"
              placeholder="Over Due"
              value={dateOverDue}
              onChange={(e) => {
                setOverDueDate(e.target.value);
                filter();
              }}
            >
              <option value="">Over Due Days</option>
              {day.map((item, auditIndex) => (
                <option value={auditIndex}>{item}</option>
              ))}
            </select>
          </div>
          <div className="col-md-15 col-lg-15">
            <label
              for=""
              class="form-label"
              style={{ fontSize: "13px", fontWeight: "bold" }}
            >
              Status
            </label>
            <select
              className="form-select"
              ref={myElementRefAuditStatus}
              aria-label="Default select example"
              name="status"
              value={status}
              onChange={(e) => {
                setStatus(e.target.value);
                filter();
              }}
              required
            >
              <option value="">Select Status</option>
              <option value="1">Ongoing</option>
              <option value="2">Submitted</option>
              <option value="3">Approved</option>
              <option value="4">Rejected</option>
            </select>
          </div>
          <div className="col-md-15 col-lg-15">
            <label
              for=""
              class="form-label"
              style={{ fontSize: "13px", fontWeight: "bold" }}
            >
              Risk
            </label>
            <select
              className="form-select"
              id="risk"
              ref={myElementRefRisk}
              aria-label="Default select example"
              name="risk"
              value={risk}
              onChange={(e) => {
                setRisk(e.target.value);
                filter();
              }}
              required
            >
              <option value="">Select Risk</option>
              <option value="Low">Low</option>
              <option value="Medium">Medium</option>
              <option value="High">High</option>
              <option value="Very High">Very High</option>
            </select>
          </div> */}
        </div>
        <div className="col-12 col-lg-12 mt-4">
          <div className="card p-3 overflow-hidden">
            <div className="table-responsive all_tbl">
              <div className="table-responsive">
                {loadingallAuditPA ? (
                  <div className="d-flex justify-content-center align-items-center" style={{ height: "200px" }}>
                    <Spin size="large" />
                  </div>
                ) : (
                  <Table
                    dataSource={dataSource}
                    columns={columns}
                    pagination={{
                      pageSize: 4,
                      showSizeChanger: false,
                      position: ["bottomCenter"],
                    }}
                    scroll={{ x: 2000 }}
                    sticky={true}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Audit Rules"
        visible={isRulesModalVisible}
        onCancel={closeRulesModal}
        footer={[
          <Button key="close" onClick={closeRulesModal}>
            Close
          </Button>,
        ]}
        width={800} // Adjust width as needed
      >
        {selectedAudit && (
          <div className="container my-4">
            {selectedAudit.checkboxlist.map((checklistItem, checklistIndex) => (
              <div
                style={{
                  backgroundColor: "#013879",
                  color: "white",
                  border: "1px white solid",
                }}
                className="card-header"
                key={checklistIndex}
              >
                <h4 style={{ backgroundColor: "#013879", color: "white" }}>
                  Act: {checklistItem?.act}
                </h4>
                {checklistItem.rules.map((ruleItem, ruleIndex) => (
                  <div className="card mb-3" key={ruleIndex}>
                    <div
                      className="card-header text-white"
                      style={{ backgroundColor: "#05304c" }}
                    >
                      <h5 className="mb-0">
                        Rule-{ruleIndex + 1}: {ruleItem.rule}
                      </h5>
                      {/* <button
                              onClick={() =>
                                toggleExpand(selectedAuditIndex, checklistIndex, ruleIndex)
                              }
                              className="btn btn-link"
                              style={{ color: "white" }}
                            >
                              {expandedRules[
                                `${selectedAuditIndex}-${checklistIndex}-${ruleIndex}`
                              ]
                                ? "View Less"
                                : "View More"}
                            </button> */}
                    </div>
                    {/* {expandedRules[
                            `${selectedAuditIndex}-${checklistIndex}-${ruleIndex}`
                          ] && ( */}
                    <div className="card-body bg-light fade-in">
                      <div className="mb-2">
                        <a
                          href={ruleItem.docFile}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Document Rule {ruleIndex + 1}
                        </a>
                      </div>
                      {ruleItem.questions.map((questionItem, questionIndex) => (
                        <div key={questionIndex} style={{ border: '1px solid black', borderRadius: '8px', padding: '5px', gap: '5px' }}>
                          <strong>Question-{questionIndex + 1}:</strong>{questionItem.question}
                          <button
                            onClick={() =>
                              toggleExpandQues(selectedAuditIndex, checklistIndex, ruleIndex, questionIndex)
                            }
                            className="btn btn-link"
                            style={{ color: "#013879" }}
                          >
                            {expandedQues[`${selectedAuditIndex}-${checklistIndex}-${ruleIndex}-${questionIndex}`]
                              ? "View Less"
                              : "View More"}
                          </button>

                          {expandedQues[`${selectedAuditIndex}-${checklistIndex}-${ruleIndex}-${questionIndex}`] && (
                            <div className="card mb-3">
                              <div className="card-body">
                                <h6 className="card-title">
                                  {/* <strong>Question-{questionIndex + 1}:</strong> {questionItem.question} */}
                                </h6>
                                {isExpanded && (
                                  <>
                                    <hr />
                                    <p className="card-text">
                                      <strong>Description:</strong> {questionItem.description}
                                    </p>
                                    <hr />
                                    <p className="card-text">
                                      <strong>Compliance Activity:</strong> {questionItem.compliancetype}
                                    </p>
                                    <hr />
                                    <p className="card-text">
                                      <strong>Consequences:</strong> {questionItem.consequences}
                                    </p>
                                  </>
                                )}
                                <button className="btn btn-link" onClick={toggleView}>
                                  {isExpanded ? "Hide Details" : "View Details"}
                                </button>
                                <hr />
                                <p className="card-text">
                                  <strong>Frequency:</strong> {questionItem.frequency}
                                </p>
                                <hr />
                                <p className="card-text">
                                  <strong>Category:</strong> {questionItem.categorycomp}
                                </p>
                                <hr />
                                <p className="card-text">
                                  <strong>Risk:</strong> {questionItem.risk}
                                </p>
                                <hr />
                                <p className="card-text">
                                  <strong>Start Date:</strong> {questionItem.startDate}
                                </p>
                                <hr />
                                <p className="card-text">
                                  <strong>Due Date:</strong> {questionItem.dueDate}
                                </p>
                                <hr />
                                <p className="card-text">
                                  <strong>Date of Completion:</strong> {questionItem.DateofCompletion}
                                </p>
                                <hr />
                                <p className="card-text">
                                  <strong>Delay in Days:</strong> {questionItem.DelayinDays}
                                </p>
                                <hr />
                                <p className="card-text">
                                  <strong>Complied Status:</strong> {questionItem.CompliedStatus}
                                </p>
                                <hr />
                                <p className="card-text">
                                  <strong>Remarks:</strong> {questionItem.RemarksStatus}
                                </p>
                                <hr />
                                <Button
                                  onClick={() =>
                                    handleEditClick(selectedAudit?._id, selectedAuditIndex, checklistIndex, ruleIndex, questionIndex)
                                  }

                                >
                                  Edit
                                </Button>
                                <div className="card-text">
                                  <a
                                    href={questionItem.docattachment}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Document Question {questionIndex + 1}
                                  </a>
                                  <hr />
                                  <form
                                    onSubmit={handleSubmitNew}
                                    style={{ display: "flex", gap: "165px" }}
                                  >
                                    <div>
                                      <input
                                        type="file"
                                        onChange={(e) =>
                                          handleFileChange(
                                            selectedAuditIndex,
                                            checklistIndex,
                                            ruleIndex,
                                            questionIndex,
                                            e.target.files[0]
                                          )
                                        }
                                      />
                                    </div>
                                    <button
                                      className="w-80"
                                      style={{
                                        backgroundColor: "#013879",
                                        color: "white",
                                        borderRadius: "6px",
                                        height: "35px !important",
                                        width: "110px",
                                        border: "none",
                                      }}
                                      type="submit"
                                    >
                                      Upload File
                                    </button>
                                  </form>
                                  {uploadStatus === "success" && (
                                    <Checkmark
                                      style={{
                                        width: "20px",
                                        position: "relative",
                                        top: "15px",
                                      }}
                                      size="16px"
                                    />
                                  )}
                                  {uploadStatus === "error" && (
                                    <p style={{ color: "red" }}>Upload failed. Please try again.</p>
                                  )}
                                  <hr />
                                  <div>
                                    <a
                                      href={questionItem.questionDoc}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      Document Audit {questionIndex + 1}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {/* <br/> */}
                        </div>

                      ))}

                    </div>
                    {/* )} */}
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
      </Modal>
      <Modal
        title="Edit Question"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
      >
        <Form onFinish={handleModalSubmit} layout="vertical">
          <Form.Item
            label="Delay in Days"
            name="DelayinDays"
          // rules={[{ required: true, message: 'Please enter the delay in days' }]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            label="Date of Completion"
            name="DateofCompletion"
          // rules={[{ required: true, message: 'Please enter the date of completion' }]}
          >
            <Input type="date" />
          </Form.Item>
          <Form.Item
            label="Complied Status"
            name="CompliedStatus"
          // rules={[{ required: true, message: 'Please select the compliance status' }]}
          >
            <select
              className="custom-select"
            >
              <option value="Pending">Pending</option>
              <option value="Pending for Descrepency">Pending for Descrepency</option>
              <option value="Complied">Complied</option>
              <option value="Partially Complied">Partially Complied</option>
              <option value="Not Complied">Not Complied</option>
              <option value="Not Applicable">Not Applicable</option>
            </select>
          </Form.Item>
          <Form.Item
            label="Remarks Status"
            name="RemarksStatus"
          // rules={[{ required: true, message: 'Please enter the remarks status' }]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" isLoading={isLoading}>Save Changes</Button>
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default AuditForPA;
