import React,{useState,useEffect,useRef} from 'react'
import { Link,NavLink, useNavigate } from 'react-router-dom'
import { FormLabel,styled} from '@mui/material';
import { Modal } from 'antd';
import {stateGets,branchGet,companyGet,auditGetDataAll,auditOnCreate,checklistGetonCreateAudit,companyTableGet} from "../../store/actions/otherActions";
import { useDispatch,useSelector } from 'react-redux';
import AuditTable from './AuditTable';
import AuditChecklistTable from './AuditChecklistTable';
import Checkilist from '../Checklist/Checklist'
import checklist from '../../../src/checklist.jpg'
const AudNotification = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [auditor, setAuditor] = useState();
    const [title,setTitle] = useState('');
    const [fileto,setFile] = useState('');
    const [startDate,setStartDate] = useState(''); 
    const [endDate,setEndDate] = useState('');
    const [auditstatus,setAuditStatus] = useState('');
    const [risk,setRisk] = useState('');
    const [endDateDisabled, setEndDateDisabled] = useState(true);
    const [scope,setScope] = useState('');
    const [briefauditor,setBriefAuditor] = useState('');  
    const [value, setValue] = useState('Select Checklist');
    const [state, setState] = useState('');
    const [company, setCompany] = useState('');
    const [branch,setBranch] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);  
    const [error, setError] = useState(''); 
    const myElementRefTable = useRef(null);
    const myElementRefTitle = useRef(null);
    const myElementRefState = useRef(null);
    const myElementRefStartdate = useRef(null);
    const myElementRefEnddate = useRef(null); 
    const myElementRefRisk = useRef(null);
    const myElementRefAuditStatus = useRef(null);
    const myElementRefScope = useRef(null);
    const myElementRefAuditor = useRef(null);
    const myElementRefBriefAuditor = useRef(null); 
    const myElementRefCompany = useRef(null);
    const myElementCompany = useRef(null);
    const myElementRefBranch = useRef(null);
    const myElementRefTableInput = useRef(null);
    const myElementRefTab1 = useRef(null);
    const getState = useSelector((state) => state.getState);
    const { loadings,stateInfo } = getState;  
    const getBranch = useSelector((state) => state.getBranch);
    const { branchInfo } = getBranch; 
    const getCompanyTable = useSelector(state => state.getCompanyTable)
    const {loadingcompanytable, companyGetTableInfo } = getCompanyTable;
    const createOnAudit = useSelector((state) => state.createOnAudit);
    const { auditorCreateInfo } = createOnAudit;
    const getAuditor = useSelector((state) => state.getAuditor);
    const { auditorInfo } = getAuditor; 
    const onCreateChecklistAudit = useSelector((state) => state.onCreateChecklistAudit);
    const { loadingoncreate,auditorChecklistInfoOncreate } = onCreateChecklistAudit; 
    useEffect(()=>{
        dispatch(stateGets());
        // const elementcompanybranch = myElementCompany.current;
        // const postBody = {
        //   id : elementcompanybranch.value
        // }
        // if (elementcompanybranch) {
        //   dispatch(branchGet(postBody));
        // }
        dispatch(companyTableGet());
        dispatch(auditGetDataAll())
        // dispatch(getExecutive())
        dispatch(checklistGetonCreateAudit());
    },[dispatch]);
    const getBbranchs = (company) => {
        // const elementcompanybranch = myElementRefCompany1.current;
        // alert(company);return;
        const postBody = {
         id : company
       }
        dispatch(branchGet(postBody));
      }
    useEffect(()=>{
        setAuditor('');
        setTitle('');
        setEndDate('');
        setScope('');
        setBriefAuditor('');
        setState('');
        setCompany('');
        setBranch('');
        setStartDate('');
        setRisk('');
        setAuditStatus('');
        setSelectedRows([])
        const elementtitle = myElementRefTitle.current;
        const elementcompany = myElementRefCompany.current;
        const elementstate = myElementRefState.current;
        const elementbranch = myElementRefBranch.current;
        const elementsdate = myElementRefStartdate.current;
        const elementedate =  myElementRefEnddate.current;
        const elementrisk = myElementRefRisk.current;
        const elementauditstatus = myElementRefAuditStatus.current;
        const elementscope =  myElementRefScope.current;
        const elementauditor =  myElementRefAuditor.current;
        const elementbauditor =  myElementRefBriefAuditor.current;
        // elementtitle.innerText='';
        // elementcompany.innerText='';
        // elementstate.innerText='';
        // elementbranch.innerText='';
        // elementsdate.innerText='';
        // elementedate.innerText='';  
        // // elementrisk.innerText='';
        // elementauditstatus.innerText='';
        // elementscope.innerText='';
        // elementauditor.innerText='';
        // elementbauditor.innerText='';
        // unsetRefValue(elementtitle);
        // unsetRefValue(elementcompany);
        // unsetRefValue(elementstate);
        // unsetRefValue(elementbranch);
        // unsetRefValue(elementrisk);
        // unsetRefValue(elementauditstatus);
        // unsetRefValue(elementauditor);
        // unsetRefValue(elementbauditor);
        
        const elementtable= myElementRefTable.current;
        if(typeof(myElementRefTable) === 'object') {
            // elementtable.style.display = 'none';
        }
        const elementtableinput = myElementRefTableInput.current;
        if(elementtableinput)
        {
           elementtableinput.innerText='';
        } 
        // unsetRefValue(elementtable);
        // unsetRefValue(elementtableinput);
    },[]);
    const showtable = () => {
        const elementtable = myElementRefTable.current;
        const elementtableinput = myElementRefTableInput.current;
        // elementtable.style.display = 'inline';
        // const elementtable = myElementRefTable.current;
        if(elementtableinput)
        {
             elementtableinput.innerText='';
        } 
    }
    const handleStartDateChange = (event) => {
        const selectedStartDate = event.target.value;
        setStartDate(selectedStartDate);
        if (endDate && selectedStartDate > endDate) {
        setEndDate(null);
        }
        
    };
    const handleEndDateChange = (event) => {
        const selectedEndDate = event.target.value;
        setEndDate(selectedEndDate);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const elementtitle = myElementRefTitle.current;
        const elementcompany = myElementRefCompany.current;
        const elementstate = myElementRefState.current;
        const elementbranch = myElementRefBranch.current;
        const elementsdate = myElementRefStartdate.current;
        const elementedate =  myElementRefEnddate.current;
        const elementrisk = myElementRefRisk.current;
        const elementauditstatus = myElementRefAuditStatus.current;
        const elementscope =  myElementRefScope.current;
        const elementauditor =  myElementRefAuditor.current;
        const elementbauditor =  myElementRefBriefAuditor.current;
        let i=true;
        //alert(typeof(title))
        // alert(i)
        if(i===true)
        {
            // showtable();
            // alert('start')
            const formData = new FormData();
            formData.append("title", title);
            formData.append("state", state);
            formData.append("company", company);
            formData.append("branch", branch);
            formData.append("start_date", startDate);
            formData.append("end_date", endDate);
            formData.append("executive", '659d4f2609c9923c9e7b8f72');
            formData.append("auditor", auditor);
            formData.append("scope", scope);
            formData.append("briefauditor", briefauditor);
            // formData.append("checkboxlist", selectedRowIds);
            formData.append("risk", risk);
            formData.append("auditstatus", auditstatus);
            // alert('end')
            dispatch(auditOnCreate(formData));
            // alert('after')
            setAuditor('');
            setTitle('');
            setStartDate('');
            setEndDate('');
            setScope('');
            setBriefAuditor('');
            setState('');
            setCompany('');
            setBranch('');
            setRisk('');
            setAuditStatus('');
            setSelectedRows([])
            const elementtitle = myElementRefTitle.current;
            const elementcompany = myElementRefCompany.current;
            const elementstate = myElementRefState.current;
            const elementbranch = myElementRefBranch.current;
            const elementsdate = myElementRefStartdate.current;
            const elementedate =  myElementRefEnddate.current;
            const elementrisk = myElementRefRisk.current;
            const elementauditstatus = myElementRefAuditStatus.current;
            const elementscope =  myElementRefScope.current;
            const elementauditor =  myElementRefAuditor.current;
            const elementbauditor =  myElementRefBriefAuditor.current;
            // alert(typeof(myElementRefTable))
            // if(typeof(myElementRefTable) === 'object') {
            //     elementtable.style.display = 'none';
            // }
            // const elementtableinput = myElementRefTableInput.current;
            // if(elementtable)
            // {
            //    elementtableinput.innerText='';
            // } 
            
            // elementtitle.innerText='';
            // elementcompany.innerText='';
            // elementstate.innerText='';
            // elementbranch.innerText='';
            // elementsdate.innerText='';
            // elementedate.innerText='';  
            // elementrisk.innerText='';
            // elementauditstatus.innerText='';
            // elementscope.innerText='';
            // elementauditor.innerText='';
            // elementbauditor.innerText='';
            // unsetRefValue(elementtitle);
            // unsetRefValue(elementcompany);
            // unsetRefValue(elementstate);
            // unsetRefValue(elementbranch);
            // unsetRefValue(elementrisk);
            // unsetRefValue(elementauditstatus);
            // unsetRefValue(elementauditor);
            // unsetRefValue(elementbauditor);
            // unsetRefValue(elementtableinput);
            // unsetRefValue(elementtable);
            // // setTimeout(() => {
            // //     navigate(0);
            // // }, 5000);
            
            // const elementTab1 = myElementRefTab1.current;
            // if (elementTab1) {
            //     elementTab1.click();
            // }
        }
        
        // console.log('Selected Row IDs:', selectedRowIds);
        // dispatch(auditOnCreate())
    }   
    const unsetRefValue = (ref) => {
        ref = null;
    };
    const viewall = () => {
        setTimeout(() => {
            dispatch(auditGetDataAll());
        }, 2000);
    }
    const createnew = () => {
        setTimeout(() => {
            dispatch(checklistGetonCreateAudit());
        }, 2000);
    }
    const tocategorypage = () => {
        navigate('/dashboard')
    };
    const filter = () => {
        const elementstate = myElementRefState.current;
        const elementcompany = myElementRefCompany.current;
        const elementbranch = myElementRefBranch.current;
        // const elementdate = myElementRefDate.current;
        const postBody = {
            // created_at:elementdate.value,
            state:elementstate.value,
            company:elementcompany.value,
            branch:elementbranch.value
        }
        // dispatch(checklistCreateFilters(postBody));
    }  
    const handleProductImageUpload = (e) => {
        const file = e.target.files[0];
       // alert(JSON.stringify(file))
        setFile(file);
        TransformFileData(file);
    };
    //reading image using The FileReader object lets web applications asynchronously read the contents of files (or raw data buffers) stored on the user's computer, using File or Blob objects to specify the file or data to read.
    const TransformFileData = (file) => {
        const reader = new FileReader();
        const fileType =file.type;
        let types = false; 
        if(fileType!=="image/jpeg" && fileType!=="image/bmp" && fileType!=="image/jpg" && fileType!=="image/png" && fileType!=="application/pdf"){
            types = true; 
            alert('You can only upload JPG/JPEG/PNG/BMP/PDF file!');
            return false;
        }
        else{
            types = false;
        }
        if(types===false){
         //   alert('sdsds')
            if (file) {
                reader.readAsDataURL(file);
                reader.onloadend = () => {
                //   setImage(reader.result);
                }
            }
        }
        else{
            // setImage("");
        }
     
    }; 
    return (
        <React.Fragment>
            <div className='dashboard_wrapper'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                          <div className='card'>
                            <div className='card-body'>
                            <ul className="nav nav-pills mb-3 bg-light" id="pills-tab" role="tablist">
                                <li className="nav-item col-md-6 col-lg-6 col-12" role="presentation">
                                    {/* <button className="nav-link w-100 active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" ref={myElementRefTab1} type="button" role="tab" aria-controls="pills-home" aria-selected="true" onClick={viewall}>View All</button> */}
                                </li>
                                <li className="nav-item col-md-6 col-lg-6 col-12" role="presentation">
                                    {/* <button className="nav-link w-100" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false" onClick={createnew}>Create New</button> */}
                                </li>
                            </ul>
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                    <AuditTable />
                                </div>
                                <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                </div>
                            </div>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AudNotification;
const Spanning =  styled(FormLabel)`
color: red;
font-size:13px;
`