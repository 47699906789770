import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, Select, Form, Upload, message, Spin } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import {
  createBranches,
  BranchesUpdateById,
  companyTableGet,
  BranchesGetById,
  stateGets,
} from "../../store/actions/otherActions"; // Redux actions
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const { Option } = Select;

const TestBranch = ({ addOrEdit, recordForEdit,setOpenPopup }) => {
  console.log("addOrEdit", addOrEdit);
  console.log("recordForEdit", recordForEdit);
  //    alert(recordForEdit.id);

  const dispatch = useDispatch();
  const getCompanyTable = useSelector((state) => state.getCompanyTable);
  const { loadingcompanytable, companyGetTableInfo } = getCompanyTable;
  const stateInfo = useSelector((state) => state.getState.stateInfo || []);
  const [selectedState, setSelectedState] = useState("");

  useEffect(() => {
    dispatch(stateGets());
  }, [dispatch]);

  // const handleSelectChange = (e) => {
  //   setSelectedState(e.target.value);
  // };

   // Handle the change in select dropdown
   const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value, // The value will be state.name
    });
  };
  

  const [formData, setFormData] = useState({
    company: "",
    name: "",
    details: "",
    branchimage: "",
    remarks: "",
    branchaddress: "",
    branchstate: "",
    branchdistrict: "",
    branchpin: "",
    contractorAddBranchFimage: "",
    contractorAddBranchFRemark: "",
    branchOpeningDateF: "",
    noOfEmpBranchF: "",
    managerNameF1: "",
    managerNameF1image: "",
    managerNameF1Remark: "",
    managerMobNoF1: "",
    managerMobNoF1Remark: "",
    managerEmailF1: "",
    managerEmailF1Remark: "",
    managerAadharNoF1: "",
    managerAadharNoF1image: "",
    managerAadharNoF1Remark: "",
    managerPanF1: "",
    managerPanF1image: "",
    managerPanF1Remark: "",
    shopsEstLicenseF2: "",
    shopsEstLicenseF2image: "",
    shopsEstLicenseF2Remark: "",
    contractLabRegNoF5: "",
    contractLabRegNoF5image: "",
    contractLabRegNoF5Remark: "",
    regDateContractorF5: "",
    coOfContractEmpF5: "",
    noOfContractorsF5: "",
    contractorNameF51: "",
    contractorNameF51image: "",
    contractorNameF51Remark: "",
    establishmentNameF51: "",
    establishmentNameF51image: "",
    establishmentNameF51Remark: "",
    regisocontractaddress: "",
    regStateContractorF51: "",
    regDistContractorF51: "",
    regPinContractorF51: "",
    regAddContractorF51image: "",
    regAddContractorF51Remark: "",
    expiryDateF52: "",
    renewalDateF52: "",
    natureOfWorkF52: "",
    natureOfWorkF52image: "",
    natureOfWorkF52Remark: "",
    noOfEmpDeployedF52: "",
    companyTypeF53: "",
    companyTypeF53image: "",
    companyTypeF53Remark: "",
    contractLabLicNoF53: "",
    contractLabLicNoF53image: "",
    contractLabLicNoF53Remark: "",
    licenseDateF53: "",
    expiryDateF53: "",
    renewalDateF53: "",
    noOfWorkerF53: "",
    panF53: "",
    panF53image: "",
    panF53Remark: "",
    gstF53: "",
    gstF53image: "",
    gstF53Remark: "",
    esicRegF53: "",
    esicRegF53image: "",
    esicRegF53Remark: "",
    pfRegContractorsE3: "",
    pfRegContractorsE3image: "",
    pfRegContractorsE3Remark: "",
    shopsandEstContractorsE3: "",
    shopsandEstContractorsE3image: "",
    shopsandEstContractorsE3Remark: "",
    lwfRegContractorsE3: "",
    lwfRegContractorsE3image: "",
    lwfRegContractorsE3Remark: "",
    profTaxContractorsE3: "",
    profTaxContractorsE3image: "",
    profTaxContractorsE3Remark: "",
    licensenumber: "",
    licenseimage: "",
    licenseremark: "",
    dor: "",
    doe: "",
    doddr: "",
    managernamelicense: "",
    managerlicenseimage: "",
    managerlicenseremark: "",
    noe: "",
    nom: "",
    nof: "",
    issuingauth: "",
    issuingauthimage: "",
    issuingauthremark: "",
    licensenumber1: "",
    licenseimage1: "",
    licenseremark1: "",
    dor1: "",
    doe1: "",
    doddr1: "",
    managernamelicense1: "",
    managerlicenseimage1: "",
    managerlicenseremark1: "",
    noe1: "",
    nom1: "",
    nof1: "",
    issuingauth1: "",
    issuingauthimage1: "",
    issuingauthremark1: "",
    licensenumber2: "",
    licenseimage2: "",
    licenseremark2: "",
    dor2: "",
    issuingauth2: "",
    issuingauthimage2: "",
    issuingauthremark2: "",
    dateofclosure: "",
    // Additional fields
    powerHP: "",
    powerHPremark2: "",
    powerKW: "",
    powerKWremark2: "",
    ApprovedPower: "",
    ApprovedPowerremark2: "",
    // NSP
    licensenumberNSP: "",
    licenseimageNSP: "",
    licenseremarkNSP: "",
    dorNSP: "",
    doeNSP: "",
    doddrNSP: "",
    issuingauthNSP: "",
    issuingauthdetailsNSP: "",
    issuingauthimageNSP: "",
    issuingauthremarkNSP: "",
    //OTP
    licensenumberOTP: "",
    licenseimageOTP: "",
    licenseremarkOTP: "",
    dorOTP: "",
    doeOTP: "",
    doddrOTP: "",
    issuingauthOTP: "",
    issuingauthdetailsOTP: "",
    issuingauthimageOTP: "",
    issuingauthremarkOTP: "",
    //TL
    licensenumberTL: "",
    licenseimageTL: "",
    licenseremarkTL: "",
    dorTL: "",
    doeTL: "",
    doddrTL: "",
    issuingauthTL: "",
    issuingauthdetailsTL: "",
    issuingauthimageTL: "",
    issuingauthremarkTL: "",
    //WOE
    licensenumberWOE: "",
    licenseimageWOE: "",
    licenseremarkWOE: "",
    dorWOE: "",
    doeWOE: "",
    doddrWOE: "",
    issuingauthWOE: "",
    issuingauthdetailsWOE: "",
    issuingauthimageWOE: "",
    issuingauthremarkWOE: "",
    isFactoryOrSE: "",
    // isContracorsYesorNo: "",
    isNightShiftPermission: "",
  });
  useEffect(() => {
    if (recordForEdit) {
      setFormData((prev) => ({
        ...prev,
        company: recordForEdit.company || "",
        name: recordForEdit.name || "",
        details: recordForEdit.details || "",
        branchimage: recordForEdit.branchimage || "",
        remarks: recordForEdit.remarks || "",
        branchaddress: recordForEdit.branchaddress || "",
        branchstate:
          recordForEdit.branchstate?._id || recordForEdit.branchstate || "", // Handle both cases
        branchdistrict: recordForEdit.branchdistrict || "",
        branchpin: recordForEdit.branchpin || "",
        contractorAddBranchFimage:
          recordForEdit.contractorAddBranchFimage || "",
        contractorAddBranchFRemark:
          recordForEdit.contractorAddBranchFRemark || "",
        branchOpeningDateF: recordForEdit.branchOpeningDateF || "",
        noOfEmpBranchF: recordForEdit.noOfEmpBranchF || "",
        managerNameF1: recordForEdit.managerNameF1 || "",
        managerNameF1image: recordForEdit.managerNameF1image || "",
        managerNameF1Remark: recordForEdit.managerNameF1Remark || "",
        managerMobNoF1: recordForEdit.managerMobNoF1 || "",
        managerMobNoF1Remark: recordForEdit.managerMobNoF1Remark || "",
        managerEmailF1: recordForEdit.managerEmailF1 || "",
        managerEmailF1Remark: recordForEdit.managerEmailF1Remark || "",
        managerAadharNoF1: recordForEdit.managerAadharNoF1 || "",
        managerAadharNoF1image: recordForEdit.managerAadharNoF1image || "",
        managerAadharNoF1Remark: recordForEdit.managerAadharNoF1Remark || "",
        managerPanF1: recordForEdit.managerPanF1 || "",
        managerPanF1image: recordForEdit.managerPanF1image || "",
        managerPanF1Remark: recordForEdit.managerPanF1Remark || "",
        shopsEstLicenseF2: recordForEdit.shopsEstLicenseF2 || "",
        shopsEstLicenseF2image: recordForEdit.shopsEstLicenseF2image || "",
        shopsEstLicenseF2Remark: recordForEdit.shopsEstLicenseF2Remark || "",
        contractLabRegNoF5: recordForEdit.contractLabRegNoF5 || "",
        contractLabRegNoF5image: recordForEdit.contractLabRegNoF5image || "",
        contractLabRegNoF5Remark: recordForEdit.contractLabRegNoF5Remark || "",
        regDateContractorF5: recordForEdit.regDateContractorF5 || "",
        coOfContractEmpF5: recordForEdit.coOfContractEmpF5 || "",
        noOfContractorsF5: recordForEdit.noOfContractorsF5 || "",
        contractorNameF51: recordForEdit.contractorNameF51 || "",
        contractorNameF51image: recordForEdit.contractorNameF51image || "",
        contractorNameF51Remark: recordForEdit.contractorNameF51Remark || "",
        establishmentNameF51: recordForEdit.establishmentNameF51 || "",
        establishmentNameF51image:
          recordForEdit.establishmentNameF51image || "",
        establishmentNameF51Remark:
          recordForEdit.establishmentNameF51Remark || "",
        regisocontractaddress: recordForEdit.regisocontractaddress || "",
        regStateContractorF51: recordForEdit.regStateContractorF51 || "",
        regDistContractorF51: recordForEdit.regDistContractorF51 || "",
        regPinContractorF51: recordForEdit.regPinContractorF51 || "",
        regAddContractorF51image: recordForEdit.regAddContractorF51image || "",
        regAddContractorF51Remark:
          recordForEdit.regAddContractorF51Remark || "",
        expiryDateF52: recordForEdit.expiryDateF52 || "",
        renewalDateF52: recordForEdit.renewalDateF52 || "",
        natureOfWorkF52: recordForEdit.natureOfWorkF52 || "",
        natureOfWorkF52image: recordForEdit.natureOfWorkF52image || "",
        natureOfWorkF52Remark: recordForEdit.natureOfWorkF52Remark || "",
        noOfEmpDeployedF52: recordForEdit.noOfEmpDeployedF52 || "",
        companyTypeF53: recordForEdit.companyTypeF53 || "",
        companyTypeF53image: recordForEdit.companyTypeF53image || "",
        companyTypeF53Remark: recordForEdit.companyTypeF53Remark || "",
        contractLabLicNoF53: recordForEdit.contractLabLicNoF53 || "",
        contractLabLicNoF53image: recordForEdit.contractLabLicNoF53image || "",
        contractLabLicNoF53Remark:
          recordForEdit.contractLabLicNoF53Remark || "",
        licenseDateF53: recordForEdit.licenseDateF53 || "",
        expiryDateF53: recordForEdit.expiryDateF53 || "",
        renewalDateF53: recordForEdit.renewalDateF53 || "",
        noOfWorkerF53: recordForEdit.noOfWorkerF53 || "",
        panF53: recordForEdit.panF53 || "",
        panF53image: recordForEdit.panF53image || "",
        panF53Remark: recordForEdit.panF53Remark || "",
        gstF53: recordForEdit.gstF53 || "",
        gstF53image: recordForEdit.gstF53image || "",
        gstF53Remark: recordForEdit.gstF53Remark || "",
        esicRegF53: recordForEdit.esicRegF53 || "",
        esicRegF53image: recordForEdit.esicRegF53image || "",
        esicRegF53Remark: recordForEdit.esicRegF53Remark || "",
        pfRegContractorsE3: recordForEdit.pfRegContractorsE3 || "",
        pfRegContractorsE3image: recordForEdit.pfRegContractorsE3image || "",
        pfRegContractorsE3Remark: recordForEdit.pfRegContractorsE3Remark || "",
        shopsandEstContractorsE3: recordForEdit.shopsandEstContractorsE3 || "",
        shopsandEstContractorsE3image:
          recordForEdit.shopsandEstContractorsE3image || "",
        shopsandEstContractorsE3Remark:
          recordForEdit.shopsandEstContractorsE3Remark || "",
        lwfRegContractorsE3: recordForEdit.lwfRegContractorsE3 || "",
        lwfRegContractorsE3image: recordForEdit.lwfRegContractorsE3image || "",
        lwfRegContractorsE3Remark:
          recordForEdit.lwfRegContractorsE3Remark || "",
        profTaxContractorsE3: recordForEdit.profTaxContractorsE3 || "",
        profTaxContractorsE3image:
          recordForEdit.profTaxContractorsE3image || "",
        profTaxContractorsE3Remark:
          recordForEdit.profTaxContractorsE3Remark || "",
        licensenumber: recordForEdit.licensenumber || "",
        licenseimage: recordForEdit.licenseimage || "",
        licenseremark: recordForEdit.licenseremark || "",
        dor: recordForEdit.dor || "",
        doe: recordForEdit.doe || "",
        doddr: recordForEdit.doddr || "",
        managernamelicense: recordForEdit.managernamelicense || "",
        managerlicenseimage: recordForEdit.managerlicenseimage || "",
        managerlicenseremark: recordForEdit.managerlicenseremark || "",
        noe: recordForEdit.noe || "",
        nom: recordForEdit.nom || "",
        nof: recordForEdit.nof || "",
        issuingauth: recordForEdit.issuingauth || "",
        issuingauthimage: recordForEdit.issuingauthimage || "",
        issuingauthremark: recordForEdit.issuingauthremark || "",
        licensenumber1: recordForEdit.licensenumber1 || "",
        licenseimage1: recordForEdit.licenseimage1 || "",
        licenseremark1: recordForEdit.licenseremark1 || "",
        dor1: recordForEdit.dor1 || "",
        doe1: recordForEdit.doe1 || "",
        doddr1: recordForEdit.doddr1 || "",
        managernamelicense1: recordForEdit.managernamelicense1 || "",
        managerlicenseimage1: recordForEdit.managerlicenseimage1 || "",
        managerlicenseremark1: recordForEdit.managerlicenseremark1 || "",
        noe1: recordForEdit.noe1 || "",
        nom1: recordForEdit.nom1 || "",
        nof1: recordForEdit.nof1 || "",
        issuingauth1: recordForEdit.issuingauth1 || "",
        issuingauthimage1: recordForEdit.issuingauthimage1 || "",
        issuingauthremark1: recordForEdit.issuingauthremark1 || "",
        licensenumber2: recordForEdit.licensenumber2 || "",
        licenseimage2: recordForEdit.licenseimage2 || "",
        licenseremark2: recordForEdit.licenseremark2 || "",
        dor2: recordForEdit.dor2 || "",
        issuingauth2: recordForEdit.issuingauth2 || "",
        issuingauthimage2: recordForEdit.issuingauthimage2 || "",
        issuingauthremark2: recordForEdit.issuingauthremark2 || "",
        dateofclosure: recordForEdit.dateofclosure || "",
        powerHP: recordForEdit.powerHP || "",
        powerHPremark2: recordForEdit.powerHPremark2 || "",
        powerKW: recordForEdit.powerKW || "",
        powerKWremark2: recordForEdit.powerKWremark2 || "",
        ApprovedPower: recordForEdit.ApprovedPower || "",
        ApprovedPowerremark2: recordForEdit.ApprovedPowerremark2 || "",
        licensenumberNSP: recordForEdit.licensenumberNSP || "",
        licenseimageNSP: recordForEdit.licenseimageNSP || "",
        licenseremarkNSP: recordForEdit.licenseremarkNSP || "",
        dorNSP: recordForEdit.dorNSP || "",
        doeNSP: recordForEdit.doeNSP || "",
        doddrNSP: recordForEdit.doddrNSP || "",
        issuingauthNSP: recordForEdit.issuingauthNSP || "",
        issuingauthdetailsNSP: recordForEdit.issuingauthdetailsNSP || "",
        issuingauthimageNSP: recordForEdit.issuingauthimageNSP || "",
        issuingauthremarkNSP: recordForEdit.issuingauthremarkNSP || "",
        licensenumberOTP: recordForEdit.licensenumberOTP || "",
        licenseimageOTP: recordForEdit.licenseimageOTP || "",
        licenseremarkOTP: recordForEdit.licenseremarkOTP || "",
        dorOTP: recordForEdit.dorOTP || "",
        doeOTP: recordForEdit.doeOTP || "",
        doddrOTP: recordForEdit.doddrOTP || "",
        issuingauthOTP: recordForEdit.issuingauthOTP || "",
        issuingauthdetailsOTP: recordForEdit.issuingauthdetailsOTP || "",
        issuingauthimageOTP: recordForEdit.issuingauthimageOTP || "",
        issuingauthremarkOTP: recordForEdit.issuingauthremarkOTP || "",
        licensenumberTL: recordForEdit.licensenumberTL || "",
        licenseimageTL: recordForEdit.licenseimageTL || "",
        licenseremarkTL: recordForEdit.licenseremarkTL || "",
        dorTL: recordForEdit.dorTL || "",
        doeTL: recordForEdit.doeTL || "",
        doddrTL: recordForEdit.doddrTL || "",
        issuingauthTL: recordForEdit.issuingauthTL || "",
        issuingauthdetailsTL: recordForEdit.issuingauthdetailsTL || "",
        issuingauthimageTL: recordForEdit.issuingauthimageTL || "",
        issuingauthremarkTL: recordForEdit.issuingauthremarkTL || "",
        licensenumberWOE: recordForEdit.licensenumberWOE || "",
        licenseimageWOE: recordForEdit.licenseimageWOE || "",
        licenseremarkWOE: recordForEdit.licenseremarkWOE || "",
        dorWOE: recordForEdit.dorWOE || "",
        doeWOE: recordForEdit.doeWOE || "",
        doddrWOE: recordForEdit.doddrWOE || "",
        issuingauthWOE: recordForEdit.issuingauthWOE || "",
        issuingauthdetailsWOE: recordForEdit.issuingauthdetailsWOE || "",
        issuingauthimageWOE: recordForEdit.issuingauthimageWOE || "",
        issuingauthremarkWOE: recordForEdit.issuingauthremarkWOE || "",
        isFactoryOrSE: recordForEdit.isFactoryOrSE || "",
        isContracorsYesorNo: recordForEdit.isContracorsYesorNo || "",
        isNightShiftPermission: recordForEdit.isNightShiftPermission || "",
        isOTPermission: recordForEdit.isOTPermission || "",
        isWeeklyOffExemption: recordForEdit.isWeeklyOffExemption || "",
        isTradeLicense: recordForEdit.isTradeLicense || "",
      }));
    }
  }, [recordForEdit]);
  const [selectedOption, setSelectedOption] = useState(""); // To track the selected option ("Factory" or "S&E")
  const [isContractLabourEngaged, setIsContractLabourEngaged] = useState(""); // Initially set to "NO"

  const [loading, setLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState("");

  // Prefill form when editing
  useEffect(() => {
    if (recordForEdit) {
      setFormData({
        ...recordForEdit,
        branchstate:
          recordForEdit.branchstate?._id || recordForEdit.branchstate || "",
      });
    }
  }, [recordForEdit]);

  useEffect(() => {
    if (recordForEdit && recordForEdit.id) {
      console.log("Fetching branch data for ID:", recordForEdit.id);
      dispatch(BranchesGetById(recordForEdit.id));
    }
  }, [dispatch, recordForEdit]);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle file uploads
  const handleFileChange = (e, fieldName) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prev) => ({ ...prev, [fieldName]: file }));

      // Generate preview for images
      const reader = new FileReader();
      reader.onload = () => setImagePreview(reader.result);
      reader.readAsDataURL(file);
    }
  };

  // Handle company select
  const handleCompanySelect = (value) => {
    setFormData((prev) => ({ ...prev, company: value }));
  };

  // Prefill form with recordForEdit data
  useEffect(() => {
    if (recordForEdit) {
      setFormData({
        ...recordForEdit,
        branchstate:
          recordForEdit.branchstate?._id || recordForEdit.branchstate || "",
      });
      setSelectedOption(recordForEdit.isFactoryOrSE); // Initialize option
    }
  }, [recordForEdit]);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    setFormData((prev) => ({ ...prev, isFactoryOrSE: option }));
  };

  useEffect(() => {
    if (formData.isContracorsYesorNo) {
      setIsContractLabourEngaged(formData.isContracorsYesorNo);
    }
  }, [formData.isContracorsYesorNo]);

  const handleSelectionChange = (value) => {
    setIsContractLabourEngaged(value);

    if (value === "YES") {
      // Set isContracorsYesorNo to "YES" without resetting other fields
      setFormData((prev) => ({
        ...prev,
        isContracorsYesorNo: "YES",
      }));
    } else if (value === "NO") {
      // Reset specific fields when value is "NO"
      setFormData((prev) => ({
        ...prev,
        isContracorsYesorNo: "NO",
        contractLabRegNoF5: "",
        contractLabRegNoF5image: "",
        contractLabRegNoF5Remark: "",
        regDateContractorF5: "",
        coOfContractEmpF5: "",
        noOfContractorsF5: "",
        contractorNameF51: "",
        contractorNameF51image: "",
        contractorNameF51Remark: "",
        establishmentNameF51: "",
        establishmentNameF51image: "",
        establishmentNameF51Remark: "",
        regisocontractaddress: "",
        regStateContractorF51: "",
        regDistContractorF51: "",
        regPinContractorF51: "",
        regAddContractorF51image: "",
        regAddContractorF51Remark: "",
        expiryDateF52: "",
        renewalDateF52: "",
        natureOfWorkF52: "",
        natureOfWorkF52image: "",
        natureOfWorkF52Remark: "",
        noOfEmpDeployedF52: "",
        companyTypeF53: "",
        companyTypeF53image: "",
        companyTypeF53Remark: "",
        contractLabLicNoF53: "",
        contractLabLicNoF53image: "",
        contractLabLicNoF53Remark: "",
        licenseDateF53: "",
        expiryDateF53: "",
        renewalDateF53: "",
        noOfWorkerF53: "",
        panF53: "",
        panF53image: "",
        panF53Remark: "",
        gstF53: "",
        gstF53image: "",
        gstF53Remark: "",
        pfRegContractorsE3: "",
        pfRegContractorsE3image: "",
        pfRegContractorsE3Remark: "",
        esicRegF53: "",
        esicRegF53image: "",
        esicRegF53Remark: "",
        shopsandEstContractorsE3: "",
        shopsandEstContractorsE3image: "",
        shopsandEstContractorsE3Remark: "",
        lwfRegContractorsE3: "",
        lwfRegContractorsE3image: "",
        lwfRegContractorsE3Remark: "",
        profTaxContractorsE3: "",
        profTaxContractorsE3image: "",
        profTaxContractorsE3Remark: "",
      }));
    }
  };

  // Reset the form
  const resetForm = () => {
    setFormData({
      company: "",
      name: "",
      details: "",
      branchimage: "",
      remarks: "",
      branchaddress: "",
      branchstate: "",
      branchdistrict: "",
      branchpin: "",
      contractorAddBranchFimage: "",
      contractorAddBranchFRemark: "",
      branchOpeningDateF: "",
      noOfEmpBranchF: "",
      managerNameF1: "",
      managerNameF1image: "",
      managerNameF1Remark: "",
      managerMobNoF1: "",
      managerMobNoF1Remark: "",
      managerEmailF1: "",
      managerEmailF1Remark: "",
      managerAadharNoF1: "",
      managerAadharNoF1image: "",
      managerAadharNoF1Remark: "",
      managerPanF1: "",
      managerPanF1image: "",
      managerPanF1Remark: "",
      shopsEstLicenseF2: "",
      shopsEstLicenseF2image: "",
      shopsEstLicenseF2Remark: "",
      contractLabRegNoF5: "",
      contractLabRegNoF5image: "",
      contractLabRegNoF5Remark: "",
      regDateContractorF5: "",
      coOfContractEmpF5: "",
      noOfContractorsF5: "",
      contractorNameF51: "",
      contractorNameF51image: "",
      contractorNameF51Remark: "",
      establishmentNameF51: "",
      establishmentNameF51image: "",
      establishmentNameF51Remark: "",
      regisocontractaddress: "",
      regStateContractorF51: "",
      regDistContractorF51: "",
      regPinContractorF51: "",
      regAddContractorF51image: "",
      regAddContractorF51Remark: "",
      expiryDateF52: "",
      renewalDateF52: "",
      natureOfWorkF52: "",
      natureOfWorkF52image: "",
      natureOfWorkF52Remark: "",
      noOfEmpDeployedF52: "",
      companyTypeF53: "",
      companyTypeF53image: "",
      companyTypeF53Remark: "",
      contractLabLicNoF53: "",
      contractLabLicNoF53image: "",
      contractLabLicNoF53Remark: "",
      licenseDateF53: "",
      expiryDateF53: "",
      renewalDateF53: "",
      noOfWorkerF53: "",
      panF53: "",
      panF53image: "",
      panF53Remark: "",
      gstF53: "",
      gstF53image: "",
      gstF53Remark: "",
      esicRegF53: "",
      esicRegF53image: "",
      esicRegF53Remark: "",
      pfRegContractorsE3: "",
      pfRegContractorsE3image: "",
      pfRegContractorsE3Remark: "",
      shopsandEstContractorsE3: "",
      shopsandEstContractorsE3image: "",
      shopsandEstContractorsE3Remark: "",
      lwfRegContractorsE3: "",
      lwfRegContractorsE3image: "",
      lwfRegContractorsE3Remark: "",
      profTaxContractorsE3: "",
      profTaxContractorsE3image: "",
      profTaxContractorsE3Remark: "",
      licensenumber: "",
      licenseimage: "",
      licenseremark: "",
      dor: "",
      doe: "",
      doddr: "",
      managernamelicense: "",
      managerlicenseimage: "",
      managerlicenseremark: "",
      noe: "",
      nom: "",
      nof: "",
      issuingauth: "",
      issuingauthimage: "",
      issuingauthremark: "",
      licensenumber1: "",
      licenseimage1: "",
      licenseremark1: "",
      dor1: "",
      doe1: "",
      doddr1: "",
      managernamelicense1: "",
      managerlicenseimage1: "",
      managerlicenseremark1: "",
      noe1: "",
      nom1: "",
      nof1: "",
      issuingauth1: "",
      issuingauthimage1: "",
      issuingauthremark1: "",
      licensenumber2: "",
      licenseimage2: "",
      licenseremark2: "",
      dor2: "",
      issuingauth2: "",
      issuingauthimage2: "",
      issuingauthremark2: "",
      dateofclosure: "",
      // Additional fields
      powerHP: "",
      powerHPremark2: "",
      powerKW: "",
      powerKWremark2: "",
      ApprovedPower: "",
      ApprovedPowerremark2: "",
      // NSP
      licensenumberNSP: "",
      licenseimageNSP: "",
      licenseremarkNSP: "",
      dorNSP: "",
      doeNSP: "",
      doddrNSP: "",
      issuingauthNSP: "",
      issuingauthdetailsNSP: "",
      issuingauthimageNSP: "",
      issuingauthremarkNSP: "",
      //OTP
      licensenumberOTP: "",
      licenseimageOTP: "",
      licenseremarkOTP: "",
      dorOTP: "",
      doeOTP: "",
      doddrOTP: "",
      issuingauthOTP: "",
      issuingauthdetailsOTP: "",
      issuingauthimageOTP: "",
      issuingauthremarkOTP: "",
      //TL
      licensenumberTL: "",
      licenseimageTL: "",
      licenseremarkTL: "",
      dorTL: "",
      doeTL: "",
      doddrTL: "",
      issuingauthTL: "",
      issuingauthdetailsTL: "",
      issuingauthimageTL: "",
      issuingauthremarkTL: "",
      //WOE
      licensenumberWOE: "",
      licenseimageWOE: "",
      licenseremarkWOE: "",
      dorWOE: "",
      doeWOE: "",
      doddrWOE: "",
      issuingauthWOE: "",
      issuingauthdetailsWOE: "",
      issuingauthimageWOE: "",
      issuingauthremarkWOE: "",
      isFactoryOrSE: "",
      isNightShiftPermission: "",
    });
    setImagePreview("");
  };

  // Handle form submission
  // Handle form submission
  const handleSubmit = async () => {
    const form = new FormData();
    Object.keys(formData).forEach((key) => {
      form.append(key, formData[key]);
    });

    try {
      setLoading(true);
      let response;
      if (recordForEdit) {
        console.log("ID to Update:", recordForEdit.id); // Log the ID being passed
        response = await dispatch(BranchesUpdateById(form, recordForEdit.id)); // Pass the correct ID
      } else {
        response = await dispatch(createBranches(form));
      }

      if (
        (recordForEdit && response?.type === "BRANCHES_UPDATE_SUCCESS") ||
        (!recordForEdit && response?.type === "BRANCHES_ADD_SUCCESS")
      ) {
        toast.success("Form submitted successfully!", {
          position: "bottom-right",
        });
        resetForm();
        // addOrEdit(false); // Notify parent component
        addOrEdit({ ...formData, id: recordForEdit ? recordForEdit.id : undefined }); // Update or add data and close the modal
      }
    } catch (error) {
      toast.error("Failed to submit form.", { position: "bottom-right" });
      console.error(error);
    } finally {
      setOpenPopup(false)
      setLoading(false);
    }
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit}>
      <table className="table  creat_tbl">
        <tbody>
          {loadingcompanytable ? (
            <Spin tip="Loading companies..." />
          ) : (
            <Form.Item label="Select Company" required>
              <Select
                value={formData.company}
                placeholder="Select a company"
                onChange={handleCompanySelect}
              >
                {/* Default option */}
                <Option value="" disabled>
                  Select a Company
                </Option>
                {/* Map through companies */}
                {companyGetTableInfo?.map((company) => (
                  <Option key={company._id} value={company._id}>
                    {company.companyname}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <br />
          <tr>
            <td>
              <label className="form-label">Branch Name/ID</label>
              <input
                type="text"
                className="form-control"
                name="name"
                placeholder="Enter branch name"
                value={formData.name}
                onChange={handleInputChange}
                required
                //
              />
            </td>
            <td>
              <div className="form-group files1">
                <input
                  type="file"
                  multiple=""
                  name="branchimage"
                  accept="image/*,application/pdf"
                  onChange={(e) => handleFileChange(e, "branchimage")}
                  className="form-control"
                  style={{ height: "10px" }}
                  placeholder="Enter branch branchimage"
                />
              </div>

              {typeof formData.branchimage === "string" ? (
                <div style={{ marginTop: "10px" }}>
                  <a
                    href={formData.branchimage}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "blue", textDecoration: "underline" }}
                  >
                    View Uploaded File/Image
                  </a>
                </div>
              ) : (
                formData.branchimage && (
                  <div style={{ marginTop: "10px" }}>
                    <a
                      href={URL.createObjectURL(formData.branchimage)}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "blue", textDecoration: "underline" }}
                    >
                      View Uploaded File/Image
                    </a>
                  </div>
                )
              )}
            </td>
            <td>
              <label className="form-label">Remarks</label>
              <input
                type="text"
                className="form-control"
                placeholder="Remark"
                value={formData.remarks || ""}
                onChange={handleInputChange}
                id="remarks"
                name="remarks"
              />
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <h4>F. Details of the Branch's </h4>
              <div>
                <table className="table creat_tbl">
                  <tr className="align-middle">
                    <td colSpan="3">
                      <label htmlFor="">Is Factory or S&E</label>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3" style={{ width: "100%" }}>
                      <button
                        type="button"
                        style={{
                          backgroundColor: "rgb(206, 233, 242)",
                          width: "20%",
                        }}
                        className={`btn ${
                          selectedOption === "Factory"
                            ? "btn-primary text-white"
                            : "btn-light"
                        }`}
                        onClick={() => handleOptionChange("Factory")}
                      >
                        Factory
                      </button>
                      <button
                        type="button"
                        style={{
                          backgroundColor: "rgb(206, 233, 242)",
                          width: "20%",
                        }}
                        className={`btn ${
                          selectedOption === "S&E"
                            ? "btn-primary text-white"
                            : "btn-light"
                        }`}
                        onClick={() => handleOptionChange("S&E")}
                      >
                        S&E
                      </button>
                    </td>
                  </tr>
                </table>
                <div>
                  {/** div for Is Factory or S&E starts */}
                  <table className="table creat_tbl">
                    <tr>
                      <td>
                        <table className="table creat_tbl">
                          <tr>
                            <td>
                              <label for="">
                                Registered Address of the Branch
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                name="branchaddress"
                                id="branchaddress"
                                value={formData.branchaddress || ""}
                                onChange={handleInputChange}
                                placeholder="Address"
                                // ref={inputRefs.inputRef61}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                            <select
  className="form-select"
  value={formData.branchstate}
  name="branchstate"
  onChange={handleSelectChange}
>
  <option value="">Select State</option>
  {Array.isArray(stateInfo) &&
    stateInfo.map((state) => (
      <option key={state._id} value={state.name}> {/* Using state.name here */}
        {state.name}
      </option>
    ))}
</select>

                            </td>
                          </tr>
                          <tr>
                            <td>
                              <input
                                type="text"
                                class="form-control"
                                name="branchdistrict"
                                id="branchdistrict"
                                value={formData.branchdistrict || ""}
                                onChange={handleInputChange}
                                placeholder="District"
                                // ref={inputRefs.inputRef63}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <input
                                type="number"
                                class="form-control"
                                name="branchpin"
                                id="branchpin"
                                value={formData.branchpin || ""}
                                onChange={handleInputChange}
                                placeholder="PIN"
                                // ref={inputRefs.inputRef64}
                              />
                            </td>
                          </tr>
                        </table>
                      </td>
                      <td>
                        <div>
                          <div class="form-group files1">
                            <input
                              type="file"
                              name="contractorAddBranchFimage"
                              id="contractorAddBranchFimage"
                              class="form-control"
                              multiple=""
                              accept="image/*,application/pdf"
                              style={{ height: "10px" }}
                              onChange={(e) =>
                                handleFileChange(e, "contractorAddBranchFimage")
                              }

                              // ref={inputRefs.inputRef67}
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <label for="">Remark</label>
                        <input
                          type="text"
                          class="form-control"
                          value={formData.contractorAddBranchFRemark || ""}
                          name="contractorAddBranchFRemark"
                          id="contractorAddBranchFRemark"
                          onChange={handleInputChange}
                          placeholder="Write Here"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3">
                        <label for="">Date of Opening</label>
                        <input
                          type="date"
                          class="form-control"
                          value={formData.branchOpeningDateF || ""}
                          name="branchOpeningDateF"
                          id="branchOpeningDateF"
                          onChange={handleInputChange}
                          placeholder="Date of Opening"
                          // ref={inputRefs.inputRef68}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3">
                        <label for="">Number of Employees</label>
                        <input
                          type="number"
                          class="form-control"
                          name="noOfEmpBranchF"
                          id="noOfEmpBranchF"
                          value={formData.noOfEmpBranchF || ""}
                          min="0"
                          //   onKeyPress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))" // Problematic line
                          onChange={handleInputChange}
                        />
                      </td>
                    </tr>
                  </table>
                </div>
                <h4>F.1. Manager Details</h4>
                <table className="table creat_tbl">
                  <tr>
                    <td>
                      <label for="">Name of the Manager</label>
                      <input
                        type="text"
                        class="form-control"
                        name="managerNameF1"
                        id="managerNameF1"
                        value={formData.managerNameF1 || ""}
                        onChange={handleInputChange}
                        placeholder="Manager Details"
                      />
                    </td>
                    <td>
                      <div>
                        <div class="form-group files1">
                          <input
                            type="file"
                            name="managerNameF1image"
                            // value={formData.managerNameF1image || " "}
                            id="managerNameF1image"
                            class="form-control"
                            multiple=""
                            accept="image/*,application/pdf"
                            style={{ height: "10px" }}
                            onChange={(e) =>
                              handleFileChange(e, "managerNameF1image")
                            }
                          />
                        </div>
                        {/* {managerNameF1image && ( */}
                      </div>
                    </td>
                    <td>
                      <label for="">Remark</label>
                      <input
                        type="text"
                        class="form-control"
                        value={formData.managerNameF1Remark || ""}
                        name="managerNameF1Remark"
                        id="managerNameF1Remark"
                        onChange={handleInputChange}
                        placeholder="Write Here"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="">Mobile Number</label>
                      <input
                        type="number"
                        class="form-control"
                        name="managerMobNoF1"
                        id="managerMobNoF1"
                        value={formData.managerMobNoF1}
                        onChange={handleInputChange}
                        placeholder="Mobile Number"
                      />
                    </td>
                    <td colspan="1">
                      <label for="">Remark</label>
                      <input
                        type="text"
                        class="form-control"
                        value={formData.managerMobNoF1Remark || ""}
                        name="managerMobNoF1Remark"
                        id="managerMobNoF1Remark"
                        onChange={handleInputChange}
                        placeholder="Write Here"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="">Email</label>
                      <input
                        type="email"
                        class="form-control"
                        name="managerEmailF1"
                        id="managerEmailF1"
                        value={formData.managerEmailF1 || ""}
                        onChange={handleInputChange}
                        placeholder="Email"
                      />
                    </td>
                    <td>
                      <label for="">Remark</label>
                      <input
                        type="text"
                        class="form-control"
                        value={formData.managerEmailF1Remark || ""}
                        name="managerEmailF1Remark"
                        id="managerEmailF1Remark"
                        onChange={handleInputChange}
                        placeholder="Write Here"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="">Aadhaar Number</label>
                      <input
                        type="text"
                        class="form-control"
                        name="managerAadharNoF1"
                        id="managerAadharNoF1"
                        value={formData.managerAadharNoF1 || ""}
                        onChange={handleInputChange}
                        placeholder="Aadhar Number"
                      />
                    </td>
                  </tr>
                </table>
                {selectedOption === "S&E" && (
                  <div>
                    <h4>F.2. Details of Registration & Licenses</h4>
                    <table className="table creat_tbl">
                      <tbody>
                        <tr>
                          <td>
                            <label htmlFor={`shopsEstLicenseF2`}>
                              Shops and Establishment License
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="shopsEstLicenseF2"
                              id="shopsEstLicenseF2"
                              value={formData.shopsEstLicenseF2 || ""}
                              onChange={handleInputChange}
                              placeholder="Shops and Establishment License"
                              // ref={inputRefsfactoryRefClicknotshow.inputRef1}
                            />
                          </td>
                          <td>
                            <div className="form-group files1">
                              <input
                                type="file"
                                name="shopsEstLicenseF2image" // Make sure name is not empty
                                id="shopsEstLicenseF2image" // Unique id for each file input
                                className="form-control"
                                multiple=""
                                accept="image/*,application/pdf"
                                style={{
                                  height: "10px" /*,display: 'inline'*/,
                                }}
                                onChange={(e) =>
                                  handleFileChange(e, "shopsEstLicenseF2image")
                                }

                                // ref={inputRefsfactoryRefClicknotshow.inputRef2}
                              />
                            </div>
                          </td>
                          <td>
                            <label htmlFor="shopsEstLicenseF2Remark">
                              Remark
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={formData.shopsEstLicenseF2Remark || ""}
                              name="shopsEstLicenseF2Remark"
                              id="shopsEstLicenseF2Remark"
                              onChange={handleInputChange}
                              placeholder="Write Here"
                            />
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <label className="form-label">License Number</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Licence Number"
                              value={formData.licensenumber || ""}
                              id="licensenumber"
                              name="licensenumber"
                              onChange={handleInputChange}

                              // ref={inputRefsfactoryRefClicknotshow.inputRef3}
                            />
                          </td>
                          <td colspan="2">
                            <div>
                              <div class="form-group files1">
                                <input
                                  type="file"
                                  class="form-control"
                                  multiple=""
                                  accept="image/*,application/pdf"
                                  style={{
                                    height: "10px" /*,display: 'inline'*/,
                                  }}
                                  id="licenseimage"
                                  name="licenseimage"
                                  onChange={(e) =>
                                    handleFileChange(e, "licenseimage")
                                  }

                                  // ref={inputRefsfactoryRefClicknotshow.inputRef4}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <label for="">Remark</label>
                            <input
                              type="text"
                              class="form-control"
                              id="licenseremark"
                              name="licenseremark"
                              value={formData.licenseremark || ""}
                              onChange={handleInputChange}
                              placeholder="Write Here"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colspan="4">
                            <label className="form-label">
                              Date of Registration
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              placeholder="Date of Registration"
                              value={formData.dor || ""}
                              onChange={handleInputChange}
                              id="dor"
                              name="dor"
                              // ref={inputRefsfactoryRefClicknotshow.inputRef5}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colspan="4">
                            <label className="form-label">Date of Expiry</label>
                            <input
                              type="date"
                              className="form-control"
                              placeholder="Date of Expiry"
                              value={formData.doe || ""}
                              onChange={handleInputChange}
                              id="doe"
                              name="doe"
                              // ref={inputRefsfactoryRefClicknotshow.inputRef6}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colspan="4">
                            <label className="form-label">
                              Due Date of Renewal
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              placeholder="Due Date of Renewal"
                              value={formData.doddr || ""}
                              onChange={handleInputChange}
                              id="doddr"
                              name="doddr"
                              // ref={inputRefsfactoryRefClicknotshow.inputRef7}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            <label className="form-label">
                              Manager Name of the License
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Manager Name of the License"
                              value={formData.managernamelicense || ""}
                              onChange={handleInputChange}
                              name="managernamelicense"
                              id="managernamelicense"
                              // ref={inputRefsfactoryRefClicknotshow.inputRef8}
                            />
                          </td>
                          <td>
                            <div className="form-group files1">
                              <input
                                type="file"
                                multiple=""
                                accept="image/*,application/pdf"
                                className="form-control"
                                style={{
                                  height: "10px" /*,display: 'inline'*/,
                                }}
                                onChange={(e) =>
                                  handleFileChange(e, "managerlicenseimage")
                                }
                                id="managerlicenseimage"
                                name="managerlicenseimage"
                                // ref={inputRefsfactoryRefClicknotshow.inputRef9}
                              />
                            </div>
                            {/* {managerlicenseimage && ( */}
                          </td>
                          <td>
                            <label className="form-label">Remarks</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Remark"
                              value={formData.managerlicenseremark || ""}
                              onChange={handleInputChange}
                              id="managerlicenseremark"
                              name="managerlicenseremark"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colspan="4">
                            <label className="form-label">
                              Number of the Employees
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Number of the Employees"
                              value={formData.noe || ""}
                              onChange={handleInputChange}
                              min="0"
                              onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                              id="noe"
                              name="noe"
                              // ref={inputRefsfactoryRefClicknotshow.inputRef10}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colspan="4">
                            <label className="form-label">Male</label>
                            {/* {isVisible && ( */}
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Male"
                              value={formData.nom || ""}
                              onChange={handleInputChange}
                              min="0"
                              onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                              id="nom"
                              name="nom"
                              // ref={inputRefsfactoryRefClicknotshow.inputRef11}
                            />
                            {/* )} */}
                          </td>
                        </tr>
                        <tr>
                          <td colspan="4">
                            <label className="form-label">Female</label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Female"
                              value={formData.nof || ""}
                              onChange={handleInputChange}
                              min="0"
                              onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                              id="nof"
                              name="nof"
                              // ref={inputRefsfactoryRefClicknotshow.inputRef12}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            <label className="form-label">
                              Issuing Authority
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Issuing Authority"
                              value={formData.issuingauth || ""}
                              onChange={handleInputChange}
                              name="issuingauth"
                              id="issuingauth"
                              // ref={inputRefsfactoryRefClicknotshow.inputRef13}
                            />
                          </td>
                          <td>
                            <div className="form-group files1">
                              <input
                                type="file"
                                multiple=""
                                accept="image/*,application/pdf"
                                className="form-control"
                                style={{
                                  height: "10px" /*,display: 'inline'*/,
                                }}
                                onChange={(e) =>
                                  handleFileChange(e, "issuingauthimage")
                                }
                                id="issuingauthimage"
                                name="issuingauthimage"
                                // ref={inputRefsfactoryRefClicknotshow.inputRef14}
                              />
                            </div>
                          </td>
                          <td>
                            <label className="form-label">Remarks</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Remark"
                              value={formData.issuingauthremark || ""}
                              onChange={handleInputChange}
                              id="issuingauthremark"
                              name="issuingauthremark"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label className="form-label">
                              Date of Closure
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              // placeholder="Remark"
                              value={formData.dateofclosure || ""}
                              onChange={handleInputChange}
                              id="dateofclosure"
                              name="dateofclosure"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
                {selectedOption === "Factory" && (
                  <div>
                    <h4>F.3. Factory License </h4>
                    <table className="table  creat_tbl">
                      <tr>
                        <td>
                          <label className="form-label">License Number</label>

                          <input
                            type="text"
                            className="form-control"
                            placeholder="Licence Number"
                            value={formData.licensenumber1 || ""}
                            id="licensenumber1"
                            name="licensenumber1"
                            onChange={handleInputChange}
                            // ref={inputRefsfactoryRefClicknotshow.inputRef15}
                          />
                        </td>
                        <td colspan="2">
                          <div>
                            <div class="form-group files1">
                              <input
                                type="file"
                                class="form-control"
                                multiple=""
                                accept="image/*,application/pdf"
                                style={{
                                  height: "10px" /*,display: 'inline'*/,
                                }}
                                id="licenseimage1"
                                name="licenseimage1"
                                onChange={(e) =>
                                  handleFileChange(e, "licenseimage1")
                                }

                                // ref={inputRefsfactoryRefClicknotshow.inputRef16}
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <label for="">Remark</label>
                          <input
                            type="text"
                            class="form-control"
                            id="licenseremark1"
                            name="licenseremark1"
                            value={formData.licenseremark1 || ""}
                            onChange={handleInputChange}
                            placeholder="Write Here"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="4">
                          <label className="form-label">
                            Date of Registration
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Date of Registration"
                            value={formData.dor1 || ""}
                            onChange={handleInputChange}
                            id="dor1"
                            name="dor1"
                            // ref={inputRefsfactoryRefClicknotshow.inputRef17}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="4">
                          <label className="form-label">Date of Expiry</label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Date of Expiry"
                            value={formData.doe1 || ""}
                            onChange={handleInputChange}
                            id="doe1"
                            name="doe1"
                            // ref={inputRefsfactoryRefClicknotshow.inputRef18}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="4">
                          <label className="form-label">
                            Due Date of Renewal
                          </label>
                          {/* {isVisible && ( */}
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Due Date of Renewal"
                            value={formData.doddr1 || ""}
                            onChange={handleInputChange}
                            id="doddr1"
                            name="doddr1"
                            // ref={inputRefsfactoryRefClicknotshow.inputRef19}
                          />
                          {/* )} */}
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <label className="form-label">
                            Manager Name of the License
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Manager Name of the License"
                            value={formData.managernamelicense1 || ""}
                            onChange={handleInputChange}
                            name="managernamelicense1"
                            id="managernamelicense1"
                            // ref={inputRefsfactoryRefClicknotshow.inputRef20}
                          />
                        </td>
                        <td>
                          <div className="form-group files1">
                            <input
                              type="file"
                              multiple=""
                              accept="image/*,application/pdf"
                              className="form-control"
                              style={{ height: "10px" /*,display: 'inline'*/ }}
                              onChange={(e) =>
                                handleFileChange(e, "managerlicenseimage1")
                              }
                              id="managerlicenseimage1"
                              name="managerlicenseimage1"
                              // ref={inputRefsfactoryRefClicknotshow.inputRef21}
                            />
                          </div>
                        </td>
                        <td>
                          <label className="form-label">Remarks</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Remark"
                            value={formData.managerlicenseremark1 || ""}
                            onChange={handleInputChange}
                            id="managerlicenseremark1"
                            name="managerlicenseremark1"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="4">
                          <label className="form-label">
                            Number of the Employees
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Number of the Employees"
                            value={formData.noe1 || ""}
                            onChange={handleInputChange}
                            min="0"
                            onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                            id="noe1"
                            name="noe1"
                            // ref={inputRefsfactoryRefClicknotshow.inputRef22}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="4">
                          <label className="form-label">Male</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Male"
                            value={formData.nom1 || ""}
                            onChange={handleInputChange}
                            min="0"
                            onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                            id="nom1"
                            name="nom1"
                            // ref={inputRefsfactoryRefClicknotshow.inputRef23}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="4">
                          <label className="form-label">Female</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Female"
                            value={formData.nof1 || ""}
                            onChange={handleInputChange}
                            min="0"
                            onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                            id="nof1"
                            name="nof1"
                            // ref={inputRefsfactoryRefClicknotshow.inputRef24}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <label className="form-label">
                            Issuing Authority
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Issuing Authority"
                            value={formData.issuingauth1 || ""}
                            onChange={handleInputChange}
                            name="issuingauth1"
                            id="issuingauth1"
                            // ref={inputRefsfactoryRefClicknotshow.inputRef25}
                          />
                        </td>
                        <td>
                          <div className="form-group files1">
                            <input
                              type="file"
                              multiple=""
                              accept="image/*,application/pdf"
                              className="form-control"
                              style={{ height: "10px" /*,display: 'inline'*/ }}
                              onChange={(e) =>
                                handleFileChange(e, "issuingauthimage1")
                              }
                              id="issuingauthimage1"
                              name="issuingauthimage1"
                              // ref={inputRefsfactoryRefClicknotshow.inputRef26}
                            />
                          </div>
                        </td>
                        <td>
                          <label className="form-label">Remarks</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Remark"
                            value={formData.issuingauthremark1 || ""}
                            onChange={handleInputChange}
                            id="issuingauthremark1"
                            name="issuingauthremark1"
                          />
                        </td>
                      </tr>
                    </table>

                    <h4>F.4. Factory Plan</h4>
                    <table className="table  creat_tbl">
                      <tr>
                        <td>
                          <label className="form-label">License Number</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Licence Number"
                            value={formData.licensenumber2 || ""}
                            id="licensenumber2"
                            name="licensenumber2"
                            onChange={handleInputChange}
                            // ref={inputRefsfactoryRefClicknotshow.inputRef27}
                          />
                        </td>
                        <td colspan="2">
                          <div>
                            <div class="form-group files1">
                              <input
                                type="file"
                                class="form-control"
                                multiple=""
                                accept="image/*,application/pdf"
                                style={{
                                  height: "10px" /*,display: 'inline'*/,
                                }}
                                id="licenseimage2"
                                name="licenseimage2"
                                onChange={(e) =>
                                  handleFileChange(e, "licenseimage2")
                                }

                                // ref={inputRefsfactoryRefClicknotshow.inputRef28}
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <label for="">Remark</label>
                          <input
                            type="text"
                            class="form-control"
                            id="licenseremark2"
                            name="licenseremark2"
                            value={formData.licenseremark2 || ""}
                            onChange={handleInputChange}
                            placeholder="Write Here"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="4">
                          <label className="form-label">
                            Date of Registration
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Date of Registration"
                            value={formData.dor2 || ""}
                            onChange={handleInputChange}
                            id="dor2"
                            name="dor2"
                            // ref={inputRefsfactoryRefClicknotshow.inputRef29}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <label className="form-label">
                            Issuing Authority
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Issuing Authority"
                            value={formData.issuingauth2 || ""}
                            onChange={handleInputChange}
                            name="issuingauth2"
                            id="issuingauth2"
                            // ref={inputRefsfactoryRefClicknotshow.inputRef30}
                          />
                        </td>
                        <td>
                          <div className="form-group files1">
                            <input
                              type="file"
                              multiple=""
                              accept="image/*,application/pdf"
                              className="form-control"
                              style={{ height: "10px" /*,display: 'inline'*/ }}
                              onChange={(e) =>
                                handleFileChange(e, "issuingauthimage2")
                              }
                              id="issuingauthimage2"
                              name="issuingauthimage2"
                              // ref={inputRefsfactoryRefClicknotshow.inputRef31}
                            />
                          </div>
                        </td>
                        <td>
                          <label className="form-label">Remarks</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Remark"
                            value={formData.issuingauthremark2 || ""}
                            onChange={handleInputChange}
                            id="issuingauthremark2"
                            name="issuingauthremark2"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <label className="form-label">Power-KW</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Power-KW"
                            value={formData.powerKW || ""}
                            onChange={handleInputChange}
                            name="powerKW"
                            id="powerKW"
                            // ref={inputRefsfactoryRefClicknotshow.inputRef85}
                          />
                        </td>
                        <td>
                          <label className="form-label">Remarks</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Remark"
                            value={formData.powerKWremark2 || ""}
                            onChange={handleInputChange}
                            id="powerKWremark2"
                            name="powerKWremark2"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <label className="form-label">Power-HP</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Power-HP"
                            value={formData.powerHP || ""}
                            onChange={handleInputChange}
                            name="powerHP"
                            id="powerHP"
                            // ref={inputRefsfactoryRefClicknotshow.inputRef86}
                          />
                        </td>
                        <td>
                          <label className="form-label">Remarks</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Remark"
                            value={formData.powerHPremark2 || ""}
                            onChange={handleInputChange}
                            id="powerHPremark2"
                            name="powerHPremark2"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <label className="form-label">
                            Approved Power Details
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Approved Power"
                            value={formData.ApprovedPower || ""}
                            onChange={handleInputChange}
                            name="ApprovedPower"
                            id="ApprovedPower"
                            // ref={inputRefsfactoryRefClicknotshow.inputRef87}
                          />
                        </td>
                        <td>
                          <label className="form-label">Remarks</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Remark"
                            value={formData.ApprovedPowerremark2 || ""}
                            onChange={handleInputChange}
                            id="ApprovedPowerremark2"
                            name="ApprovedPowerremark2"
                          />
                        </td>
                      </tr>
                    </table>
                  </div>
                )}
                <div>
                  <h4>F.5. Details of the Labour Contractors</h4>
                  <table className="table creat_tbl">
                    <tr className="align-middle">
                      <td colSpan="3">
                        <label>Is Contract labour Engaged(Yes/No)</label>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3" style={{ width: "100%" }}>
                        <button
                          type="button"
                          style={{
                            backgroundColor: "rgb(206, 233, 242)",
                            width: "20%",
                          }}
                          className={`btn ${
                            isContractLabourEngaged === "YES"
                              ? "btn-primary text-white"
                              : "btn-light"
                          }`}
                          onClick={() => handleSelectionChange("YES")}
                        >
                          YES
                        </button>
                        <button
                          type="button"
                          style={{
                            backgroundColor: "rgb(206, 233, 242)",
                            width: "20%",
                          }}
                          className={`btn ${
                            isContractLabourEngaged === "NO"
                              ? "btn-primary text-white"
                              : "btn-light"
                          }`}
                          onClick={() => handleSelectionChange("NO")}
                        >
                          NO
                        </button>
                      </td>
                    </tr>
                  </table>
                  {isContractLabourEngaged === "YES" && (
                    <div>
                      <table className="table  creat_tbl">
                        <tr>
                          <td>
                            <label htmlFor="">
                              Contract Labour Registration Number
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="contractLabRegNoF5"
                              id="contractLabRegNoF5"
                              value={formData.contractLabRegNoF5 || ""}
                              onChange={handleInputChange}
                              placeholder="Contract Labour Registration Number"
                            />
                          </td>
                          <td>
                            <div>
                              <div class="form-group files1">
                                <input
                                  type="file"
                                  name="contractLabRegNoF5image"
                                  id="contractLabRegNoF5image"
                                  class="form-control"
                                  multiple=""
                                  accept="image/*,application/pdf"
                                  style={{ height: "10px" }}
                                  onChange={(e) =>
                                    handleFileChange(
                                      e,
                                      "contractLabRegNoF5image"
                                    )
                                  }

                                  // ref={icleinputRefs.inputRef33}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <label for="">Remark</label>
                            <input
                              type="text"
                              class="form-control"
                              value={formData.contractLabRegNoF5Remark || ""}
                              name="contractLabRegNoF5Remark"
                              id="contractLabRegNoF5Remark"
                              onChange={handleInputChange}
                              placeholder="Write Here"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3">
                            <label for="">Date of Registration</label>
                            <input
                              type="date"
                              class="form-control"
                              value={formData.regDateContractorF5 || ""}
                              name="regDateContractorF5"
                              id="regDateContractorF5"
                              onChange={handleInputChange}
                              placeholder="Type here"
                              // ref={icleinputRefs.inputRef34}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3">
                            <label for="">Number of Contract Employee</label>
                            <input
                              type="number"
                              class="form-control"
                              name="coOfContractEmpF5"
                              id="coOfContractEmpF5"
                              value={formData.coOfContractEmpF5 || ""}
                              onChange={handleInputChange}
                              placeholder="Number of Contract Employee"
                              // ref={icleinputRefs.inputRef35}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3">
                            <label for="">Number of Contractors</label>
                            <input
                              type="number"
                              class="form-control"
                              name="noOfContractorsF5"
                              id="noOfContractorsF5"
                              value={formData.noOfContractorsF5 || ""}
                              onChange={handleInputChange}
                              placeholder="Number of Contractors"
                              // ref={icleinputRefs.inputRef36}
                            />
                          </td>
                        </tr>
                      </table>
                      <h4>F.5.1. Details of the Labour Contractors</h4>
                      <table className="table  creat_tbl">
                        <tr>
                          <td>
                            <label for="">Name of the Contractor</label>
                            <input
                              type="text"
                              class="form-control"
                              name="contractorNameF51"
                              id="contractorNameF51"
                              value={formData.contractorNameF51 || ""}
                              onChange={handleInputChange}
                              placeholder="Name of the Contractor"
                              // ref={icleinputRefs.inputRef37}
                            />
                          </td>
                          <td>
                            <div>
                              <div class="form-group files1">
                                <input
                                  type="file"
                                  name="contractorNameF51image"
                                  id="contractorNameF51image"
                                  class="form-control"
                                  multiple=""
                                  accept="image/*,application/pdf"
                                  style={{ height: "10px" }}
                                  onChange={(e) =>
                                    handleFileChange(
                                      e,
                                      "contractorNameF51image"
                                    )
                                  }

                                  // ref={icleinputRefs.inputRef38}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <label for="">Remark</label>
                            <input
                              type="text"
                              class="form-control"
                              value={formData.contractorNameF51Remark || ""}
                              name="contractorNameF51Remark"
                              id="contractorNameF51Remark"
                              onChange={handleInputChange}
                              placeholder="Write Here"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label for="">Name of the Establishment</label>
                            <input
                              type="text"
                              class="form-control"
                              value={formData.establishmentNameF51 || ""}
                              name="establishmentNameF51"
                              id="establishmentNameF51"
                              onChange={handleInputChange}
                              placeholder="Name of the Establishment"
                              // ref={icleinputRefs.inputRef39}
                            />
                          </td>
                          <td>
                            <div>
                              <div class="form-group files1">
                                <input
                                  type="file"
                                  name="establishmentNameF51image"
                                  id="establishmentNameF51image"
                                  class="form-control"
                                  multiple=""
                                  accept="image/*,application/pdf"
                                  style={{ height: "10px" }}
                                  onChange={(e) =>
                                    handleFileChange(
                                      e,
                                      "establishmentNameF51image"
                                    )
                                  }

                                  // ref={icleinputRefs.inputRef40}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <label for="">Remark</label>
                            <input
                              type="text"
                              class="form-control"
                              value={formData.establishmentNameF51Remark || ""}
                              name="establishmentNameF51Remark"
                              id="establishmentNameF51Remark"
                              onChange={handleInputChange}
                              placeholder="Write Here"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label for="">
                              Registered Address of the Contractor
                            </label>
                            <table className="table  creat_tbl">
                              <tr>
                                <td>
                                  <input
                                    type="text"
                                    class="form-control"
                                    name="regisocontractaddress"
                                    id="regisocontractaddress"
                                    value={formData.regisocontractaddress || ""}
                                    onChange={handleInputChange}
                                    placeholder="Address"
                                    // ref={icleinputRefs.inputRef41}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <select
                                    className="form-select"
                                    aria-label="Default select example"
                                    name="regStateContractorF51"
                                    id="regStateContractorF51"
                                    value={formData.regStateContractorF51 || ""}
                                    onChange={handleInputChange}

                                    // ref={icleinputRefs.inputRef42}
                                  >
                                    <option value="">Select State</option>
                                    {stateInfo != "undefind" &&
                                      stateInfo?.length > 0 &&
                                      stateInfo.map((item) => (
                                        <option value={item._id}>
                                          {item.name}
                                        </option>
                                      ))}
                                    ;
                                  </select>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <input
                                    type="text"
                                    class="form-control"
                                    value={formData.regDistContractorF51 || ""}
                                    name="regDistContractorF51"
                                    id="regDistContractorF51"
                                    onChange={handleInputChange}
                                    placeholder="District"
                                    // ref={icleinputRefs.inputRef43}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <input
                                    type="number"
                                    class="form-control"
                                    name="regPinContractorF51"
                                    id="regPinContractorF51"
                                    value={formData.regPinContractorF51 || ""}
                                    onChange={handleInputChange}
                                    placeholder="PIN"
                                    // ref={icleinputRefs.inputRef44}
                                  />
                                </td>
                              </tr>
                            </table>
                          </td>

                          <td>
                            <div>
                              <div class="form-group files1">
                                <input
                                  type="file"
                                  name="regAddContractorF51image"
                                  id="regAddContractorF51image"
                                  class="form-control"
                                  multiple=""
                                  accept="image/*,application/pdf"
                                  style={{ height: "10px" }}
                                  onChange={(e) =>
                                    handleFileChange(
                                      e,
                                      "regAddContractorF51image"
                                    )
                                  }

                                  // ref={icleinputRefs.inputRef45}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <label for="">Remark</label>
                            <input
                              type="text"
                              class="form-control"
                              value={formData.regAddContractorF51Remark || ""}
                              name="regAddContractorF51Remark"
                              id="regAddContractorF51Remark"
                              onChange={handleInputChange}
                              placeholder="Write Here"
                            />
                          </td>
                        </tr>
                      </table>
                      <h4>F.5.2. Agreement Date</h4>
                      <table className="table  creat_tbl">
                        <tr>
                          <td colspan="3">
                            <label for="">Date of Expiry</label>
                            <input
                              type="date"
                              class="form-control"
                              value={formData.expiryDateF52 || ""}
                              name="expiryDateF52"
                              id="expiryDateF52"
                              onChange={handleInputChange}
                              placeholder="Date of Expiry"
                              // ref={icleinputRefs.inputRef46}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3">
                            <label for="">Due Date for Renewal</label>
                            <input
                              type="date"
                              class="form-control"
                              name="renewalDateF52"
                              id="renewalDateF52"
                              value={formData.renewalDateF52 || ""}
                              onChange={handleInputChange}
                              placeholder="Due Date for Renewal"
                              // ref={icleinputRefs.inputRef47}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label for="">Nature of Work</label>
                            <input
                              type="text"
                              class="form-control"
                              name="natureOfWorkF52"
                              id="natureOfWorkF52"
                              value={formData.natureOfWorkF52 || ""}
                              onChange={handleInputChange}
                              placeholder="Type here"
                              // ref={icleinputRefs.inputRef48}
                            />
                          </td>
                          <td>
                            <div>
                              <div class="form-group files1">
                                <input
                                  type="file"
                                  name="natureOfWorkF52image"
                                  id="natureOfWorkF52image"
                                  class="form-control"
                                  multiple=""
                                  accept="image/*,application/pdf"
                                  style={{ height: "10px" }}
                                  onChange={(e) =>
                                    handleFileChange(e, "natureOfWorkF52image")
                                  }

                                  // ref={icleinputRefs.inputRef49}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <label for="">Remark</label>
                            <input
                              type="text"
                              class="form-control"
                              value={formData.natureOfWorkF52Remark || ""}
                              name="natureOfWorkF52Remark"
                              id="natureOfWorkF52Remark"
                              onChange={handleInputChange}
                              placeholder="Write Here"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3">
                            <label for="">Number of Employees Deployed</label>
                            <input
                              type="number"
                              class="form-control"
                              name="noOfEmpDeployedF52"
                              id="noOfEmpDeployedF52"
                              value={formData.noOfEmpDeployedF52 || ""}
                              onChange={handleInputChange}
                              min="0"
                              onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                              placeholder="Type here"
                              // ref={icleinputRefs.inputRef50}
                            />
                          </td>
                        </tr>
                      </table>
                      <h4>F.5.3. Contractors Registration Details</h4>
                      <table className="table  creat_tbl">
                        <tr>
                          <td>
                            <label for="">Type of the Company</label>
                            <select
                              class="form-select"
                              name="companyTypeF53"
                              id="companyTypeF53"
                              value={formData.companyTypeF53 || ""}
                              onChange={handleInputChange}
                              placeholder="Type of the Company"
                              // ref={icleinputRefs.inputRef53}
                            >
                              <option value="">Type of the Company</option>
                              <option value="1">Private Limited Company</option>
                              <option value="2">Public Limited Company</option>
                              <option value="3">Sole Proprietorship</option>
                              <option value="4">Partnership</option>
                              <option value="5">
                                Limited Liability Partnership (LLP)
                              </option>
                              <option value="6">
                                Non-Government Organization (NGO)
                              </option>
                              <option value="7">
                                One Person Company (OPC)
                              </option>
                              <option value="8">Others</option>
                            </select>
                          </td>
                          <td>
                            <div>
                              <div class="form-group files1">
                                <input
                                  type="file"
                                  name="companyTypeF53image"
                                  id="companyTypeF53image"
                                  class="form-control"
                                  multiple=""
                                  accept="image/*,application/pdf"
                                  style={{ height: "10px" }}
                                  onChange={(e) =>
                                    handleFileChange(e, "companyTypeF53image")
                                  }

                                  // ref={icleinputRefs.inputRef54}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <label for="">Remark</label>
                            <input
                              type="text"
                              class="form-control"
                              name="companyTypeF53Remark"
                              id="companyTypeF53Remark"
                              value={formData.companyTypeF53Remark || ""}
                              onChange={handleInputChange}
                              placeholder="Write Here"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3">
                            {/* <div style={{ display: divstyleshowhidemyRefE3div === 'show' ? 'none' : 'inline' }}
                          ref={myRefE3div}>  *E3 div starts */}

                            <table className="table creat_tbl">
                              <tr>
                                <td>
                                  <label for="">
                                    Contract Labour License Number
                                  </label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    value={formData.contractLabLicNoF53 || ""}
                                    name="contractLabLicNoF53"
                                    id="contractLabLicNoF53"
                                    onChange={handleInputChange}
                                    placeholder="Contract Labour License Number"
                                    // ref={icleinputRefs.inputRef55}
                                  />
                                </td>
                                <td>
                                  <div>
                                    <div class="form-group files1">
                                      <input
                                        type="file"
                                        name="contractLabLicNoF53image"
                                        id="contractLabLicNoF53image"
                                        class="form-control"
                                        multiple=""
                                        accept="image/*,application/pdf"
                                        style={{
                                          height: "10px" /*,display: 'inline'*/,
                                        }}
                                        onChange={(e) =>
                                          handleFileChange(
                                            e,
                                            "contractLabLicNoF53image"
                                          )
                                        }

                                        // ref={icleinputRefs.inputRef56}
                                      />
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <label for="">Remark</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    nname="contractLabLicNoF53Remark"
                                    value={
                                      formData.contractLabLicNoF53Remark || ""
                                    }
                                    name="contractLabLicNoF53Remark"
                                    id="contractLabLicNoF53Remark"
                                    onChange={handleInputChange}
                                    placeholder="Write Here"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td colspan="3">
                                  <label for="">License Date</label>
                                  <input
                                    type="date"
                                    class="form-control"
                                    name="licenseDateF53"
                                    id="licenseDateF53"
                                    value={formData.licenseDateF53 || ""}
                                    onChange={handleInputChange}
                                    placeholder="License Date"
                                    // ref={icleinputRefs.inputRef57}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td colspan="3">
                                  <label for="">Date of Expiry</label>
                                  <input
                                    type="date"
                                    class="form-control"
                                    value={formData.expiryDateF53 || ""}
                                    name="expiryDateF53"
                                    id="expiryDateF53"
                                    onChange={handleInputChange}
                                    placeholder="Date of Expiry"
                                    // ref={icleinputRefs.inputRef58}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td colspan="3">
                                  <label for="">Due Date for Renewal</label>
                                  <input
                                    type="date"
                                    class="form-control"
                                    value={formData.renewalDateF53 || ""}
                                    name="renewalDateF53"
                                    id="renewalDateF53"
                                    onChange={handleInputChange}
                                    placeholder="Due Date for Renewal"
                                    // ref={icleinputRefs.inputRef59}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td colspan="3">
                                  <label for="">
                                    Number of Workers as per the License
                                  </label>
                                  <input
                                    type="number"
                                    class="form-control"
                                    value={formData.noOfWorkerF53 || ""}
                                    name="noOfWorkerF53"
                                    id="noOfWorkerF53"
                                    onChange={handleInputChange}
                                    placeholder="Number of Workers as per the License"
                                    // ref={icleinputRefs.inputRef60}
                                  />
                                </td>
                              </tr>
                            </table>
                            {/* </div>     */}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label for="">PAN</label>
                            <input
                              type="text"
                              class="form-control"
                              value={formData.panF53 || ""}
                              name="panF53"
                              id="panF53"
                              onChange={handleInputChange}
                              placeholder="PAN"
                              // ref={icleinputRefs.inputRef70}
                            />
                          </td>
                          <td>
                            <div>
                              <div class="form-group files1">
                                <input
                                  type="file"
                                  name="panF53image"
                                  id="panF53image"
                                  class="form-control"
                                  multiple=""
                                  accept="image/*,application/pdf"
                                  style={{ height: "10px" }}
                                  onChange={(e) =>
                                    handleFileChange(e, "panF53image")
                                  }

                                  // ref={icleinputRefs.inputRef71}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <label for="">Remark</label>
                            <input
                              type="text"
                              class="form-control"
                              value={formData.panF53Remark || ""}
                              name="panF53Remark"
                              id="panF53Remark"
                              placeholder="Remark"
                              onChange={handleInputChange}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label for="">GST</label>
                            <input
                              type="text"
                              class="form-control"
                              value={formData.gstF53 || ""}
                              name="gstF53"
                              id="gstF53"
                              onChange={handleInputChange}
                              placeholder="GST"
                              // ref={icleinputRefs.inputRef72}
                            />
                          </td>
                          <td>
                            <div>
                              <div class="form-group files1">
                                <input
                                  type="file"
                                  name="gstF53image"
                                  id="gstF53image"
                                  class="form-control"
                                  multiple=""
                                  accept="image/*,application/pdf"
                                  style={{ height: "10px" }}
                                  onChange={(e) =>
                                    handleFileChange(e, "gstF53image")
                                  }

                                  // ref={icleinputRefs.inputRef73}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <label for="">Remark</label>
                            <input
                              type="text"
                              class="form-control"
                              value={formData.gstF53Remark || ""}
                              name="gstF53Remark"
                              id="gstF53Remark"
                              onChange={handleInputChange}
                              placeholder="Write Here"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label for="">PF Registration</label>
                            <input
                              type="text"
                              class="form-control"
                              value={formData.pfRegContractorsE3 || ""}
                              name="pfRegContractorsE3"
                              id="pfRegContractorsE3"
                              onChange={handleInputChange}
                              placeholder="PF Registration"
                              // ref={icleinputRefs.inputRef74}
                            />
                          </td>
                          <td>
                            <div>
                              <div class="form-group files1">
                                <input
                                  type="file"
                                  name="pfRegContractorsE3image"
                                  id="pfRegContractorsE3image"
                                  class="form-control"
                                  multiple=""
                                  accept="image/*,application/pdf"
                                  style={{ height: "10px" }}
                                  onChange={(e) =>
                                    handleFileChange(
                                      e,
                                      "pfRegContractorsE3image"
                                    )
                                  }

                                  // ref={icleinputRefs.inputRef75}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <label for="">Remark</label>
                            <input
                              type="text"
                              class="form-control"
                              value={formData.pfRegContractorsE3Remark || ""}
                              name="pfRegContractorsE3Remark"
                              id="pfRegContractorsE3Remark"
                              onChange={handleInputChange}
                              placeholder="Write Here"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label for="">ESIC Registration</label>
                            <input
                              type="text"
                              class="form-control"
                              value={formData.esicRegF53 || ""}
                              name="esicRegF53"
                              id="esicRegF53"
                              onChange={handleInputChange}
                              placeholder="ESIC Registration"
                              // ref={icleinputRefs.inputRef76}
                            />
                          </td>
                          <td>
                            <div>
                              <div class="form-group files1">
                                <input
                                  type="file"
                                  name="esicRegF53image"
                                  id="esicRegF53image"
                                  class="form-control"
                                  multiple=""
                                  accept="image/*,application/pdf"
                                  style={{ height: "10px" }}
                                  onChange={(e) =>
                                    handleFileChange(e, "esicRegF53image")
                                  }

                                  // ref={icleinputRefs.inputRef77}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <label for="">Remark</label>
                            <input
                              type="text"
                              class="form-control"
                              value={formData.esicRegF53Remark || ""}
                              name="esicRegF53Remark"
                              id="esicRegF53Remark"
                              onChange={handleInputChange}
                              placeholder="Write Here"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label for="">Shops and Establishment</label>
                            <input
                              type="text"
                              class="form-control"
                              value={formData.shopsandEstContractorsE3 || ""}
                              name="shopsandEstContractorsE3"
                              id="shopsandEstContractorsE3"
                              onChange={handleInputChange}
                              placeholder="Shops and Establishment"
                              // ref={icleinputRefs.inputRef78}
                            />
                          </td>
                          <td>
                            <div>
                              <div class="form-group files1">
                                <input
                                  type="file"
                                  name="shopsandEstContractorsE3image"
                                  id="shopsandEstContractorsE3image"
                                  class="form-control"
                                  multiple=""
                                  accept="image/*,application/pdf"
                                  style={{ height: "10px" }}
                                  onChange={(e) =>
                                    handleFileChange(
                                      e,
                                      "shopsandEstContractorsE3image"
                                    )
                                  }

                                  // ref={icleinputRefs.inputRef79}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <label for="">Remark</label>
                            <input
                              type="text"
                              class="form-control"
                              value={
                                formData.shopsandEstContractorsE3Remark || ""
                              }
                              name="shopsandEstContractorsE3Remark"
                              id="shopsandEstContractorsE3Remark"
                              onChange={handleInputChange}
                              placeholder="Write Here"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label for="">LWF Registration</label>
                            <input
                              type="text"
                              class="form-control"
                              value={formData.lwfRegContractorsE3 || ""}
                              name="lwfRegContractorsE3"
                              id="lwfRegContractorsE3"
                              onChange={handleInputChange}
                              placeholder="LWF Registration"
                              // ref={icleinputRefs.inputRef80}
                            />
                          </td>
                          <td>
                            <div>
                              <div class="form-group files1">
                                <input
                                  type="file"
                                  name="lwfRegContractorsE3image"
                                  id="lwfRegContractorsE3image"
                                  class="form-control"
                                  multiple=""
                                  accept="image/*,application/pdf"
                                  style={{ height: "10px" }}
                                  onChange={(e) =>
                                    handleFileChange(
                                      e,
                                      "lwfRegContractorsE3image"
                                    )
                                  }

                                  // ref={icleinputRefs.inputRef81}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <label for="">Remark</label>
                            <input
                              type="text"
                              class="form-control"
                              value={formData.lwfRegContractorsE3Remark || ""}
                              name="lwfRegContractorsE3Remark"
                              id="lwfRegContractorsE3Remark"
                              onChange={handleInputChange}
                              placeholder="Write Here"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label for="">Professional Tax</label>
                            <input
                              type="text"
                              class="form-control"
                              value={formData.profTaxContractorsE3 || ""}
                              name="profTaxContractorsE3"
                              id="profTaxContractorsE3"
                              onChange={handleInputChange}
                              placeholder="Professional Tax"
                              // ref={icleinputRefs.inputRef82}
                            />
                          </td>
                          <td>
                            <div>
                              <div class="form-group files1">
                                <input
                                  type="file"
                                  name="profTaxContractorsE3image"
                                  id="profTaxContractorsE3image"
                                  class="form-control"
                                  multiple=""
                                  accept="image/*,application/pdf"
                                  style={{ height: "10px" }}
                                  onChange={(e) =>
                                    handleFileChange(
                                      e,
                                      "profTaxContractorsE3image"
                                    )
                                  }

                                  // ref={icleinputRefs.inputRef83}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <label for="">Remark</label>
                            <input
                              type="text"
                              class="form-control"
                              value={formData.profTaxContractorsE3Remark || ""}
                              name="profTaxContractorsE3Remark"
                              id="profTaxContractorsE3Remark"
                              onChange={handleInputChange}
                              placeholder="Write Here"
                            />
                          </td>
                        </tr>
                      </table>
                    </div>
                  )}
                </div>
                <h4>F.5.4 Night Shift Permission</h4>

                <table className="table  creat_tbl">
                  <tr>
                    <td colSpan="3">
                      <label>Is Night Shift Permission Granted? (Yes/No)</label>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3" style={{ width: "100%" }}>
                      <button
                        type="button"
                        style={{
                          backgroundColor: "rgb(206, 233, 242)",
                          width: "20%",
                        }}
                        className={`btn ${
                          formData?.isNightShiftPermission === "YES"
                            ? "btn-primary text-white"
                            : "btn-light"
                        }`}
                        onClick={() =>
                          setFormData((prev) => ({
                            ...prev,
                            isNightShiftPermission: "YES",
                          }))
                        }
                      >
                        YES
                      </button>
                      <button
                        type="button"
                        style={{
                          backgroundColor: "rgb(206, 233, 242)",
                          width: "20%",
                        }}
                        className={`btn ${
                          formData?.isNightShiftPermission === "NO"
                            ? "btn-primary text-white"
                            : "btn-light"
                        }`}
                        onClick={() =>
                          setFormData((prev) => ({
                            ...prev,
                            isNightShiftPermission: "NO",
                            licensenumberNSP: "",
                            licenseimageNSP: "",
                            licenseremarkNSP: "",
                            dorNSP: "",
                            doeNSP: "",
                            doddrNSP: "",
                            issuingauthNSP: "",
                            issuingauthimageNSP: "",
                            issuingauthremarkNSP: "",
                          }))
                        }
                      >
                        NO
                      </button>
                    </td>
                  </tr>
                </table>
                {formData?.isNightShiftPermission === "YES" && (
                  <table className="table creat_tbl">
                    <tr>
                      <td>
                        <label className="form-label">License Number</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Licence Number"
                          value={formData?.licensenumberNSP || ""}
                          id="licensenumberNSP"
                          name="licensenumberNSP"
                          onChange={handleInputChange}
                        />
                      </td>
                      <td colspan="5">
                        <div>
                          <div class="form-group files1">
                            <input
                              type="file"
                              class="form-control"
                              multiple=""
                              accept="image/*,application/pdf"
                              style={{ height: "10px" }}
                              id="licenseimageNSP"
                              name="licenseimageNSP"
                              onChange={(e) =>
                                handleFileChange(e, "licenseimageNSP")
                              }
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <label for="">Remark</label>
                        <input
                          type="text"
                          class="form-control"
                          id="licenseremarkNSP"
                          name="licenseremarkNSP"
                          value={formData?.licenseremarkNSP || ""}
                          onChange={handleInputChange}
                          placeholder="Write Here"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colspan="8">
                        <label className="form-label">
                          Date of Registration
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          placeholder="Date of Registration"
                          value={formData?.dorNSP || ""}
                          onChange={handleInputChange}
                          id="dorNSP"
                          name="dorNSP"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colspan="8">
                        <label className="form-label">Date of Expiry</label>
                        <input
                          type="date"
                          className="form-control"
                          placeholder="Date of Expiry"
                          value={formData?.doeNSP || ""}
                          onChange={handleInputChange}
                          id="doeNSP"
                          name="doeNSP"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colspan="8">
                        <label className="form-label">
                          Due Date of Renewal
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          placeholder="Due Date of Renewal"
                          value={formData?.doddrNSP || ""}
                          onChange={handleInputChange}
                          id="doddrNSP"
                          name="doddrNSP"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <label className="form-label">Issuing Authority</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Issuing Authority"
                          value={formData?.issuingauthNSP || ""}
                          onChange={handleInputChange}
                          name="issuingauthNSP"
                          id="issuingauthNSP"
                        />
                      </td>
                      <td colspan="4">
                        <div className="form-group files1">
                          <input
                            type="file"
                            multiple=""
                            accept="image/*,application/pdf"
                            className="form-control"
                            style={{ height: "10px" }}
                            onChange={(e) =>
                              handleFileChange(e, "issuingauthimageNSP")
                            }
                            id="issuingauthimageNSP"
                            name="issuingauthimageNSP"
                          />
                        </div>
                      </td>
                      <td>
                        <label className="form-label">Remarks</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Remark"
                          value={formData?.issuingauthremarkNSP || ""}
                          onChange={handleInputChange}
                          id="issuingauthremarkNSP"
                          name="issuingauthremarkNSP"
                        />
                      </td>
                    </tr>
                  </table>
                )}

                {/* F.5.5 OT Permission */}
                <h4>F.5.5 OT Permission</h4>
                <table className="table creat_tbl">
                  <tr>
                    <td colSpan="3">
                      <label>Is OT Permission Granted? (Yes/No)</label>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3" style={{ width: "100%" }}>
                      <button
                        type="button"
                        style={{
                          backgroundColor: "rgb(206, 233, 242)",
                          width: "20%",
                        }}
                        className={`btn ${
                          formData?.isOTPermission === "YES"
                            ? "btn-primary text-white"
                            : "btn-light"
                        }`}
                        onClick={() =>
                          setFormData((prev) => ({
                            ...prev,
                            isOTPermission: "YES",
                          }))
                        }
                      >
                        YES
                      </button>
                      <button
                        type="button"
                        style={{
                          backgroundColor: "rgb(206, 233, 242)",
                          width: "20%",
                        }}
                        className={`btn ${
                          formData?.isOTPermission === "NO"
                            ? "btn-primary text-white"
                            : "btn-light"
                        }`}
                        onClick={() =>
                          setFormData((prev) => ({
                            ...prev,
                            isOTPermission: "NO",
                            licensenumberOTP: "",
                            licenseimageOTP: "",
                            licenseremarkOTP: "",
                            dorOTP: "",
                            doeOTP: "",
                            doddrOTP: "",
                            issuingauthOTP: "",
                            issuingauthimageOTP: "",
                            issuingauthremarkOTP: "",
                          }))
                        }
                      >
                        NO
                      </button>
                    </td>
                  </tr>
                </table>
                {formData?.isOTPermission === "YES" && (
                  <table className="table creat_tbl">
                    <tr>
                      <td>
                        <label className="form-label">License Number</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Licence Number"
                          value={formData?.licensenumberOTP || ""}
                          id="licensenumberOTP"
                          name="licensenumberOTP"
                          onChange={handleInputChange}
                        />
                      </td>
                      <td colSpan="5">
                        <div className="form-group files1">
                          <input
                            type="file"
                            className="form-control"
                            multiple=""
                            accept="image/*,application/pdf"
                            style={{ height: "10px" }}
                            id="licenseimageOTP"
                            name="licenseimageOTP"
                            onChange={(e) =>
                              handleFileChange(e, "licenseimageOTP")
                            }
                          />
                        </div>
                      </td>
                      <td>
                        <label>Remark</label>
                        <input
                          type="text"
                          className="form-control"
                          id="licenseremarkOTP"
                          name="licenseremarkOTP"
                          value={formData?.licenseremarkOTP || ""}
                          onChange={handleInputChange}
                          placeholder="Write Here"
                        />
                      </td>
                    </tr>
                    {/* Other fields like Date of Registration, Expiry, Renewal */}
                    <tr>
                      <td colSpan="8">
                        <label className="form-label">
                          Date of Registration
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          value={formData?.dorOTP || ""}
                          onChange={handleInputChange}
                          id="dorOTP"
                          name="dorOTP"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="8">
                        <label className="form-label">Date of Expiry</label>
                        <input
                          type="date"
                          className="form-control"
                          value={formData?.doeOTP || ""}
                          onChange={handleInputChange}
                          id="doeOTP"
                          name="doeOTP"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="8">
                        <label className="form-label">
                          Due Date of Renewal
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          value={formData?.doddrOTP || ""}
                          onChange={handleInputChange}
                          id="doddrOTP"
                          name="doddrOTP"
                        />
                      </td>
                    </tr>
                    {/* Issuing Authority */}
                    <tr>
                      <td colSpan="2">
                        <label className="form-label">Issuing Authority</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Issuing Authority"
                          value={formData?.issuingauthOTP || ""}
                          onChange={handleInputChange}
                          name="issuingauthOTP"
                          id="issuingauthOTP"
                        />
                      </td>
                      <td colSpan="4">
                        <div className="form-group files1">
                          <input
                            type="file"
                            multiple=""
                            accept="image/*,application/pdf"
                            className="form-control"
                            style={{ height: "10px" }}
                            onChange={(e) =>
                              handleFileChange(e, "issuingauthimageOTP")
                            }
                            id="issuingauthimageOTP"
                            name="issuingauthimageOTP"
                          />
                        </div>
                      </td>
                      <td>
                        <label className="form-label">Remarks</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Remark"
                          value={formData?.issuingauthremarkOTP || ""}
                          onChange={handleInputChange}
                          id="issuingauthremarkOTP"
                          name="issuingauthremarkOTP"
                        />
                      </td>
                    </tr>
                  </table>
                )}

                {/* F.5.6 Weekly Off Exemption */}
                <h4>F.5.6 Weekly Off Exemption</h4>
                <table className="table creat_tbl">
                  <tr>
                    <td colSpan="3">
                      <label>Is Weekly Off Exemption Granted? (Yes/No)</label>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3" style={{ width: "100%" }}>
                      <button
                        type="button"
                        style={{
                          backgroundColor: "rgb(206, 233, 242)",
                          width: "20%",
                        }}
                        className={`btn ${
                          formData?.isWeeklyOffExemption === "YES"
                            ? "btn-primary text-white"
                            : "btn-light"
                        }`}
                        onClick={() =>
                          setFormData((prev) => ({
                            ...prev,
                            isWeeklyOffExemption: "YES",
                          }))
                        }
                      >
                        YES
                      </button>
                      <button
                        type="button"
                        style={{
                          backgroundColor: "rgb(206, 233, 242)",
                          width: "20%",
                        }}
                        className={`btn ${
                          formData?.isWeeklyOffExemption === "NO"
                            ? "btn-primary text-white"
                            : "btn-light"
                        }`}
                        onClick={() =>
                          setFormData((prev) => ({
                            ...prev,
                            isWeeklyOffExemption: "NO",
                            licensenumberWOE: "",
                            licenseimageWOE: "",
                            licenseremarkWOE: "",
                            dorWOE: "",
                            doeWOE: "",
                            doddrWOE: "",
                            issuingauthWOE: "",
                            issuingauthimageWOE: "",
                            issuingauthremarkWOE: "",
                          }))
                        }
                      >
                        NO
                      </button>
                    </td>
                  </tr>
                </table>
                {formData?.isWeeklyOffExemption === "YES" && (
                  <table className="table creat_tbl">
                    <tr>
                      <td>
                        <label className="form-label">License Number</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Licence Number"
                          value={formData?.licensenumberWOE || ""}
                          id="licensenumberWOE"
                          name="licensenumberWOE"
                          onChange={handleInputChange}
                        />
                      </td>
                      <td colSpan="5">
                        <div className="form-group files1">
                          <input
                            type="file"
                            className="form-control"
                            multiple=""
                            accept="image/*,application/pdf"
                            style={{ height: "10px" }}
                            id="licenseimageWOE"
                            name="licenseimageWOE"
                            onChange={(e) =>
                              handleFileChange(e, "licenseimageWOE")
                            }
                          />
                        </div>
                      </td>
                      <td>
                        <label>Remark</label>
                        <input
                          type="text"
                          className="form-control"
                          id="licenseremarkWOE"
                          name="licenseremarkWOE"
                          value={formData?.licenseremarkWOE || ""}
                          onChange={handleInputChange}
                          placeholder="Write Here"
                        />
                      </td>
                    </tr>
                    {/* Other fields like Date of Registration, Expiry, Renewal */}
                    <tr>
                      <td colSpan="8">
                        <label className="form-label">
                          Date of Registration
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          value={formData?.dorWOE || ""}
                          onChange={handleInputChange}
                          id="dorWOE"
                          name="dorWOE"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="8">
                        <label className="form-label">Date of Expiry</label>
                        <input
                          type="date"
                          className="form-control"
                          value={formData?.doeWOE || ""}
                          onChange={handleInputChange}
                          id="doeWOE"
                          name="doeWOE"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="8">
                        <label className="form-label">
                          Due Date of Renewal
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          value={formData?.doddrWOE || ""}
                          onChange={handleInputChange}
                          id="doddrWOE"
                          name="doddrWOE"
                        />
                      </td>
                    </tr>
                    {/* Issuing Authority */}
                    <tr>
                      <td colSpan="2">
                        <label className="form-label">Issuing Authority</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Issuing Authority"
                          value={formData?.issuingauthWOE || ""}
                          onChange={handleInputChange}
                          name="issuingauthWOE"
                          id="issuingauthWOE"
                        />
                      </td>
                      <td colSpan="4">
                        <div className="form-group files1">
                          <input
                            type="file"
                            multiple=""
                            accept="image/*,application/pdf"
                            className="form-control"
                            style={{ height: "10px" }}
                            onChange={(e) =>
                              handleFileChange(e, "issuingauthimageWOE")
                            }
                            id="issuingauthimageWOE"
                            name="issuingauthimageWOE"
                          />
                        </div>
                      </td>
                      <td>
                        <label className="form-label">Remarks</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Remark"
                          value={formData?.issuingauthremarkWOE || ""}
                          onChange={handleInputChange}
                          id="issuingauthremarkWOE"
                          name="issuingauthremarkWOE"
                        />
                      </td>
                    </tr>
                  </table>
                )}

                <h4>F.5.7 Trade License</h4>
                <table className="table creat_tbl">
                  <tr>
                    <td colSpan="3">
                      <label>Is Trade License Available? (Yes/No)</label>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3" style={{ width: "100%" }}>
                      <button
                        type="button"
                        style={{
                          backgroundColor: "rgb(206, 233, 242)",
                          width: "20%",
                        }}
                        className={`btn ${
                          formData?.isTradeLicense === "YES"
                            ? "btn-primary text-white"
                            : "btn-light"
                        }`}
                        onClick={() =>
                          setFormData((prev) => ({
                            ...prev,
                            isTradeLicense: "YES",
                          }))
                        }
                      >
                        YES
                      </button>
                      <button
                        type="button"
                        style={{
                          backgroundColor: "rgb(206, 233, 242)",
                          width: "20%",
                        }}
                        className={`btn ${
                          formData?.isTradeLicense === "NO"
                            ? "btn-primary text-white"
                            : "btn-light"
                        }`}
                        onClick={() =>
                          setFormData((prev) => ({
                            ...prev,
                            isTradeLicense: "NO",
                            licensenumberTL: "",
                            licenseimageTL: "",
                            licenseremarkTL: "",
                            dorTL: "",
                            doeTL: "",
                            doddrTL: "",
                            issuingauthTL: "",
                            issuingauthimageTL: "",
                            issuingauthremarkTL: "",
                          }))
                        }
                      >
                        NO
                      </button>
                    </td>
                  </tr>
                </table>
                {formData?.isTradeLicense === "YES" && (
                  <table className="table creat_tbl">
                    <tr>
                      <td>
                        <label className="form-label">License Number</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Licence Number"
                          value={formData?.licensenumberTL || ""}
                          id="licensenumberTL"
                          name="licensenumberTL"
                          onChange={handleInputChange}
                        />
                      </td>
                      <td colSpan="5">
                        <div className="form-group files1">
                          <input
                            type="file"
                            className="form-control"
                            multiple=""
                            accept="image/*,application/pdf"
                            style={{ height: "10px" }}
                            id="licenseimageTL"
                            name="licenseimageTL"
                            onChange={(e) =>
                              handleFileChange(e, "licenseimageTL")
                            }
                          />
                        </div>
                      </td>
                      <td>
                        <label>Remark</label>
                        <input
                          type="text"
                          className="form-control"
                          id="licenseremarkTL"
                          name="licenseremarkTL"
                          value={formData?.licenseremarkTL || ""}
                          onChange={handleInputChange}
                          placeholder="Write Here"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="8">
                        <label className="form-label">
                          Date of Registration
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          placeholder="Date of Registration"
                          value={formData?.dorTL || ""}
                          onChange={handleInputChange}
                          id="dorTL"
                          name="dorTL"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="8">
                        <label className="form-label">Date of Expiry</label>
                        <input
                          type="date"
                          className="form-control"
                          placeholder="Date of Expiry"
                          value={formData?.doeTL || ""}
                          onChange={handleInputChange}
                          id="doeTL"
                          name="doeTL"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="8">
                        <label className="form-label">
                          Due Date of Renewal
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          placeholder="Due Date of Renewal"
                          value={formData?.doddrTL || ""}
                          onChange={handleInputChange}
                          id="doddrTL"
                          name="doddrTL"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <label className="form-label">Issuing Authority</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Issuing Authority"
                          value={formData?.issuingauthTL || ""}
                          onChange={handleInputChange}
                          name="issuingauthTL"
                          id="issuingauthTL"
                        />
                      </td>
                      <td colSpan="4">
                        <div className="form-group files1">
                          <input
                            type="file"
                            multiple=""
                            accept="image/*,application/pdf"
                            className="form-control"
                            style={{ height: "10px" }}
                            onChange={(e) =>
                              handleFileChange(e, "issuingauthimageTL")
                            }
                            id="issuingauthimageTL"
                            name="issuingauthimageTL"
                          />
                        </div>
                      </td>
                      <td>
                        <label className="form-label">Remarks</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Remark"
                          value={formData?.issuingauthremarkTL || ""}
                          onChange={handleInputChange}
                          id="issuingauthremarkTL"
                          name="issuingauthremarkTL"
                        />
                      </td>
                    </tr>
                  </table>
                )}
              </div>{" "}
            </td>
          </tr>
        </tbody>
        <button
          style={{
            backgroundColor: "#013879",
            color: "white",
            width: "340%",
            borderRadius: "4px",
            height: "40px",
          }}
          // type="primary"
          onSubmit="submit"
          loading={loading}
          disabled={loading}
        >
          Submit
        </button>
      </table>
    </Form>
  );
};

export default TestBranch;
