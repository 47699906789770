// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fixed-checklist {
    margin-top: 20px;
    font-family: Arial, sans-serif;
  }
  
  .checklist-section {
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .checklist-section h4 {
    margin-bottom: 10px;
    font-size: 1.4em;
    font-weight: bold;
    color: #333;
  }
  
  .checklist-section ol {
    padding-left: 20px;
    line-height: 1.6;
    margin-bottom: 10px;
  }
  
  .checklist-section ol li {
    margin-bottom: 8px;
  }
  
  .checklist-section ol li ol {
    padding-left: 20px;
  }
  
  .checklist-section ol li ol li {
    margin-bottom: 6px;
    list-style-type: square;
  }
  
  .checklist-section li {
    font-size: 1em;
    color: #333;
  }


  .ant-select-dropdown {
    z-index: 1051 !important; /* Ensure it's above the modal */
  }
  
  `, "",{"version":3,"sources":["webpack://./src/pages/Notice/NoticeCss.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,8BAA8B;EAChC;;EAEA;IACE,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,yBAAyB;EAC3B;;EAEA;IACE,mBAAmB;IACnB,gBAAgB;IAChB,iBAAiB;IACjB,WAAW;EACb;;EAEA;IACE,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,uBAAuB;EACzB;;EAEA;IACE,cAAc;IACd,WAAW;EACb;;;EAGA;IACE,wBAAwB,EAAE,gCAAgC;EAC5D","sourcesContent":[".fixed-checklist {\n    margin-top: 20px;\n    font-family: Arial, sans-serif;\n  }\n  \n  .checklist-section {\n    margin-bottom: 20px;\n    padding: 15px;\n    border: 1px solid #ddd;\n    border-radius: 8px;\n    background-color: #f9f9f9;\n  }\n  \n  .checklist-section h4 {\n    margin-bottom: 10px;\n    font-size: 1.4em;\n    font-weight: bold;\n    color: #333;\n  }\n  \n  .checklist-section ol {\n    padding-left: 20px;\n    line-height: 1.6;\n    margin-bottom: 10px;\n  }\n  \n  .checklist-section ol li {\n    margin-bottom: 8px;\n  }\n  \n  .checklist-section ol li ol {\n    padding-left: 20px;\n  }\n  \n  .checklist-section ol li ol li {\n    margin-bottom: 6px;\n    list-style-type: square;\n  }\n  \n  .checklist-section li {\n    font-size: 1em;\n    color: #333;\n  }\n\n\n  .ant-select-dropdown {\n    z-index: 1051 !important; /* Ensure it's above the modal */\n  }\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
