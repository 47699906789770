// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.align-top-cell {
    vertical-align: top !important;
    /* padding-top: 50px !important; */
  }
  .ant-btn  {
    width: 100% !important;
  }
  .but-class{
    width: 125px !important;
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .fade-in {
    animation: fadeIn 0.5s ease-in-out;
  }`, "",{"version":3,"sources":["webpack://./src/css/Kio.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,kCAAkC;EACpC;EACA;IACE,sBAAsB;EACxB;EACA;IACE,uBAAuB;EACzB;EACA;IACE;MACE,UAAU;IACZ;IACA;MACE,UAAU;IACZ;EACF;;EAEA;IACE,kCAAkC;EACpC","sourcesContent":[".align-top-cell {\n    vertical-align: top !important;\n    /* padding-top: 50px !important; */\n  }\n  .ant-btn  {\n    width: 100% !important;\n  }\n  .but-class{\n    width: 125px !important;\n  }\n  @keyframes fadeIn {\n    from {\n      opacity: 0;\n    }\n    to {\n      opacity: 1;\n    }\n  }\n  \n  .fade-in {\n    animation: fadeIn 0.5s ease-in-out;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
