import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { SearchOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table } from "antd";
import Popup from "../../components/Popup";
import Highlighter from "react-highlight-words";
import BranchForm from "./BranchForm";
import TestBranch from "./TestBranch";
import { useDispatch, useSelector } from "react-redux";
import { categoryCreate, categoryGet, AllbranchesGet, TableBranchesGet, BranchesUpdateById, branchStatusUpdate } from '../../store/actions/otherActions';
import * as XLSX from 'xlsx';
import { Select } from "antd";

const BranchTables = ({ branches }) => {
  const searchInput = useRef(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [pageTitle, setPageTitle] = useState("");
  const [modalWidth, setModalWidth] = useState();
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const getState = useSelector((state) => state.getState);
  const { loadings, stateInfo } = getState;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(AllbranchesGet());
    dispatch(TableBranchesGet());
  }, [dispatch]);
  const formatDateToInput = (isoDate) => {
    if (!isoDate) return ""; // Return an empty string for empty or invalid dates
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero
    const day = String(date.getDate()).padStart(2, "0"); // Add leading zero
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    if (branches?.length > 0) {
      const branchArr = branches.map((branch, index) => ({
        key: index + 1,
        id: branch._id,
        createdAt: formatDateToInput(branch.createdAt),
        company: branch.company,
        name: branch.name,
        details: branch.details,
        branchimage: branch.branchimage,
        remarks: branch.remarks,
        branchaddress: branch.branchaddress,
        region: branch.region,
        branchstate: branch.branchstate,
        branchdistrict: branch.branchdistrict,
        branchpin: branch.branchpin,
        contractorAddBranchFimage: branch.contractorAddBranchFimage,
        contractorAddBranchFRemark: branch.contractorAddBranchFRemark,
        branchOpeningDateF: formatDateToInput(branch.branchOpeningDateF),
        noOfEmpBranchF: branch.noOfEmpBranchF,
        managerNameF1: branch.managerNameF1,
        managerNameF1image: branch.managerNameF1image,
        managerNameF1Remark: branch.managerNameF1Remark,
        managerMobNoF1: branch.managerMobNoF1,
        managerMobNoF1Remark: branch.managerMobNoF1Remark,
        managerEmailF1: branch.managerEmailF1,
        managerEmailF1Remark: branch.managerEmailF1Remark,
        managerAadharNoF1: branch.managerAadharNoF1,
        managerAadharNoF1image: branch.managerAadharNoF1image,
        managerAadharNoF1Remark: branch.managerAadharNoF1Remark,
        managerPanF1: branch.managerPanF1,
        managerPanF1image: branch.managerPanF1image,
        managerPanF1Remark: branch.managerPanF1Remark,
        shopsEstLicenseF2: branch.shopsEstLicenseF2,
        shopsEstLicenseF2image: branch.shopsEstLicenseF2image,
        shopsEstLicenseF2Remark: branch.shopsEstLicenseF2Remark,
        licensenumber: branch.licensenumber,
        licenseimage: branch.licenseimage,
        licenseremark: branch.licenseremark,
        lis_status_se: branch.lis_status_se,
        dor: formatDateToInput(branch.dor),
        doe: formatDateToInput(branch.doe),
        doddr: formatDateToInput(branch.doddr),
        managernamelicense: branch.managernamelicense,
        managerlicenseimage: branch.managerlicenseimage,
        managerlicenseremark: branch.managerlicenseremark,
        noe: branch.noe,
        nom: branch.nom,
        nof: branch.nof,
        issuingauth: branch.issuingauth,
        doeimage: branch.doeimage,
        issuingauthremark: branch.issuingauthremark,
        licensenumber1: branch.licensenumber1,
        licenseimage1: branch.licenseimage1,
        licenseremark1: branch.licenseremark1,
        lis_status_factory: branch.lis_status_factory,
        dor1: formatDateToInput(branch.dor1),
        doe1: formatDateToInput(branch.doe1),
        doddr1: formatDateToInput(branch.doddr1),
        managernamelicense1: branch.managernamelicense1,
        managerlicenseimage1: branch.managerlicenseimage1,
        managerlicenseremark1: branch.managerlicenseremark1,
        noe1: branch.noe1,
        nom1: branch.nom1,
        nof1: branch.nof1,
        issuingauth1: branch.issuingauth1,
        doeimage1: branch.doeimage1,
        issuingauthremark1: branch.issuingauthremark1,
        licensenumber2: branch.licensenumber2,
        licenseimage2: branch.licenseimage2,
        licenseremark2: branch.licenseremark2,
        dor2: formatDateToInput(branch.dor2),
        issuingauth2: branch.issuingauth2,
        doeimage2: branch.doeimage2,
        issuingauthremark2: branch.issuingauthremark2,
        dateofclosure: formatDateToInput(branch.dateofclosure),
        powerHP: branch.powerHP,
        powerHPremark2: branch.powerHPremark2,
        powerKW: branch.powerKW,
        powerKWremark2: branch.powerKWremark2,
        ApprovedPower: branch.ApprovedPower,
        ApprovedPowerremark2: branch.ApprovedPowerremark2,
        licensenumberNSP: branch.licensenumberNSP,
        licenseimageNSP: branch.licenseimageNSP,
        licenseremarkNSP: branch.licenseremarkNSP,
        dorNSP: formatDateToInput(branch.dorNSP),
        doeNSP: formatDateToInput(branch.doeNSP),
        doddrNSP: formatDateToInput(branch.doddrNSP),
        issuingauthNSP: branch.issuingauthNSP,
        issuingauthdetailsNSP: branch.issuingauthdetailsNSP,
        doeimageNSP: branch.doeimageNSP,
        issuingauthremarkNSP: branch.issuingauthremarkNSP,
        licensenumberOTP: branch.licensenumberOTP,
        licenseimageOTP: branch.licenseimageOTP,
        licenseremarkOTP: branch.licenseremarkOTP,
        dorOTP: formatDateToInput(branch.dorOTP),
        doeOTP: formatDateToInput(branch.doeOTP),
        doddrOTP: formatDateToInput(branch.doddrOTP),
        issuingauthOTP: branch.issuingauthOTP,
        issuingauthdetailsOTP: branch.issuingauthdetailsOTP,
        doeimageOTP: branch.doeimageOTP,
        issuingauthremarkOTP: branch.issuingauthremarkOTP,
        licensenumberTL: branch.licensenumberTL,
        licenseimageTL: branch.licenseimageTL,
        licenseremarkTL: branch.licenseremarkTL,
        dorTL: formatDateToInput(branch.dorTL),
        doeTL: formatDateToInput(branch.doeTL),
        doddrTL: formatDateToInput(branch.doddrTL),
        issuingauthTL: branch.issuingauthTL,
        issuingauthdetailsTL: branch.issuingauthdetailsTL,
        doeimageTL: branch.doeimageTL,
        issuingauthremarkTL: branch.issuingauthremarkTL,
        licensenumberWOE: branch.licensenumberWOE,
        licenseimageWOE: branch.licenseimageWOE,
        licenseremarkWOE: branch.licenseremarkWOE,
        dorWOE: formatDateToInput(branch.dorWOE),
        doeWOE: formatDateToInput(branch.doeWOE),
        doddrWOE: formatDateToInput(branch.doddrWOE),
        issuingauthWOE: branch.issuingauthWOE,
        issuingauthdetailsWOE: branch.issuingauthdetailsWOE,
        doeimageWOE: branch.doeimageWOE,
        issuingauthremarkWOE: branch.issuingauthremarkWOE,
        isFactoryOrSE: branch.isFactoryOrSE,
        isContracorsYesorNo: branch.isContracorsYesorNo,
        isNightShiftPermission: branch.isNightShiftPermission,
        isOTPermission: branch.isOTPermission,
        isWeeklyOffExemption: branch.isWeeklyOffExemption,
        isTradeLicense: branch.isTradeLicense,
        lis_statusNSP: branch.lis_statusNSP,
        lis_statusTL: branch.lis_statusTL,
        lis_statusOTP: branch.lis_statusOTP,
        lis_statusWOE: branch.lis_statusWOE,
        issuingauthimage: branch.issuingauthimage,
        issuingauthimage1: branch.issuingauthimage1,
        issuingauthimageNSP: branch.issuingauthimageNSP,
        issuingauthimageWOE: branch.issuingauthimageWOE,
        issuingauthimageOTP: branch.issuingauthimageOTP,
        issuingauthimageTL: branch.issuingauthimageTL,

        pfnumber: branch.pfnumber,
        pfimage: branch.pfimage,
        pfdremark: branch.pfdremark,
        doc: formatDateToInput(branch.doc),
        pfstate: branch.pfstate,
        pfdistrict: branch.pfdistrict,
        pfpin: branch.pfpin,
        pfaddress: branch.pfaddress,
        pfaddressimage: branch.pfaddressimage,
        pfaddressremark: branch.pfaddressremark,
        esinumber: branch.esinumber,
        esiimage: branch.esiimage,
        esidremark: branch.esidremark,
        esidoc: formatDateToInput(branch.esidoc),
        esiaddress: branch.esiaddress,
        esistate: branch.esistate,
        esidistrict: branch.esidistrict,
        esipin: branch.esipin,
        esiaddressimage: branch.esiaddressimage,
        esiaddressremark: branch.esiaddressremark,
        registrationlwfD3: branch.registrationlwfD3,
        registrationlwfD3image: branch.registrationlwfD3image,
        registrationlwfD3remark: branch.registrationlwfD3remark,
        doregistrationlwfD3: formatDateToInput(branch.doregistrationlwfD3),
        registrationptrD3: branch.registrationptrD3,
        registrationptrD3image: branch.registrationptrD3image,
        registrationptrD3remark: branch.registrationptrD3remark,
        doregistrationptrD3: formatDateToInput(branch.doregistrationptrD3),
        registrationPTED3: branch.registrationPTED3,
        registrationPTED3image: branch.registrationPTED3image,
        registrationPTED3remark: branch.registrationPTED3remark,
        doregistrationPTED3: formatDateToInput(branch.doregistrationPTED3),
        licensenumberMSME: branch.licensenumberMSME,
        licenseimageMSME: branch.licenseimageMSME,
        licenseremarkMSME: branch.licenseremarkMSME,
        dorMSME: formatDateToInput(branch.dorMSME),
        issuingauthMSME: branch.issuingauthMSME,
        issuingauthimageMSME: branch.issuingauthimageMSME,
        issuingauthremarkMSME: branch.issuingauthremarkMSME,
        licensenumberBOCW: branch.licensenumberBOCW,
        licenseimageBOCW: branch.licenseimageBOCW,
        licenseremarkBOCW: branch.licenseremarkBOCW,
        dorBOCW: formatDateToInput(branch.dorBOCW),
        doeBOCW: formatDateToInput(branch.doeBOCW),
        ackFileBOCW: branch.ackFileBOCW,
        reasonBOCW: branch.reasonBOCW,
        doddrBOCW: formatDateToInput(branch.doddrBOCW),
        noeBOCW: branch.noeBOCW,
        nomBOCW: branch.nomBOCW,
        nofBOCW: branch.nofBOCW,
        issuingauthBOCW: branch.issuingauthBOCW,
        issuingauthimageBOCW: branch.issuingauthimageBOCW,
        issuingauthremarkBOCW: branch.issuingauthremarkBOCW,
        licensenumberISMW: branch.licensenumberISMW,
        licenseimageISMW: branch.licenseimageISMW,
        licenseremarkISMW: branch.licenseremarkISMW,
        dorISMW: formatDateToInput(branch.dorISMW),
        ackFileISMW: branch.ackFileISMW,
        reasonISMW: branch.reasonISMW,
        doeISMW: formatDateToInput(branch.doeISMW),
        doddrISMW: formatDateToInput(branch.doddrISMW),
        noeISMW: branch.noeISMW,
        nomISMW: branch.nomISMW,
        nofISMW: branch.nofISMW,
        issuingauthISMW: branch.issuingauthISMW,
        issuingauthdetailsISMW: branch.issuingauthdetailsISMW,
        issuingauthimageISMW: branch.issuingauthimageISMW,
        issuingauthremarkISMW: branch.issuingauthremarkISMW,
        licensenumberFASSAI: branch.licensenumberFASSAI,
        licenseimageFASSAI: branch.licenseimageFASSAI,
        licenseremarkFASSAI: branch.licenseremarkFASSAI,
        dorFASSAI: formatDateToInput(branch.dorFASSAI),
        doeFASSAI: formatDateToInput(branch.doeFASSAI),
        doddrFASSAI: formatDateToInput(branch.doddrFASSAI),
        issuingauthFASSAI: branch.issuingauthFASSAI,
        issuingauthdetailsFASSAI: branch.issuingauthdetailsFASSAI,
        ackFileFASSAI: branch.ackFileFASSAI,
        reasonFASSAI: branch.reasonFASSAI,
        issuingauthremarkFASSAI: branch.issuingauthremarkFASSAI,
        licensenumberSB: branch.licensenumberSB,
        licenseimageSB: branch.licenseimageSB,
        licenseremarkSB: branch.licenseremarkSB,
        dorSB: formatDateToInput(branch.dorSB),
        doeSB: formatDateToInput(branch.doeSB),
        doddrSB: formatDateToInput(branch.doddrSB),
        issuingauthSB: branch.issuingauthSB,
        issuingauthdetailsSB: branch.issuingauthdetailsSB,
        ackFileSB: branch.ackFileSB,
        reasonSB: branch.reasonSB,
        issuingauthremarkSB: branch.issuingauthremarkSB,
        is_contract: branch.is_contract,
        contractLabourRegistration: branch.contractLabourRegistration,
        contractLabourRegistrationFile: branch.contractLabourRegistrationFile,
        contractLabourRegistrationRemarks: branch.contractLabourRegistrationRemarks,
        contractLabourRegistrationNumber: branch.contractLabourRegistrationNumber,
        dateOfContractReg: formatDateToInput(branch.dateOfContractReg),
        nOofContractors: branch.nOofContractors,
        nOofContractEmp: branch.nOofContractEmp,
        dateofContractSur: formatDateToInput(branch.dateofContractSur),
        isPF: branch.isPF,
        isESI: branch.isESI,
        isLWF: branch.isLWF,
        isPTR: branch.isPTR,
        isPTE: branch.isPTE,
        isMSME: branch.isMSME,
        isBOCW: branch.isBOCW,
        isISMW: branch.isISMW,
        isFASSAI: branch.isFASSAI,
        isSB: branch.isSB,
        lis_status_pf: branch.lis_status_pf,
        lis_status_esi: branch.lis_status_esi,
        lis_status_lwf: branch.lis_status_lwf,
        lis_status_ptr: branch.lis_status_ptr,
        lis_status_pte: branch.lis_status_pte,
        lis_status_msme: branch.lis_status_msme,
        lis_status_bocw: branch.lis_status_bocw,
        lis_status_ismw: branch.lis_status_ismw,
        lis_status_fassai: branch.lis_status_fassai,
        lis_status_sb: branch.lis_status_sb,
        isActive: branch.isActive,
      }));

      setDataSource(branchArr);
      setFilteredData(branchArr);
    }
  }, [branches]);


  const formatDate = (currentDate) => {
    const date = new Date(currentDate);
    return date.toLocaleString();
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const openInPopupForUpdate = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
    setPageTitle("View/Edit Branch");
    setModalWidth("800px");
  };

  const addOrEdit = (values) => {
    const updatedData = dataSource.map((branch) =>
      branch.id === values.id ? { ...branch, ...values } : branch
    );
    setDataSource(updatedData);
    setFilteredData(updatedData);
    setOpenPopup(false);
  };

  const handleStatusChange = (event, record) => {
    const value = Number(event.target.value); // Convert to number
    console.log("Selected Value:", value);
    console.log("Record ID:", record.id);

    const postbody = { isActive: value };

    dispatch(branchStatusUpdate(postbody, record.id));
    dispatch(AllbranchesGet());
    // dispatch(TableBranchesGet());

  };
  const getStatusColor = (value) => {
    switch (value) {
      case 0:
        return { backgroundColor: "#ff4d4d", color: "white" }; // Modern Red
      case 1:
        return { backgroundColor: "#28a745", color: "white" }; // Modern Green
      case 2:
        return { backgroundColor: "#ffc107", color: "black" }; // Modern Yellow
      default:
        return {};
    }
  };

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "key",
      key: "key",
      width: 80,
    },
    {
      title: "Branch Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      ...getColumnSearchProps("company"),
    },
    {
      title: "State",
      dataIndex: "branchstate",
      key: "branchstate",
      ...getColumnSearchProps("branchstate"),
    },
    {
      title: "Active Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (text, record) => (
        <select
          value={record.isActive}
          onChange={(event) => handleStatusChange(event, record)}
          style={{
            width: "auto",
            padding: "8px",
            textAlign:'center',
            fontWeight: "bold",
            borderRadius: "5px",
            border: "1px solid #ccc",
            cursor: "pointer",
            outline: "none",
            ...getStatusColor(record.isActive), // Apply background to selected option
          }}
          className="status-dropdown"
        >
          <option style={{ backgroundColor: "#ff4d4d", color: "white" }} value={0} className="option-red">
            Not Active
          </option>
          <option style={{ backgroundColor: "#28a745", color: "white" }} value={1} className="option-green">
            Active
          </option>
          <option style={{ backgroundColor: "#ffc107", color: "black" }} value={2} className="option-yellow">
            Partial Active
          </option>
        </select>
      ),
    },

    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      key: "action",
      title: "Actions",
      width: 170,
      render: (record) => {
        return (
          <>
            <Link
              className="text-white btn btn-primary text-decoration-none mx-2"
              onClick={() => openInPopupForUpdate(record)}
            >
              View/Edit <EditOutlined />
            </Link>
          </>
        );
      },
    },
  ];



  return (
    <React.Fragment>

      <div className="card p-3">
        <div className="table-responsive">
          <Table
            columns={columns}
            dataSource={filteredData}
            pagination={{ pageSize: 50, showSizeChanger: false, position: ["bottomCenter"] }}
            scroll={{ x: 1000 }}
            sticky={true}
          />
        </div>
      </div>
      <Popup openPopup={openPopup} pageTitle={pageTitle} setOpenPopup={setOpenPopup} modalWidth={modalWidth}>
        {openPopup && <TestBranch addOrEdit={addOrEdit} recordForEdit={recordForEdit} setOpenPopup={setOpenPopup} />}
      </Popup>
    </React.Fragment>
  );
};

export default BranchTables;
