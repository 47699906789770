// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiDialogTitle-root+.css-o1kxqj-MuiDialogContent-root{
    width: auto !important;
}
.css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
    width: auto !important;
    text-align: center !important;

}
/* hr{
    height: 10vh !important;
} */
.desc{
    width: 615% !important;
    min-height: 70px !important;
}
`, "",{"version":3,"sources":["webpack://./src/css/Pop.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;AACA;IACI,sBAAsB;IACtB,6BAA6B;;AAEjC;AACA;;GAEG;AACH;IACI,sBAAsB;IACtB,2BAA2B;AAC/B","sourcesContent":[".MuiDialogTitle-root+.css-o1kxqj-MuiDialogContent-root{\n    width: auto !important;\n}\n.css-bdhsul-MuiTypography-root-MuiDialogTitle-root {\n    width: auto !important;\n    text-align: center !important;\n\n}\n/* hr{\n    height: 10vh !important;\n} */\n.desc{\n    width: 615% !important;\n    min-height: 70px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
