import React, { useEffect, useState ,useRef} from "react";
import { Table, Pagination, DatePicker, Select, Spin } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchDueDates, stateGets, DueDatesDeleteById, getLabourContractorTable, deleteLabourContractorById } from '../../../store/actions/otherActions';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2'; // Import SweetAlert2
import CreateLabourCont from './CreateLabourCont';
import Popup from "../../../components/Popup";
import { SearchOutlined } from "@ant-design/icons";
import { Input, Button } from "antd";
import Highlighter from "react-highlight-words";

const { RangePicker } = DatePicker;
const { Option } = Select;

const ViewAllContractors = () => {
    const dispatch = useDispatch();
    const { dueDates, loading, totalCount, totalPages, currentPage } = useSelector(state => state.dueDateRed);

    const getState = useSelector((state) => state.getState);
    const { stateInfo } = getState;
    const getLabourContractorRed = useSelector((state) => state.getLabourContractorRed);
    const { getLabourContractorInfo } = getLabourContractorRed;
    const { getLabourContractorInfoData } = getLabourContractorRed;
    // console.log("getLabourContractorInfoData", getLabourContractorInfoData);

    const [dateRange, setDateRange] = useState([null, null]);
    const [state, setState] = useState("");
    const [pageSize, setPageSize] = useState("");
    const [openPopup, setOpenPopup] = useState(false);
    const [pageTitle, setPageTitle] = useState("");
    const [modalWidth, setModalWidth] = useState();
    const [recordForEdit, setRecordForEdit] = useState(null);
    const [dataSource, setDataSource] = useState([]);
    // console.log("recordForEdit", recordForEdit);

    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex, setSearchText, setSearchedColumn) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters, setSearchText) => {
        clearFilters();
        setSearchText("");
    };

    useEffect(() => {
        dispatch(stateGets());
        dispatch(getLabourContractorTable());
    }, [dispatch, currentPage]);

    const formatDateToInput = (isoDate) => {
        if (!isoDate) return ""; // Return an empty string for empty or invalid dates
        const date = new Date(isoDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero
        const day = String(date.getDate()).padStart(2, "0"); // Add leading zero
        return `${day}/${month}/${year}`;
    };

    const handleDelete = async (id) => {
        if (!id) return;

        try {
            // Custom confirmation dialog
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!',
                reverseButtons: true,
            });

            if (result.isConfirmed) {
                // Proceed with deletion
                await dispatch(deleteLabourContractorById(id));

                Swal.fire({
                    icon: 'success',
                    title: 'Deleted!',
                    text: 'Labour Contractor deleted successfully.',
                });

                // ✅ Re-fetch data using **current filters**
                dispatch(getLabourContractorTable({
                    state: state || "", // ✅ Ensure the current state filter is used
                    page: currentPage,  // ✅ Keep the same page number
                    limit: 50,          // ✅ Maintain the same pagination limit
                }));
            }
        } catch (error) {
            console.error("Delete Error:", error);
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: error?.message || 'An unexpected error occurred.',
            });
        }
    };

    const getColumnSearchProps = (dataIndex, searchInput, setSearchText, setSearchedColumn, searchedColumn, searchText) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex, setSearchText, setSearchedColumn)}
                    style={{ marginBottom: 8, display: "block" }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex, setSearchText, setSearchedColumn)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button
                    onClick={() => handleReset(clearFilters, setSearchText)}
                    size="small"
                    style={{ width: 90 }}
                >
                    Reset
                </Button>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : false,
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });

    const handlePaginationChange = (page, pageSize = 50) => {
        dispatch(getLabourContractorTable({
            state: state || "", // ✅ Send only ONE state ID
            page,
            limit: pageSize,
        }));
    };




    const formattedLCInfo = getLabourContractorInfoData?.map((item, index) => ({
        ...item,
        key: index + 1, // Ensure unique keys
    })) || [];



    const columns = [
        {
            title: "Sr. No.",
            dataIndex: "key",
            key: "key",
            width: 80,
        },
        {
            title: "Name",
            dataIndex: "contractorName",
            key: "contractorName",
            ...getColumnSearchProps("contractorName", searchInput, setSearchText, setSearchedColumn, searchedColumn, searchText),
        },
        {
            title: "State",
            dataIndex: "state",
            key: "state",
        },
        {
            title: "Establishment Name",
            dataIndex: "establishmentName",
            key: "establishmentName",
            ...getColumnSearchProps("establishmentName", searchInput, setSearchText, setSearchedColumn, searchedColumn, searchText),
        },
        {
            title: "Email",
            dataIndex: "contEmail",
            key: "contEmail",
        },
        {
            title: "Contact No.",
            dataIndex: "contPhone" || "Not Available",
            key: "contPhone",
        },
        {
            title: "Created Date",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (createdAt) => formatDateToInput(createdAt),
        },
        {
            key: "action",
            title: "Actions",
            width: 250,
            render: (record) => (
                <>
                    <Link
                        className="text-white btn btn-primary text-decoration-none mx-2"
                        onClick={() => openInPopupForUpdate(record)}
                    >
                        View/Edit <EditOutlined />
                    </Link>
                    <Link className="btn btn-danger mx-2" onClick={() => handleDelete(record._id)}>
                        <DeleteOutlined />
                    </Link>
                </>
            ),
        },
    ];
    useEffect(() => {
        dispatch(getLabourContractorTable({
            state: state || "", // ✅ Ensure only one state ID is sent
            page: currentPage,
            limit: 50,
        }));
    }, [dispatch, currentPage, state]);


    const openInPopupForUpdate = (item) => {
        console.log("item", item);

        setRecordForEdit(item);
        setOpenPopup(true);
        setPageTitle("View/Edit Labour Contractors");
        setModalWidth("800px");
    };

    

    return (
        <React.Fragment>
            <div className="container-fluid">
                {/* Filters Section */}
                <div className="row g-3 mb-3 pt-1 align-items-end">
                    {/* State Filter */}
                    <div>
                        <label htmlFor="state" className="form-label fw-semibold">State Filter</label>
                        <select
                            className="form-select"
                            id="state"
                            value={state}
                            onChange={(e) => {
                                setState(e.target.value || ""); // ✅ Store only one state ID
                                handlePaginationChange(1, 50); // ✅ Reset pagination to page 1
                            }}
                        >
                            <option value="">Select State</option>
                            {stateInfo?.map((item) => (
                                <option key={item._id} value={item._id}>
                                    {item.name}
                                </option>
                            ))}
                        </select>
                    </div>


                </div>

                {/* Table Section */}
                <div className="table-responsive">
                    {loading ? (
                        <div className="d-flex justify-content-center align-items-center" style={{ height: "500px" }}>
                            <Spin size="large" />
                        </div>
                    ) : (
                        <Table
                            columns={columns}
                            dataSource={formattedLCInfo}
                            pagination={false}
                            rowKey="key"
                            sticky
                        />
                    )}
                </div>

                {/* Pagination */}
                <div className="d-flex justify-content-center mt-3">
                    <Pagination
                        current={currentPage}
                        total={totalCount}
                        pageSize={50}  // Ensure it's set
                        onChange={(page) => handlePaginationChange(page, 50)}
                        showSizeChanger={false}
                    />
                </div>

            </div>
            <Popup openPopup={openPopup} pageTitle={pageTitle} setOpenPopup={setOpenPopup} modalWidth={modalWidth}>
                {openPopup && <CreateLabourCont recordForEdit={recordForEdit} setOpenPopup={setOpenPopup} />}
            </Popup>
        </React.Fragment>
    );
};

export default ViewAllContractors;
